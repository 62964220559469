export interface Project {
    id: string
    name: string
    createdAt: Date
    lastModifiedAt: Date
    /**
     * Base url for the API without '/' (slash) character. This url will be
     * used at the beginning for all endpoints for the project.
     */
    baseUrl: string
    /**
     * Ordered list of API collections.
     */
    collections: Collection[]
    /**
     * Defines whether this project is shared for view via share link
     */
    isSharedForView: boolean
    /**
     * Share link for viewing this project
     */
    shareLink: string
    /**
     * Holds constant for project card background
     */
    backgroundImage?: BackgroundImage
    /**
     * Code shared by all project endpoints
     */
    sharedCode: SharedCode[]
    /**
     * Logged user's role in context of shared project
     */
    loggedUserShareRole?: ShareRoles
    openApiSettings: OpenApiSettings
}

export interface OpenApiSettings {
    /**
     * This says whether the Date type should be generated with open api format
     * 'date' or 'date-time'
     */
    dateType: 'date' | 'date-time'
}

export type BackgroundImage = 'orange' | 'yellow' | 'red' | 'green' | 'black' | 'blue' | 'turquoise' | 'purple'

export type ProgressState = 'notStarted' | 'inProgress' | 'done'
export type ProgressDomain = 'backend' | 'frontend' | 'e2e'

/**
 * Represents collection of APIs regarding a specific area (like user, articles etc.)
 */
export interface Collection {
    id: string
    name: string
    description: string
    endpoints: Endpoint[]
}

/**
 * Definition for code shared by all project endpoints
 */
export interface SharedCode {
    id: string
    /**
     * Name of the file and tab
     */
    name: string
    /**
     * Piece of code in JSON escaped format
     */
    content: string
}

/**
 * Definition for a specific HTTP endpoint.
 */
export interface Endpoint {
    id: string
    name: string
    description: string
    requestType: RequestType
    requestUrl: string
    bodyRequest?: BodyRequestDefinition
    queryParameters?: UrlRequestDefinition
    pathParameters?: UrlRequestDefinition
    response: ResponseDefinition[]

    /**
     * Every endpoint can be in different progress state for different domain, for example:
     * Done on backend, In progress on frontend, Not started on e2e tests
     */
    progressState: Record<ProgressDomain, ProgressState>
}

export interface BodyRequestDefinition {
    /**
     * Holds the request as a text format which can be then displayed
     * and edited by the user. Typically Typescript definition
     */
    stringFormat: string
}

export interface UrlRequestDefinition {
    parameters: UrlRequestParameter[]
}

export interface UrlRequestParameter {
    /**
     * Name of the parameter.
     */
    name: string
    /**
     * Data type of the parameter
     */
    type: 'string' | 'number' | 'boolean'
    /**
     * Holds small description of param
     */
    description?: string
}

export type DotColors = 'green' | 'red' | 'yellow' | 'orange' | 'white'

/**
 * User's role in context of shared project
 */
export type ShareRoles = 'view' | 'edit' | 'owner' | 'link'

export interface ResponseDefinition {
    httpCode: number
    /**
     * Holds the response as a text format which can be then displayed
     * and edited by the user. Typically Typescript definition
     */
    stringFormat: string
    dotColor: DotColors
}

export enum RequestType {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE',
}
