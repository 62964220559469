import { Box, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import ThemeSwitcher from './ThemeSwitcher'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import Text from '../components/Text'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedThemeType } from 'services/selectors/userSettings-selectors'
import { setSelectedThemeType } from '../services/actions/userSettings-actions'

const PREFIX = 'ProfileDropdown'

const classes = {
    container: `${PREFIX}-container`,
    secondMenuItem: `${PREFIX}-secondMenuItem`,
    thirdMenuItem: `${PREFIX}-thirdMenuItem`,
    emailText: `${PREFIX}-emailText`,
    logoutIcon: `${PREFIX}-logoutIcon`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        height: 201,
        width: 320,
        backgroundColor: theme.palette.additional.add1,
        filter: theme.palette.dropdown.filter,
        borderRadius: 8,
        marginTop: 20,
    },

    [`& .${classes.secondMenuItem}`]: {
        height: 74,
        width: 320,
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    },

    [`& .${classes.thirdMenuItem}`]: {
        flex: 1,
        display: 'flex',
        height: 47,
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
    },

    [`& .${classes.emailText}`]: {
        marginLeft: 50,
        marginTop: 6,
        userSelect: 'none',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        marginRight: 24,
    },

    [`& .${classes.logoutIcon}`]: {
        marginLeft: 16,
        marginRight: 10,
        color: theme.palette.alert.main,
        cursor: 'pointer',
    },
}))

interface ProfileDropdownProps {
    email: string
    onLogout: () => void
    onProfilePress: () => void
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ email, onLogout, onProfilePress }) => {
    const theme = useTheme()

    const dispatch = useDispatch()
    const themeType = useSelector(getSelectedThemeType)

    const onThemeToggle = () => {
        if (themeType === 'light') {
            dispatch(setSelectedThemeType('dark'))
        }
        if (themeType === 'dark') {
            dispatch(setSelectedThemeType('auto'))
        }
        if (themeType === 'auto') {
            dispatch(setSelectedThemeType('light'))
        }
    }

    return (
        <StyledBox className={classes.container}>
            <ThemeSwitcher selectedThemeStyle={themeType} toggleTheme={onThemeToggle} />
            <Box className={classes.secondMenuItem} onClick={onProfilePress}>
                <Box style={{ height: 14 }} />
                <Box style={{ display: 'flex', flexDirection: 'row', marginLeft: 16 }}>
                    <PersonOutlineRoundedIcon style={{ color: theme.palette.text.faded }} />
                    <Text fontSize={16} style={{ marginLeft: 10, userSelect: 'none' }}>
                        Profile
                    </Text>
                </Box>
                <Text fontSize={14} className={classes.emailText}>
                    {email}
                </Text>
            </Box>
            <Box className={classes.thirdMenuItem}>
                <LogoutOutlinedIcon className={classes.logoutIcon} />
                <Text fontSize={16} style={{ userSelect: 'none' }} onClick={onLogout}>
                    Logout
                </Text>
            </Box>
        </StyledBox>
    )
}

export default ProfileDropdown
