export const propertyOf = <TObj>(name: keyof TObj): keyof TObj => name

export const delay = (ms: number): Promise<unknown> => {
    return new Promise((res) => setTimeout(res, ms))
}

export const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
    return value !== null && value !== undefined
}

export const hasDuplicates = (array: string[]): boolean => new Set(array).size !== array.length

export const trimString = (string: string, length: number): string =>
    string.length > length ? string.substring(0, length) + '...' : string

export const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) return error.message
    return String(error)
}

/**
 * Returns the current time as UTC seconds.
 * E.g. If it's GMT Sunday, June 12, 2022 2:13:20 AM the method returns 1655000000
 */
export const getCurrentTimestampInSeconds = () => {
    return Math.floor(new Date().getTime() / 1000.0)
}
