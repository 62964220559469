import { styled } from '@mui/material/styles'
import { Box, useTheme, Typography, TextField, Button, CircularProgress } from '@mui/material'
import { Project, SharedCode } from 'shared/types/project-types'
import Text from 'components/Text'
import { useEffect, useState } from 'react'
import Fonts from 'constants/fonts'
import { MAIN_BORDER_RADIUS } from 'constants/ui'
import { useDispatch } from 'react-redux'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { updateSharedCodeName } from 'services/actions/project-actions'
import React from 'react'
import GradientButton from 'components/GradientButton'

const PREFIX = 'NameUpdateForm'

const classes = {
    tabNameTextfield: `${PREFIX}-tabNameTextfield`,
    tabNameInput: `${PREFIX}-tabNameInput`,
    deleteButton: `${PREFIX}-deleteButton`,
    deleteTitle: `${PREFIX}-deleteTitle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.tabNameTextfield}`]: {
        backgroundColor: theme.palette.forms.inputBackgroundMain,
        border: 'none',
        color: theme.palette.text.normal,
        borderRadius: 4,
        '& .MuiOutlinedInput-root': {
            '&:hover': {
                backgroundColor: theme.palette.input.hoverBackground,
            },
            '&:hover fieldset': {
                border: `2px solid ${theme.palette.primary.light}`,
            },
            '&.Mui-focused': {
                backgroundColor: theme.palette.input.hoverBackground,
            },
            '&.Mui-focused fieldset': {
                border: `2px solid ${theme.palette.primary.light}`,
            },
            '&.Mui-disabled fieldset': {
                border: `2px solid ${theme.palette.neutral.neutral2}`,
            },
        },
    },

    [`& .${classes.tabNameInput}`]: {
        border: 'none',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        color: theme.palette.text.normal,
        fontSize: Fonts.size.normal,
        caretColor: theme.palette.primary.light,
    },

    [`& .${classes.deleteButton}`]: {
        color: theme.palette.text.normal,
        fontWeight: 'normal',
        height: 52,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.dropdown.hover,
            fontWeight: 'bold',
        },
    },

    [`& .${classes.deleteTitle}`]: {
        height: 52,
        display: 'flex',
        fontSize: 16,
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 'bold',
            color: theme.palette.dropdown.titleHover,
        },
    },
}))

interface NameUpdateFormProps {
    sharedCode: SharedCode
    onDeleteTab: () => void
    activeProject?: Project
    closeForm: () => void
    isVisible: boolean
    isMobileView: boolean
    checkIfNameElementIsOverflown: () => void
    onNameUpdated: () => void
}

const NameUpdateForm: React.FC<NameUpdateFormProps> = ({
    sharedCode,
    onDeleteTab,
    activeProject,
    closeForm,
    checkIfNameElementIsOverflown,
    isVisible,
    isMobileView,
    onNameUpdated,
}) => {
    const theme = useTheme()

    const dispatch = useDispatch()
    const [newName, setNewName] = useState(sharedCode.name)
    const [isDuplicateNameErrorVisible, setIsDuplicateNameErrorVisible] = useState(false)
    const [isNewNameSaving, setIsNewNameSaving] = useState(false)

    useEffect(() => {
        if (isVisible) {
            setIsDuplicateNameErrorVisible(false)
            setNewName(sharedCode.name)
        }
    }, [isVisible])

    const onSaveNewName = () => {
        if (newName === sharedCode.name) return

        if (!newName || newName === '' || newName === null) {
            setNewName(sharedCode.name)
            return
        }
        if (activeProject) {
            if (doesExistSharedCodeWithSameNameInActiveProject()) {
                setIsDuplicateNameErrorVisible(true)
                return
            }

            setIsNewNameSaving(true)
            dispatch(updateSharedCodeName(sharedCode.id, newName, activeProject))
            onNameUpdated()
            setIsNewNameSaving(false)
            closeForm()
            // Wait, so the DOM is updated
            setTimeout(() => checkIfNameElementIsOverflown(), 50)
        }

        return
    }

    const doesExistSharedCodeWithSameNameInActiveProject = (name?: string): boolean => {
        if (name === undefined) name = newName

        if (!activeProject) return false
        return activeProject.sharedCode.findIndex((sc) => sc.id !== sharedCode.id && sc.name === name) !== -1
    }

    return (
        <StyledBox
            style={{
                borderRadius: 8,
                boxShadow: theme.palette.shadow.main,
                overflow: 'hidden',
            }}
        >
            {isMobileView && (
                <Box style={{ backgroundColor: theme.palette.primary.main, paddingBlock: 12, paddingInline: 16 }}>
                    <Text color='normal' fontSize={14}>
                        Edit {sharedCode.name}
                    </Text>
                </Box>
            )}
            <Box
                style={{
                    paddingInline: theme.spacing(2),
                    paddingTop: theme.spacing(1.5),
                    paddingBottom: theme.spacing(1),
                    backgroundColor: theme.palette.additional.add1,
                }}
            >
                <TextField
                    size='small'
                    autoFocus
                    placeholder='Name'
                    value={newName}
                    onChange={(e) => {
                        if (e.target.value.includes(' ')) return
                        const newValue = e.target.value
                        setNewName(newValue)

                        if (doesExistSharedCodeWithSameNameInActiveProject(newValue)) {
                            setIsDuplicateNameErrorVisible(true)
                        } else {
                            setIsDuplicateNameErrorVisible(false)
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onSaveNewName()
                        }
                        if (e.key === 'Escape') {
                            closeForm()
                        }
                    }}
                    className={classes.tabNameTextfield}
                    inputProps={{
                        className: classes.tabNameInput,
                    }}
                    onFocus={(event) => {
                        event.target.select()
                    }}
                    onBlur={() => {
                        if (!isMobileView) onSaveNewName()
                    }}
                />
                <Text
                    color={isDuplicateNameErrorVisible ? theme.palette.alert.main : theme.palette.text.light}
                    fontSize={12}
                    style={{ paddingTop: theme.spacing(1), opacity: isDuplicateNameErrorVisible || isNewNameSaving ? 1 : 0 }}
                >
                    {isDuplicateNameErrorVisible ? (
                        'This name already exists.'
                    ) : isNewNameSaving ? (
                        <>
                            <CircularProgress size={12} sx={{ marginRight: theme.spacing(1) }} /> Updating...
                        </>
                    ) : (
                        'PLACEHOLDER'
                    )}
                </Text>
            </Box>
            {isMobileView && (
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        backgroundColor: theme.palette.additional.add1,
                        paddingBottom: 14,
                    }}
                >
                    <Button variant='text' onClick={onDeleteTab} style={{ color: theme.palette.alert.main }}>
                        Delete
                    </Button>

                    <Button variant='text' onClick={closeForm}>
                        Cancel
                    </Button>
                    <GradientButton title='Save' onClick={onSaveNewName} />
                </Box>
            )}
            {!isMobileView && (
                <Box
                    className={classes.deleteButton}
                    style={{ borderTopRightRadius: MAIN_BORDER_RADIUS, borderTopLeftRadius: MAIN_BORDER_RADIUS }}
                    onClick={onDeleteTab}
                >
                    <DeleteOutlineIcon sx={{ marginLeft: 2, color: theme.palette.alert.main }} />
                    <Typography className={classes.deleteTitle}>Delete tab</Typography>
                </Box>
            )}
        </StyledBox>
    )
}

export default NameUpdateForm
