export enum RouteConstants {
    home = '/',
    content = '/content',
    surveys = '/surveys',
    plan = '/plan',
    user = '/user',
    profile = '/profile',
    project = '/project',
    projectLoading = '/load/project',
    projectsList = '/projects',
    unauthenticated = '/unauthenticated',
    shared = '/shared',
    projectDetails = '/projectDetails',
    login = '/login',
}

export interface LoadProjectRouteParams {
    projectId: string
}

export interface LoadProjectState {
    originalPath: string
}

export interface RouteParameters {
    projectId: string
    collectionId: string
    endpointId: string
}
