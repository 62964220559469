import { Box, Stack, useTheme } from '@mui/material'
import GradientButton from 'components/GradientButton'
import Text from 'components/Text'
import Registration from '../../assets/images/auth/registration/Registration.png'
import Registration2x from '../../assets/images/auth/registration/Registration2x.png'
import Registration3x from '../../assets/images/auth/registration/Registration3x.png'
import useIsMobileView from 'hooks/useIsMobileView'
import { useState } from 'react'
import { EMAIL_REGEX } from 'constants/ui'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import StyledTextField from 'components/StyledTextField'
import { delay } from 'shared/utils/generic-utils'
import { UserManager } from 'services/api/UserManager'
import { AUTH_LOCAL_STORAGE_KEY, parseJwtEncodedTokenAndGetReduxState } from 'services/types/auth-types'
import { setAuthState } from 'services/actions/auth-actions'
import { useDispatch } from 'react-redux'
import { RouteConstants } from 'navigation/navigation-types'
import { useHistory } from 'react-router-dom'
import { showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'

interface RegistrationFormProps {
    toggleToLoginMode: () => void
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ toggleToLoginMode }) => {
    const theme = useTheme()
    const isMobileView = useIsMobileView(theme)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const [email, setEmail] = useState('')
    const [isEmailInErrorState, setIsEmailInErrorState] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState('')

    const [password, setPassword] = useState('')
    const [isPasswordInErrorState, setIsPasswordInErrorState] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('')

    const [passwordRepeated, setPasswordRepeated] = useState('')
    const [isPasswordRepeatedInErrorState, setIsPasswordRepeatedInErrorState] = useState(false)
    const [passwordRepeatedErrorMessage, setPasswordRepeatedErrorMessage] = useState('')

    const handleEmailChange = (email: string) => {
        setEmail(email)
    }

    const onRegister = async () => {
        setIsEmailInErrorState(false)
        setIsPasswordInErrorState(false)
        setIsPasswordRepeatedInErrorState(false)

        let isFormInErrorState = false

        if (email.length === 0) {
            setIsEmailInErrorState(true)
            setEmailErrorMessage('Fill the email field')
            isFormInErrorState = true
        }

        if (email.length !== 0 && !EMAIL_REGEX.test(email)) {
            setIsEmailInErrorState(true)
            setEmailErrorMessage("Email doesn't have a correct format")
            isFormInErrorState = true
        }

        if (password.length === 0) {
            setIsPasswordInErrorState(true)
            setPasswordErrorMessage('Fill the password field')
            isFormInErrorState = true
        }

        if (passwordRepeated.length === 0) {
            setIsPasswordRepeatedInErrorState(true)
            setPasswordRepeatedErrorMessage('Fill the password field')
            isFormInErrorState = true
        }

        if (password.length !== 0 && passwordRepeated.length !== 0 && password !== passwordRepeated) {
            setIsPasswordRepeatedInErrorState(true)
            setPasswordRepeatedErrorMessage('Passwords are not matching')
            isFormInErrorState = true
        }

        if (isFormInErrorState) return

        setIsLoading(true)
        try {
            await delay(2000)
            const { token } = await UserManager.getManager().registerAsync(email, password)
            localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, token)
            const state = parseJwtEncodedTokenAndGetReduxState(token)
            dispatch(setAuthState(state))
            history.push(RouteConstants.projectsList)
        } catch (e) {
            showFlashMessageWithTimeout(dispatch, e, 'error', 4000)
            setIsLoading(false)
        }

        // TODO set based on error from server
        // setIsEmailInErrorState(true)
        // setEmailErrorMessage('This email is already in use')
    }

    return (
        <Stack direction={isMobileView ? 'column-reverse' : 'row'} spacing={3} alignItems='stretch'>
            <Box style={{ maxHeight: 360, maxWidth: 250 }}>
                <img
                    src={Registration}
                    srcSet={`${Registration2x} 2x, ${Registration3x} 3x`}
                    style={{ maxWidth: '100%', width: 'auto', height: 'auto' }}
                    alt='registration image'
                />
            </Box>
            <form>
                <Stack direction='column' flex={1} spacing={3} width={isMobileView ? undefined : 316} justifyContent='flex-start'>
                    <Stack direction='row' alignItems={'center'} spacing={1.5}>
                        <ArrowBackIosNewIcon
                            style={{ color: theme.palette.text.faded, cursor: 'pointer', fontSize: 16 }}
                            onClick={toggleToLoginMode}
                        />
                        <Text fontSize={20} fontWeight={700}>
                            Register
                        </Text>
                    </Stack>
                    <StyledTextField
                        value={email}
                        label='Email address'
                        onChange={(e) => handleEmailChange(e.target.value)}
                        type='email'
                        size='small'
                        showTriangleError
                        error={isEmailInErrorState}
                        errorText={emailErrorMessage}
                        autoFocus
                    />
                    <StyledTextField
                        value={password}
                        label='Password'
                        onChange={(e) => setPassword(e.target.value)}
                        type='password'
                        style={{ color: theme.palette.text.primary }}
                        size='small'
                        showTriangleError
                        error={isPasswordInErrorState}
                        errorText={passwordErrorMessage}
                    />
                    <StyledTextField
                        value={passwordRepeated}
                        label='Password'
                        onChange={(e) => setPasswordRepeated(e.target.value)}
                        type='password'
                        style={{ color: theme.palette.text.primary }}
                        size='small'
                        showTriangleError
                        error={isPasswordRepeatedInErrorState}
                        errorText={passwordRepeatedErrorMessage}
                    />

                    <Stack direction='row' justifyContent='stretch'>
                        <GradientButton
                            disabled={email.length === 0 || password.length === 0 || passwordRepeated.length === 0}
                            title='Create account'
                            onClick={onRegister}
                            style={{ flex: 1 }}
                            isLoading={isLoading}
                        />
                        <Box style={{ width: 32 }}></Box>
                    </Stack>
                </Stack>
            </form>
        </Stack>
    )
}

export default RegistrationForm
