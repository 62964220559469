/**
 * Holds information about state of UI, that would normally be held as local state,
 * but it is affected from multiple unrelated components across the app
 * e.g. initiation of endpoint creating can done from sidePanel and from collectionDetail
 */
export interface TemporaryUiChangesReduxState {
    /**
     * Holds info about endpoint that is being created
     */
    createdEndpointInfo?: CreatedEndpointInfo
    isShareModalOpen: boolean
}

export enum TemporaryUiChangesActionTypes {
    SET_CREATED_ENDPOINT_INFO = 'SET_CREATED_ENDPOINT_INFO',
    SET_SHARE_MODAL_OPEN = 'SET_SHARE_MODAL_OPEN',
}

export interface SetCreatedEndpointInfo {
    type: typeof TemporaryUiChangesActionTypes.SET_CREATED_ENDPOINT_INFO
    payload: {
        createdEndpointInfo: CreatedEndpointInfo | undefined
    }
}

export interface SetShareModalOpen {
    type: typeof TemporaryUiChangesActionTypes.SET_SHARE_MODAL_OPEN
    payload: {
        isOpen: boolean
    }
}

export type TemporaryUiChangesActions = SetCreatedEndpointInfo | SetShareModalOpen

export interface CreatedEndpointInfo {
    collectionId: string
}
