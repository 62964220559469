import { UserSharing } from 'shared/types/user-types'
import { Endpoint, Project, ShareRoles, SharedCode } from 'shared/types/project-types'
import * as monaco from 'monaco-editor'

export interface ProjectReduxState {
    active?: Project
    sharedUsersInActiveProject?: UserSharing[]
    // List of opened collections ids in side menu
    openedCollections: string[]
    isSyncingWithServer: boolean

    /**
     * Cache of all exported types from shared code.
     * It is used to optimize searching in shared code, for example to improve intellisense in editor, or to show type definition in tooltip in editor
     */
    sharedCodeCache?: SharedCodeCache
}

export enum ProjectActionTypes {
    SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT',
    ADD_COLLECTION = 'ADD_COLLECTION',
    REMOVE_COLLECTION = 'REMOVE_COLLECTION',
    ADD_ENDPOINT = 'ADD_ENDPOINT',
    REMOVE_ENDPOINT = 'REMOVE_ENDPOINT',
    SET_RESPONSE = 'SET_RESPONSE',
    SET_REQUEST = 'SET_REQUEST',
    CHANGE_ENDPOINT_DETAIL = 'CHANGE_ENDPOINT_DETAIL',
    UPDATE_ENDPOINT_DETAIL = 'UPDATE_ENDPOINT_DETAIL',
    SET_LOGGED_USER_PROJECT_ROLE = 'SET_LOGGED_USER_PROJECT_ROLE',
    SET_OPENED_COLLECTIONS_IDS = 'SET_OPENED_COLLECTIONS_IDS',
    SET_SHARED_CODE = 'SET_SHARED_CODE',
    SET_IS_SYNCING_WITH_SERVER = 'SET_IS_SYNCING_WITH_SERVER',
    SET_SHARED_CODE_CACHE = 'SET_SHARED_CODE_CACHE',
}

export interface SetIsSyncingWithServer {
    type: typeof ProjectActionTypes.SET_IS_SYNCING_WITH_SERVER
    payload: boolean
}

export interface SetActiveProject {
    type: typeof ProjectActionTypes.SET_ACTIVE_PROJECT
    payload: {
        project: Project | undefined
    }
}

export interface SetResponse {
    type: typeof ProjectActionTypes.SET_RESPONSE
    payload: {
        response: string
        responseHttpCode: number
        endpointId: string
    }
}

export interface SetRequest {
    type: typeof ProjectActionTypes.SET_REQUEST
    payload: {
        request: string
        endpointId: string
    }
}

export interface AddCollection {
    type: typeof ProjectActionTypes.ADD_COLLECTION
    payload: {
        id: string
        name: string
        description: string
        endpoints: Endpoint[]
    }
}

export interface RemoveCollection {
    type: typeof ProjectActionTypes.REMOVE_COLLECTION
    payload: {
        id: string
    }
}

export type EndpointDetailTypes =
    | 'name'
    | 'description'
    | 'requestType'
    | 'requestUrl'
    | 'request'
    | 'newRequestParam'
    | 'removeRequestParam'

export interface UpdateEndpointDetail {
    type: typeof ProjectActionTypes.UPDATE_ENDPOINT_DETAIL
    payload: {
        endpoint: Endpoint
    }
}

export interface AddEndpoint {
    type: typeof ProjectActionTypes.ADD_ENDPOINT
    payload: {
        collectionId: string
        endpoint: Endpoint
    }
}

export interface RemoveEndpoint {
    type: typeof ProjectActionTypes.REMOVE_ENDPOINT
    payload: {
        collectionId: string
        endpointId: string
    }
}

export interface SetLoggedUsersProjectRole {
    type: typeof ProjectActionTypes.SET_LOGGED_USER_PROJECT_ROLE
    payload: {
        projectRole: ShareRoles
    }
}

export interface SetOpenedCollectionsIds {
    type: typeof ProjectActionTypes.SET_OPENED_COLLECTIONS_IDS
    payload: {
        collectionIds: string[]
    }
}

export interface SetSharedCode {
    type: typeof ProjectActionTypes.SET_SHARED_CODE
    payload: {
        sharedCode: SharedCode
    }
}

export interface SetSharedCodeCache {
    type: typeof ProjectActionTypes.SET_SHARED_CODE_CACHE
    payload: {
        sharedCodeCache: SharedCodeCache
    }
}

export type ProjectActions =
    | AddCollection
    | RemoveCollection
    | AddEndpoint
    | RemoveEndpoint
    | SetResponse
    | SetRequest
    | SetActiveProject
    | UpdateEndpointDetail
    | SetLoggedUsersProjectRole
    | SetOpenedCollectionsIds
    | SetSharedCode
    | SetIsSyncingWithServer
    | SetSharedCodeCache

/**
 * Holds information about exported type from shared code, that is saved in cache
 */
export interface SharedCodeCacheTypeInfo {
    name: string
    fileName: string
    type: monaco.languages.CompletionItemKind
    typeDefinition: string
    isExported: boolean
}

/**
 * Holds information about exported shared code types
 * Key is in format "typeName@fileName"
 * Example: interface "Foo" exported from file "Bar" would have key "Foo@Bar"
 */
export type SharedCodeCache = Record<string, SharedCodeCacheTypeInfo>
