import { isTypeImportedFromFile } from 'components/MonacoEditor/monaco-utils'
import { Collection, Endpoint, Project, RequestType, UrlRequestDefinition, UrlRequestParameter } from 'shared/types/project-types'
import { hasDuplicates } from 'shared/utils/generic-utils'
import { EditedSharedCodeTypeInfo } from 'types/sharedCode-types'
import { normalizeString, transformStringToCamelCase } from './generic-utils'

export interface PreviousParams {
    endpointId?: string
    queryParams?: UrlRequestDefinition
    bodyRequest?: string
}

/**
 * Save params from endpoint to previousParams and then set previous params to endpoint based on new request type.
 * If changing to POST/PUT/DELETE and the prefillRequest is true, the body request is filled with default interface
 * Used when request type is changing to create local cache for params
 * @param newRequestType
 * @param endpoint
 * @param previousParams
 * @param prefillRequest
 */
export const savePreviousParamsAndSetPreviousParamsToEndpoint = (
    newRequestType: RequestType,
    endpoint: Endpoint,
    previousParams: PreviousParams,
    prefillRequest: boolean
): void => {
    if (!previousParams.endpointId || previousParams.endpointId !== endpoint.id) {
        previousParams.endpointId = endpoint.id
        previousParams.bodyRequest = ''
        previousParams.queryParams = { parameters: [] }
    }

    if (newRequestType === RequestType.GET) {
        // If changing to GET, save bodyRequest string and set old queryParams
        if (endpoint.bodyRequest && endpoint.bodyRequest.stringFormat) {
            previousParams.bodyRequest = endpoint.bodyRequest.stringFormat
            endpoint.bodyRequest.stringFormat = ''
        }

        if (previousParams.queryParams) endpoint.queryParameters = previousParams.queryParams
    } else {
        // If changing to PUT/POST/DELETE, save queryParams and set old bodyRequest string or default interface
        if (endpoint.queryParameters) {
            previousParams.queryParams = { ...endpoint.queryParameters }
            endpoint.queryParameters.parameters = []
        }

        if (previousParams.bodyRequest && previousParams.bodyRequest.trim().length > 0) {
            endpoint.bodyRequest = {
                stringFormat: previousParams.bodyRequest,
            }
        } else if (prefillRequest) {
            const endpointNameCamelCase = transformStringToCamelCase(endpoint.name) // transform to camelCase
            const endpointNameCapitalLetter = endpointNameCamelCase.charAt(0).toUpperCase() + endpointNameCamelCase.slice(1) // capitalize first letter
            endpoint.bodyRequest = {
                stringFormat: `export interface ${endpointNameCapitalLetter}Request {\n   \n}`,
            }
        }
    }
}

/**
 * Finds endpoint in project where requestType and requestUrl are the same as in the endpoint parameter and return its name
 * @param endpoint
 * @param project
 * @returns
 */
export const getDuplicatedUrlAndRequestTypeEndpointName = (endpoint: Endpoint, project: Project): string | undefined => {
    let duplicatedEndpointName = undefined

    project.collections.forEach((c) => {
        const duplicatedEndpoint = c.endpoints.find(
            (e) => e.id !== endpoint.id && e.requestUrl === endpoint.requestUrl && e.requestType === endpoint.requestType
        )

        if (duplicatedEndpoint !== undefined) {
            duplicatedEndpointName = duplicatedEndpoint.name
        }
    })

    return duplicatedEndpointName
}

/**
 * Get path variables from url
 * @param url
 * @returns
 */
export const getPathVariables = (url: string) => {
    const result: UrlRequestParameter[] = []
    if (!url.includes(':')) {
        return result
    }
    let urlParts = url.split('/')
    if (hasDuplicates(urlParts)) {
        urlParts = urlParts.filter((item, pos) => {
            return urlParts.indexOf(item) === pos
        })
    }
    urlParts.forEach((sp: string) => {
        if (sp[0] === ':' && sp[1] !== undefined) {
            result.push({ name: sp.slice(1), description: '', type: 'string' })
        }
    })
    return result
}

/**
 * Goes through all endpoints and shared code in the project and check if the types are imported. If the type is not imported anywhere, it will be filtered out
 * @param types
 * @param project
 * @returns
 */
export const filterTypesOnlyImportedInEndpointOrInSharedCode = (
    types: EditedSharedCodeTypeInfo[],
    project: Project
): EditedSharedCodeTypeInfo[] => {
    const result: EditedSharedCodeTypeInfo[] = []
    types.forEach((t) => {
        let isAddedToResult = false
        for (const collection of project.collections) {
            for (const endpoint of collection.endpoints) {
                if (endpoint.bodyRequest && endpoint.bodyRequest.stringFormat) {
                    const isTypeImportedInBodyRequest = isTypeImportedFromFile(endpoint.bodyRequest.stringFormat, t.name, t.file)

                    if (isTypeImportedInBodyRequest) {
                        result.push(t)
                        isAddedToResult = true
                        break
                    }
                }

                for (const response of endpoint.response) {
                    const isTypeImportedInResponse = isTypeImportedFromFile(response.stringFormat, t.name, t.file)

                    if (isTypeImportedInResponse) {
                        result.push(t)
                        isAddedToResult = true
                        break
                    }
                }
            }
            if (isAddedToResult) break
        }

        for (const sharedCode of project.sharedCode) {
            if (isAddedToResult) break
            if (sharedCode.content && sharedCode.name !== t.file) {
                const isTypeImportedInSharedCode = isTypeImportedFromFile(sharedCode.content, t.name, t.file)

                if (isTypeImportedInSharedCode) {
                    result.push(t)
                    isAddedToResult = true
                    break
                }
            }
        }
    })

    return result
}

/**
 * Goes through all endpoints and shared code in the project and replaces all instances of the imported types with the new ones
 * @param removedTypes
 * @param project
 */
export const replaceRemovedTypesWithNewOnes = (removedTypes: EditedSharedCodeTypeInfo[], project: Project): void => {
    removedTypes.forEach((removedType) => {
        if (removedType.newType) {
            replaceRemovedTypesWithNewOnesInEndpoints(removedType, project)
            replaceRemovedTypesWithNewOnesInSharedCode(removedType, project)
        }
    })
}

const replaceRemovedTypesWithNewOnesInEndpoints = (removedType: EditedSharedCodeTypeInfo, project: Project): void => {
    project.collections.forEach((collection) => {
        collection.endpoints.forEach((endpoint) => {
            if (endpoint.bodyRequest && endpoint.bodyRequest.stringFormat) {
                const isTypeImportedInBodyRequest = isTypeImportedFromFile(
                    endpoint.bodyRequest.stringFormat,
                    removedType.name,
                    removedType.file
                )

                if (isTypeImportedInBodyRequest && removedType.newType) {
                    endpoint.bodyRequest.stringFormat = replaceTypeNameInCode(
                        removedType.name,
                        removedType.newType.name,
                        endpoint.bodyRequest.stringFormat
                    )
                }
            }

            endpoint.response.forEach((response) => {
                const isTypeImportedInResponse = isTypeImportedFromFile(response.stringFormat, removedType.name, removedType.file)

                if (isTypeImportedInResponse && removedType.newType) {
                    response.stringFormat = replaceTypeNameInCode(
                        removedType.name,
                        removedType.newType.name,
                        response.stringFormat
                    )
                }
            })
        })
    })
}

const replaceRemovedTypesWithNewOnesInSharedCode = (removedType: EditedSharedCodeTypeInfo, project: Project): void => {
    project.sharedCode.forEach((sharedCode) => {
        if (sharedCode.content && sharedCode.name !== removedType.file) {
            const isTypeImportedInSharedCode = isTypeImportedFromFile(sharedCode.content, removedType.name, removedType.file)

            if (isTypeImportedInSharedCode && removedType.newType) {
                sharedCode.content = replaceTypeNameInCode(removedType.name, removedType.newType.name, sharedCode.content)
            }
        }
    })
}

const replaceTypeNameInCode = (oldTypeName: string, newTypeName: string, code: string) => {
    code = code.replaceAll(` ${oldTypeName} `, ` ${newTypeName} `)
    code = code.replaceAll(` ${oldTypeName};`, ` ${newTypeName};`)
    code = code.replaceAll(` ${oldTypeName}}`, ` ${newTypeName}}`)
    code = code.replaceAll(` ${oldTypeName},`, ` ${newTypeName},`)
    code = code.replaceAll(` ${oldTypeName}\n`, ` ${newTypeName}\n`)

    code = code.replaceAll(`:${oldTypeName} `, `:${newTypeName} `)
    code = code.replaceAll(`:${oldTypeName};`, `:${newTypeName};`)
    code = code.replaceAll(`:${oldTypeName}}`, `:${newTypeName}}`)
    code = code.replaceAll(`:${oldTypeName},`, `:${newTypeName},`)
    code = code.replaceAll(`:${oldTypeName}\n`, `:${newTypeName}\n`)

    code = code.replaceAll(`,${oldTypeName} `, `,${newTypeName} `)
    code = code.replaceAll(`,${oldTypeName};`, `,${newTypeName};`)
    code = code.replaceAll(`,${oldTypeName}}`, `,${newTypeName}}`)
    code = code.replaceAll(`,${oldTypeName},`, `,${newTypeName},`)
    code = code.replaceAll(`,${oldTypeName}\n`, `,${newTypeName}\n`)

    code = code.replaceAll(`{${oldTypeName} `, `{${newTypeName} `)
    code = code.replaceAll(`{${oldTypeName};`, `{${newTypeName};`)
    code = code.replaceAll(`{${oldTypeName}}`, `{${newTypeName}}`)
    code = code.replaceAll(`{${oldTypeName},`, `{${newTypeName},`)
    code = code.replaceAll(`{${oldTypeName}\n`, `{${newTypeName}\n`)

    return code
}

export const getCollectionByEndpointId = (endpointId: string, project: Project): Collection | undefined => {
    let result: Collection | undefined

    for (const collection of project.collections) {
        for (const e of collection.endpoints) {
            if (e.id === endpointId) {
                result = collection
                break
            }
        }

        if (result) break
    }

    return result
}

/**
 * Updates all the imported code in the project during renaming of shared code tab. Modifies the passed {@link project}.
 */
export const updateImportsFromFileInEndpointsAndSharedCode = (
    oldImportName: string,
    newImportName: string,
    project: Project
): void => {
    /**
     * Matches all possible import statements
     * Source https://stackoverflow.com/questions/52086611/regex-for-matching-js-import-statements and expanded the first group to contain the "import", brackets and "from"
     * 1st group - import statement until the quotation mark that starts the imported location
     * 2nd group - imported location (oldImportName)
     * 3rd group - quotation mark that ends the imported location
     *
     * e.g.
     * import Foo from "Bar"
     * 1st group - import Foo from "
     * 2nd group - Bar
     * 3rd group - "
     *
     * import { Foo } from 'Bar'
     * 1st group - import { Foo } from '
     * 2nd group - Bar
     * 3rd group - '
     */
    const importRegex = RegExp(
        // eslint-disable-next-line
        `(import[ \n\t]*.*[ \n\t]*from[ \n\t]*['"])(${oldImportName})(['"])`
    )

    for (const collection of project.collections) {
        for (const endpoint of collection.endpoints) {
            if (endpoint.bodyRequest) {
                endpoint.bodyRequest.stringFormat = endpoint.bodyRequest.stringFormat.replace(importRegex, `$1${newImportName}$3`)
            }

            for (const response of endpoint.response) {
                response.stringFormat = response.stringFormat.replace(importRegex, `$1${newImportName}$3`)
            }
        }
    }

    for (const sharedCode of project.sharedCode) {
        sharedCode.content = sharedCode.content.replace(importRegex, `$1${newImportName}$3`)
    }
}

export const generateNewResponseContent = (endpointName: string): string => {
    endpointName = transformStringToCamelCase(endpointName) // transform to camelCase
    endpointName = endpointName.charAt(0).toUpperCase() + endpointName.slice(1) // capitalize first letter
    return `export interface ${endpointName}Response {\n   \n}`
}

/**
 * duplicateNameCheck - checks if name is already used in the project (collection or endpoint)
 * @param name - name to check
 * @param type - type of name to check (collection or endpoint)
 * @param project - active project to check the name in
 * @param collectionId - collectionId to find the collection in the project (only used for endpoint type) or to filter out the collection itself when finding collection name (only used for collection type)
 * @param endpointId - endpointId to filter out the endpoint itself when finding endpoint name (only used for endpoint type)
 * @returns boolean - true if name is already used, false otherwise
 */
export function duplicateNameCheck(
    name: string,
    type: 'collection' | 'endpoint',
    project: Project | undefined,
    collectionId?: string,
    endpointId?: string
) {
    if (!project) return false

    if (type === 'endpoint') {
        const collection = project.collections.find((collection) => collection.id === collectionId)
        if (!collection) return false

        const isDuplicateEndpointName = collection.endpoints
            .filter((endpoint) => endpoint.id !== endpointId)
            .some((endpoint) => normalizeString(endpoint.name) === normalizeString(name))

        return isDuplicateEndpointName
    }

    if (type === 'collection') {
        const isDuplicateName = project.collections
            .filter((collection) => collection.id !== collectionId)
            .some((collection) => normalizeString(collection.name) === normalizeString(name))

        return isDuplicateName
    }

    return false
}
