import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'

interface EmptyButtonProps {
    text: string
    onClick: () => void
    style?: React.CSSProperties
}

const EmptyButton: React.FC<EmptyButtonProps> = ({ text, onClick, style }) => {
    const theme = useTheme()
    return (
        <Box
            onClick={onClick}
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `1px solid ${theme.palette.primary.light}`,
                borderRadius: 4,
                cursor: 'pointer',
            }}
        >
            <Typography style={{ color: theme.palette.primary.light, padding: 10, userSelect: 'none' }}>{text}</Typography>
        </Box>
    )
}

export default EmptyButton
