import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { z } from 'zod'

import GradientButton from 'components/GradientButton'
import Text from 'components/Text'
import { UserManager } from 'services/api/UserManager'
import { getAuthState } from 'services/selectors/auth-selectors'
import StyledTextField from 'components/StyledTextField'
import { showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import ModalBase from 'components/ModalBase'
import EmptyButton from 'components/EmptyButton'

const changePasswordFormValidationSchema = z
    .object({
        currentPassword: z.string().min(1, { message: 'Current password is required.' }),
        newPassword: z.string().min(1, { message: 'New password is required.' }),
        confirmNewPassword: z.string().min(1, { message: 'Confirm new password is required.' }),
    })
    .required()
    .refine((formData) => formData.newPassword === formData.confirmNewPassword, {
        path: ['confirmNewPassword'],
        message: "Passwords don't match.",
    })
    .refine((formData) => formData.currentPassword !== formData.newPassword, {
        path: ['newPassword'],
        message: 'New password is identical.',
    })

type ChangePasswordForm = z.infer<typeof changePasswordFormValidationSchema>

const ChangePasswordModal: React.FC<{
    isOpen: boolean
    onClose: () => void
}> = ({ isOpen, onClose }) => {
    const dispatch = useDispatch()
    const { userEmail } = useSelector(getAuthState)

    const {
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = useForm<ChangePasswordForm>({
        resolver: zodResolver(changePasswordFormValidationSchema),
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
    })

    const handleClose = () => {
        onClose()
        reset()
    }

    const onSubmit = async (data: ChangePasswordForm) => {
        UserManager.getManager()
            .changePasswordAsync(userEmail, data.currentPassword, data.newPassword)
            .then(() => {
                handleClose()
                showFlashMessageWithTimeout(dispatch, 'Your password has been changed.', 'success', 4000)
            })
            .catch((e) => {
                showFlashMessageWithTimeout(dispatch, e, 'error', 4000)
            })
    }

    return (
        <ModalBase isOpen={isOpen} onClose={handleClose} center>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack
                    spacing={4}
                    sx={{
                        bgcolor: 'background.default',
                        borderRadius: 4,
                        border: 1,
                        borderColor: 'neutral.dark1',
                        p: 4,
                        width: 440,
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Text fontWeight={700} fontSize={20}>
                            Change password
                        </Text>
                    </Box>
                    <Stack spacing={4}>
                        <Controller
                            name='currentPassword'
                            control={control}
                            render={({ field, fieldState }) => (
                                <StyledTextField
                                    {...field}
                                    label='Current password'
                                    type='password'
                                    autoComplete='current-password'
                                    error={!!fieldState.error}
                                    errorText={fieldState.error?.message}
                                    autoFocus
                                    fullWidth
                                />
                            )}
                        />
                        <Controller
                            name='newPassword'
                            control={control}
                            render={({ field, fieldState }) => (
                                <StyledTextField
                                    {...field}
                                    label='New password'
                                    type='password'
                                    autoComplete='new-password'
                                    error={!!fieldState.error}
                                    errorText={fieldState.error?.message}
                                    fullWidth
                                />
                            )}
                        />
                        <Controller
                            name='confirmNewPassword'
                            control={control}
                            render={({ field, fieldState }) => (
                                <StyledTextField
                                    {...field}
                                    label='Confirm new password'
                                    type='password'
                                    autoComplete='new-password'
                                    error={!!fieldState.error}
                                    errorText={fieldState.error?.message}
                                    fullWidth
                                />
                            )}
                        />
                    </Stack>
                    <Stack direction='row' spacing={3} sx={{ justifyContent: 'flex-end' }}>
                        <EmptyButton text='Cancel' onClick={handleClose} />
                        <GradientButton title='Confirm changes' isLoading={isSubmitting} type='submit' variant='contained' />
                    </Stack>
                </Stack>
            </form>
        </ModalBase>
    )
}

export default ChangePasswordModal
