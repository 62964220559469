import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveProject } from 'services/selectors/projects-selectors'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ShareIcon from '@mui/icons-material/Share'
import ChangeUrlModal from 'components/ChangeUrlModal'
import { setActiveProject, updateProjectBaseUrl, updateProjectName } from 'services/actions/project-actions'
import { ProjectsManager } from 'services/api/ProjectsManager'
import { showFlashMessage } from 'services/actions/flashMessage-actions'
import RenameProjectModal from 'components/RenameProjectModal'
import Text from 'components/Text'
import moment from 'moment'
import { getErrorMessage } from 'shared/utils/generic-utils'
import { downloadFile, downloadFileFromBodyContentAsync } from 'utils/generic-utils'
import { setShareModalOpen } from 'services/actions/temporaryUiChanges-actions'
import { FileUpload, Info } from '@mui/icons-material'
import DeleteModal from 'components/DeleteModal'

const PREFIX = 'ProjectDetails'

const classes = {
    primaryText: `${PREFIX}-primaryText`,
    secondaryText: `${PREFIX}-secondaryText`,
    sectionTitle: `${PREFIX}-sectionTitle`,
    inlineBox: `${PREFIX}-inlineBox`,
    container: `${PREFIX}-container`,
    flexRow: `${PREFIX}-flexRow`,
}

const StyledBox = styled(Box)(() => ({
    [`& .${classes.primaryText}`]: {
        fontWeight: 400,
    },

    [`& .${classes.secondaryText}`]: {
        fontWeight: 400,
    },

    [`& .${classes.sectionTitle}`]: {
        fontWeight: 600,
        marginBottom: 10,
    },

    [`& .${classes.inlineBox}`]: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 16,
        cursor: 'pointer',
    },

    [`&.${classes.container}`]: {
        display: 'flex',
        paddingLeft: 24,
        paddingTop: 30,
        flexDirection: 'column',
        flex: 1,
    },

    [`& .${classes.flexRow}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}))

const ProjectDetails: React.FC = () => {
    const theme = useTheme()

    const dispatch = useDispatch()
    const activeProject = useSelector(getActiveProject)
    const [changeUrlModalOpen, setChangeUrlModalOpen] = useState<boolean>(false)
    const [renameProjectModalOpen, setRenameProjectModalOpen] = useState<boolean>(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

    if (!activeProject) {
        return (
            <StyledBox style={{ display: 'flex', flex: 1 }}>
                <Text color='normal' fontSize={16}>
                    Active project is undefined
                </Text>
            </StyledBox>
        )
    }

    const handleUrlChange = (newUrl: string) => {
        dispatch(updateProjectBaseUrl(newUrl))
    }

    const handleProjectNameChange = (newName: string) => {
        dispatch(updateProjectName(newName))
    }

    const onDelete = async (id: string) => {
        try {
            const _projectManager = ProjectsManager.getManager()
            await _projectManager.deleteProjectAsync(id)
            dispatch(setActiveProject(undefined))
            setIsDeleteModalOpen(false)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }

    const onExport = async (id: string) => {
        try {
            const _projectManager = ProjectsManager.getManager()
            const result = await _projectManager.exportProjectAsync(id)
            downloadFile(JSON.stringify(result), `${result.project.name}.json`, 'text/json')
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }

    const onGenerateOpenApi = async (id: string) => {
        try {
            const _projectManager = ProjectsManager.getManager()
            const result = await _projectManager.getOpenApiAsync(id)
            downloadFile(result.content, result.fileName, 'text/json')
        } catch (e) {
            dispatch(showFlashMessage(e, 'error'))
        }
    }

    const onGenerateTypescriptCode = async (id: string) => {
        try {
            const _projectManager = ProjectsManager.getManager()
            const bodyContent = await _projectManager.getProjectDefinitionsContentAsync(id)
            console.log(bodyContent.length)
            await downloadFileFromBodyContentAsync(bodyContent, 'typescript-definitions.zip')
        } catch (e) {
            dispatch(showFlashMessage(e, 'error'))
        }
    }

    const onPublishNpmPackage = async (id: string, version: string) => {
        try {
            const _projectManager = ProjectsManager.getManager()
            const result = await _projectManager.publishNpmPackageAsync(id, version)
            // TODO: integrate to UI instead of flash messages
            dispatch(showFlashMessage('NPM package sucessfully published', 'success'))
            dispatch(showFlashMessage(`${result.packageUrl}`, 'success'))
        } catch (e) {
            dispatch(showFlashMessage(e, 'error'))
        }
    }

    const onGetLatestNpmVersion = async (id: string) => {
        try {
            const _projectManager = ProjectsManager.getManager()
            const result = await _projectManager.getLatestNpmPackageVersion(id)
            // TODO: integrate to UI instead of flash messages
            dispatch(showFlashMessage(`${result.version}`, 'success'))
            dispatch(showFlashMessage(`${result.packageUrl}`, 'success'))
        } catch (e) {
            dispatch(showFlashMessage(e, 'error'))
        }
    }

    return (
        <StyledBox className={classes.container}>
            <Text color='light' fontSize={20} style={{ fontWeight: 700, marginBottom: 24 }}>
                {activeProject.name}
            </Text>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 24,
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box className={classes.flexRow}>
                        <Text color='light' fontSize={14} className={classes.primaryText} style={{ width: 100 }}>
                            Created:
                        </Text>
                        <Text color='light' fontSize={16} className={classes.secondaryText}>{`${moment(
                            activeProject.createdAt
                        ).format('YYYY-MM-DD')}`}</Text>
                    </Box>
                    <Box className={classes.flexRow}>
                        <Text color='light' fontSize={14} className={classes.primaryText} style={{ width: 100 }}>
                            Last edit:
                        </Text>
                        <Text color='light' fontSize={16} className={classes.secondaryText}>{`${moment(
                            activeProject.lastModifiedAt
                        ).format('YYYY-MM-DD')}`}</Text>
                    </Box>
                    <Box className={classes.flexRow}>
                        <Text color='light' fontSize={14} className={classes.primaryText} style={{ width: 100 }}>
                            Api base URL:
                        </Text>
                        <Text color='light' fontSize={16} className={classes.secondaryText}>
                            {activeProject.baseUrl}
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Text color='light' fontSize={16} className={classes.sectionTitle}>
                    Actions
                </Text>
                <Box className={classes.inlineBox} onClick={() => setChangeUrlModalOpen(true)}>
                    <ModeEditIcon style={{ color: theme.palette.text.faded, marginRight: 10 }} />
                    <Text color='light' fontSize={14} className={classes.primaryText}>
                        Change URL
                    </Text>
                </Box>
                <Box className={classes.inlineBox} onClick={() => setRenameProjectModalOpen(true)}>
                    <ModeEditIcon style={{ color: theme.palette.text.faded, marginRight: 10 }} />
                    <Text color='light' fontSize={14} className={classes.primaryText}>
                        Rename
                    </Text>
                </Box>
                <Box
                    className={classes.inlineBox}
                    onClick={() => {
                        dispatch(setShareModalOpen(true))
                    }}
                >
                    <ShareIcon style={{ color: theme.palette.neutral.light3, marginRight: 10 }} />
                    <Text color='light' fontSize={14} className={classes.primaryText}>
                        Share
                    </Text>
                </Box>
                <Box className={classes.inlineBox} onClick={() => onExport(activeProject.id)}>
                    <FileDownloadIcon style={{ color: theme.palette.neutral.light3, marginRight: 10 }} />
                    <Text color='light' fontSize={14} className={classes.primaryText}>
                        Export project
                    </Text>
                </Box>
                <Box className={classes.inlineBox} onClick={() => onGenerateOpenApi(activeProject.id)}>
                    <FileDownloadIcon style={{ color: theme.palette.neutral.light3, marginRight: 10 }} />
                    <Text color='light' fontSize={14} className={classes.primaryText}>
                        Generate OpenAPI document
                    </Text>
                </Box>
                <Box className={classes.inlineBox} onClick={() => onGenerateTypescriptCode(activeProject.id)}>
                    <FileDownloadIcon style={{ color: theme.palette.neutral.light3, marginRight: 10 }} />
                    <Text color='light' fontSize={14} className={classes.primaryText}>
                        Generate Typescript definitions
                    </Text>
                </Box>
                {/* TODO: editable version */}
                <Box className={classes.inlineBox} onClick={() => onPublishNpmPackage(activeProject.id, '1.0.2')}>
                    <FileUpload style={{ color: theme.palette.neutral.light3, marginRight: 10 }} />
                    <Text color='light' fontSize={14} className={classes.primaryText}>
                        Publish NPM package
                    </Text>
                </Box>
                <Box className={classes.inlineBox} onClick={() => onGetLatestNpmVersion(activeProject.id)}>
                    <Info style={{ color: theme.palette.neutral.light3, marginRight: 10 }} />
                    <Text color='light' fontSize={14} className={classes.primaryText}>
                        Get latest NPM package version
                    </Text>
                </Box>
                <Box className={classes.inlineBox} onClick={() => setIsDeleteModalOpen(true)}>
                    <DeleteOutlineIcon style={{ color: theme.palette.alert.main, marginRight: 10 }} />
                    <Text color='light' fontSize={14} className={classes.primaryText}>
                        Delete project
                    </Text>
                </Box>
            </Box>
            <ChangeUrlModal
                isOpen={changeUrlModalOpen}
                onClose={() => setChangeUrlModalOpen(false)}
                onConfirm={(newUrl: string) => handleUrlChange(newUrl)}
                url={activeProject.baseUrl}
            />
            <RenameProjectModal
                isOpen={renameProjectModalOpen}
                onClose={() => setRenameProjectModalOpen(false)}
                onConfirm={(newName: string) => handleProjectNameChange(newName)}
                projectName={activeProject.name}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onDelete={() => onDelete(activeProject.id)}
                name={activeProject.name}
                type='project'
            />
        </StyledBox>
    )
}

export default ProjectDetails
