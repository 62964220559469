import { Box, Tooltip, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { MenuItem } from './SidePanel'
import SidePanelMenuItemTitle from './SidePanelMenuItemTitle'
import SubMenuItem from './SubMenuItem'
import { Collection } from 'shared/types/project-types'
import { useDispatch } from 'react-redux'
import NameInput from './NameInput'
import { CreatedEndpointInfo } from 'services/types/temporaryUiChanges-types'
import { setCreatedEndpointInfo } from 'services/actions/temporaryUiChanges-actions'
import { styled } from '@mui/material/styles'
import { handleTabNavigation } from 'utils/generic-utils'

const PREFIX = 'MenuItem'

const classes = {
    menuCell: `${PREFIX}-menuCell`,
}

export const MENU_ITEM_HEIGHT = 32

const StyledBox = styled(Box)(() => ({
    [`&.${classes.menuCell}`]: {
        minHeight: MENU_ITEM_HEIGHT,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}))

interface MenuItemProps {
    item: MenuItem
    validateCollectionName: (name: string, collectionId: string) => boolean
    validateEndpointName: (name: string, collectionId: string, endpointId?: string) => boolean
    getNameForEmptyEndpoint: (collectionId: string) => string
    isRouteActive: boolean
    isReadOnly: boolean
    handleGoTo: (title: string) => void
    onEndpointAdd: (endpointName: string) => void
    onEndpointDuplicate: (collectionId: string, endpointId: string) => void
    onEndpointRemove: (endpointId: string) => void
    toggleCollectionOpen: (collectionId: string) => void
    isOpened: boolean
    isMobileView: boolean
    toggleMenu: () => void
    toggleDropdown: (itemId: string) => void
    dropdownFor?: string
    onCollectionRemove: (collection: Collection) => void
    idToRename?: string
    toggleEditedStateFor: (value: string) => void
    createdEndpointInfo: CreatedEndpointInfo | undefined
    setEndpointIdToRename: (endpointId: string) => void
}

const SidePanelMenuItem: React.FC<MenuItemProps> = ({
    item,
    validateCollectionName,
    validateEndpointName,
    getNameForEmptyEndpoint,
    isRouteActive,
    handleGoTo,
    isReadOnly,
    onEndpointDuplicate,
    onEndpointAdd,
    onEndpointRemove,
    toggleCollectionOpen,
    isOpened,
    isMobileView,
    toggleMenu,
    toggleDropdown,
    dropdownFor,
    onCollectionRemove,
    idToRename,
    toggleEditedStateFor,
    createdEndpointInfo,
    setEndpointIdToRename,
}) => {
    const { title, route } = item
    const dispatch = useDispatch()
    const theme = useTheme()
    const [createdEndpointName, setCreatedEndpointName] = useState('')
    const [isNameDuplicate, setIsNameDuplicate] = useState(false)

    const onSubMenuItemPress = (openInNewTab: boolean, endpointRoute: string) => {
        handleTabNavigation(openInNewTab, endpointRoute, () => handleGoTo(endpointRoute))
        if (isMobileView) toggleMenu()
    }

    return (
        <StyledBox
            className={classes.menuCell}
            style={{
                cursor: 'pointer',
            }}
        >
            <SidePanelMenuItemTitle
                isReadOnly={isReadOnly}
                isRouteActive={isRouteActive}
                validateCollectionName={validateCollectionName}
                collectionId={item.id}
                title={title}
                onRedirect={(openInNewTab) => handleTabNavigation(openInNewTab, route, () => handleGoTo(route))}
                toggleCollectionOpen={toggleCollectionOpen}
                isOpened={isOpened}
                isMobileView={isMobileView}
                toggleMenu={toggleMenu}
                toggleDropdown={() => toggleDropdown(item.id)}
                isDropdownOpen={dropdownFor === item.id}
                onCollectionRemove={onCollectionRemove}
                isInEditedState={item.id === idToRename}
                toggleEditedStateFor={toggleEditedStateFor}
            />
            {isOpened && (
                <>
                    {item.subMenu.map((subMenuItem) => (
                        <div key={subMenuItem.id}>
                            <SubMenuItem
                                endpointId={subMenuItem.id}
                                validateEndpointName={validateEndpointName}
                                getNameForEmptyEndpoint={getNameForEmptyEndpoint}
                                collectionId={item.id}
                                title={subMenuItem.title}
                                route={subMenuItem.route}
                                onSubMenuItemPress={(e) => onSubMenuItemPress(e, subMenuItem.route)}
                                onEndpointDuplicate={() => onEndpointDuplicate(item.id, subMenuItem.id)}
                                requestType={subMenuItem.requestType}
                                toggleDropdown={() => toggleDropdown(subMenuItem.id)}
                                isDropdownOpen={dropdownFor === subMenuItem.id}
                                isInEditedState={subMenuItem.id === idToRename}
                                toggleEditedStateFor={toggleEditedStateFor}
                                handleGoTo={handleGoTo}
                                onEndpointRemove={onEndpointRemove}
                                setEndpointIdToRename={setEndpointIdToRename}
                            />
                        </div>
                    ))}

                    {createdEndpointInfo && createdEndpointInfo.collectionId === item.id && (
                        <Box style={{ backgroundColor: theme.palette.primary.main }}>
                            <Tooltip
                                title={'This name already exist in the same collection'}
                                open={isNameDuplicate}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        marginLeft: 24,
                                    }}
                                >
                                    <Box style={{ height: MENU_ITEM_HEIGHT, flex: 1, display: 'flex', alignItems: 'center' }}>
                                        <NameInput
                                            value={createdEndpointName}
                                            handleChange={(e) => {
                                                setIsNameDuplicate(validateEndpointName(e.target.value, item.id))
                                                setCreatedEndpointName(e.target.value)
                                            }}
                                            onBlur={() => {
                                                if (createdEndpointName.length > 0) {
                                                    onEndpointAdd(
                                                        !isNameDuplicate ? createdEndpointName : getNameForEmptyEndpoint(item.id)
                                                    )
                                                    setIsNameDuplicate(false)
                                                } else {
                                                    dispatch(setCreatedEndpointInfo(undefined))
                                                }

                                                setCreatedEndpointName('')
                                            }}
                                            onEscape={() => {
                                                dispatch(setCreatedEndpointInfo(undefined))
                                                setCreatedEndpointName('')
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Tooltip>
                        </Box>
                    )}
                </>
            )}
        </StyledBox>
    )
}

export default SidePanelMenuItem
