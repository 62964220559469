export type ModeType = 'view' | 'edit'

export const themeTypes = ['light', 'dark', 'auto'] as const
export type ThemeType = typeof themeTypes[number]

export const SELECTED_THEME_KEY = 'selected-theme'

/**
 * Holds settings of monaco editor for user
 */
export interface EditorSettings {
    /**
     * If true, when user creates response, it will prefill response interface in the content
     */
    prefillResponseBodyOnCreate: boolean

    /**
     * If true, when user changes type of endpoint to POST/PUT/DELETE, it will prefill body request with interface
     */
    prefillRequestBodyOnCreate: boolean
}

export interface OpenApiSettings {
    /**
     * This says whether the Date type should be generated with open api format
     * 'date' or 'date-time'
     */
    dateType: 'date' | 'date-time'
}

export interface UserSettingsReduxState {
    selectedMode: ModeType
    editorSettings: EditorSettings
    selectedTheme: ThemeType
    openApiSettings: OpenApiSettings
}

export enum UserSettingsActionTypes {
    SET_SELECTED_MODE = 'SET_SELECTED_MODE',
    SET_SELECTED_THEME = 'SET_SELECTED_THEME',
    SET_EDITOR_SETTINGS = 'SET_EDITOR_SETTINGS',
}

export interface SetSelectedMode {
    type: typeof UserSettingsActionTypes.SET_SELECTED_MODE
    payload: ModeType
}

export interface SetSelectedTheme {
    type: typeof UserSettingsActionTypes.SET_SELECTED_THEME
    payload: ThemeType
}

export interface SetEditorSettings {
    type: typeof UserSettingsActionTypes.SET_EDITOR_SETTINGS
    payload: EditorSettings
}

export type UserSettingsActions = SetSelectedMode | SetSelectedTheme | SetEditorSettings
