import { TextField } from '@mui/material'
import Fonts from 'constants/fonts'
import { styled } from '@mui/material/styles'

const PREFIX = 'NameInput'

const classes = {
    input: `${PREFIX}-input`,
    inputError: `${PREFIX}-inputError`,
    textFieldInput: `${PREFIX}-textFieldInput`,
    textField: `${PREFIX}-textField`,
    inputProps: `${PREFIX}-inputProps`,
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    [`&.${classes.input}`]: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: `1px solid ${theme.palette.neutral.dark3}`,
            },
            '&:hover fieldset': {
                border: `1px solid ${theme.palette.neutral.dark3}`,
            },
            '&.Mui-focused fieldset': {
                border: `1px solid ${theme.palette.neutral.dark3}`,
            },
        },
        backgroundColor: theme.palette.forms.hover,
        borderRadius: 6,
    },
    [`&.${classes.inputError}`]: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: `1px solid ${theme.palette.error.main}`,
            },
            '&:hover fieldset': {
                border: `1px solid ${theme.palette.error.main}`,
            },
            '&.Mui-focused fieldset': {
                border: `1px solid ${theme.palette.error.main}`,
            },
        },
        backgroundColor: theme.palette.forms.hover,
        borderRadius: 6,
    },
    [`& .${classes.textFieldInput}`]: {
        height: 28,
        width: 144,
        color: theme.palette.text.normal,
        backgroundColor: theme.palette.input.hoverBackground,
        fontSize: Fonts.size.small,
    },
    [`& .${classes.textField}`]: {
        fontColor: theme.palette.text.light,
    },
    [`& .${classes.inputProps}`]: {
        caretColor: theme.palette.primary.light,
    },
}))

interface NameInputProps {
    value?: string
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: () => void
    onEscape?: () => void
    error?: boolean
}

const NameInput: React.FC<NameInputProps> = ({ value, handleChange, onBlur, onEscape, error }) => {
    return (
        <StyledTextField
            autoFocus
            className={error ? classes.inputError : classes.input}
            value={value}
            onChange={handleChange}
            InputProps={{
                className: classes.textFieldInput,
                classes: {
                    input: classes.textField,
                },
            }}
            inputProps={{
                className: classes.inputProps,
                style: {
                    fontSize: Fonts.size.small,
                },
            }}
            onBlur={onBlur}
            onKeyPress={(e) => {
                if (e.key === 'Enter' && onBlur) onBlur()
            }}
            onKeyDown={(e) => {
                if (e.key === 'Escape' && onEscape) onEscape()
            }}
            onFocus={(event) => {
                event.target.select()
            }}
        />
    )
}

export default NameInput
