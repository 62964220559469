import { Box, Stack, useTheme } from '@mui/material'
import GradientButton from 'components/GradientButton'
import Text from 'components/Text'
import Login from '../../assets/images/auth/login/Login.png'
import Login2x from '../../assets/images/auth/login/Login2x.png'
import Login3x from '../../assets/images/auth/login/Login3x.png'
import useIsMobileView from 'hooks/useIsMobileView'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { EMAIL_REGEX } from 'constants/ui'
import { AUTH_LOCAL_STORAGE_KEY, parseJwtEncodedTokenAndGetReduxState } from 'services/types/auth-types'
import { setAuthState } from 'services/actions/auth-actions'
import { useHistory } from 'react-router-dom'
import { RouteConstants } from 'navigation/navigation-types'
import StyledTextField from 'components/StyledTextField'
import { UserManager } from 'services/api/UserManager'
import { showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'

interface LoginFormProps {
    toggleToRegistrationMode: () => void
    toggleToForgotPasswordMode: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({ toggleToRegistrationMode, toggleToForgotPasswordMode }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const history = useHistory()
    const isMobileView = useIsMobileView(theme)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [email, setEmail] = useState('')
    const [isEmailInErrorState, setIsEmailInErrorState] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState('')

    const [password, setPassword] = useState('')
    const [isPasswordInErrorState, setIsPasswordInErrorState] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('')

    const handleEmailChange = (email: string) => {
        setEmail(email)
    }

    const onLogin = async (email: string, password: string) => {
        let isFormInErrorState = false
        if (email.length === 0) {
            setIsEmailInErrorState(true)
            setEmailErrorMessage('Fill the email field')
            isFormInErrorState = true
        }

        if (email.length !== 0 && !EMAIL_REGEX.test(email)) {
            setIsEmailInErrorState(true)
            setEmailErrorMessage("Email doesn't have a correct format")
            isFormInErrorState = true
        }

        if (password.length === 0) {
            setIsPasswordInErrorState(true)
            setPasswordErrorMessage('Fill the password field')
            isFormInErrorState = true
        }

        if (isFormInErrorState) return

        setIsLoading(true)
        try {
            const { token } = await UserManager.getManager().loginAsync(email, password)
            localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, token)
            const state = parseJwtEncodedTokenAndGetReduxState(token)
            dispatch(setAuthState(state))
            history.push(RouteConstants.projectsList)
        } catch (e) {
            showFlashMessageWithTimeout(dispatch, e, 'error', 4000)
            setIsLoading(false)
        }

        // TODO set based on error from server
        // setIsEmailInErrorState(true)
        // setEmailErrorMessage('This email doesn’t exist.')
        // setIsPasswordInErrorState(true)
        // setPasswordErrorMessage('The password is incorrect')
    }

    return (
        <Stack direction={isMobileView ? 'column-reverse' : 'row'} spacing={3} alignItems='stretch'>
            <Box style={{ maxHeight: 360, maxWidth: 250 }}>
                <img
                    src={Login}
                    srcSet={`${Login2x} 2x, ${Login3x} 3x`}
                    style={{ maxWidth: '100%', width: 'auto', height: 'auto' }}
                    alt='login image'
                />
            </Box>
            <form>
                <Stack direction='column' flex={1} spacing={2} width={isMobileView ? undefined : 316} justifyContent='flex-start'>
                    <Text fontWeight={700} fontSize={20}>
                        Sign in
                    </Text>
                    <StyledTextField
                        value={email}
                        label='Email address'
                        onChange={(e) => handleEmailChange(e.target.value)}
                        type='email'
                        size='small'
                        showTriangleError
                        error={isEmailInErrorState}
                        errorText={emailErrorMessage}
                        autoFocus
                    />
                    <StyledTextField
                        value={password}
                        label='Password'
                        onChange={(e) => setPassword(e.target.value)}
                        type='password'
                        style={{ color: theme.palette.text.primary }}
                        size='small'
                        showTriangleError
                        error={isPasswordInErrorState}
                        errorText={passwordErrorMessage}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') onLogin(email, password)
                        }}
                    />

                    <Text
                        fontSize={14}
                        style={{
                            color: theme.palette.neutral.unique1,
                            fontWeight: 400,
                            cursor: 'pointer',
                        }}
                        onClick={toggleToForgotPasswordMode}
                    >
                        Forgot password?
                    </Text>

                    <Stack direction='row' justifyContent='stretch'>
                        <GradientButton
                            isLoading={isLoading}
                            disabled={email.length === 0 || password.length === 0}
                            title='Sign in'
                            onClick={() => onLogin(email, password)}
                            style={{ flex: 1 }}
                        />
                        <Box style={{ width: 32 }}></Box>
                    </Stack>

                    {process.env.NODE_ENV === 'development' && (
                        <Stack direction='row' justifyContent='stretch'>
                            <GradientButton
                                isLoading={isLoading}
                                title='Mock login'
                                onClick={() => {
                                    onLogin('test@test.com', 'test')
                                }}
                                style={{ flex: 1 }}
                            />
                            <Box style={{ width: 32 }}></Box>
                        </Stack>
                    )}

                    <Text
                        fontSize={14}
                        color='normal'
                        style={{
                            fontWeight: 400,
                        }}
                    >
                        New user?{' '}
                        <span
                            style={{ color: theme.palette.neutral.unique1, cursor: 'pointer' }}
                            onClick={() => toggleToRegistrationMode()}
                        >
                            Register now
                        </span>
                    </Text>
                </Stack>
            </form>
        </Stack>
    )
}

export default LoginForm
