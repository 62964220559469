import Button, { ButtonProps } from '@mui/material/Button'
import Fonts from 'constants/fonts'
import { styled } from '@mui/material/styles'
import { FC, ReactNode } from 'react'

interface StyledButtonProps extends ButtonProps {
    category?: 'normal' | 'secondary' | 'alert' | 'accent'
    disabled?: boolean
}

const StyledButton = styled(Button)<StyledButtonProps>(({ theme, category, disabled }) => {
    const lightColor =
        category === 'normal'
            ? theme.palette.primary.light
            : category === 'secondary'
            ? theme.palette.secondary.light
            : category === 'alert'
            ? theme.palette.alert.light
            : category === 'accent'
            ? theme.palette.accent.light
            : theme.palette.primary.light
    const mainColor =
        category === 'normal'
            ? theme.palette.primary.main
            : category === 'secondary'
            ? theme.palette.secondary.main
            : category === 'alert'
            ? theme.palette.alert.main
            : category === 'accent'
            ? theme.palette.accent.main
            : theme.palette.primary.main
    const darkColor =
        category === 'normal'
            ? theme.palette.primary.dark
            : category === 'secondary'
            ? theme.palette.secondary.dark
            : category === 'alert'
            ? theme.palette.alert.dark
            : category === 'accent'
            ? theme.palette.accent.dark
            : theme.palette.primary.dark
    return {
        background: disabled
            ? `linear-gradient(280.61deg, ${mainColor}66 1.38%, ${lightColor}66 168.24%)`
            : `linear-gradient(280.61deg, ${mainColor} 1.38%, ${lightColor} 168.24%)`,
        '&:hover': {
            background: disabled
                ? `linear-gradient(280.61deg, ${mainColor}66 1.38%, ${lightColor}66 168.24%)`
                : `linear-gradient(270deg, ${darkColor} -3.09%, ${mainColor} 111.86%)`,
        },
        textTransform: 'none',
        fontSize: Fonts.size.normal,
    }
})

export interface GradientButtonBaseProps extends StyledButtonProps {
    children?: ReactNode
    buttonType?: 'normal' | 'secondary' | 'alert' | 'accent'
}

const GradientButtonBase: FC<GradientButtonBaseProps> = ({ children, buttonType, ...rest }) => {
    return (
        <StyledButton
            variant='contained'
            disableElevation={rest.disableElevation === false ? false : true}
            category={buttonType}
            {...rest}
        >
            {children}
        </StyledButton>
    )
}

export default GradientButtonBase
