import Navigation from './Navigation'
import { RouteConstants, RouteParameters, LoadProjectRouteParams } from 'navigation/navigation-types'
import { Route, Switch } from 'react-router-dom'
import UserProfile from 'screens/UserProfile'
import ProjectsOverview from 'screens/ProjectsOverview'
import CollectionDetails from 'screens/CollectionDetails'
import EndpointDetails from 'screens/EndpointDetails'
import { propertyOf } from '../shared/utils/generic-utils'
import { FC, useEffect, useState } from 'react'
import ProtectedRoute from './ProtectedRoute'
import Unauthenticated from 'screens/auth/Unauthenticated'
import LoadProject from 'screens/loading/LoadProject'
import SharedCodeScreen from 'screens/sharedCode/SharedCodeScreen'
import ProjectDetails from 'screens/ProjectDetails'
import Login from 'screens/auth/Login'
import { AUTH_LOCAL_STORAGE_KEY, parseJwtEncodedTokenAndGetReduxState } from 'services/types/auth-types'
import { logoutUser, setAuthState } from 'services/actions/auth-actions'
import { useDispatch, useSelector } from 'react-redux'
import AppLoading from 'screens/auth/AppLoading'
import { getAuthState } from 'services/selectors/auth-selectors'
import NotMatch from './NotMatch'
import LandingPage from 'screens/LandingPage'

const MainNavigator: FC = () => {
    const { isAuthenticated } = useSelector(getAuthState)
    const [finishedAuthTokenRetrieval, setFinishedAuthTokenRetrieval] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(() => {
        console.debug('Initializing token from storage...')
        const token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (token) {
            console.debug('Token found, parsing...')
            const state = parseJwtEncodedTokenAndGetReduxState(token)
            dispatch(setAuthState(state))
        } else {
            console.debug('No token found...')
            dispatch(logoutUser())
        }
        setFinishedAuthTokenRetrieval(true)
    }, [dispatch])

    if (!finishedAuthTokenRetrieval) return <AppLoading />
    /**
     * This is because our landing page is in a different sub-domain hosted on Wordpress
     * and we want to redirect there users who go to root domain and who are not authenticated
     */
    if (
        finishedAuthTokenRetrieval &&
        !isAuthenticated &&
        window.location.pathname === '/' &&
        process.env.NODE_ENV !== 'development'
    ) {
        window.location.replace(`https://home.${window.location.hostname}`)
    }

    return (
        <Navigation>
            <Switch>
                <Route exact path={RouteConstants.unauthenticated} component={Unauthenticated} />
                <Route exact path={RouteConstants.login} component={Login} />
                <Route
                    exact
                    path={`${RouteConstants.projectLoading}/:${propertyOf<LoadProjectRouteParams>('projectId')}`}
                    component={LoadProject}
                />
                <Route exact path={RouteConstants.home} component={isAuthenticated ? ProjectsOverview : LandingPage} />
                <ProtectedRoute exact path={RouteConstants.projectsList} component={ProjectsOverview} />
                <ProtectedRoute exact path={`${RouteConstants.user}${RouteConstants.profile}`} component={UserProfile} />
                <ProtectedRoute
                    exact
                    path={`${RouteConstants.project}/:${propertyOf<RouteParameters>('projectId')}`}
                    component={ProjectDetails}
                />
                <ProtectedRoute
                    exact
                    path={`${RouteConstants.project}/:${propertyOf<RouteParameters>('projectId')}${RouteConstants.shared}`}
                    component={SharedCodeScreen}
                />
                <ProtectedRoute exact path={`${RouteConstants.user}${RouteConstants.profile}`} component={UserProfile} />
                <ProtectedRoute
                    exact
                    path={`${RouteConstants.project}/:${propertyOf<RouteParameters>('projectId')}/:${propertyOf<RouteParameters>(
                        'collectionId'
                    )}`}
                    component={CollectionDetails}
                />
                <ProtectedRoute
                    exact
                    path={`${RouteConstants.project}/:${propertyOf<RouteParameters>('projectId')}/:${propertyOf<RouteParameters>(
                        'collectionId'
                    )}/:${propertyOf<RouteParameters>('endpointId')}`}
                    component={EndpointDetails}
                />

                <Route path='*' component={NotMatch} />
            </Switch>
        </Navigation>
    )
}

export default MainNavigator
