import { useTheme, MenuItem, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import DropdownMenu from 'components/DropdownMenu'
import Text from 'components/Text'
import React, { useState } from 'react'
import { EditedSharedCodeTypeInfo } from 'types/sharedCode-types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const PREFIX = 'NewTypeSelector'

const classes = {
    container: `${PREFIX}-container`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderRadius: 4,
        padding: '8px 16px',
        marginRight: theme.spacing(2),
        border: `1px solid ${theme.palette.text.faded}`,
    },
}))

interface NewTypeSelectorProps {
    items: EditedSharedCodeTypeInfo[]
    selectedItem: EditedSharedCodeTypeInfo
    onSelect: (typeInfo: EditedSharedCodeTypeInfo) => void
}

const NewTypeSelector: React.FC<NewTypeSelectorProps> = ({ items, selectedItem, onSelect }) => {
    const theme = useTheme()

    const [isOpen, setIsOpen] = useState(false)

    return (
        <DropdownMenu
            placement='bottom'
            open={isOpen}
            onClose={() => setIsOpen(false)}
            content={
                <Box>
                    <MenuItem
                        value={'None'}
                        style={{ color: theme.palette.text.normal }}
                        onClick={() => {
                            onSelect({ file: '', name: 'None' })
                            setIsOpen(false)
                        }}
                    >
                        {'None'}
                    </MenuItem>
                    {items.map((item) => {
                        return (
                            <MenuItem
                                key={item.file + item.name}
                                value={item.name}
                                onClick={() => {
                                    onSelect(item)
                                    setIsOpen(false)
                                }}
                                style={{ color: theme.palette.text.normal }}
                            >
                                {item.name}
                            </MenuItem>
                        )
                    })}
                </Box>
            }
        >
            <StyledBox
                className={classes.container}
                style={{ color: theme.palette.text.normal, cursor: 'pointer', maxWidth: 180 }}
                onClick={() => setIsOpen(true)}
            >
                <Text
                    color='normal'
                    fontSize={16}
                    style={{
                        marginRight: theme.spacing(2),
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {selectedItem.name}
                </Text>

                <KeyboardArrowDownIcon
                    style={{
                        transform: isOpen ? 'rotate(-180deg)' : undefined,
                    }}
                />
            </StyledBox>
        </DropdownMenu>
    )
}

export default NewTypeSelector
