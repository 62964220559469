import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, useTheme, Tooltip, Typography, Dialog } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
    getActiveProject,
    isProjectEditable,
    getActiveProjectSharedCode,
    getCollectionById,
    getSelectedModeType,
    getEndpointById,
} from 'services/selectors/projects-selectors'
import { Endpoint, Project, RequestType, UrlRequestParameter } from 'shared/types/project-types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { generateNewUrlRequestParameter } from 'shared/utils/project-utils'
import InlineMonacoEditor from 'components/MonacoEditor/InlineMonacoEditor'
import TextEditor from 'components/TextEditor'
import DropdownMenu from 'components/DropdownMenu'
import {
    addUrlRequestParameterToEndpoint,
    updateEndpointDetail,
    editEndpointUrlRequestParameterFromEndpoint,
    removeEndpoint,
    removeUrlRequestParameterFromEndpoint,
    setResponse,
    editResponse,
    editBodyRequestForEndpoint,
    editEndpointPathVariable,
    moveResponseToFirst,
} from 'services/actions/project-actions'
import AddIcon from '@mui/icons-material/Add'
import ResponseInfoDropdown from './ResponseInfoDropdown'
import RequestTypeDropdownMenu from './RequestTypeDropdownMenu'
import RequestParameters from './RequestParameters'
import { showFlashMessage, showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import { useHistory, useParams } from 'react-router-dom'
import GradientButton from 'components/GradientButton'
import { RouteConstants } from 'navigation/navigation-types'
import Fonts from 'constants/fonts'
import Text from 'components/Text'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import EndpointInput from './EndpointInput'
import ResponseTab from './ResponseTab'
import { MAIN_BORDER_RADIUS } from 'constants/ui'
import DeleteModal from 'components/DeleteModal'
import { ReduxState } from 'services/types/mainReducer-types'
import { ModeType } from 'services/types/userSettings-types'
import { setSelectedModeType } from 'services/actions/userSettings-actions'
import PathVariable from './PathVariable'
import { getErrorMessage } from 'shared/utils/generic-utils'
import {
    duplicateNameCheck,
    getDuplicatedUrlAndRequestTypeEndpointName,
    getPathVariables,
    PreviousParams,
    savePreviousParamsAndSetPreviousParamsToEndpoint,
} from 'utils/project-utils'
import useIsMobileView from 'hooks/useIsMobileView'
import { getIsPrefillRequestOnCreateActive } from 'services/selectors/userSettings-selectors'
import NotMatch from 'navigation/NotMatch'
import EditPanel from 'components/EditPanel'

const PREFIX = 'ApiDetails'

const classes = {
    mainContainer: `${PREFIX}-mainContainer`,
    inputRow: `${PREFIX}-inputRow`,
    paramRequestRow: `${PREFIX}-paramRequestRow`,
    editorBox: `${PREFIX}-editorBox`,
    requestTypeMenu: `${PREFIX}-requestTypeMenu`,
    addButton: `${PREFIX}-addButton`,
    responseTabRow: `${PREFIX}-responseTabRow`,
    responseCreate: `${PREFIX}-responseCreate`,
    endpointInfoText: `${PREFIX}-endpointInfoText`,
    tabListItem: `${PREFIX}-tabListItem`,
    tabListItemTitle: `${PREFIX}-tabListItemTitle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.mainContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 8,
    },

    [`& .${classes.inputRow}`]: {
        display: 'flex',
        marginTop: 24,
        marginBottom: 16,
    },

    [`& .${classes.paramRequestRow}`]: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 24,
        marginBottom: 8,
    },

    [`& .${classes.editorBox}`]: {
        borderBottomLeftRadius: MAIN_BORDER_RADIUS,
        borderBottomRightRadius: MAIN_BORDER_RADIUS,
        backgroundColor: theme.palette.monacoEditor.backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: 8,
        position: 'relative',
    },

    [`& .${classes.requestTypeMenu}`]: {
        height: 40,
        width: 84,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        cursor: 'pointer',
        marginRight: 10,
    },

    [`& .${classes.addButton}`]: {
        background: 'linear-gradient(280deg, #8864D3 2%, #C690F1 168%)',
        textTransform: 'none',
        fontSize: 16,
        width: 64,
        marginTop: 16,
    },

    [`& .${classes.responseTabRow}`]: {
        display: 'flex',
        flex: 1,
    },

    [`& .${classes.responseCreate}`]: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32,
        backgroundColor: '#55577033',
        cursor: 'pointer',
    },

    [`& .${classes.endpointInfoText}`]: {
        padding: theme.spacing(1),
        borderRadius: 6,
        border: `2px solid transparent`,
        '&:hover': {
            backgroundColor: theme.palette.input.hoverBackground,
        },
    },

    [`& .${classes.tabListItem}`]: {
        color: theme.palette.text.normal,
        fontWeight: 'normal',
        height: 52,
        width: 256,
        maxWidth: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.dropdown.hover,
            fontWeight: 'bold',
        },
    },

    [`& .${classes.tabListItemTitle}`]: {
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}))

interface ApiProps {
    endpointId: string
    shouldSetEditMode?: boolean
}

const editorOptions = {
    minimap: {
        enabled: false,
    },
    roundedSelection: false,
    scrollBeyondLastLine: false,
    automaticLayout: true,
    scrollbars: {
        alwaysConsumeMouseWheel: false,
        handleMouseWheel: false,
    },
}

const previousParams: PreviousParams = {}

const ApiDetails: React.FC<ApiProps> = ({ endpointId, shouldSetEditMode }: ApiProps) => {
    const activeProject = useSelector(getActiveProject)
    const activeProjectSharedCode = useSelector(getActiveProjectSharedCode)
    const isPrefillRequestActive = useSelector(getIsPrefillRequestOnCreateActive)
    const endpoint = useSelector((state: ReduxState) => getEndpointById(state, endpointId))
    const isReadOnly = !useSelector(isProjectEditable)
    const selectedMode = useSelector(getSelectedModeType)
    const tabRow = useRef<HTMLDivElement>(null)
    const isEditable = isReadOnly ? false : selectedMode === 'edit' ? true : false
    const [descriptionEditorOpen, setDescriptionEditorOpen] = useState(false)
    const [titleEditorOpen, setTitleEditorOpen] = useState(shouldSetEditMode ? shouldSetEditMode : false)
    const [isRequestTypeMenuOpen, setIsRequestTypeMenuOpen] = useState<boolean>(false)
    const [selectedResponseCode, setSelectedResponseCode] = useState(
        endpoint && endpoint.response.length > 0 ? endpoint.response[0].httpCode : null
    )
    const [responseCreateMenuOpen, setResponseCreateMenuOpen] = useState(false)
    const [requestUrl, setRequestUrl] = useState<string>(endpoint?.requestUrl || '')
    const [requestType, setRequestType] = useState<RequestType>(endpoint?.requestType || RequestType.GET)
    const [bodyRequestText, setBodyRequestText] = useState<string>(endpoint?.bodyRequest?.stringFormat || '')
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [pathParameters, setPathParameters] = useState<UrlRequestParameter[]>([])
    const [duplicateUrlEndpointName, setDuplicateUrlEndpointName] = useState<string | undefined>(undefined)
    const [isTabRowOverflown, setIsTabRowOverflown] = useState<boolean>(false)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
    const [isTabsListOpen, setIsTabsListOpen] = useState<boolean>(false)
    const [nameErrorText, setNameErrorText] = useState<string>('')
    const dispatch = useDispatch()
    const theme = useTheme()
    const isMobileView = useIsMobileView(theme)
    const history = useHistory()
    const params = useParams<{ projectId: string; collectionId: string; endpointId: string }>()
    const { collectionId, projectId } = params
    const collection = useSelector((state: ReduxState) => getCollectionById(state, collectionId))

    // Check for project ID
    if (projectId !== activeProject?.id) return <NotMatch />

    React.useEffect(() => {
        checkIfTabRowIsOverflown()
    }, [tabRow])

    React.useLayoutEffect(() => {
        window.addEventListener('resize', checkIfTabRowIsOverflown)
        return () => window.removeEventListener('resize', checkIfTabRowIsOverflown)
    }, [])

    const checkIfTabRowIsOverflown = () => {
        if (!tabRow.current) return

        if (tabRow.current.clientWidth < tabRow.current.scrollWidth) {
            setIsTabRowOverflown(true)
        } else {
            setIsTabRowOverflown(false)
        }
    }

    useEffect(() => {
        if (shouldSetEditMode) {
            setTitleEditorOpen(true)
        } else {
            setTitleEditorOpen(false)
        }
    }, [shouldSetEditMode])

    useEffect(() => {
        if (endpoint) {
            setDuplicateUrlEndpointName(undefined)
            setRequestType(endpoint.requestType)
            setRequestUrl(endpoint.requestUrl)
            detectAndSetPathVariables(endpoint.requestUrl)
            setBodyRequestText(endpoint.bodyRequest?.stringFormat || '')
            if (endpoint && endpoint.response.length > 0) {
                setSelectedResponseCode(endpoint.response[0].httpCode)
            } else {
                setSelectedResponseCode(null)
            }
        }
    }, [endpointId])

    useEffect(() => {
        setTitleEditorOpen(false)
        setDescriptionEditorOpen(false)
    }, [endpointId])

    useEffect(() => {
        if (endpoint?.bodyRequest?.stringFormat) setBodyRequestText(endpoint.bodyRequest.stringFormat)
    }, [endpoint?.bodyRequest?.stringFormat])

    const onEndpointNameChange = (text: string) => {
        setNameErrorText('')

        if (text === '') {
            setNameErrorText('Name of endpoint should not be empty')
        }
        if (text && duplicateNameCheck(text, 'endpoint', activeProject, collectionId, endpointId)) {
            setNameErrorText('This name of the endpoint already exist in the same collection')
        }
    }

    const onNameSave = (text?: string) => {
        if (nameErrorText) {
            showFlashMessageWithTimeout(dispatch, nameErrorText, 'error', 3000)
            return
        }
        if (endpoint) {
            const copy: Endpoint = { ...endpoint }
            copy.name = text ?? ''
            dispatch(updateEndpointDetail(copy))
            setTitleEditorOpen(false)
            setNameErrorText('')
        }
    }

    const onDescriptionSave = (description?: string) => {
        if (endpoint) {
            const copy: Endpoint = { ...endpoint }
            copy.description = description ?? ''
            dispatch(updateEndpointDetail(copy))
        }
        setDescriptionEditorOpen(false)
    }

    const onRequestTypeChange = (requestType: RequestType) => {
        if (endpoint && endpoint.requestType !== requestType && activeProject) {
            const copy: Endpoint = { ...endpoint }

            const currentRequestTypeIsGet = endpoint.requestType === RequestType.GET
            const newRequestTypeIsGet = requestType === RequestType.GET

            // Saving of previous params is needed only when changing from GET to POST/DELETE/PUT or vice versa
            if (currentRequestTypeIsGet !== newRequestTypeIsGet)
                savePreviousParamsAndSetPreviousParamsToEndpoint(requestType, copy, previousParams, isPrefillRequestActive)
            copy.requestType = requestType

            // If there is endpoint with same requestType and requestUrl, do not save to redux
            if (doesDuplicateEndpointExists(copy, activeProject)) return

            dispatch(updateEndpointDetail(copy))
        }
    }

    const onRequestUrlSave = () => {
        if (endpoint && activeProject) {
            if (endpoint.requestUrl === requestUrl) return

            const copy: Endpoint = { ...endpoint }
            copy.requestUrl = requestUrl

            if (copy.pathParameters) {
                copy.pathParameters.parameters = pathParameters
            }

            // If there is endpoint with same requestType and requestUrl, do not save to redux
            if (doesDuplicateEndpointExists(copy, activeProject)) return

            dispatch(updateEndpointDetail(copy))
        }
    }

    const doesDuplicateEndpointExists = (endpoint: Endpoint, project: Project): boolean => {
        if (endpoint.requestUrl.length === 0) {
            setDuplicateUrlEndpointName(undefined)
            return false
        }
        const duplicatedEndpointName = getDuplicatedUrlAndRequestTypeEndpointName(endpoint, project)
        if (duplicatedEndpointName !== undefined) {
            setDuplicateUrlEndpointName(duplicatedEndpointName)
            return true
        } else {
            setDuplicateUrlEndpointName(undefined)
            return false
        }
    }

    const onRemoveUrlRequestParameter = async (parameterIndex: number) => {
        if (activeProject) {
            dispatch(removeUrlRequestParameterFromEndpoint(endpointId, parameterIndex, activeProject))
        }
    }

    const onAddUrlRequestParameter = async () => {
        if (activeProject) {
            const parameter: UrlRequestParameter = generateNewUrlRequestParameter()
            dispatch(addUrlRequestParameterToEndpoint(endpointId, parameter, activeProject))
        }
    }

    const onEditRequestParameter = async (parameter: UrlRequestParameter, parameterIndex: number) => {
        if (activeProject) {
            dispatch(editEndpointUrlRequestParameterFromEndpoint(endpointId, parameter, parameterIndex, activeProject))
        }
    }

    const onEndpointRemove = async () => {
        setIsDeleteModalOpen(false)
        if (endpoint && activeProject) {
            try {
                dispatch(removeEndpoint(collectionId, endpoint, activeProject))
                // After endpoint delete, redirect to upper endpoint. In case of last endpoint delete, redirect to collection.
                let pathToRedirect = ''
                const currentCollection = activeProject.collections.find((c) => c.id === collectionId)
                if (currentCollection) {
                    const endpointIndex = currentCollection.endpoints.findIndex((e) => e.id === endpointId)
                    if (endpointIndex !== undefined && endpointIndex > 0)
                        pathToRedirect = `${RouteConstants.project}/${projectId}/${collectionId}/${
                            currentCollection.endpoints[endpointIndex - 1].id
                        }`
                    else {
                        pathToRedirect = `${RouteConstants.project}/${projectId}/${collectionId}`
                    }
                }
                history.push(pathToRedirect)
            } catch (e) {
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }
    }

    const onRequestUrlChange = (requestUrl: string) => {
        setRequestUrl(requestUrl)
    }

    const onCopyRequestUrl = () => {
        if (!requestUrl) {
            showFlashMessageWithTimeout(dispatch, 'Request url is empty.', 'error')
            return
        }
        if (activeProject?.baseUrl) {
            navigator.clipboard.writeText(`${activeProject.baseUrl}${endpoint?.requestUrl}`)
            showFlashMessageWithTimeout(dispatch, 'Copied to clipboard', 'success')
        } else {
            showFlashMessageWithTimeout(dispatch, 'Base url for the project is not set', 'error')
        }
    }

    const onCopyResponse = () => {
        if (endpoint) {
            const response = endpoint?.response.find((r) => r.httpCode === selectedResponseCode)?.stringFormat
            if (response && response.length > 0) {
                navigator.clipboard.writeText(response)
                showFlashMessageWithTimeout(dispatch, 'Copied to clipboard', 'success')
            } else {
                showFlashMessageWithTimeout(dispatch, 'Response is empty', 'error')
            }
        }
    }

    const RequestTypeColors = {
        [RequestType.GET]: theme.palette.blue.main,
        [RequestType.POST]: theme.palette.accent.main,
        [RequestType.PUT]: theme.palette.secondary.main,
        [RequestType.DELETE]: theme.palette.alert.main,
    }

    const handleEditorOnBlur = () => {
        const dotColor = endpoint?.response.find((r) => r.httpCode === selectedResponseCode)?.dotColor
        if (selectedResponseCode && dotColor) {
            dispatch(editResponse(selectedResponseCode, selectedResponseCode, dotColor, endpointId))
        }
    }

    const handleBodyRequestEditorOnBlur = () => {
        if (activeProject && bodyRequestText) {
            dispatch(editBodyRequestForEndpoint(endpointId, bodyRequestText, activeProject))
        }
    }

    const onChangeModeType = (type: ModeType) => {
        if (activeProject) {
            dispatch(setSelectedModeType(type))
        }
    }

    const detectAndSetPathVariables = (string: string) => {
        setPathParameters(getPathVariables(string))
    }

    const onPathVariableUpdate = (variable: UrlRequestParameter) => {
        if (activeProject) {
            dispatch(editEndpointPathVariable(activeProject, endpointId, variable))
        }
    }

    const moveTabToFirstPlace = (code: number) => {
        if (activeProject) dispatch(moveResponseToFirst(code, endpointId))
    }

    if (!collection) return <Typography>{`Error. Collection with id: ${collectionId} not found.`}</Typography>
    if (!activeProject) return <Typography>{`Error. Current project not found`}</Typography>

    return (
        <StyledBox className={classes.mainContainer}>
            <EditPanel
                name={collection.name}
                createdAt={activeProject.createdAt}
                lastModifiedAt={activeProject.lastModifiedAt}
                isReadOnly={isReadOnly}
                onChangeModeType={onChangeModeType}
                onDelete={() => setIsDeleteModalOpen(true)}
            />
            <Box style={{ maxWidth: 1000 }}>
                {/* ENDPOINT TITLE */}
                {!isReadOnly && titleEditorOpen ? (
                    <TextEditor
                        onChange={onEndpointNameChange}
                        defaultValue={endpoint?.name}
                        onSave={onNameSave}
                        onCancel={() => setTitleEditorOpen(false)}
                        fontSize={Fonts.size.large}
                        errorMessage={nameErrorText}
                        bold
                        saveOnEnter
                        saveOnBlur
                    />
                ) : (
                    <Text
                        className={classes.endpointInfoText}
                        color='normal'
                        fontSize={24}
                        style={{ fontWeight: 'bold' }}
                        onClick={() => (isEditable ? setTitleEditorOpen(true) : null)}
                    >
                        {endpoint?.name}
                    </Text>
                )}

                {/* ENDPOINT DESCRIPTION */}
                {!isReadOnly && descriptionEditorOpen ? (
                    <TextEditor
                        multiline
                        defaultValue={endpoint?.description}
                        onSave={onDescriptionSave}
                        onCancel={() => setDescriptionEditorOpen(false)}
                        saveOnBlur
                    />
                ) : (
                    <Text
                        className={classes.endpointInfoText}
                        color={endpoint?.description ? 'normal' : 'faded'}
                        fontSize={16}
                        style={{ whiteSpace: 'pre-line' }}
                        onClick={() => (isEditable ? setDescriptionEditorOpen(true) : null)}
                    >
                        {endpoint?.description ? endpoint.description : 'Add description...'}
                    </Text>
                )}
                <Box className={classes.inputRow}>
                    {/* ENDPOINT TYPE DROPDOWN */}
                    <DropdownMenu
                        arrow={false}
                        content={
                            <RequestTypeDropdownMenu
                                onRequestTypeSelect={(type) => {
                                    setRequestType(type)
                                    onRequestTypeChange(type)
                                    setIsRequestTypeMenuOpen(false)
                                }}
                            />
                        }
                        open={isRequestTypeMenuOpen}
                        onClose={() => setIsRequestTypeMenuOpen(false)}
                    >
                        <Box
                            className={classes.requestTypeMenu}
                            style={{
                                backgroundColor: RequestTypeColors[requestType],
                            }}
                            onClick={() => (isEditable ? setIsRequestTypeMenuOpen(true) : null)}
                        >
                            <Text color={theme.palette.neutral.neutral1} fontSize={16} style={{ fontWeight: 'bold' }}>
                                {requestType}
                            </Text>
                        </Box>
                    </DropdownMenu>
                    {/* ENDPOINT URL INPUT */}
                    <EndpointInput
                        requestUrl={requestUrl}
                        isReadOnly={!isEditable}
                        onRequestUrlChange={onRequestUrlChange}
                        onRequestUrlSave={onRequestUrlSave}
                        onCopyRequestUrl={onCopyRequestUrl}
                        getPathVariables={(path: string) => detectAndSetPathVariables(path)}
                        duplicateUrlEndpointName={duplicateUrlEndpointName}
                    />
                </Box>

                {endpoint?.requestType === RequestType.GET && (
                    <Text color='normal' fontSize={16} style={{ fontWeight: 600 }}>
                        Query params
                    </Text>
                )}
                {endpoint?.queryParameters && endpoint.queryParameters.parameters.length > 0 ? (
                    <>
                        {endpoint.queryParameters.parameters.map((p, i) => (
                            <RequestParameters
                                key={i}
                                urlRequestParameter={p}
                                handleParameterRemove={() => onRemoveUrlRequestParameter(i)}
                                handleParameterEdit={(parameter) => onEditRequestParameter(parameter, i)}
                                isReadOnly={!isEditable}
                            />
                        ))}
                    </>
                ) : endpoint?.requestType === RequestType.GET ? (
                    <Text color='faded' fontSize={16} style={{ marginBottom: theme.spacing(2) }}>
                        No parameters
                    </Text>
                ) : null}
                {isEditable && (
                    <GradientButton
                        title='Add'
                        onClick={() => {
                            onAddUrlRequestParameter()
                        }}
                        className={classes.addButton}
                        size='small'
                    />
                )}

                {pathParameters.length > 0 && (
                    <Text color='normal' fontSize={16} style={{ fontWeight: 600, marginTop: 16 }}>
                        Path variables
                    </Text>
                )}
                {pathParameters.length > 0 &&
                    pathParameters.map((pv: UrlRequestParameter, i) => {
                        return (
                            <PathVariable
                                key={i}
                                pathVariable={pv}
                                onDescriptionUpdate={onPathVariableUpdate}
                                onTypeUpdate={onPathVariableUpdate}
                                isReadOnly={!isEditable}
                            />
                        )
                    })}

                {/* POST AND PUT */}
                {(endpoint?.requestType === RequestType.POST || endpoint?.requestType === RequestType.PUT) && (
                    <>
                        <Text color='normal' fontSize={16} style={{ fontWeight: 600, marginTop: 16 }}>
                            Body request
                        </Text>
                        <Box display='flex' flexDirection='column'>
                            <Box className={classes.editorBox}>
                                <InlineMonacoEditor
                                    sharedCodes={activeProjectSharedCode}
                                    onBlur={handleBodyRequestEditorOnBlur}
                                    onSaveAction={handleBodyRequestEditorOnBlur}
                                    width={'99%'}
                                    defaultLanguage='typescript'
                                    value={bodyRequestText}
                                    theme={'customTheme'}
                                    onChange={(newBodyRequestText) => {
                                        if (newBodyRequestText) {
                                            setBodyRequestText(newBodyRequestText)
                                        }
                                    }}
                                    options={{ ...editorOptions, readOnly: isReadOnly }}
                                />
                            </Box>
                        </Box>
                    </>
                )}

                <Box className={classes.paramRequestRow}>
                    <Text color='normal' fontSize={16} style={{ fontWeight: 600 }}>
                        Response
                    </Text>
                </Box>

                {/* EDITOR WITH TABS */}
                <Box className={classes.responseTabRow}>
                    <Box
                        style={{
                            display: 'flex',
                            overflowX: 'hidden',
                        }}
                        ref={tabRow}
                    >
                        {endpoint &&
                            endpoint.response.map((r, index, array) => (
                                <ResponseTab
                                    key={r.httpCode}
                                    r={r}
                                    selectedResponseCode={selectedResponseCode}
                                    setSelectedResponseCode={setSelectedResponseCode}
                                    isReadOnly={isReadOnly}
                                    endpointId={endpointId}
                                    isFirst={index === 0}
                                    onAfterDelete={() => {
                                        if (index === 0) {
                                            if (array[index + 1]) setSelectedResponseCode(array[index + 1].httpCode)
                                            else {
                                                setSelectedResponseCode(null)
                                            }
                                        } else {
                                            setSelectedResponseCode(array[index - 1].httpCode)
                                        }

                                        setTimeout(() => checkIfTabRowIsOverflown())
                                    }}
                                />
                            ))}
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                        {isEditable && (
                            <>
                                {isTabRowOverflown && (
                                    <Box className={classes.responseCreate} onClick={() => setIsTabsListOpen(true)}>
                                        <KeyboardArrowDownIcon
                                            style={{
                                                color: theme.palette.text.light,
                                            }}
                                        />
                                    </Box>
                                )}
                                <DropdownMenu
                                    open={responseCreateMenuOpen}
                                    onClose={() => {
                                        setResponseCreateMenuOpen(false)
                                    }}
                                    borderRadius={MAIN_BORDER_RADIUS}
                                    content={
                                        <ResponseInfoDropdown
                                            mode='create'
                                            endpointId={endpointId}
                                            onClose={() => setResponseCreateMenuOpen(false)}
                                            setSelectedResponseCode={setSelectedResponseCode}
                                            isMobileView={isMobileView}
                                            onAfterCreate={() => {
                                                setTimeout(() => checkIfTabRowIsOverflown())
                                            }}
                                        />
                                    }
                                    arrow={false}
                                >
                                    <Box
                                        className={classes.responseCreate}
                                        style={{
                                            borderTopRightRadius: 3,
                                        }}
                                        onClick={() => {
                                            if (isMobileView) setIsEditDialogOpen(true)
                                            else setResponseCreateMenuOpen(true)
                                        }}
                                    >
                                        <AddIcon style={{ color: theme.palette.text.light }} />
                                    </Box>
                                </DropdownMenu>
                                {endpoint && endpoint.response.length === 0 && (
                                    <Text color='faded' fontSize={16} style={{ marginLeft: 16 }}>
                                        No response added
                                    </Text>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
                {endpoint && selectedResponseCode !== null && (
                    <Box display='flex' flexDirection='column'>
                        <Box className={classes.editorBox}>
                            {selectedResponseCode && (
                                <InlineMonacoEditor
                                    sharedCodes={activeProjectSharedCode}
                                    onBlur={handleEditorOnBlur}
                                    onSaveAction={handleEditorOnBlur}
                                    width={'99%'}
                                    defaultLanguage='typescript'
                                    value={
                                        endpoint?.response.find((r) => r.httpCode === selectedResponseCode)?.stringFormat || ''
                                    }
                                    theme={'customTheme'}
                                    onChange={(responseText) => {
                                        if (responseText !== undefined && selectedResponseCode) {
                                            dispatch(setResponse(responseText, selectedResponseCode, endpointId))
                                        }
                                    }}
                                    options={{ ...editorOptions, readOnly: !isEditable }}
                                />
                            )}
                            {endpoint && endpoint.response.length > 0 && (
                                <Tooltip title='Copy to clipboard' placement='left-start' arrow>
                                    <ContentCopyRoundedIcon
                                        style={{
                                            color: theme.palette.neutral.neutral2,
                                            cursor: 'pointer',
                                            marginRight: 40,
                                            transform: 'rotateX(180deg)',
                                            position: 'absolute',
                                            right: 0,
                                            top: 20,
                                        }}
                                        onClick={onCopyResponse}
                                    />
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                )}
                {/* This box exists for additional space at the bottom, in case of too long request param list. */}
                <Box style={{ height: 200 }} />
            </Box>
            {endpoint && (
                <DeleteModal
                    type='endpoint'
                    name={endpoint.name}
                    isOpen={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onDelete={onEndpointRemove}
                />
            )}
            <Dialog onClose={() => setIsEditDialogOpen(false)} open={isEditDialogOpen} style={{ overflow: 'hidden' }}>
                <ResponseInfoDropdown
                    mode='create'
                    endpointId={endpointId}
                    onClose={() => setIsEditDialogOpen(false)}
                    setSelectedResponseCode={setSelectedResponseCode}
                    isMobileView={isMobileView}
                    onAfterCreate={() => {
                        setTimeout(() => checkIfTabRowIsOverflown())
                    }}
                />
            </Dialog>
            <Dialog onClose={() => setIsTabsListOpen(false)} open={isTabsListOpen} style={{ overflow: 'hidden' }}>
                <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', overflowX: 'hidden' }}>
                    {endpoint?.response.map((r) => {
                        return (
                            <Box
                                className={classes.tabListItem}
                                key={r.httpCode}
                                onClick={() => {
                                    setSelectedResponseCode(r.httpCode)
                                    moveTabToFirstPlace(r.httpCode)
                                    setIsTabsListOpen(false)
                                }}
                            >
                                <Typography className={classes.tabListItemTitle}>{r.httpCode}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Dialog>
        </StyledBox>
    )
}

export default ApiDetails
