import {
    BackgroundImage,
    Collection,
    Endpoint,
    Project,
    RequestType,
    SharedCode,
    UrlRequestParameter,
} from 'shared/types/project-types'
import { v4 as uuidv4 } from 'uuid'

import bgOrange from '../../images/bg-orange.png'
import bgYellow from '../../images/bg-yellow.png'
import bgRed from '../../images/bg-red.png'
import bgGreen from '../../images/bg-green.png'
import bgBlack from '../../images/bg-black.png'
import bgBlue from '../../images/bg-blue.png'
import bgTurquoise from '../../images/bg-turquoise.png'
import bgPurple from '../../images/bg-purple.png'

export const findEndpointById = (project: Project, endpointId: string): Endpoint | undefined => {
    let endpoint = undefined
    for (let index = 0; index < project.collections.length; index++) {
        if (endpoint === undefined) {
            endpoint = project.collections[index].endpoints.find((e) => e.id === endpointId)
        }
    }
    return endpoint
}

export const findCollectionIndexByEndpointId = (project: Project, endpointId: string): number => {
    return project.collections.findIndex((c) => {
        return c.endpoints.some((e) => e.id === endpointId)
    })
}

export const findEndpointIndexByEndpointId = (project: Project, endpointId: string): number => {
    const collectionIndex = findCollectionIndexByEndpointId(project, endpointId)
    if (collectionIndex === -1) {
        return -1
    }
    return project.collections[collectionIndex].endpoints.findIndex((e) => e.id === endpointId)
}

/**
 * Returns indexes used to address specific endpoint inside a project
 */
export const getIndexesByEndpointId = (
    project: Project,
    endpointId: string
): { collectionIndex: number; endpointIndex: number } => {
    const collectionIndex = findCollectionIndexByEndpointId(project, endpointId)
    const endpointIndex = findEndpointIndexByEndpointId(project, endpointId)
    return {
        collectionIndex,
        endpointIndex,
    }
}

// Map in format: { [collection ID]: [...endpoint ID's] }
export const getEndpointIdsByCollectionIds = (project: Project): { [collectionId: string]: string[] } => {
    const result: { [collectionId: string]: string[] } = {}
    project.collections.map((collection) => {
        const endpointsIds: string[] = collection.endpoints.map((e) => e.id)
        result[collection.id] = endpointsIds
    })
    return result
}

// TODO: Change this simple id to id returned from backend
export const generateNewCollection = (): Collection => {
    const result: Collection = {
        id: uuidv4(),
        name: 'Untitled',
        description: '',
        endpoints: [],
    }
    return result
}

export const generateNewEndpoint = (): Endpoint => {
    const result: Endpoint = {
        id: uuidv4(),
        name: '',
        description: '',
        requestType: RequestType.GET,
        requestUrl: '',
        bodyRequest: { stringFormat: '' },
        queryParameters: { parameters: [] },
        response: [],
        pathParameters: { parameters: [] },
        progressState: {
            backend: 'notStarted',
            frontend: 'notStarted',
            e2e: 'notStarted',
        },
    }

    return result
}

export const generateNewSharedCode = (): SharedCode => {
    const result: SharedCode = {
        id: uuidv4(),
        name: '',
        content: '',
    }
    return result
}

export const duplicateEndpoint = (endpoint: Endpoint): Endpoint => {
    const endpointCopy: Endpoint = JSON.parse(JSON.stringify(endpoint))
    endpointCopy.id = uuidv4()
    endpointCopy.name = `${endpointCopy.name}-copy`
    endpointCopy.requestUrl = ''
    return endpointCopy
}

export const generateNewProject = (name: string, backgroundImage?: BackgroundImage): Omit<Project, 'id'> => {
    const result: Omit<Project, 'id'> = {
        name: name,
        baseUrl: '',
        collections: [],
        isSharedForView: false,
        shareLink: '',
        backgroundImage: backgroundImage ? backgroundImage : undefined,
        sharedCode: [],
        createdAt: new Date(),
        lastModifiedAt: new Date(),
        openApiSettings: { dateType: 'date-time' },
    }

    return result
}

export const generateNewUrlRequestParameter = (): UrlRequestParameter => {
    const result: UrlRequestParameter = {
        name: '',
        type: 'string',
    }
    return result
}

export const getBackgroundImage = {
    ['orange']: 'url(' + bgOrange + ')',
    ['red']: 'url(' + bgRed + ')',
    ['yellow']: 'url(' + bgYellow + ')',
    ['green']: 'url(' + bgGreen + ')',
    ['black']: 'url(' + bgBlack + ')',
    ['blue']: 'url(' + bgBlue + ')',
    ['turquoise']: 'url(' + bgTurquoise + ')',
    ['purple']: 'url(' + bgPurple + ')',
}
