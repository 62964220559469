import { Box, Stack, useTheme } from '@mui/material'
import { FC } from 'react'
import { ThemeType } from 'services/types/userSettings-types'
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded'
import ModeNightOutlinedIcon from '@mui/icons-material/ModeNightOutlined'
import Brightness6OutlinedIcon from '@mui/icons-material/Brightness6Outlined'
import Text from './Text'

interface ThemeCardProps {
    themeOption: ThemeType
    isActive: boolean
    onClick: () => void
}

const getOptionHint = (themeType: ThemeType) => {
    switch (themeType) {
        case 'auto':
            return 'Dark and Light'
        case 'dark':
            return 'Dark mode'
        case 'light':
            return 'Light mode'
    }
}

const ThemeCard: FC<ThemeCardProps> = ({ themeOption, isActive, onClick }) => {
    const theme = useTheme()

    const getThemeIcon = (style: ThemeType) => {
        switch (style) {
            case 'auto':
                return <Brightness6OutlinedIcon sx={{ color: theme.palette.blue.main }} />
            case 'dark':
                return <ModeNightOutlinedIcon sx={{ color: theme.palette.blue.main }} />
            case 'light':
                return <WbSunnyRoundedIcon sx={{ color: theme.palette.yellow }} />
        }
    }

    console.log(themeOption)

    return (
        <Box
            display='flex'
            flexDirection='row'
            marginRight={theme.spacing(3.5)}
            sx={{
                minHeight: 56,
                minWidth: 140,
                cursor: 'pointer',
                borderRadius: '4px',
                border: `2px solid ${isActive ? theme.palette.neutral.light2 : 'transparent'}`,
                backgroundColor: isActive ? theme.palette.background.paper : undefined,
                '&:hover': {
                    backgroundColor: theme.palette.background.paper,
                },
            }}
            onClick={onClick}
        >
            <Box display='flex' alignItems='center' marginLeft={theme.spacing(1.5)} marginRight={theme.spacing(1)}>
                {getThemeIcon(themeOption)}
            </Box>
            <Stack display='flex' flexDirection='column' justifyContent='center' marginRight={theme.spacing(1.5)}>
                <Text fontSize={12}>{themeOption === 'auto' ? 'Automatic' : 'Manual'}</Text>
                <Text fontSize={16}>{getOptionHint(themeOption)}</Text>
            </Stack>
        </Box>
    )
}

export default ThemeCard
