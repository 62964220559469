import { GlobalVars } from 'global'
import {
    ChangePasswordRequest,
    LoginRequest,
    LoginResponse,
    RegisterUserRequest,
    RegisterUserResponse,
} from 'shared/payload-types/user-payloads'
import { ErrorCategory, logError } from './Analytics'
import { handleNetworkResponseAndGetJsonAsync, handleNetworkResponseWithNoPayloadAsync, Network } from './Network'

export class UserManager {
    static _instance: UserManager | null = null

    static getManager(): UserManager {
        if (this._instance === null) {
            this._instance = new UserManager()
        }
        return this._instance
    }

    /**
     * Login function for a user
     * @returns
     */
    async loginAsync(email: string, password: string): Promise<LoginResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/login`

        const request: LoginRequest = {
            email,
            password,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'loginAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const data: LoginResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return data
        } else {
            logError(ErrorCategory.Network, 'loginAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }

    /**
     * Register new user function
     * @returns
     */
    async registerAsync(email: string, password: string): Promise<LoginResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/register`

        const request: RegisterUserRequest = {
            email,
            password,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'registerAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const data: RegisterUserResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return data
        } else {
            logError(ErrorCategory.Network, 'registerAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }

    async changePasswordAsync(email: string, currentPassword: string, newPassword: string): Promise<void> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/user/password/change`

        const request: ChangePasswordRequest = {
            email,
            currentPassword,
            newPassword,
        }

        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'changePasswordAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            return handleNetworkResponseWithNoPayloadAsync(response, url)
        } else {
            logError(ErrorCategory.Network, 'changePasswordAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }
}
