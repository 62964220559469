import React, { useEffect, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { RouteConstants } from './navigation-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveProject, getOpenedCollectionsList, isProjectEditable } from 'services/selectors/projects-selectors'
import { Collection, Project, Endpoint, RequestType } from 'shared/types/project-types'
import { duplicateEndpoint, findEndpointById, generateNewEndpoint } from 'shared/utils/project-utils'
import {
    addCollection,
    addEndpoint,
    removeCollection,
    removeEndpoint,
    setOpenedCollectionsIds,
} from 'services/actions/project-actions'
import { generateNewCollection } from 'shared/utils/project-utils'
import SidePanelMenuItem from './SidePanelMenuItem'
import { showFlashMessage, showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import Fonts from 'constants/fonts'
import Text from 'components/Text'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DeleteModal from 'components/DeleteModal'
import { getErrorMessage } from 'shared/utils/generic-utils'
import { duplicateNameCheck, generateNewResponseContent, getCollectionByEndpointId } from 'utils/project-utils'
import { getIsPrefillResponseOnCreateActive } from 'services/selectors/userSettings-selectors'
import { getCreatedEndpointInfo } from 'services/selectors/temporaryUiChanges-selectors'
import { setCreatedEndpointInfo } from 'services/actions/temporaryUiChanges-actions'
import { styled } from '@mui/material/styles'
import { handleTabNavigation, handleLinkClick, getNextUntitledItemNumber } from 'utils/generic-utils'

const PREFIX = 'SidePanel'

const classes = {
    sideNavigationPanel: `${PREFIX}-sideNavigationPanel`,
    sideNavigationBox: `${PREFIX}-sideNavigationBox`,
    activeProjectTitleContainer: `${PREFIX}-activeProjectTitleContainer`,
    activeProjectTitle: `${PREFIX}-activeProjectTitle`,
    menuItemList: `${PREFIX}-menuItemList`,
    addCollectionContainer: `${PREFIX}-addCollectionContainer`,
}

export const OPENED_MENU_WIDTH = 264
export const TOP_PANEL_HEIGHT = 56

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.sideNavigationPanel}`]: {
        position: 'fixed',
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.topPanelBorder}`,
        height: '100%',
        zIndex: 4,
        // Hides scrollbar
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    [`& .${classes.sideNavigationBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    [`& .${classes.activeProjectTitleContainer}`]: {
        paddingLeft: 24,
        // Used for pixel perfect design.
        marginTop: -1,
        display: 'flex',
        alignItems: 'center',
    },
    [`& .${classes.activeProjectTitle}`]: {
        textTransform: 'uppercase',
        letterSpacing: 2,
        cursor: 'pointer',
        paddingBottom: 6,
        paddingTop: 5,
    },
    [`& .${classes.menuItemList}`]: {
        height: '100vh',
        marginBottom: 56,
        '&::-webkit-scrollbar': {
            width: 0,
        },
        overflow: 'auto',
    },
    [`& .${classes.addCollectionContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 18,
        cursor: 'pointer',
        marginTop: 12,
        marginBottom: 40,
    },
}))

interface SidePanelProps {
    handleGoTo: (link: string) => void
    isMobileView: boolean
    toggleMenu: () => void
}

export interface MenuItem {
    id: string
    title: string
    route: string
    subMenu: { id: string; title: string; route: RouteConstants | string; requestType: RequestType }[]
}

const createMenuItemsFromProject = (project: Project): MenuItem[] => {
    const result: MenuItem[] = []
    project.collections.map((c: Collection) => {
        const subMenu = c.endpoints.map((ep: Endpoint) => {
            return {
                id: ep.id,
                title: ep.name,
                route: `${RouteConstants.project}/${project.id}/${c.id}/${ep.id}`,
                requestType: ep.requestType,
            }
        })
        const menuItem = {
            id: c.id,
            title: c.name,
            route: `${RouteConstants.project}/${project.id}/${c.id}`,
            subMenu: subMenu,
        }
        result.push(menuItem)
    })

    return result
}

const SidePanel: React.FC<SidePanelProps> = ({ handleGoTo, isMobileView, toggleMenu }) => {
    const location = useLocation()
    const history = useHistory()
    const theme = useTheme()
    const activeProject = useSelector(getActiveProject)
    const isPrefillResponseActive = useSelector(getIsPrefillResponseOnCreateActive)
    const dispatch = useDispatch()
    const isReadOnly = !useSelector(isProjectEditable)
    const createdEndpointInfo = useSelector(getCreatedEndpointInfo)
    const [activeRoute, setActiveRoute] = useState<string>(location.pathname)
    const [dropdownFor, setDropdownFor] = useState<string | undefined>(undefined)
    const [collectionToRemove, setCollectionToRemove] = useState<Collection | undefined>(undefined)
    const openedCollections = useSelector(getOpenedCollectionsList)
    // Holds id of collection or endpoint to rename
    const [idToRename, setIdToRename] = useState<string>('')
    const [endpointToRemove, setEndpointToRemove] = useState<Endpoint | undefined>(undefined)
    const isProjectDetailsScreenOpened = location.pathname === `${RouteConstants.project}/${activeProject?.id}`

    useEffect(() => {
        setActiveRoute(location.pathname)
    }, [location.pathname])

    useEffect(() => {
        if (createdEndpointInfo) {
            if (!openedCollections.includes(createdEndpointInfo.collectionId)) {
                toggleCollectionOpen(createdEndpointInfo.collectionId)
            }

            setIdToRename('')
        }
    }, [createdEndpointInfo])

    let menuItems: MenuItem[] = []

    if (activeProject) {
        menuItems = createMenuItemsFromProject(activeProject)
    } else {
        menuItems = []
    }

    const isRouteActive = (route: string) => activeRoute === route

    const onCollectionAdd = async () => {
        if (activeProject) {
            const newCollection = generateNewCollection()
            const allCollectionNames = activeProject.collections.map((col) => col.name)
            newCollection.name = `Untitled ${getNextUntitledItemNumber(allCollectionNames)}`
            try {
                dispatch(addCollection(newCollection, activeProject))
                handleGoTo(`${RouteConstants.project}/${activeProject.id}/${newCollection.id}`)
                dispatch(setOpenedCollectionsIds([...openedCollections, newCollection.id]))
                setIdToRename(newCollection.id)
            } catch (e) {
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }
    }

    const validateCollectionName = (name: string, collectionId: string) => {
        return duplicateNameCheck(name, 'collection', activeProject, collectionId)
    }

    const validateEndpointName = (name: string, collectionId: string, endpointId?: string) => {
        return duplicateNameCheck(name, 'endpoint', activeProject, collectionId, endpointId)
    }

    /**
     * Returns name for empty endpoint in format 'Untitled 1', 'Untitled 2' etc. based on amount of other Untitled endpoints in collection
     * @param collectionId id of collection where is empty endpoint
     * @returns name for endpoint
     * */
    const getNameForEmptyEndpoint = (collectionId: string) => {
        const collection = activeProject?.collections.find((c) => c.id === collectionId)
        if (activeProject && collection) {
            return `Untitled ${getNextUntitledItemNumber(collection.endpoints.map((endpoint) => endpoint.name))}`
        }
        return 'Untitled'
    }

    const onEndpointAdd = async (endpointName: string) => {
        if (activeProject && !isReadOnly && createdEndpointInfo) {
            try {
                const newEndpoint: Endpoint = generateNewEndpoint()
                newEndpoint.name = endpointName

                if (isPrefillResponseActive) {
                    newEndpoint.response.push({
                        httpCode: 200,
                        dotColor: 'green',
                        stringFormat: generateNewResponseContent(newEndpoint.name),
                    })
                }

                handleGoTo(`${RouteConstants.project}/${activeProject.id}/${createdEndpointInfo.collectionId}/${newEndpoint.id}`)
                dispatch(addEndpoint(createdEndpointInfo.collectionId, newEndpoint, activeProject))
                dispatch(setCreatedEndpointInfo(undefined))
            } catch (e) {
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }
    }

    const toggleCollectionOpen = (collectionId: string) => {
        if (openedCollections.includes(collectionId)) {
            let copy = [...openedCollections]
            copy = copy.filter((id) => id !== collectionId)
            dispatch(setOpenedCollectionsIds(copy))
        } else {
            dispatch(setOpenedCollectionsIds([...openedCollections, collectionId]))
        }
    }

    const toggleDropdown = (itemId: string) => {
        if (dropdownFor === itemId) {
            setDropdownFor(undefined)
        } else {
            setDropdownFor(itemId)
        }
    }

    const onCollectionRemove = async () => {
        if (collectionToRemove && activeProject) {
            try {
                dispatch(removeCollection(collectionToRemove, activeProject))
                let pathToRedirect = ''
                const collectionIndex = activeProject.collections.findIndex((c) => c.id === collectionToRemove.id)
                if (collectionIndex !== undefined && collectionIndex > 0) {
                    pathToRedirect = `${RouteConstants.project}/${activeProject.id}/${
                        activeProject.collections[collectionIndex - 1].id
                    }`
                } else {
                    pathToRedirect = `${RouteConstants.project}/${activeProject.id}`
                }
                history.push(pathToRedirect)
                setCollectionToRemove(undefined)
            } catch (e) {
                setCollectionToRemove(undefined)
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }
    }

    const onEndpointDuplicate = async (collectionId: string, endpointId: string) => {
        if (activeProject) {
            const collection = activeProject.collections.find((c) => c.id === collectionId)
            if (collection) {
                const endpoint = collection.endpoints.find((e) => e.id === endpointId)
                if (endpoint) {
                    const endpointCopy = duplicateEndpoint(endpoint)
                    try {
                        dispatch(addEndpoint(collectionId, endpointCopy, activeProject))
                        // Redirect does not work without timeout. After duplication we redirect to this endpoint
                        setTimeout(() => {
                            handleGoTo(`${RouteConstants.project}/${activeProject.id}/${collectionId}/${endpointCopy.id}`)
                        }, 50)
                    } catch (e) {
                        dispatch(showFlashMessage(getErrorMessage(e), 'error'))
                    }
                }
            }
        }
    }

    const onEndpointRemove = async () => {
        if (endpointToRemove && activeProject) {
            try {
                const collection = getCollectionByEndpointId(endpointToRemove.id, activeProject)
                if (collection) {
                    const endpointIndex = collection.endpoints.findIndex((e) => e.id === endpointToRemove.id)
                    let pathToRedirect = ''

                    dispatch(removeEndpoint(collection.id, endpointToRemove, activeProject))

                    if (collection && endpointIndex !== undefined && endpointIndex > 0) {
                        pathToRedirect = `${RouteConstants.project}/${activeProject.id}/${collection.id}/${
                            collection.endpoints[endpointIndex - 1].id
                        }`
                    } else {
                        pathToRedirect = `${RouteConstants.project}/${activeProject.id}/${collection.id}`
                    }

                    handleGoTo(pathToRedirect)
                }
                setEndpointToRemove(undefined)
            } catch (e) {
                setEndpointToRemove(undefined)
                showFlashMessageWithTimeout(dispatch, getErrorMessage(e), 'error')
            }
        }
    }

    return (
        <StyledBox
            className={classes.sideNavigationPanel}
            style={{
                marginTop: isMobileView ? TOP_PANEL_HEIGHT + 42 : TOP_PANEL_HEIGHT,
                width: isMobileView ? '100%' : OPENED_MENU_WIDTH,
                paddingTop: isMobileView ? 20 : 0,
            }}
        >
            <Box className={classes.sideNavigationBox}>
                <Box
                    className={classes.activeProjectTitleContainer}
                    style={{
                        backgroundColor: isProjectDetailsScreenOpened ? theme.palette.primary.main : undefined,
                    }}
                >
                    {activeProject && (
                        <Text
                            color={theme.palette.text.normal}
                            fontSize={14}
                            onMouseDown={(e) => {
                                handleTabNavigation(handleLinkClick(e), `${RouteConstants.project}/${activeProject.id}`, () =>
                                    handleGoTo(`${RouteConstants.project}/${activeProject.id}`)
                                )
                                if (isMobileView) toggleMenu()
                            }}
                            className={classes.activeProjectTitle}
                        >
                            {activeProject.name}
                        </Text>
                    )}
                </Box>
                <Box className={classes.menuItemList}>
                    {menuItems.map((item: MenuItem) => (
                        <div key={item.id}>
                            <SidePanelMenuItem
                                item={item}
                                validateCollectionName={validateCollectionName}
                                validateEndpointName={validateEndpointName}
                                getNameForEmptyEndpoint={getNameForEmptyEndpoint}
                                isReadOnly={isReadOnly}
                                isRouteActive={isRouteActive(item.route)}
                                handleGoTo={handleGoTo}
                                onEndpointAdd={onEndpointAdd}
                                onEndpointDuplicate={onEndpointDuplicate}
                                toggleCollectionOpen={toggleCollectionOpen}
                                isOpened={openedCollections.includes(item.id)}
                                isMobileView={isMobileView}
                                toggleMenu={toggleMenu}
                                toggleDropdown={toggleDropdown}
                                dropdownFor={dropdownFor}
                                onCollectionRemove={(collection: Collection) => setCollectionToRemove(collection)}
                                idToRename={idToRename}
                                toggleEditedStateFor={(value: string) => setIdToRename(value)}
                                onEndpointRemove={(endpointId: string) => {
                                    if (activeProject) {
                                        const endpoint = findEndpointById(activeProject, endpointId)

                                        if (endpoint) {
                                            setEndpointToRemove(endpoint)
                                        }
                                    }
                                }}
                                createdEndpointInfo={createdEndpointInfo}
                                setEndpointIdToRename={(endpointId) => {
                                    setIdToRename(endpointId)
                                }}
                            />
                        </div>
                    ))}
                    <Box className={classes.addCollectionContainer} onClick={onCollectionAdd}>
                        <AddRoundedIcon style={{ color: theme.palette.neutral.unique1, fontSize: 16 }} />
                        <Typography
                            style={{
                                color: theme.palette.neutral.unique1,
                                marginLeft: 10,
                                fontSize: Fonts.size.small,
                                fontWeight: 400,
                                cursor: 'pointer',
                            }}
                        >
                            Add collection
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <DeleteModal
                type='collection'
                name={collectionToRemove?.name}
                isOpen={!!collectionToRemove}
                onClose={() => setCollectionToRemove(undefined)}
                onDelete={onCollectionRemove}
            />
            <DeleteModal
                type='endpoint'
                name={endpointToRemove?.name}
                isOpen={!!endpointToRemove}
                onClose={() => setEndpointToRemove(undefined)}
                onDelete={onEndpointRemove}
            />
        </StyledBox>
    )
}

export default SidePanel
