import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import LeavingImage from 'assets/images/LeavingImage'
import EmptyButton from 'components/EmptyButton'
import GradientButton from 'components/GradientButton'
import ModalBase from 'components/ModalBase'
import Text from 'components/Text'

const PREFIX = 'UnsavedChangesWarningModal'

const classes = {
    container: `${PREFIX}-container`,
    buttonsContainer: `${PREFIX}-buttonsContainer`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        minHeight: 468,
        backgroundColor: theme.palette.background.default,
        borderRadius: 16,
        border: `1px solid ${theme.palette.neutral.dark1}`,
        boxShadow: theme.palette.shadow.main,
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: theme.spacing(4),
    },

    [`& .${classes.buttonsContainer}`]: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row-reverse',
        marginRight: 24,
        marginTop: 40,
        marginBottom: 24,
    },
}))

interface UnsavedChangesWarningModalProps {
    isOpen: boolean
    onClose: () => void
    onSaveChanges: () => void
    onLeave: () => void
}

const UnsavedChangesWarningModal: React.FC<UnsavedChangesWarningModalProps> = ({ isOpen, onClose, onSaveChanges, onLeave }) => {
    return (
        <ModalBase isOpen={isOpen} onClose={onClose}>
            <StyledBox className={classes.container}>
                <Box>
                    <LeavingImage />
                </Box>
                <Text color='normal' fontSize={20} style={{ fontWeight: 700 }}>
                    Do you want to leave without saving changes?
                </Text>
                <Text color='light' fontSize={16}>
                    Current changes are not saved.
                </Text>
                <Box className={classes.buttonsContainer}>
                    <GradientButton buttonType='alert' title='Leave' onClick={onLeave} />
                    <EmptyButton text='SaveChanges' onClick={onSaveChanges} style={{ marginRight: 20 }} />
                </Box>
            </StyledBox>
        </ModalBase>
    )
}

export default UnsavedChangesWarningModal
