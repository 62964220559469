import { Box, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import Fonts from 'constants/fonts'
import { ModeType } from '../../services/types/userSettings-types'

const PREFIX = 'ModeDropdown'

const classes = {
    item: `${PREFIX}-item`,
    itemTitle: `${PREFIX}-itemTitle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.item}`]: {
        color: theme.palette.text.normal,
        fontWeight: 'normal',
        width: 84,
        height: 52,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.dropdown.hover,
            fontWeight: 'bold',
        },
    },

    [`& .${classes.itemTitle}`]: {
        height: 52,
        width: 84,
        display: 'flex',
        fontSize: Fonts.size.normal,
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 'bold',
            color: theme.palette.dropdown.titleHover,
        },
        // Used to make text bold on icon hover. Does not work without it.
        zIndex: 2,
    },
}))

const MAIN_BORDER_RADIUS = 10

interface ModeDropdownProps {
    onChangeModeType: (type: ModeType) => void
}

const ModeDropdown: React.FC<ModeDropdownProps> = ({ onChangeModeType }) => {
    const theme = useTheme()
    return (
        <StyledBox
            style={{
                boxShadow: theme.palette.shadow.main,
                marginTop: 12,
                borderRadius: MAIN_BORDER_RADIUS,
            }}
        >
            <Box
                className={classes.item}
                style={{ borderTopRightRadius: MAIN_BORDER_RADIUS, borderTopLeftRadius: MAIN_BORDER_RADIUS }}
                onClick={() => onChangeModeType('edit')}
            >
                <Typography className={classes.itemTitle} sx={{ paddingLeft: 2 }}>
                    Edit
                </Typography>
            </Box>
            <Box
                className={classes.item}
                style={{ borderBottomRightRadius: MAIN_BORDER_RADIUS, borderBottomLeftRadius: MAIN_BORDER_RADIUS }}
                onClick={() => onChangeModeType('view')}
            >
                <Typography className={classes.itemTitle} sx={{ paddingLeft: 2 }}>
                    View
                </Typography>
            </Box>
        </StyledBox>
    )
}

export default ModeDropdown
