import { ReduxState } from 'services/types/mainReducer-types'
import { ModeType } from 'services/types/userSettings-types'
import { Collection, Endpoint, Project, SharedCode } from 'shared/types/project-types'

export const getActiveProject = (state: ReduxState): Project | undefined => state.projects.active

export const getCollectionById = (state: ReduxState, id: string): Collection | undefined => {
    if (state.projects.active) {
        const collection = state.projects.active.collections.find((c) => c.id === id)
        return collection
    } else return undefined
}

export const getEndpointById = (state: ReduxState, endpointId: string): Endpoint | undefined => {
    if (state.projects.active) {
        let endpoint

        for (const collection of state.projects.active.collections) {
            const endpointInCollection = collection.endpoints.find((e) => e.id === endpointId)

            if (endpointInCollection) {
                endpoint = endpointInCollection
            }
        }

        return endpoint
    }
    return undefined
}

export const getActiveProjectSharedCode = (state: ReduxState): SharedCode[] | undefined => state.projects.active?.sharedCode

export const isProjectEditable = (state: ReduxState): boolean => state.projects.active?.loggedUserShareRole !== 'view'

export const getOpenedCollectionsList = (state: ReduxState): string[] => state.projects.openedCollections

export const getIsSyncingWithServer = (state: ReduxState): boolean => state.projects.isSyncingWithServer

export const getSelectedModeType = (state: ReduxState): ModeType => state.userSettings.selectedMode
