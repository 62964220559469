import { styled } from '@mui/material/styles'
import { Box, useTheme, Divider } from '@mui/material'
import Text from 'components/Text'
import { useEffect, useState } from 'react'
import React from 'react'
import GradientButton from 'components/GradientButton'
import ModalBase from 'components/ModalBase'
import NewTypeSelector from './NewTypeSelector'
import EmptyButton from 'components/EmptyButton'
import { EditedSharedCodeTypeInfo } from 'types/sharedCode-types'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ChangesDetectedImage from 'assets/images/ChangesDetectedImage'

const PREFIX = 'TypesComparisonModal'

const classes = {
    container: `${PREFIX}-container`,
    scrollContainer: `${PREFIX}-scrollContainer`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        width: 560,
        maxWidth: '90%',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 16,
        boxShadow: theme.palette.shadow.main,
        border: `1px solid ${theme.palette.neutral.dark1}`,
        padding: theme.spacing(3),
    },

    [`& .${classes.scrollContainer}`]: {
        flex: 1,
        overflow: 'auto',
        maxHeight: 650,
        '&::-webkit-scrollbar': {
            width: '0.2em',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.text.faded,
        },
    },
}))

interface TypesComparisonModalProps {
    isOpen: boolean
    onConfirm: (types: EditedSharedCodeTypeInfo[]) => void
    onClose: () => void
    deletedTypes: EditedSharedCodeTypeInfo[]
    addedTypes: EditedSharedCodeTypeInfo[]
}

interface EditedSharedCodeTypeInfoGroupedByFile {
    fileName: string
    types: EditedSharedCodeTypeInfo[]
}

const EMPTY_TYPE: EditedSharedCodeTypeInfo = {
    name: 'None',
    file: '',
}

const TypesComparisonModal: React.FC<TypesComparisonModalProps> = ({ isOpen, onConfirm, onClose, deletedTypes, addedTypes }) => {
    const theme = useTheme()
    const [removedTypes, setRemovedTypes] = useState<EditedSharedCodeTypeInfo[]>(deletedTypes)

    const groupTypesByFileName = (types: EditedSharedCodeTypeInfo[]): EditedSharedCodeTypeInfoGroupedByFile[] => {
        const result: EditedSharedCodeTypeInfoGroupedByFile[] = []

        const fileNames: string[] = []

        for (const typeObject of types) {
            if (!fileNames.includes(typeObject.file)) {
                fileNames.push(typeObject.file)
            }
        }

        for (const fileName of fileNames) {
            result.push({
                fileName: fileName,
                types: types.filter((t) => t.file === fileName),
            })
        }

        return result
    }
    const groupedRemovedTypes: EditedSharedCodeTypeInfoGroupedByFile[] = groupTypesByFileName(removedTypes)

    useEffect(() => {
        setRemovedTypes(deletedTypes)
    }, [deletedTypes])

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} center>
            <StyledBox className={classes.container}>
                <Box className={classes.scrollContainer}>
                    <Box style={{ flex: 1, display: 'flex', marginBottom: theme.spacing(4), justifyContent: 'center' }}>
                        <ChangesDetectedImage style={{ height: 226, width: 252 }} />
                    </Box>
                    <Box style={{ display: 'flex', marginBottom: theme.spacing(4) }}>
                        <Text color='normal' bold fontSize={20} style={{ flex: 1, textAlign: 'center' }}>
                            Update imported types
                        </Text>
                    </Box>
                    <Box style={{ marginBottom: theme.spacing(4) }}>
                        <Text color='normal' fontSize={16} style={{ flex: 1 }}>
                            Some of the used types got renamed or removed.
                        </Text>
                        <Text color='normal' fontSize={16} style={{ flex: 1, marginTop: 8 }}>
                            Choose replacement strategy for the types. You can see the old types on the left and new ones on the
                            right.
                        </Text>
                    </Box>
                    <Box style={{}}>
                        {groupedRemovedTypes.map((removedTypeFile, index) => {
                            return (
                                <Box key={removedTypeFile.fileName}>
                                    {index > 0 && (
                                        <Divider
                                            style={{
                                                borderColor: theme.palette.text.faded,
                                                marginTop: theme.spacing(2),
                                                marginBottom: theme.spacing(1),
                                            }}
                                        />
                                    )}
                                    <Text
                                        color='normal'
                                        fontSize={16}
                                        style={{
                                            flex: 1,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            marginBottom: theme.spacing(1),
                                        }}
                                    >
                                        Source tab: {removedTypeFile.fileName}
                                    </Text>
                                    {removedTypeFile.types.map((removedType) => {
                                        return (
                                            <Box
                                                key={removedType.name + removedType.file}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    marginBottom: theme.spacing(1.5),
                                                }}
                                            >
                                                <Box
                                                    style={{
                                                        flex: 2,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Text
                                                        color='normal'
                                                        fontSize={16}
                                                        style={{
                                                            flex: 1,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                        bold
                                                    >
                                                        {removedType.name}
                                                    </Text>
                                                </Box>

                                                <ArrowForwardIcon style={{ color: theme.palette.text.normal, flex: 1 }} />

                                                <Box style={{ flex: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                                    <NewTypeSelector
                                                        items={addedTypes.filter(
                                                            (addedType) => addedType.file === removedType.file
                                                        )}
                                                        selectedItem={removedType.newType || EMPTY_TYPE}
                                                        onSelect={(newType: EditedSharedCodeTypeInfo) => {
                                                            const copy = [...removedTypes]

                                                            for (let i = 0; i < copy.length; i++) {
                                                                if (
                                                                    copy[i].name === removedType.name &&
                                                                    copy[i].file === removedType.file
                                                                ) {
                                                                    copy[i].newType = newType
                                                                }
                                                            }

                                                            setRemovedTypes(copy)
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: theme.spacing(2),
                        marginTop: theme.spacing(4),
                    }}
                >
                    <EmptyButton text='Cancel' onClick={onClose} style={{ width: 82 }} />
                    <GradientButton width={133} title='Save changes' onClick={() => onConfirm(removedTypes)} />
                </Box>
            </StyledBox>
        </ModalBase>
    )
}

export default TypesComparisonModal
