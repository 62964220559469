import { createTheme } from '@mui/material/styles'
import Fonts from 'constants/fonts'

const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#C690F1',
            main: '#8864D3',
            dark: '#5338BC',
        },
        secondary: {
            light: '#FFAF6B',
            main: '#FF851D',
            dark: '#DC590F',
        },
        accent: {
            light: '#54C6BE',
            main: '#54C6BE',
            dark: '#1B8188',
        },
        success: {
            main: '#1B8188',
            light: '#d8f4ef',
        },
        info: {
            main: '#DC590F',
            light: '#ffe2ca',
        },
        alert: {
            light: '#F9675D',
            main: '#EE3B3B',
            dark: '#CF1B1B',
        },
        blue: {
            light: '#6DADF8',
            main: '#4C75DE',
            dark: '#2135A0',
        },
        yellow: '#FFD76F',
        neutral: {
            light1: '#28293D',
            light2: '#555770',
            light3: '#8F90A6',
            main: '#F2F2F5',
            dark1: '#28293D',
            dark2: '#F2F2F5',
            dark3: '#8F90A6',
            black: '#101014',
            neutral1: '#FAFAFC',
            neutral2: '#BDBDC6',
            // Used for side panel text.
            unique1: '#8864D3',
            // Used for create project card border color.
            unique2: '#BDBDC6',
            // Used for project card background color on hover.
            unique3: '#F2EDFB',
        },
        forms: {
            hover: '#332A46',
            shadow: '#241b4f88',
            paperTransparent: '#e0e0e088',
            dropdownMain: '#FAFAFC',
            inputBackgroundMain: '#F2F2F5',
            inputBackgroundNeutral: 'rgba(255, 255, 255, 0.05)',
            backgroundDisabled: '#FFFFFF',
        },
        monacoEditorBackground: '#fffffe',
        background: {
            default: '#FDFDFD',
            paper: '#FAFAFC',
        },
        text: {
            normal: '#28293D',
            light: '#555770',
            faded: '#8F90A6',
        },
        flashMessages: {
            errorBackground: '#fdc8c5',
        },
        // Shadow can be changed, its not final set
        shadow: {
            main: '0px 2px 8px rgba(65, 32, 158, 0.08), 0px 20px 32px rgba(136, 100, 211, 0.24)',
            secondaryBigger: '0px 2px 8px rgba(65, 32, 158, 0.08), 0px 20px 32px rgba(136, 100, 211, 0.24)',
            secondary: '0px 0px 2px rgba(65, 32, 158, 0.04), 0px 4px 8px rgba(136, 100, 211, 0.16)',
        },
        topPanelBorder: '#F2F2F5',
        dropdown: {
            mainBackground: '#FFFFFF',
            hover: '#F2EDFB',
            secondaryHover: '#C690F166',
            titleHover: '#8864D3',
            filter: 'drop-shadow(0px 2px 8px rgba(65, 32, 158, 0.04)) drop-shadow(0px 16px 24px rgba(136, 100, 211, 0.16))',
        },
        input: {
            mainBackground: '#FFFFFF',
            hoverBackground: '#F2EDFB',
            secondaryBackground: 'transparent',
        },
        monacoEditor: {
            backgroundColor: '#F9F9F9',
            tabBackground: 'rgba(220, 220, 220, 0.4)',
            activeTabBackground: '#F9F9F9',
            lineNumberForeground: '#237893',
            lineNumberActiveForeground: '#0b216f',
        },
        additional: {
            add1: '#F2F2F5',
        },
    },
    typography: {
        fontFamily: ['Open Sans', 'Zilla Slab'].join(','),
    },
})

lightTheme.components = {
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                fontSize: Fonts.size.small,
                color: lightTheme.palette.text.light,
                backgroundColor: lightTheme.palette.background.paper,
                border: `1px solid #8F90A6`,
            },
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                backgroundColor: lightTheme.palette.input.secondaryBackground,
                borderRadius: 6,

                '& .MuiOutlinedInput-root': {
                    color: lightTheme.palette.text.normal,
                    backgroundColor: lightTheme.palette.input.mainBackground,

                    '&:hover': {
                        backgroundColor: lightTheme.palette.input.hoverBackground,
                    },

                    '& fieldset': {
                        border: `2px solid ${lightTheme.palette.text.faded}`,
                    },

                    '&:hover fieldset': {
                        border: `2px solid ${lightTheme.palette.text.faded}`,
                    },

                    '&.Mui-focused fieldset': {
                        border: `2px solid ${lightTheme.palette.primary.light}`,
                    },

                    input: {
                        '&::placeholder': {
                            color: lightTheme.palette.text.faded,
                            opacity: 1,
                            fontSize: Fonts.size.normal,
                        },
                        fontSize: Fonts.size.normal,
                        color: lightTheme.palette.text.light,
                    },
                },

                label: {
                    color: lightTheme.palette.text.faded,

                    '&.Mui-focused': {
                        color: lightTheme.palette.text.faded,
                    },
                },
            },
        },
    },
}

const darkTheme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            light: '#C690F1',
            main: '#8864D3',
            dark: '#5338BC',
        },
        secondary: {
            light: '#FFAF6B',
            main: '#FF851D',
            dark: '#DC590F',
        },
        success: {
            main: '#91e1d3',
            light: '#092326',
        },
        info: {
            main: '#FFAF6B',
            light: '#210E05',
        },
        accent: {
            light: '#91E1D3',
            main: '#54C6BE',
            dark: '#1B8188',
        },
        alert: {
            light: '#F9675D',
            main: '#EE3B3B',
            dark: '#CF1B1B',
        },
        blue: {
            light: '#6DADF8',
            main: '#4C75DE',
            dark: '#2135A0',
        },
        yellow: '#FFD76F',
        neutral: {
            light1: '#FAFAFC',
            light2: '#F2F2F5',
            light3: '#BDBDC6',
            main: '#F2F2F5',
            dark1: '#28293D',
            dark2: '#555770',
            dark3: '#8F90A6',
            black: '#101014',
            neutral1: '#FAFAFC',
            neutral2: '#BDBDC6',
            // Used for side panel text.
            unique1: '#C690F1',
            // Used for create project card border color.
            unique2: '#8F90A6',
            // Used for project card background color on hover.
            unique3: '#332A46',
        },
        forms: {
            hover: '#332A46',
            shadow: '#241b4f88',
            paperTransparent: '#28293D88',
            dropdownMain: '#FAFAFC',
            inputBackgroundMain: '#55577042',
            inputBackgroundNeutral: 'rgba(255, 255, 255, 0.05)',
            backgroundDisabled: '#28282B',
        },
        monacoEditorBackground: '#1e1e1e',
        background: {
            default: '#101014',
            paper: '#28293D',
        },
        text: {
            normal: '#FAFAFC',
            light: '#F2F2F5',
            faded: '#BDBDC6',
        },
        flashMessages: {
            errorBackground: '#380808',
        },
        // Shadow can be changed, its not final set
        shadow: {
            main: '0px 2px 6px rgba(0, 0, 0, 0.06), 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 20px 32px rgba(30, 14, 74, 0.32)',
            secondaryBigger: '8px 8px 18px #00000022',
            secondary: '8px 8px 18px #00000022',
        },
        topPanelBorder: '#28293D',
        dropdown: {
            mainBackground: '#28293D',
            hover: '#332A46',
            secondaryHover: '#C690F166',
            titleHover: '#C690F1',
            filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 16px 24px rgba(30, 14, 74, 0.32))',
        },
        input: {
            mainBackground: 'rgba(255, 255, 255, 0.05)',
            secondaryBackground: 'transparent',
            hoverBackground: '#332A46',
        },
        monacoEditor: {
            backgroundColor: '#313247',
            tabBackground: 'rgba(85, 87, 112, 0.2)',
            activeTabBackground: '#313247',
            lineNumberForeground: '#858585',
            lineNumberActiveForeground: '#C690F1',
        },
        additional: {
            add1: '#22232C',
        },
    },
    typography: {
        fontFamily: ['Open Sans', 'Zilla Slab'].join(','),
    },
})

darkTheme.components = {
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                fontSize: Fonts.size.small,
                color: darkTheme.palette.text.light,
                backgroundColor: darkTheme.palette.background.paper,
                border: '1px solid #8F90A6',
            },
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            root: {
                color: darkTheme.palette.neutral.main,
            },
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                backgroundColor: darkTheme.palette.input.secondaryBackground,
                borderRadius: 6,

                '& .MuiOutlinedInput-root': {
                    color: darkTheme.palette.text.normal,
                    backgroundColor: darkTheme.palette.input.mainBackground,

                    '&:hover': {
                        backgroundColor: darkTheme.palette.input.hoverBackground,
                    },

                    '& fieldset': {
                        border: `2px solid ${darkTheme.palette.text.faded}`,
                    },

                    '&:hover fieldset': {
                        border: `2px solid ${darkTheme.palette.text.faded}`,
                    },

                    '&.Mui-focused fieldset': {
                        border: `2px solid ${darkTheme.palette.primary.light}`,
                    },

                    input: {
                        '&::placeholder': {
                            color: darkTheme.palette.text.faded,
                            opacity: 1,
                            fontSize: Fonts.size.normal,
                        },
                        fontSize: Fonts.size.normal,
                        color: darkTheme.palette.text.light,
                    },
                },

                label: {
                    color: darkTheme.palette.text.faded,

                    '&.Mui-focused': {
                        color: darkTheme.palette.text.faded,
                    },
                },
            },
        },
    },
}

export { lightTheme, darkTheme }
