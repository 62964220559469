import React, { FC, ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import { Box, ClickAwayListener, Fade, Paper, PopperPlacementType } from '@mui/material'
import PopperUnstyled from '@mui/base/PopperUnstyled'

const PREFIX = 'DropdownMenu'
const classes = {
    popoverRoot: `${PREFIX}-popoverRoot`,
    content: `${PREFIX}-content`,
    popper: `${PREFIX}-popper`,
    arrow: `${PREFIX}-arrow`,
}

const StyledPopperUnstyled = styled(PopperUnstyled)(({ theme }) => {
    const color = theme.palette.background.paper
    return {
        [`& .${classes.popoverRoot}`]: {
            backgroundColor: 'transparent',
            maxWidth: 1000,
        },
        [`& .${classes.content}`]: {},
        [`&.${classes.popper}`]: {
            zIndex: 2000,
            '&[x-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: '-0.71em',
                marginLeft: 4,
                marginRight: 4,
                '&::before': {
                    transformOrigin: '0 100%',
                },
            },
            '&[x-placement*="top"] $arrow': {
                bottom: 0,
                left: 0,
                marginBottom: '-0.71em',
                marginLeft: 4,
                marginRight: 4,
                '&::before': {
                    transformOrigin: '100% 0',
                },
            },
            '&[x-placement*="right"] $arrow': {
                left: 0,
                marginLeft: '-0.71em',
                height: '1em',
                width: '0.71em',
                marginTop: 4,
                marginBottom: 4,
                '&::before': {
                    transformOrigin: '100% 100%',
                },
            },
            '&[x-placement*="left"] $arrow': {
                right: 0,
                marginRight: '-0.71em',
                height: '1em',
                width: '0.71em',
                marginTop: 4,
                marginBottom: 4,
                '&::before': {
                    transformOrigin: '0 0',
                },
            },
        },
        [`& .${classes.arrow}`]: {
            overflow: 'hidden',
            position: 'absolute',
            width: '1em',
            height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
            boxSizing: 'border-box',
            color,
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: '100%',
                height: '100%',
                boxShadow: theme.shadows[1],
                backgroundColor: 'currentColor',
                transform: 'rotate(45deg)',
            },
        },
    }
})

interface DropdownMenuProps {
    content: ReactElement
    children: ReactElement
    open: boolean
    onClose: () => void
    arrow?: boolean
    placement?: PopperPlacementType
    borderRadius?: number
}

const DropdownMenu: FC<DropdownMenuProps> = ({ placement, arrow, open, onClose, content, children, borderRadius }) => {
    const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null)
    const [childNode, setChildNode] = React.useState<HTMLElement | null>(null)
    return (
        <>
            {React.cloneElement(children, { ...children.props, ref: setChildNode })}
            <StyledPopperUnstyled
                open={open}
                anchorEl={childNode}
                placement={placement}
                transition
                className={classes.popper}
                modifiers={[
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'window',
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: arrow || false,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={0}>
                        <Paper elevation={0} style={{ borderRadius: borderRadius ? borderRadius : undefined }}>
                            <ClickAwayListener onClickAway={onClose}>
                                <Paper
                                    elevation={0}
                                    className={classes.popoverRoot}
                                    style={{ borderRadius: borderRadius ? borderRadius : undefined }}
                                >
                                    {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
                                    <Box className={classes.content}>{content}</Box>
                                </Paper>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </StyledPopperUnstyled>
        </>
    )
}

export default DropdownMenu
