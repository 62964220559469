import { Button, CircularProgress, Stack } from '@mui/material'
import UnauthorizedImage from 'assets/images/UnauthorizedImage'
import Text from 'components/Text'
import { RouteConstants } from 'navigation/navigation-types'
import { useHistory } from 'react-router-dom'

const LandingPage: React.FC = () => {
    const history = useHistory()

    return (
        <Stack spacing={1} alignItems={'center'} justifyContent='center' height='90vh'>
            <div
                style={{
                    width: '40%',
                    height: '40%',
                }}
            >
                <UnauthorizedImage />
            </div>
            <Text fontSize={30}>It looks like you are not signed in</Text>
            <Text fontSize={20} color='faded'>
                Redirecting to home page
            </Text>
            <CircularProgress />
            <Button onClick={() => history.push(RouteConstants.login)}>Go to login</Button>
        </Stack>
    )
}

export default LandingPage
