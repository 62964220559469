import React from 'react'
import { Box } from '@mui/material'
import { getActiveProject } from 'services/selectors/projects-selectors'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NotMatch from 'navigation/NotMatch'
import ApiDetails from './ApiDetails/ApiDetails'
import { getEndpointIdsByCollectionIds } from 'shared/utils/project-utils'

interface ParamsTypes {
    projectId: string
    collectionId: string
    endpointId: string
}

const EndpointDetails: React.FC = () => {
    const params = useParams<ParamsTypes>()
    const location = useLocation<{ openEditDetail?: boolean }>()
    const activeProject = useSelector(getActiveProject)

    let collectionsMap: { [collectionId: string]: string[] } = {}
    if (activeProject) {
        collectionsMap = getEndpointIdsByCollectionIds(activeProject)
    }
    const { projectId, collectionId, endpointId } = params

    // Check for collection ID and project ID
    if (projectId !== activeProject?.id || !collectionsMap[collectionId]) return <NotMatch />
    // Check for endpoint ID
    if (!collectionsMap[collectionId].includes(endpointId)) return <NotMatch />

    return (
        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <ApiDetails endpointId={endpointId} shouldSetEditMode={location.state ? location.state.openEditDetail : false} />
        </Box>
    )
}

export default EndpointDetails
