import * as React from 'react'

function TypewriterImage(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={100} height={68} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M16.648 19.389h-.624l-.227-1.341c0-.056-.028-.084-.057-.14-.142-.168-3.63-3.94-4.708-4.638-.397-.251-.737-.335-1.077-.195-.596.223-.823.95-.88 1.09-.056.223-.879 1.396-1.871 2.262-.936.81-.426 1.928-.029 2.43l.085.085 1.589.698c.085.028.17.028.255-.028.567-.39 1.446-2.347 1.843-3.296l2.751 5.168c.057.084.142.14.227.14h2.751a.252.252 0 00.255-.252V19.64c-.028-.14-.141-.251-.283-.251z'
                fill='#5D4A44'
            />
            <path
                d='M81.225 17.21c-.057-.14-.17-.196-.255-.196H19.058c-.113 0-.198.084-.255.196l-2.41 5.028c-.17.336 0 .81.255.81H83.38c.256 0 .426-.474.256-.81l-2.411-5.028z'
                fill='#5D4A44'
            />
            <path
                d='M80.97 16.986c.113 0 .198.084.255.196l2.41 5.028c.171.336 0 .81-.255.81H16.648c-.256 0-.426-.474-.256-.81l2.411-5.028c.057-.14.17-.196.255-.196H80.97zm0-1.062H19.058c-.51 0-.992.308-1.247.81l-2.411 5.03a1.815 1.815 0 00.085 1.704c.255.39.68.642 1.134.642H83.38c.454 0 .88-.251 1.135-.642.312-.503.34-1.146.085-1.705l-2.41-5.028c-.227-.503-.681-.81-1.22-.81z'
                fill='#C690F1'
            />
            <path d='M79.76.5v23.39H20.24V.5H79.76z' fill='#fff' stroke='#28293D' />
            <path d='M19.767 20.702v3.52c0 .084.085.167.17.167H80.12c.085 0 .17-.083.17-.167v-3.52H19.767z' fill='#DDD' />
            <path d='M92.598 23.551H85.99v3.213h6.608v-3.213z' fill='#C690F1' />
            <path
                d='M93.618 28.077h-2.07a.433.433 0 01-.425-.419v-4.973c0-.223.198-.419.425-.419h2.07c.227 0 .425.196.425.42v4.972a.433.433 0 01-.425.42z'
                fill='#5D4A44'
            />
            <path
                d='M5.135 40.855v-.002l.632-1.395a185.32 185.32 0 001.694-3.805c.805-1.883 1.55-3.796 1.902-5.252.33-1.365 1.094-3.307 1.789-4.931a85.66 85.66 0 011.161-2.592h75.375l.253.544c.238.516.562 1.232.907 2.037.695 1.62 1.46 3.563 1.789 4.941.35 1.47 1.096 3.39 1.902 5.274.596 1.394 1.234 2.79 1.783 3.99.194.424.377.824.543 1.191.3.665.744 2.05 1.243 3.865.496 1.803 1.04 3.996 1.543 6.256 1.012 4.54 1.849 9.279 1.849 11.66 0 2.355-.62 3.567-1.165 4.184a2.462 2.462 0 01-.736.582 1.581 1.581 0 01-.221.092l-.02.006H2.642l-.02-.006a1.58 1.58 0 01-.22-.092c-.192-.095-.461-.27-.737-.582C1.12 66.203.5 64.99.5 62.636c0-2.381.837-7.12 1.85-11.66.503-2.26 1.046-4.453 1.542-6.256.5-1.815.942-3.2 1.243-3.865z'
                fill='#54C6BE'
                stroke='#28293D'
            />
            <path
                d='M58.678 26.4l-1.531-3.938c-.028-.056-.057-.084-.114-.084H42.967c-.029 0-.085.028-.114.084L41.322 26.4c-.029.056-.057.084-.086.084l-12.875 1.061c-.142 0-.142.28 0 .308 4.112.447 15.343 1.676 18.661 1.676h6.013c3.318 0 14.549-1.201 18.661-1.676.142-.028.142-.28 0-.308l-12.904-1.061c-.057 0-.114-.056-.114-.084zM90.471 41.431c-.397 0-.737-.251-.907-.614l-.766-1.9a.985.985 0 00-.908-.615H12.11c-.397 0-.737.252-.907.615l-.766 1.9a.986.986 0 01-.907.614.934.934 0 00-.851.531c-1.702 3.24-2.836 14.695-3.148 18.243a.976.976 0 00.964 1.062h87.011a.976.976 0 00.964-1.062c-.312-3.548-1.446-15.002-3.148-18.243a.965.965 0 00-.85-.53z'
                fill='#5D4A44'
            />
            <path
                d='M17.102 42.744h-3.347a.595.595 0 01-.595-.586v-2.71c0-.336.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71c.029.335-.255.586-.595.586z'
                fill='#E2E2E2'
            />
            <path
                d='M17.073 38.861h-3.261c-.369 0-.652.252-.652.559v1.62c.567.336 1.39.531 2.268.531.908 0 1.702-.195 2.27-.53V39.42c.028-.308-.284-.559-.625-.559z'
                fill='#fff'
            />
            <path
                d='M23.143 42.744h-3.347a.595.595 0 01-.595-.586v-2.71c0-.336.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71c.029.335-.255.586-.595.586z'
                fill='#E2E2E2'
            />
            <path
                d='M23.114 38.861h-3.261c-.369 0-.652.252-.652.559v1.62c.567.336 1.39.531 2.268.531.908 0 1.702-.195 2.27-.53V39.42c.028-.308-.284-.559-.625-.559z'
                fill='#fff'
            />
            <path
                d='M29.183 42.744h-3.346a.595.595 0 01-.596-.586v-2.71c0-.336.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71c.028.335-.255.586-.596.586z'
                fill='#E2E2E2'
            />
            <path
                d='M29.155 38.861h-3.262c-.368 0-.652.252-.652.559v1.62c.567.336 1.39.531 2.27.531.907 0 1.7-.195 2.268-.53V39.42c.028-.308-.284-.559-.624-.559z'
                fill='#fff'
            />
            <path
                d='M35.225 42.744H31.88a.595.595 0 01-.596-.586v-2.71c0-.336.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71a.577.577 0 01-.596.586z'
                fill='#E2E2E2'
            />
            <path
                d='M35.168 38.861h-3.262c-.368 0-.652.252-.652.559v1.62c.567.336 1.39.531 2.269.531.907 0 1.701-.195 2.269-.53V39.42c.028-.308-.256-.559-.624-.559z'
                fill='#fff'
            />
            <path
                d='M41.266 42.744h-3.347a.595.595 0 01-.595-.586v-2.71c0-.336.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71a.577.577 0 01-.595.586z'
                fill='#E2E2E2'
            />
            <path
                d='M41.208 38.861h-3.261c-.369 0-.653.252-.653.559v1.62c.568.336 1.39.531 2.27.531.907 0 1.7-.195 2.268-.53V39.42c.029-.308-.255-.559-.624-.559z'
                fill='#fff'
            />
            <path
                d='M47.307 42.744H43.96a.595.595 0 01-.595-.586v-2.71c0-.336.283-.587.595-.587h3.347c.34 0 .596.28.596.587v2.71a.577.577 0 01-.596.586z'
                fill='#E2E2E2'
            />
            <path
                d='M47.25 38.861h-3.262c-.369 0-.653.252-.653.559v1.62c.568.336 1.39.531 2.27.531.907 0 1.701-.195 2.268-.53V39.42c.029-.308-.255-.559-.624-.559z'
                fill='#fff'
            />
            <path
                d='M53.348 42.744H50a.595.595 0 01-.595-.586v-2.71c0-.336.283-.587.595-.587h3.347c.34 0 .596.28.596.587v2.71a.595.595 0 01-.596.586z'
                fill='#E2E2E2'
            />
            <path
                d='M53.29 38.861H50.03c-.369 0-.653.252-.653.559v1.62c.568.336 1.39.531 2.27.531.907 0 1.701-.195 2.268-.53V39.42c.029-.308-.255-.559-.624-.559z'
                fill='#fff'
            />
            <path
                d='M59.388 42.744h-3.346a.595.595 0 01-.596-.586v-2.71c0-.336.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71a.595.595 0 01-.596.586z'
                fill='#E2E2E2'
            />
            <path
                d='M59.33 38.861h-3.26c-.37 0-.653.252-.653.559v1.62c.567.336 1.39.531 2.269.531.907 0 1.702-.195 2.269-.53V39.42c.028-.308-.255-.559-.624-.559z'
                fill='#fff'
            />
            <path
                d='M65.4 42.744h-3.346a.595.595 0 01-.596-.586v-2.71c0-.336.284-.587.596-.587H65.4c.34 0 .596.28.596.587v2.71c.028.335-.255.586-.596.586z'
                fill='#E2E2E2'
            />
            <path
                d='M65.372 38.861H62.11c-.368 0-.652.252-.652.559v1.62c.567.336 1.39.531 2.269.531.907 0 1.702-.195 2.269-.53V39.42c.028-.308-.255-.559-.624-.559z'
                fill='#fff'
            />
            <path
                d='M71.441 42.744h-3.346a.595.595 0 01-.596-.586v-2.71c0-.336.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71c.028.335-.256.586-.596.586z'
                fill='#E2E2E2'
            />
            <path
                d='M71.413 38.861H68.15c-.368 0-.652.252-.652.559v1.62c.567.336 1.39.531 2.269.531.907 0 1.701-.195 2.269-.53V39.42c.028-.308-.284-.559-.624-.559z'
                fill='#fff'
            />
            <path
                d='M77.482 42.744h-3.347a.595.595 0 01-.595-.586v-2.71c0-.336.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71c.029.335-.255.586-.595.586z'
                fill='#E2E2E2'
            />
            <path
                d='M77.453 38.861h-3.261c-.369 0-.652.252-.652.559v1.62c.567.336 1.39.531 2.268.531.908 0 1.702-.195 2.27-.53V39.42c.028-.308-.284-.559-.625-.559z'
                fill='#fff'
            />
            <path
                d='M83.523 42.744h-3.347a.595.595 0 01-.595-.586v-2.71c0-.336.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71a.577.577 0 01-.595.586z'
                fill='#E2E2E2'
            />
            <path
                d='M83.494 38.861h-3.261c-.369 0-.652.252-.652.559v1.62c.567.336 1.39.531 2.268.531.908 0 1.702-.195 2.27-.53V39.42c0-.308-.284-.559-.625-.559z'
                fill='#fff'
            />
            <path
                d='M17.102 58.642h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.283-.586.595-.586h3.347c.34 0 .595.28.595.586v2.71c.029.308-.255.587-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M17.073 54.73h-3.261c-.369 0-.652.251-.652.558v1.62c.567.336 1.39.532 2.268.532.908 0 1.702-.196 2.27-.531v-1.62c.028-.308-.284-.56-.625-.56z'
                fill='#fff'
            />
            <path
                d='M23.143 58.642h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.283-.586.595-.586h3.347c.34 0 .595.28.595.586v2.71c.029.308-.255.587-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M23.114 54.73h-3.261c-.369 0-.652.251-.652.558v1.62c.567.336 1.39.532 2.268.532.908 0 1.702-.196 2.27-.531v-1.62c.028-.308-.284-.56-.625-.56z'
                fill='#fff'
            />
            <path
                d='M29.183 58.642h-3.346a.595.595 0 01-.596-.587v-2.71c0-.335.284-.586.596-.586h3.346c.34 0 .596.28.596.586v2.71c.028.308-.255.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M29.155 54.73h-3.262c-.368 0-.652.251-.652.558v1.62c.567.336 1.39.532 2.27.532.907 0 1.7-.196 2.268-.531v-1.62c.028-.308-.284-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M35.225 58.642H31.88a.595.595 0 01-.596-.587v-2.71c0-.335.284-.586.596-.586h3.346c.34 0 .596.28.596.586v2.71a.595.595 0 01-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M35.168 54.73h-3.262c-.368 0-.652.251-.652.558v1.62c.567.336 1.39.532 2.269.532.907 0 1.701-.196 2.269-.531v-1.62c.028-.308-.256-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M41.266 58.642h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.283-.586.595-.586h3.347c.34 0 .595.28.595.586v2.71a.595.595 0 01-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M41.208 54.73h-3.261c-.369 0-.653.251-.653.558v1.62c.568.336 1.39.532 2.27.532.907 0 1.7-.196 2.268-.531v-1.62c.029-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M47.307 58.642H43.96a.595.595 0 01-.595-.587v-2.71c0-.335.283-.586.595-.586h3.347c.34 0 .596.28.596.586v2.71a.595.595 0 01-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M47.25 54.73h-3.262c-.369 0-.653.251-.653.558v1.62c.568.336 1.39.532 2.27.532.907 0 1.701-.196 2.268-.531v-1.62c.029-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M53.348 58.642H50a.595.595 0 01-.595-.587v-2.71c0-.335.283-.586.595-.586h3.347c.34 0 .596.28.596.586v2.71c0 .308-.284.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M53.29 54.73H50.03c-.369 0-.653.251-.653.558v1.62c.568.336 1.39.532 2.27.532.907 0 1.701-.196 2.268-.531v-1.62c.029-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M59.388 58.642h-3.346a.595.595 0 01-.596-.587v-2.71c0-.335.284-.586.596-.586h3.346c.34 0 .596.28.596.586v2.71c0 .308-.284.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M59.33 54.73h-3.26c-.37 0-.653.251-.653.558v1.62c.567.336 1.39.532 2.269.532.907 0 1.702-.196 2.269-.531v-1.62c.028-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M65.4 58.642h-3.346a.595.595 0 01-.596-.587v-2.71c0-.335.284-.586.596-.586H65.4c.34 0 .596.28.596.586v2.71c.028.308-.255.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M65.372 54.73H62.11c-.368 0-.652.251-.652.558v1.62c.567.336 1.39.532 2.269.532.907 0 1.702-.196 2.269-.531v-1.62c.028-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M71.441 58.642h-3.346a.595.595 0 01-.596-.587v-2.71c0-.335.284-.586.596-.586h3.346c.34 0 .596.28.596.586v2.71c.028.308-.256.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M71.413 54.73H68.15c-.368 0-.652.251-.652.558v1.62c.567.336 1.39.532 2.269.532.907 0 1.701-.196 2.269-.531v-1.62c.028-.308-.284-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M77.482 58.642h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.283-.586.595-.586h3.347c.34 0 .595.28.595.586v2.71c.029.308-.255.587-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M77.453 54.73h-3.261c-.369 0-.652.251-.652.558v1.62c.567.336 1.39.532 2.268.532.908 0 1.702-.196 2.27-.531v-1.62c.028-.308-.284-.56-.625-.56z'
                fill='#fff'
            />
            <path
                d='M83.523 58.642h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.283-.586.595-.586h3.347c.34 0 .595.28.595.586v2.71a.595.595 0 01-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M83.494 54.73h-3.261c-.369 0-.652.251-.652.558v1.62c.567.336 1.39.532 2.268.532.908 0 1.702-.196 2.27-.531v-1.62c0-.308-.284-.56-.625-.56z'
                fill='#fff'
            />
            <path
                d='M25.667 53.333H22.32a.595.595 0 01-.595-.587v-2.71c0-.335.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71a.577.577 0 01-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M25.61 49.45h-3.233c-.369 0-.652.25-.652.558v1.62c.567.336 1.39.531 2.268.531.908 0 1.702-.195 2.27-.53v-1.62c0-.308-.284-.56-.653-.56z'
                fill='#fff'
            />
            <path
                d='M31.708 53.333h-3.346a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71a.577.577 0 01-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M31.65 49.45h-3.26c-.37 0-.653.25-.653.558v1.62c.567.336 1.39.531 2.269.531.907 0 1.701-.195 2.269-.53v-1.62c.028-.308-.256-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M37.75 53.333h-3.347a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71a.577.577 0 01-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M37.692 49.45H34.43c-.369 0-.652.25-.652.558v1.62c.567.336 1.39.531 2.269.531.907 0 1.701-.195 2.269-.53v-1.62c.028-.308-.256-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M43.79 53.333h-3.346a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71a.595.595 0 01-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M43.733 49.45H40.47c-.368 0-.652.25-.652.558v1.62c.567.336 1.39.531 2.269.531.907 0 1.701-.195 2.269-.53v-1.62c.028-.308-.256-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M49.83 53.333h-3.346a.595.595 0 01-.595-.587v-2.71c0-.335.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71a.595.595 0 01-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M49.773 49.45h-3.261c-.369 0-.653.25-.653.558v1.62c.568.336 1.39.531 2.27.531.907 0 1.7-.195 2.268-.53v-1.62c.029-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M55.843 53.333h-3.347a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71c.029.335-.255.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M55.814 49.45h-3.261c-.369 0-.653.25-.653.558v1.62c.568.336 1.39.531 2.27.531.907 0 1.7-.195 2.268-.53v-1.62c.029-.308-.284-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M61.884 53.333h-3.347a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.347c.34 0 .595.28.595.587v2.71c.029.335-.255.587-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M61.855 49.45h-3.261c-.369 0-.653.25-.653.558v1.62c.568.336 1.39.531 2.27.531.907 0 1.7-.195 2.268-.53v-1.62c.029-.308-.283-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M67.924 53.333h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71c.028.335-.256.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M67.896 49.45h-3.262c-.368 0-.652.25-.652.558v1.62c.567.336 1.39.531 2.269.531.907 0 1.701-.195 2.269-.53v-1.62c.028-.308-.284-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M73.965 53.333h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71a.577.577 0 01-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M73.937 49.45h-3.262c-.368 0-.652.25-.652.558v1.62c.567.336 1.39.531 2.269.531.907 0 1.701-.195 2.269-.53v-1.62c0-.308-.284-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M80.007 53.333H76.66a.595.595 0 01-.595-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71a.577.577 0 01-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M79.95 49.45h-3.262c-.369 0-.652.25-.652.558v1.62c.567.336 1.39.531 2.269.531.907 0 1.701-.195 2.268-.53v-1.62c.029-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M86.048 53.333H82.7a.595.595 0 01-.595-.587v-2.71c0-.335.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71a.577.577 0 01-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M85.99 49.45h-3.261c-.37 0-.653.25-.653.558v1.62c.567.336 1.39.531 2.269.531.908 0 1.702-.195 2.269-.53v-1.62c.028-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M92.089 53.333h-3.347a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.347c.34 0 .595.28.595.587v2.71a.577.577 0 01-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M92.031 49.45h-3.262c-.368 0-.652.25-.652.558v1.62c.567.336 1.39.531 2.269.531.908 0 1.702-.195 2.269-.53v-1.62c.028-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M12.678 48.054H9.33a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.347c.34 0 .595.28.595.587v2.71a.595.595 0 01-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M12.62 44.141H9.358c-.368 0-.652.252-.652.56v1.62c.567.335 1.39.53 2.269.53.908 0 1.702-.195 2.269-.53V44.7c.028-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M18.945 48.054h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.284-.587.595-.587h3.347c.34 0 .596.28.596.587v2.71a.595.595 0 01-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M18.888 44.141h-3.233c-.368 0-.652.252-.652.56v1.62c.567.335 1.39.53 2.269.53.907 0 1.701-.195 2.269-.53V44.7c0-.308-.284-.56-.653-.56z'
                fill='#fff'
            />
            <path
                d='M25.213 48.054h-3.346a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71c.028.307-.256.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M25.185 44.141h-3.262c-.368 0-.652.252-.652.56v1.62c.567.335 1.39.53 2.269.53.907 0 1.701-.195 2.269-.53V44.7c.028-.308-.284-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M31.51 48.054h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.284-.587.595-.587h3.347c.34 0 .596.28.596.587v2.71c0 .307-.284.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M31.452 44.141h-3.261c-.369 0-.652.252-.652.56v1.62c.567.335 1.39.53 2.268.53.908 0 1.702-.195 2.27-.53V44.7c.028-.308-.256-.56-.625-.56z'
                fill='#fff'
            />
            <path
                d='M37.778 48.054H34.43a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.347c.34 0 .595.28.595.587v2.71a.595.595 0 01-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M37.72 44.141h-3.261c-.37 0-.653.252-.653.56v1.62c.567.335 1.39.53 2.269.53.908 0 1.702-.195 2.269-.53V44.7c.028-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M44.045 48.054H40.7a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71a.595.595 0 01-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M43.987 44.141h-3.261c-.369 0-.652.252-.652.56v1.62c.567.335 1.39.53 2.269.53.907 0 1.701-.195 2.268-.53V44.7c.029-.308-.255-.56-.623-.56z'
                fill='#fff'
            />
            <path
                d='M50.312 48.054h-3.346a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71c.028.307-.255.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M50.284 44.141h-3.262c-.368 0-.652.252-.652.56v1.62c.567.335 1.39.53 2.269.53.907 0 1.702-.195 2.269-.53V44.7c.028-.308-.284-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M56.58 48.054h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71c.029.307-.255.587-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M56.551 44.141H53.29c-.369 0-.652.252-.652.56v1.62c.567.335 1.39.53 2.269.53.907 0 1.701-.195 2.268-.53V44.7c.029-.308-.255-.56-.623-.56z'
                fill='#fff'
            />
            <path
                d='M62.877 48.054H59.53a.595.595 0 01-.595-.587v-2.71c0-.335.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71c0 .307-.283.587-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M62.82 44.141h-3.262c-.37 0-.653.252-.653.56v1.62c.567.335 1.39.53 2.27.53.907 0 1.7-.195 2.268-.53V44.7c.028-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M69.145 48.054h-3.347a.595.595 0 01-.595-.587v-2.71c0-.335.283-.587.595-.587h3.347c.34 0 .595.28.595.587v2.71a.595.595 0 01-.595.587z'
                fill='#E2E2E2'
            />
            <path
                d='M69.087 44.141h-3.261c-.37 0-.653.252-.653.56v1.62c.568.335 1.39.53 2.27.53.907 0 1.7-.195 2.268-.53V44.7c.028-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M75.411 48.054h-3.346a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71c.028.307-.255.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M75.383 44.141h-3.261c-.37 0-.653.252-.653.56v1.62c.568.335 1.39.53 2.27.53.907 0 1.7-.195 2.268-.53V44.7c.028-.308-.284-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M81.68 48.054h-3.347a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.347c.34 0 .595.28.595.587v2.71c.028.307-.255.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M81.651 44.141H78.39c-.37 0-.653.252-.653.56v1.62c.568.335 1.39.53 2.27.53.907 0 1.7-.195 2.268-.53V44.7c.028-.308-.255-.56-.624-.56z'
                fill='#fff'
            />
            <path
                d='M87.976 48.054H84.63a.595.595 0 01-.596-.587v-2.71c0-.335.284-.587.596-.587h3.346c.34 0 .596.28.596.587v2.71c0 .307-.284.587-.596.587z'
                fill='#E2E2E2'
            />
            <path
                d='M87.919 44.141h-3.262c-.368 0-.652.252-.652.56v1.62c.567.335 1.39.53 2.269.53.907 0 1.701-.195 2.269-.53V44.7c.028-.308-.256-.56-.624-.56zM73.256 64.005H26.772a.625.625 0 01-.624-.615v-2.793c0-.336.284-.615.624-.615h46.484c.34 0 .624.28.624.615v2.793a.625.625 0 01-.624.615z'
                fill='#fff'
            />
            <path d='M26.148 61.993v1.425c0 .336.284.587.653.587h46.455c.34 0 .652-.251.652-.587v-1.425h-47.76z' fill='#E2E2E2' />
            <path d='M87.947 32.017H12.082l-.596 1.2h77.056l-.595-1.2z' fill='#5D4A44' />
            <path d='M11.146 33.888l.312.503h77.085l.34-.503-.34-.67H11.486l-.34.67z' fill='#C690F1' />
            <path d='M87.777 35.593l.766-1.202H11.458l.794 1.202h75.525z' fill='#EE3B3B' />
            <path d='M12.252 35.593l.766 1.2h73.965l.794-1.2H12.252z' fill='#F2EDD8' />
        </svg>
    )
}

export default TypewriterImage
