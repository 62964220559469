import { FC } from 'react'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import UnauthorizedImage from 'assets/images/UnauthorizedImage'
import Text from 'components/Text'
import { useHistory } from 'react-router-dom'
import { RouteConstants } from 'navigation/navigation-types'

const PREFIX = 'Unauthenticated'

const classes = {
    mainContainer: `${PREFIX}-mainContainer`,
    topMargin: `${PREFIX}-topMargin`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.mainContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.text.normal,
        alignSelf: 'center',
        padding: 10,
        flex: 0.6,
        justifyContent: 'center',
    },

    [`& .${classes.topMargin}`]: {
        marginTop: `${theme.spacing(2)}`,
    },
}))

const Unauthenticated: FC = () => {
    const history = useHistory()

    return (
        <StyledBox className={classes.mainContainer}>
            <Box sx={{ display: 'flex' }}>
                <UnauthorizedImage />
            </Box>
            <Text color='normal' fontSize={24} sx={{ textAlign: 'center' }} className={classes.topMargin}>
                Not authenticated
            </Text>
            <Text color='normal' fontSize={16} sx={{ textAlign: 'center' }} className={classes.topMargin}>
                It looks like we need to know more about you before you continue. Please login.
            </Text>

            <Button
                className={classes.topMargin}
                variant='contained'
                onClick={() => {
                    history.push(RouteConstants.login)
                }}
            >
                Continue
            </Button>
        </StyledBox>
    )
}

export default Unauthenticated
