import { Box, Button, TextField, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import Fonts from 'constants/fonts'
import { MAIN_BORDER_RADIUS } from 'constants/ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import { createResponse, deleteResponse, editResponse } from 'services/actions/project-actions'
import { getActiveProject } from 'services/selectors/projects-selectors'
import { DotColors } from 'shared/types/project-types'
import { findEndpointById } from 'shared/utils/project-utils'
import Text from '../../components/Text'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DeleteModal from '../../components/DeleteModal'
import GradientButton from 'components/GradientButton'
const PREFIX = 'ResponseInfoDropdown'

const classes = {
    responseCreateDropdown: `${PREFIX}-responseCreateDropdown`,
    bottomDropdownRow: `${PREFIX}-bottomDropdownRow`,
    dotsRow: `${PREFIX}-dotsRow`,
    responseCodeTextfield: `${PREFIX}-responseCodeTextfield`,
    emailInput: `${PREFIX}-emailInput`,
    input: `${PREFIX}-input`,
    iconContainer: `${PREFIX}-iconContainer`,
    row: `${PREFIX}-row`,
    unselectableRow: `${PREFIX}-unselectableRow`,
    circle: `${PREFIX}-circle`,
    menuItemText: `${PREFIX}-menuItemText`,
    dotContainer: `${PREFIX}-dotContainer`,
    dot: `${PREFIX}-dot`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.responseCreateDropdown}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        backgroundColor: theme.palette.background.paper,
        borderRadius: MAIN_BORDER_RADIUS,
        width: 144,
        height: 120,
        filter: theme.palette.dropdown.filter,
        zIndex: 2,
    },

    [`& .${classes.bottomDropdownRow}`]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },

    [`& .${classes.dotsRow}`]: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },

    [`& .${classes.responseCodeTextfield}`]: {
        borderRadius: 4,
        marginLeft: 16,
        height: 40,
        width: 282,
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: `2px solid ${theme.palette.primary.light}`,
                backgroundColor: theme.palette.input.hoverBackground,
            },
            '&.Mui-focused fieldset': {
                border: `2px solid ${theme.palette.primary.light}`,
                backgroundColor: theme.palette.input.hoverBackground,
            },
            '&.Mui-disabled fieldset': {
                border: `2px solid ${theme.palette.neutral.neutral2}`,
            },
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },

    [`& .${classes.emailInput}`]: {
        border: 'none',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        color: theme.palette.text.normal,
        fontSize: Fonts.size.normal,
        // Used to keep input text upper than input background, without zIndex text is covered by input background.
        zIndex: 2,
        caretColor: theme.palette.primary.light,
    },

    [`& .${classes.input}`]: {
        '&::placeholder': {
            color: theme.palette.neutral.light3,
            opacity: 1,
            fontSize: Fonts.size.normal,
        },
    },

    [`& .${classes.iconContainer}`]: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 16,
        marginRight: 8,
    },

    [`& .${classes.row}`]: {
        height: 40,
        width: 324,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.input.hoverBackground,
        },
        cursor: 'pointer',
    },

    [`& .${classes.unselectableRow}`]: {
        height: 40,
        width: 324,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 16,
    },

    [`& .${classes.circle}`]: {
        height: 8,
        width: 8,
        borderRadius: 4,
        backgroundColor: 'grey',
        marginLeft: 16,
        marginRight: 8,
    },

    [`& .${classes.menuItemText}`]: {
        height: 40,
        width: 324,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.palette.text.light,
        '&:hover': {
            color: theme.palette.dropdown.titleHover,
        },
    },

    [`& .${classes.dotContainer}`]: {
        padding: theme.spacing(1),
        borderRadius: 2,
        cursor: 'pointer',
    },

    [`& .${classes.dot}`]: {
        borderRadius: MAIN_BORDER_RADIUS,
        width: 8,
        height: 8,
    },
}))

// For future usage after design
// import './scrollbar.css'

interface ResponseInfoDropdownProps {
    mode: 'create' | 'edit'
    originalResponseCode?: number
    originalDotColor?: DotColors
    endpointId: string
    isMobileView: boolean
    onClose: () => void
    onAfterDelete?: () => void
    onAfterCreate?: () => void
    setSelectedResponseCode: (val: number) => void
}

interface CodeItem {
    code: number
    description: string
}

const colors = {
    green: {
        dot: '#54C6BE',
        border: '#91E1D3',
    },
    yellow: {
        dot: '#FFD76F',
        border: '#FFD76F',
    },
    orange: {
        dot: '#FF851D',
        border: '#FFAF6B',
    },
    red: {
        dot: '#EE3B3B',
        border: '#F9675D',
    },
    white: {
        dot: '#8F90A6',
        border: '#8F90A6',
    },
}

const responseStatusCodesFilter = (codeNumber: string, usedCodes: number[], originalCodeNumber?: number) => {
    let filteredList: CodeItem[] = codeItems.filter((ci) => usedCodes.indexOf(ci.code) === -1 || ci.code === originalCodeNumber)

    if (!codeNumber) return filteredList

    filteredList = filteredList.filter((ci) => ci.code.toString().startsWith(codeNumber))

    return filteredList
}

const ResponseInfoDropdown: FC<ResponseInfoDropdownProps> = ({
    mode,
    originalResponseCode,
    originalDotColor,
    endpointId,
    isMobileView,
    onClose,
    onAfterDelete,
    onAfterCreate,
    setSelectedResponseCode,
}) => {
    const activeProject = useSelector(getActiveProject)
    const [responseCode, setResponseCode] = useState<string>(originalResponseCode ? originalResponseCode.toString() : '')
    const [selectedDotColor, setSelectedDotColor] = useState<DotColors>(originalDotColor ? originalDotColor : 'white')
    // Holds response code for delete modal
    const [deleteModalOpen, setDeleteModalOpen] = useState<number | undefined>(undefined)
    const dispatch = useDispatch()
    const theme = useTheme()
    const [usedResponseCodes, setUsedResponseCodes] = useState<number[]>([])
    const [isDuplicateNameErrorVisible, setIsDuplicateNameErrorVisible] = useState(false)
    const filteredList = responseStatusCodesFilter(responseCode, usedResponseCodes, originalResponseCode)

    useEffect(() => {
        if (activeProject) {
            const currentEndpoint = findEndpointById(activeProject, endpointId)

            if (currentEndpoint) {
                const newUsedCodes = []

                for (const response of currentEndpoint.response) {
                    newUsedCodes.push(response.httpCode)
                }

                setUsedResponseCodes(newUsedCodes)
            }
        }
    }, [endpointId, activeProject])

    const handleSubmitNewDotColor = (dotColor: DotColors) => {
        if (originalResponseCode) {
            dispatch(editResponse(originalResponseCode, originalResponseCode, dotColor, endpointId))
        }
    }

    const handleSubmitResponse = (responseCode: string) => {
        if (originalResponseCode === Number(responseCode)) {
            return
        }

        if (responseCode === undefined || responseCode.length !== 3 || isNaN(Number.parseInt(responseCode))) {
            showFlashMessageWithTimeout(dispatch, 'Please enter a valid response code', 'error')
        } else {
            if (activeProject) {
                const responseCodeConverted = Number.parseInt(responseCode)
                const currentEndpoint = findEndpointById(activeProject, endpointId)

                if (currentEndpoint) {
                    const hasDuplicate = currentEndpoint.response.some((r) => r.httpCode === responseCodeConverted)
                    if (hasDuplicate) {
                        setIsDuplicateNameErrorVisible(true)
                    } else {
                        if (mode === 'create') {
                            const baseDotColor = getBaseDotColorForResponseCode(responseCodeConverted)
                            dispatch(createResponse('', responseCodeConverted, baseDotColor, endpointId))
                            setSelectedDotColor(baseDotColor)
                            if (onAfterCreate) {
                                onAfterCreate()
                                onClose()
                            }
                        } else if (mode === 'edit' && originalResponseCode !== undefined) {
                            dispatch(editResponse(originalResponseCode, responseCodeConverted, selectedDotColor, endpointId))
                        }

                        setIsDuplicateNameErrorVisible(false)
                        setSelectedResponseCode(responseCodeConverted)
                    }
                }
            }
        }
    }

    const onDelete = () => {
        if (originalResponseCode) {
            dispatch(deleteResponse(originalResponseCode, endpointId))
            if (onAfterDelete) onAfterDelete()
        }
    }

    const getBaseDotColorForResponseCode = (code: number): DotColors => {
        const dotColor: DotColors =
            code < 200
                ? 'white'
                : code >= 200 && code < 300
                ? 'green'
                : code >= 300 && code < 400
                ? 'yellow'
                : code >= 400 && code < 500
                ? 'orange'
                : code >= 500 && code < 600
                ? 'red'
                : 'white'

        return dotColor
    }

    return (
        <StyledBox
            style={{
                maxHeight: isMobileView ? undefined : 256,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 8,
                boxShadow: theme.palette.shadow.main,
                overflow: 'hidden',
                overflowY: 'auto',
            }}
        >
            {isMobileView && (
                <Box style={{ backgroundColor: theme.palette.primary.main, paddingBlock: 12, paddingInline: 16 }}>
                    <Text color='normal' fontSize={14}>
                        Edit response {originalResponseCode}
                    </Text>
                </Box>
            )}
            <Box style={{ paddingBlock: 12, overflowX: 'auto' }}>
                <TextField
                    size='small'
                    type='number'
                    // Used to restrict to enter special characters
                    onKeyDown={(e) => {
                        if (['e', 'E', '+', '-'].includes(e.key)) e.preventDefault()
                    }}
                    value={responseCode}
                    className={classes.responseCodeTextfield}
                    placeholder='Response code'
                    autoFocus
                    style={{
                        backgroundColor: theme.palette.forms.inputBackgroundMain,
                        border: 'none',
                        color: theme.palette.text.normal,
                    }}
                    inputProps={{
                        className: classes.emailInput,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    InputProps={{
                        classes: {
                            input: classes.input,
                        },
                    }}
                    onBlur={() => {
                        if (!isMobileView && responseCode.length === 3) {
                            handleSubmitResponse(responseCode)
                        }
                    }}
                    onChange={(e) => {
                        if (e.target.value.length < 4) {
                            setResponseCode(e.target.value)
                            setIsDuplicateNameErrorVisible(false)
                        }
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmitResponse(responseCode)
                            onClose()
                        }
                    }}
                />
                <Box style={{ marginTop: 8, marginLeft: 16, marginBottom: 8 }}>
                    {isDuplicateNameErrorVisible && (
                        <Text color={theme.palette.alert.light} fontSize={12}>
                            This response code already exists.
                        </Text>
                    )}
                    {!isDuplicateNameErrorVisible && (
                        <Text fontSize={12} color='light'>
                            Type 3 numbers.
                        </Text>
                    )}
                </Box>
                {!isMobileView && mode === 'edit' && (
                    <Box
                        className={classes.row}
                        onClick={() => {
                            setDeleteModalOpen(Number(responseCode))
                        }}
                    >
                        <Box className={classes.iconContainer}>
                            <DeleteOutlineIcon style={{ color: theme.palette.alert.light, fontSize: 26 }} />
                        </Box>
                        <Text fontSize={14} color='light'>
                            Delete response
                        </Text>
                    </Box>
                )}

                {isMobileView && (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            paddingBottom: 14,
                        }}
                    >
                        {mode === 'edit' && (
                            <Button
                                variant='text'
                                onClick={() => {
                                    setDeleteModalOpen(Number(responseCode))
                                }}
                                style={{ color: theme.palette.alert.main }}
                            >
                                Delete
                            </Button>
                        )}

                        <Button variant='text' onClick={onClose}>
                            Cancel
                        </Button>
                        <GradientButton title='Save' onClick={() => handleSubmitResponse(responseCode)} />
                    </Box>
                )}

                <Box style={{ marginTop: filteredList.length > 0 ? 12 : undefined }}>
                    {filteredList.map((item: CodeItem) => {
                        return (
                            <Box
                                className={classes.row}
                                key={item.code}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setResponseCode(item.code.toString())
                                    handleSubmitResponse(item.code.toString())
                                    if (mode === 'create') onClose()
                                }}
                            >
                                <Box
                                    className={classes.circle}
                                    style={{
                                        backgroundColor:
                                            item.code < 200
                                                ? theme.palette.neutral.light1
                                                : item.code >= 200 && item.code < 300
                                                ? theme.palette.accent.main
                                                : item.code >= 300 && item.code < 400
                                                ? theme.palette.yellow
                                                : item.code >= 400 && item.code < 500
                                                ? theme.palette.secondary.main
                                                : theme.palette.alert.main,
                                    }}
                                />
                                <Typography className={classes.menuItemText}>{`${item.code}: ${item.description}`}</Typography>
                            </Box>
                        )
                    })}
                </Box>

                {(filteredList.length === 0 || filteredList[0].code === Number(responseCode)) && (
                    <>
                        <Box className={classes.unselectableRow}>
                            <Text fontSize={14} color='light'>
                                Choose color:
                            </Text>
                        </Box>
                        <Box className={classes.dotsRow}>
                            <Box
                                className={classes.dotContainer}
                                style={{
                                    border:
                                        selectedDotColor === 'white'
                                            ? `1px solid ${colors.white.border}`
                                            : `1px solid transparent`,
                                }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedDotColor('white')
                                    handleSubmitNewDotColor('white')
                                }}
                            >
                                <Box
                                    className={classes.dot}
                                    style={{
                                        backgroundColor: colors.white.dot,
                                    }}
                                />
                            </Box>

                            <Box
                                className={classes.dotContainer}
                                style={{
                                    border:
                                        selectedDotColor === 'green'
                                            ? `1px solid ${colors.green.border}`
                                            : `1px solid transparent`,
                                }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedDotColor('green')
                                    handleSubmitNewDotColor('green')
                                }}
                            >
                                <Box
                                    className={classes.dot}
                                    style={{
                                        backgroundColor: colors.green.dot,
                                    }}
                                />
                            </Box>

                            <Box
                                className={classes.dotContainer}
                                style={{
                                    border:
                                        selectedDotColor === 'yellow'
                                            ? `1px solid ${colors.yellow.border}`
                                            : `1px solid transparent`,
                                }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedDotColor('yellow')
                                    handleSubmitNewDotColor('yellow')
                                }}
                            >
                                <Box
                                    className={classes.dot}
                                    style={{
                                        backgroundColor: colors.yellow.dot,
                                    }}
                                />
                            </Box>
                            <Box
                                className={classes.dotContainer}
                                style={{
                                    border:
                                        selectedDotColor === 'orange'
                                            ? `1px solid ${colors.orange.border}`
                                            : `1px solid transparent`,
                                }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedDotColor('orange')
                                    handleSubmitNewDotColor('orange')
                                }}
                            >
                                <Box
                                    className={classes.dot}
                                    style={{
                                        backgroundColor: colors.orange.dot,
                                    }}
                                />
                            </Box>
                            <Box
                                className={classes.dotContainer}
                                style={{
                                    border:
                                        selectedDotColor === 'red' ? `1px solid ${colors.red.border}` : `1px solid transparent`,
                                }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedDotColor('red')
                                    handleSubmitNewDotColor('red')
                                }}
                            >
                                <Box
                                    className={classes.dot}
                                    style={{
                                        backgroundColor: colors.red.dot,
                                    }}
                                />
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
            <DeleteModal
                isOpen={!!deleteModalOpen}
                onClose={() => setDeleteModalOpen(undefined)}
                onDelete={onDelete}
                type='response tab'
                name={String(originalResponseCode)}
            />
        </StyledBox>
    )
}

export default ResponseInfoDropdown

const codeItems: CodeItem[] = [
    {
        code: 100,
        description: 'Continue',
    },
    {
        code: 101,
        description: 'Switching Protocols',
    },
    {
        code: 102,
        description: 'Processing',
    },
    {
        code: 103,
        description: 'Early Hints',
    },
    {
        code: 200,
        description: 'OK',
    },
    {
        code: 201,
        description: 'Created',
    },
    {
        code: 202,
        description: 'Accepted',
    },
    {
        code: 203,
        description: 'Non-Authoritative Information',
    },
    {
        code: 204,
        description: 'No Content',
    },
    {
        code: 205,
        description: 'Reset Content',
    },
    {
        code: 206,
        description: 'Partial Content',
    },
    {
        code: 300,
        description: 'Multiple Choice',
    },
    {
        code: 301,
        description: 'Moved Permanently',
    },
    {
        code: 302,
        description: 'Found',
    },
    {
        code: 303,
        description: 'See Other',
    },
    {
        code: 304,
        description: 'Not Modified',
    },
    {
        code: 307,
        description: 'Temporary Redirect',
    },
    {
        code: 308,
        description: 'Permanent Redirect',
    },
    {
        code: 400,
        description: 'Bad Request',
    },
    {
        code: 401,
        description: 'Unauthorized',
    },
    {
        code: 403,
        description: 'Forbidden',
    },
    {
        code: 404,
        description: 'Not Found',
    },
    {
        code: 405,
        description: 'Method Not Allowed',
    },
    {
        code: 406,
        description: 'Not Acceptable',
    },
    {
        code: 409,
        description: 'Conflict',
    },
    {
        code: 415,
        description: 'Unsupported Media Type',
    },
    {
        code: 500,
        description: 'Internal Server Error',
    },
    {
        code: 501,
        description: 'Not Implemented',
    },
    {
        code: 502,
        description: 'Bad Gateway',
    },
    {
        code: 503,
        description: 'Service Unavailable',
    },
    {
        code: 504,
        description: 'Gateway Timeout',
    },
]
