import React, { useState } from 'react'
import { Box, useTheme, Stack } from '@mui/material'
import Text from 'components/Text'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthState } from 'services/selectors/auth-selectors'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import GradientButton from 'components/GradientButton'
import { themeTypes } from 'services/types/userSettings-types'
import { getEditorSettings, getSelectedThemeType } from '../services/selectors/userSettings-selectors'
import { setEditorSettings, setSelectedThemeType } from '../services/actions/userSettings-actions'
import CustomSwitch from 'components/CustomSwitch'
import ThemeCard from 'components/ThemeCard'
import { EditorSettings } from '../services/types/userSettings-types'
import ChangePasswordModal from './auth/ChangePasswordModal'

const UserProfile: React.FC = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { userEmail } = useSelector(getAuthState)
    const selectedTheme = useSelector(getSelectedThemeType)
    const editorSettings = useSelector(getEditorSettings)
    const { prefillResponseBodyOnCreate, prefillRequestBodyOnCreate } = editorSettings

    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)

    const toggleEditorSettingsParam = (param: keyof EditorSettings) => {
        if (param === 'prefillRequestBodyOnCreate')
            dispatch(setEditorSettings({ ...editorSettings, prefillRequestBodyOnCreate: !prefillRequestBodyOnCreate }))
        if (param === 'prefillResponseBodyOnCreate')
            dispatch(setEditorSettings({ ...editorSettings, prefillResponseBodyOnCreate: !prefillResponseBodyOnCreate }))
    }

    return (
        <Box sx={{ flex: 1 }}>
            <Stack direction='column' marginTop={theme.spacing(2)} marginLeft={theme.spacing(3)} marginRight={theme.spacing(3)}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' marginBottom={theme.spacing(3)}>
                    <Text fontSize={30} fontWeight='bold'>
                        Profile
                    </Text>
                    <Stack direction='row' spacing={1} sx={{ cursor: 'pointer' }} onClick={() => alert('in progress')}>
                        <DeleteOutlineIcon sx={{ color: theme.palette.alert.light }} />
                        <Text color={theme.palette.alert.main} fontSize={16}>
                            Delete account
                        </Text>
                    </Stack>
                </Stack>
                <Text fontSize={20} fontWeight='bold'>
                    Account
                </Text>
                <Text
                    fontSize={16}
                    fontWeight='bold'
                    color={theme.palette.neutral.light3}
                    marginTop={theme.spacing(2)}
                    marginBottom={theme.spacing(3)}
                >
                    {`Email: ${userEmail}`}
                </Text>
                <GradientButton
                    width={165}
                    title='Change password'
                    sx={{ marginBottom: 4 }}
                    onClick={() => setIsChangePasswordModalOpen(true)}
                />
                <ChangePasswordModal isOpen={isChangePasswordModalOpen} onClose={() => setIsChangePasswordModalOpen(false)} />
                <Text fontSize={20} fontWeight={'bold'} marginBottom={theme.spacing(2)}>
                    Theme
                </Text>

                <Stack className='hideScrollBar' direction='row' overflow='auto' marginBottom={theme.spacing(4)}>
                    {themeTypes.map((themeOption, index) => (
                        <ThemeCard
                            key={`${themeOption}-${index}`}
                            themeOption={themeOption}
                            isActive={themeOption === selectedTheme}
                            onClick={() => dispatch(setSelectedThemeType(themeOption))}
                        />
                    ))}
                </Stack>
                <Text fontSize={20} fontWeight={'bold'} marginBottom={theme.spacing(3)}>
                    Section for setting up editor behavior
                </Text>

                <Stack display='flex' direction='row' alignItems='center' marginBottom={theme.spacing(3)}>
                    <Box marginRight={theme.spacing(3)}>
                        <CustomSwitch
                            checked={prefillResponseBodyOnCreate}
                            onSwitch={() => toggleEditorSettingsParam('prefillResponseBodyOnCreate')}
                        />
                    </Box>
                    <Stack display='flex' direction='column'>
                        <Text fontSize={16} marginBottom={theme.spacing(1)}>
                            Precreate response object
                        </Text>
                        <Text fontSize={14} color={theme.palette.neutral.light3}>
                            Automatically pre-creates new response object when a new response is created
                        </Text>
                    </Stack>
                </Stack>
                <Stack direction='row' alignItems='center' marginBottom={theme.spacing(3)}>
                    <Box marginRight={theme.spacing(3)}>
                        <CustomSwitch
                            checked={prefillRequestBodyOnCreate}
                            onSwitch={() => toggleEditorSettingsParam('prefillRequestBodyOnCreate')}
                        />
                    </Box>
                    <Stack display='flex' direction='column'>
                        <Text fontSize={16} marginBottom={theme.spacing(1)}>
                            Precreate request object
                        </Text>
                        <Text fontSize={14} color={theme.palette.neutral.light3}>
                            Automatically pre-creates new response object when a new POST/PUT/DELETE endpoint is created
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}

export default UserProfile
