import initState from 'services/types/initState'
import { ReduxAction } from 'services/types/mainReducer-types'
import { UserSettingsActionTypes, UserSettingsReduxState } from 'services/types/userSettings-types'

export default function userSettingsReducer(
    state: UserSettingsReduxState = initState.userSettings,
    action: ReduxAction
): UserSettingsReduxState {
    switch (action.type) {
        case UserSettingsActionTypes.SET_SELECTED_MODE: {
            return { ...state, selectedMode: action.payload }
        }
        case UserSettingsActionTypes.SET_SELECTED_THEME: {
            return { ...state, selectedTheme: action.payload }
        }

        case UserSettingsActionTypes.SET_EDITOR_SETTINGS: {
            return { ...state, editorSettings: action.payload }
        }

        default:
            return state
    }
}
