import { Box, useTheme, Typography, Dialog } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { Project, SharedCode } from 'shared/types/project-types'
import DropdownMenu from 'components/DropdownMenu'
import { useRef, useState } from 'react'
import Fonts from 'constants/fonts'
import {
    HOVER_TOOLTIP_DELAY_IN_MILLISECONDS,
    MAIN_BORDER_RADIUS,
    SHARED_CODE_TAB_MAX_WIDTH,
    SHARED_CODE_TAB_MAX_WIDTH_XS,
} from 'constants/ui'
import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import NameUpdateForm from './NameUpdateForm'
import useIsMobileView from 'hooks/useIsMobileView'
import { useDispatch } from 'react-redux'
import { useDrag, useDrop } from 'react-dnd'
import { moveSharedCode } from 'services/actions/project-actions'
import { styled } from '@mui/material/styles'

const PREFIX = 'SharedCodeTab'

const classes = {
    sharedCodeTabContainer: `${PREFIX}-sharedCodeTabContainer`,
    sharedCodeTab: `${PREFIX}-sharedCodeTab`,
}

const TAB_PADDING = 16

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.sharedCodeTabContainer}`]: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        paddingLeft: TAB_PADDING,
        paddingRight: TAB_PADDING,
        flex: 1,
        overflow: 'hidden',
        height: 32,
        justifyContent: 'space-between',
        cursor: 'pointer',
        [theme.breakpoints.down(600)]: {
            maxWidth: SHARED_CODE_TAB_MAX_WIDTH_XS - 2 * TAB_PADDING,
            minWidth: 88 - 2 * TAB_PADDING,
        },
        [theme.breakpoints.up(600)]: {
            maxWidth: SHARED_CODE_TAB_MAX_WIDTH - 2 * TAB_PADDING,
            minWidth: 96 - 2 * TAB_PADDING,
        },
    },
    [`& .${classes.sharedCodeTab}`]: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(1),
        [theme.breakpoints.down(600)]: {
            maxWidth: SHARED_CODE_TAB_MAX_WIDTH_XS - 2 * TAB_PADDING,
        },
        [theme.breakpoints.up(600)]: {
            maxWidth: SHARED_CODE_TAB_MAX_WIDTH - 2 * TAB_PADDING,
        },
    },
}))

interface SharedCodeTabProps {
    sharedCode: SharedCode
    isSelected: boolean
    isSaved: boolean
    onSelectTab: () => void
    onDeleteTab: () => void
    onNameUpdated: () => void
    activeProject: Project | undefined
    isFirst: boolean
}

const SharedCodeTab: React.FC<SharedCodeTabProps> = ({
    sharedCode,
    isSelected,
    isSaved,
    onSelectTab,
    onDeleteTab,
    onNameUpdated,
    activeProject,
    isFirst,
}) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const isMobileView = useIsMobileView(theme)
    const [isEditDropdownVisible, setIsEditDropdownVisible] = useState(false)
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)
    const [isNotSavedIconTooltipVisible, setIsNotSavedIconTooltipVisible] = useState(false)
    const [isNameElementOverflown, setIsNameElementOverflown] = useState(false)
    const [isNameUpdateDialogOpen, setIsNameUpdateDialogOpen] = useState(false)
    const nameEl = useRef<HTMLParagraphElement>(null)

    React.useEffect(() => {
        checkIfNameElementIsOverflown()
    }, [nameEl])

    const [, drag] = useDrag(() => ({
        type: 'sharedCodeTab',
        item: { id: sharedCode.id },
    }))

    const [, drop] = useDrop(() => ({
        accept: 'sharedCodeTab',
        drop: (item: { id: string }) => {
            onDrop(item.id, sharedCode.id)
        },
    }))

    const onDrop = (movedSharedCodeId: string, dropSharedCodeId: string) => {
        if (activeProject && movedSharedCodeId !== dropSharedCodeId) dispatch(moveSharedCode(movedSharedCodeId, dropSharedCodeId))
    }

    const checkIfNameElementIsOverflown = () => {
        if (!nameEl.current) return

        if (nameEl.current.clientWidth < nameEl.current.scrollWidth) {
            setIsNameElementOverflown(true)
        } else {
            setIsNameElementOverflown(false)
        }
    }

    const handleCloseTooltip = () => {
        setIsTooltipVisible(false)
    }

    const handleOpenTooltip = () => {
        if (isNameElementOverflown && !isEditDropdownVisible) setIsTooltipVisible(true)
    }

    const handleCloseNotSavedIconTooltip = () => {
        setIsNotSavedIconTooltipVisible(false)
    }

    const handleOpenNotSavedIconTooltip = () => {
        if (!isEditDropdownVisible) setIsNotSavedIconTooltipVisible(true)
    }

    return (
        <Box ref={drag}>
            <StyledBox
                ref={drop}
                className={classes.sharedCodeTabContainer}
                style={{
                    backgroundColor: isSelected
                        ? theme.palette.monacoEditor.activeTabBackground
                        : theme.palette.monacoEditor.tabBackground,
                    borderTopLeftRadius: isFirst ? 3 : undefined,
                }}
                onClick={() => {
                    if (isSelected) {
                        setIsTooltipVisible(false)

                        if (isMobileView) setIsNameUpdateDialogOpen(true)
                        else setIsEditDropdownVisible(true)
                    } else onSelectTab()
                }}
            >
                <DropdownMenu
                    open={isEditDropdownVisible}
                    onClose={() => setIsEditDropdownVisible(false)}
                    placement='bottom-start'
                    borderRadius={MAIN_BORDER_RADIUS}
                    content={
                        <NameUpdateForm
                            sharedCode={sharedCode}
                            onDeleteTab={onDeleteTab}
                            activeProject={activeProject}
                            closeForm={() => setIsEditDropdownVisible(false)}
                            checkIfNameElementIsOverflown={checkIfNameElementIsOverflown}
                            isVisible={isEditDropdownVisible}
                            isMobileView={isMobileView}
                            onNameUpdated={onNameUpdated}
                        />
                    }
                >
                    <Box className={classes.sharedCodeTab}>
                        <Tooltip
                            title={sharedCode.name}
                            open={isTooltipVisible}
                            onClose={handleCloseTooltip}
                            onOpen={handleOpenTooltip}
                            enterDelay={HOVER_TOOLTIP_DELAY_IN_MILLISECONDS}
                        >
                            <Typography
                                ref={nameEl}
                                color='normal'
                                fontSize={16}
                                style={{
                                    userSelect: 'none',
                                    flex: 1,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    color: theme.palette.text.normal,
                                    fontSize: Fonts.size.normal,
                                }}
                            >
                                {sharedCode.name}
                            </Typography>
                        </Tooltip>
                        {!isSaved && (
                            <Tooltip
                                title='Not saved'
                                open={isNotSavedIconTooltipVisible}
                                onClose={handleCloseNotSavedIconTooltip}
                                onOpen={handleOpenNotSavedIconTooltip}
                                enterDelay={HOVER_TOOLTIP_DELAY_IN_MILLISECONDS}
                            >
                                <ErrorOutlineIcon style={{ color: theme.palette.secondary.main, fontSize: 14 }} />
                            </Tooltip>
                        )}
                    </Box>
                </DropdownMenu>
            </StyledBox>

            <Dialog onClose={() => setIsNameUpdateDialogOpen(false)} open={isNameUpdateDialogOpen} style={{ overflow: 'hidden' }}>
                <NameUpdateForm
                    sharedCode={sharedCode}
                    onDeleteTab={onDeleteTab}
                    activeProject={activeProject}
                    closeForm={() => setIsNameUpdateDialogOpen(false)}
                    checkIfNameElementIsOverflown={checkIfNameElementIsOverflown}
                    isVisible={isNameUpdateDialogOpen}
                    isMobileView={isMobileView}
                    onNameUpdated={onNameUpdated}
                />
            </Dialog>
        </Box>
    )
}
export default SharedCodeTab
