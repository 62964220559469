import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { capitalizeFirstLetter } from 'shared/utils/generic-utils'

const PREFIX = 'ParameterTypeDropdownMenu'

const classes = {
    menuItem: `${PREFIX}-menuItem`,
    container: `${PREFIX}-container`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.menuItem}`]: {
        width: 127,
        height: 52,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.palette.neutral.light2,
        '&:hover': {
            backgroundColor: theme.palette.type === 'dark' ? theme.palette.forms.hover : undefined,
            color: theme.palette.primary.light,
        },
    },

    [`&.${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 3,
        marginTop: 4,
        width: 127,
    },
}))

type ParameterType = 'string' | 'number' | 'boolean'

interface ParameterTypeDropdownMenuProps {
    selectedParameterType: ParameterType
    onParameterTypeSelect: (type: 'string' | 'number' | 'boolean') => void
    isReadOnly: boolean
}

const parameterTypes: ParameterType[] = ['string', 'number', 'boolean']

const ParameterTypeDropdownMenu: React.FC<ParameterTypeDropdownMenuProps> = ({ onParameterTypeSelect }) => {
    return (
        <StyledBox className={classes.container}>
            {parameterTypes.map((t, i) => {
                return (
                    <Box
                        key={i}
                        className={classes.menuItem}
                        style={{ borderRadius: i === 0 || i === 2 ? 3 : 0 }}
                        onClick={() => onParameterTypeSelect(t)}
                    >
                        <Typography
                            style={{
                                marginLeft: 10,
                                fontWeight: 400,
                            }}
                        >
                            {capitalizeFirstLetter(t)}
                        </Typography>
                    </Box>
                )
            })}
        </StyledBox>
    )
}

export default ParameterTypeDropdownMenu
