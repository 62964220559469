import { Box, Typography, useTheme, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { ProjectMetadataPayload } from 'shared/payload-types/project-payloads'
import ModalBase from './ModalBase'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import { updateProjectBaseUrl } from 'services/actions/project-actions'
import Text from './Text'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import { showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import { setShareModalOpen } from 'services/actions/temporaryUiChanges-actions'

const PREFIX = 'ProjectDetailsModal'

const classes = {
    secondaryText: `${PREFIX}-secondaryText`,
    inlineBox: `${PREFIX}-inlineBox`,
    container: `${PREFIX}-container`,
    projectNameTitle: `${PREFIX}-projectNameTitle`,
    bodyContainer: `${PREFIX}-bodyContainer`,
    flexRow: `${PREFIX}-flexRow`,
    input: `${PREFIX}-input`,
    inputContainer: `${PREFIX}-inputContainer`,
    editIcon: `${PREFIX}-editIcon`,
    shareText: `${PREFIX}-shareText`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.secondaryText}`]: {
        fontWeight: 400,
    },

    [`& .${classes.inlineBox}`]: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 16,
        cursor: 'pointer',
    },

    [`&.${classes.container}`]: {
        width: 528,
        height: 238,
        backgroundColor: theme.palette.background.default,
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.neutral.dark1}`,
        borderRadius: 16,
        boxShadow: `0px 0px 20px ${theme.palette.forms.shadow}`,
    },

    [`& .${classes.projectNameTitle}`]: {
        fontWeight: 'bold',
        textAlign: 'center',
    },

    [`& .${classes.bodyContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 16,
        marginTop: 24,
    },

    [`& .${classes.flexRow}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    [`& .${classes.input}`]: {
        color: theme.palette.neutral.light1,
        marginTop: 2,
        width: 260,
        caretColor: theme.palette.primary.light,
    },

    [`& .${classes.inputContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
    },

    [`& .${classes.editIcon}`]: {
        color: theme.palette.text.normal,
        cursor: 'pointer',
        fontSize: 16,
        marginLeft: 10,
    },

    [`& .${classes.shareText}`]: {
        color: theme.palette.primary.light,
        cursor: 'pointer',
        caretColor: theme.palette.primary.light,
    },
}))

interface ProjectDetailsModalProps {
    isOpen: boolean
    onClose: () => void
    project: ProjectMetadataPayload
}

const ProjectDetailsModal: React.FC<ProjectDetailsModalProps> = ({ isOpen, onClose, project }) => {
    const theme = useTheme()

    const dispatch = useDispatch()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [baseUrl, setBaseUrl] = useState<string | undefined>(project.baseUrl)

    useEffect(() => {
        setBaseUrl(project.baseUrl)
    }, [project])

    const onSave = () => {
        if (baseUrl) {
            dispatch(updateProjectBaseUrl(baseUrl))
            showFlashMessageWithTimeout(dispatch, 'Api base url was successfully changed', 'success')
        } else {
            setBaseUrl(project.baseUrl)
            showFlashMessageWithTimeout(dispatch, 'Api base url can not be empty', 'error')
        }
        setEditMode(false)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBaseUrl(event.target.value)
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} center>
            <StyledBox className={classes.container}>
                <Box style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <CloseIcon style={{ color: theme.palette.neutral.light3, cursor: 'pointer' }} onClick={onClose} />
                </Box>
                <Text color='light' fontSize={20} className={classes.projectNameTitle}>
                    {project.name}
                </Text>

                <Box className={classes.bodyContainer}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box className={classes.flexRow}>
                            <Text color='light' fontSize={14} style={{ width: 130 }}>
                                Created:
                            </Text>
                            <Text color='light' fontSize={16} className={classes.secondaryText}>
                                {`${moment(project.createdAt).format('YYYY-MM-DD')}`}
                            </Text>
                        </Box>
                        <Box className={classes.flexRow}>
                            <Text color='light' fontSize={14} style={{ width: 130 }}>
                                Last edit:
                            </Text>
                            <Text color='light' fontSize={16} className={classes.secondaryText}>
                                {`${moment(project.lastModifiedAt).format('YYYY-MM-DD')}`}
                            </Text>
                        </Box>
                        <Box className={classes.flexRow}>
                            <Text color='light' fontSize={14} style={{ width: 130 }}>
                                Api base URL:
                            </Text>
                            {!editMode && (
                                <Box className={classes.inputContainer}>
                                    <Text color='normal' fontSize={16}>{`${baseUrl}`}</Text>
                                    <EditIcon className={classes.editIcon} onClick={() => setEditMode(!editMode)} />
                                </Box>
                            )}
                            {editMode && (
                                <Box className={classes.inputContainer}>
                                    <TextField
                                        value={baseUrl}
                                        onChange={handleChange}
                                        autoFocus
                                        fullWidth
                                        onBlur={onSave}
                                        variant='standard'
                                        InputLabelProps={{ shrink: false }}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        inputProps={{
                                            className: classes.input,
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Typography
                    color='light'
                    fontSize={16}
                    className={classes.shareText}
                    onClick={() => {
                        onClose()
                        dispatch(setShareModalOpen(true))
                    }}
                >{`Share project & see members`}</Typography>
            </StyledBox>
        </ModalBase>
    )
}

export default ProjectDetailsModal
