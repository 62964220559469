import store from 'services/store'
import { AuthActionTypes, AUTH_LOCAL_STORAGE_KEY, parseJwtEncodedTokenAndGetReduxState } from 'services/types/auth-types'
import { ResponseBase } from 'shared/payload-types/base-payloads'
import { ErrorCategory, logError } from './Analytics'

export interface INetworkError {
    message: string
    httpCode: number
}

class NetworkError implements INetworkError {
    public message
    public httpCode

    constructor(message: string, code: number) {
        this.message = message
        this.httpCode = code
    }
}

export class Network {
    static async fetch(input: RequestInfo, init?: RequestInit | undefined): Promise<Response | null> {
        const accessToken = store.getState().auth.token

        if (init && init.headers) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const headers = init.headers as any
            headers['Authorization'] = `Bearer ${accessToken}`
        } else {
            init = {}
            init.headers = {
                Authorization: `Bearer ${accessToken}`,
            }
        }
        const result = await fetch(input, init)
        const newToken = result.headers.get('X-Refresh-Token')
        if (newToken) {
            try {
                const parsed = parseJwtEncodedTokenAndGetReduxState(newToken)
                localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, newToken)
                store.dispatch({ type: AuthActionTypes.SET_AUTH_STATE, payload: parsed })
            } catch (e) {
                console.error('Received new token to refresh but the format was invalid')
                throw new Error('Received invalid JWT to refresh the current token')
            }
        }
        return result
    }
}

export const getAppInsightsRequestId = (response: Response | null): string | null =>
    response ? response.headers.get('app-insights-request-id') : null

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleNetworkResponseAndGetJsonAsync = async (
    response: Response,
    url?: string,
    debugData?: unknown
    /* eslint-disable  @typescript-eslint/no-explicit-any */
): Promise<any> => {
    let parsedJson: ResponseBase | null = null
    const requestId = getAppInsightsRequestId(response)
    if (!response.ok) {
        try {
            parsedJson = (await response.json()) as ResponseBase
        } catch (ex) {
            logError(
                ErrorCategory.Network,
                'handleNetworkResponseAndGetResultAsync:: Failed network request with no JSON returned',
                {
                    response,
                    url: url,
                    data: debugData,
                }
            )
            console.log(`Failed request id: ${requestId}`)
            throw new Error('Something went wrong when processing server failed response')
        }
        if (parsedJson.error) {
            logError(ErrorCategory.Network, 'handleNetworkResponseAndGetResultAsync:: Failed api request', {
                url,
                error: parsedJson.error.message,
                data: debugData,
            })
            console.log(`Failed request id: ${requestId}`)
            throw parsedJson.error.message
        } else {
            logError(
                ErrorCategory.Network,
                "handleNetworkResponseAndGetResultAsync:: Failed network request. Returned JSON doesn't contain error property",
                { response, parsedJson: parsedJson, url: url, data: debugData }
            )
            console.log(`Failed request id: ${requestId}`)
            throw new Error("Can't retrieve error when processing failed server response")
        }
    } else {
        try {
            parsedJson = (await response.json()) as ResponseBase
        } catch (ex) {
            logError(
                ErrorCategory.Network,
                'handleNetworkResponseAndGetResultAsync:: Successful api request but no JSON returned',
                { url }
            )
            console.log(`Failed request id: ${requestId}`)
            return {}
        }
    }
    if (parsedJson.error) {
        logError(ErrorCategory.Network, 'handleNetworkResponseAndGetResultAsync:: Failed api request', {
            url,
            error: parsedJson.error,
            data: debugData,
        })
        console.log(`Failed request id: ${requestId}`)
        throw parsedJson.error.message
    }
    return parsedJson
}

/**
 * Handles the network response. Automatically throws error if error is returned from the server or if there
 * is an error during parsing. If successfully parses the response doesn't do anything.
 * @param response Response to parse
 * @param url Optional server url request for debug logging.
 * @param debugData Optional data to log during error.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleNetworkResponseWithNoPayloadAsync = async (
    response: Response,
    url?: string,
    debugData?: unknown
): Promise<void> => {
    let parsedJson: ResponseBase | null = null
    if (!response.ok) {
        try {
            parsedJson = (await response.json()) as ResponseBase
        } catch (ex) {
            logError(
                ErrorCategory.Network,
                'handleNetworkResponseAndGetResultAsync:: Failed network request with no JSON returned',
                {
                    response,
                    url: url,
                    data: debugData,
                }
            )
            throw new Error('Something went wrong when processing server failed response')
        }
        if (parsedJson.error) {
            logError(ErrorCategory.Network, 'handleNetworkResponseAndGetResultAsync:: Failed api request', {
                url,
                error: parsedJson.error.message,
                data: debugData,
            })
            throw parsedJson.error.message
        } else {
            logError(
                ErrorCategory.Network,
                "handleNetworkResponseAndGetResultAsync:: Failed network request. Returned JSON doesn't contain error property",
                { response, parsedJson: parsedJson, url: url, data: debugData }
            )
            throw new Error("Can't retrieve error when processing failed server response")
        }
    }
    return
}
