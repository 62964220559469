import { FC } from 'react'
import { CircularProgress, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import LoadingImage from 'assets/images/LoadingImage'
import Text from './Text'
import { styled } from '@mui/material/styles'

const PREFIX = 'LoadingGenericContent'
const classes = {
    mainContainer: `${PREFIX}-mainContainer`,
    topMargin: `${PREFIX}-topMargin`,
}

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.mainContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
        flex: 0.6,
        justifyContent: 'center',
    },
    [`& .${classes.topMargin}`]: {
        marginTop: `${theme.spacing(2)}`,
    },
}))

interface LoadingGenericContentProps {
    title?: string
    subtitle?: string
}

const LoadingGenericContent: FC<LoadingGenericContentProps> = ({ title, subtitle }) => {
    const theme = useTheme()

    return (
        <Root className={classes.mainContainer}>
            <Box sx={{ display: 'flex', maxWidth: 250, maxHeight: 362 }}>
                <LoadingImage />
            </Box>
            <Text color='normal' fontSize={24} sx={{ textAlign: 'center' }} className={classes.topMargin}>
                {title ? title : 'Almost the speed of light'}
            </Text>
            <Text color='normal' fontSize={16} sx={{ textAlign: 'center' }} className={classes.topMargin}>
                {subtitle ? subtitle : 'Hold on one moment, please.'}
            </Text>
            <Box className={classes.topMargin}>
                <CircularProgress sx={{ color: theme.palette.text.normal }} />
            </Box>
        </Root>
    )
}

export default LoadingGenericContent
