import { Box } from '@mui/material'
import React, { useState } from 'react'
import ModalBase from './ModalBase'
import EmptyButton from './EmptyButton'
import GradientButton from './GradientButton'
import Text from './Text'
import StyledTextField from './StyledTextField'
import { showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'

const PREFIX = 'ChangeUrlModal'
const classes = {
    textfield: `${PREFIX}-textfield`,
    container: `${PREFIX}-container`,
    title: `${PREFIX}-title`,
    secondTitle: `${PREFIX}-secondTitle`,
    buttonsContainer: `${PREFIX}-buttonsContainer`,
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.textfield}`]: {
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.neutral.dark2,
            '& fieldset': {
                borderWidth: 0,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.light,
            },
        },
    },
    [`&.${classes.container}`]: {
        width: 440,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        padding: 24,
        borderRadius: 16,
        border: `1px solid ${theme.palette.neutral.dark1}`,
        boxShadow: theme.palette.shadow.main,
    },
    [`& .${classes.title}`]: {
        fontWeight: 'bold',
        marginBottom: 32,
    },
    [`& .${classes.secondTitle}`]: {
        fontWeight: 'bold',
        marginBottom: 18,
    },
    [`& .${classes.buttonsContainer}`]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 28,
    },
}))

interface ChangeUrlModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: (newUrl: string) => void
    url: string
}

const ChangeUrlModal: React.FC<ChangeUrlModalProps> = ({ isOpen, onClose, onConfirm, url }) => {
    const [baseUrl, setBaseUrl] = useState<string>(url)
    const dispatch = useDispatch()

    const onRequestUrlChange = (requestUrl: string) => {
        setBaseUrl(requestUrl)
    }

    const onModalClose = () => {
        setBaseUrl(url)
        onClose()
    }

    return (
        <ModalBase
            center
            isOpen={isOpen}
            onClose={onModalClose}
            style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
            <Root className={classes.container}>
                <Text color='light' fontSize={20} className={classes.title}>
                    Do you wish to change the api base URL?
                </Text>
                <Text color='light' fontSize={16} className={classes.secondTitle}>
                    New URL
                </Text>
                <StyledTextField onChange={(e) => onRequestUrlChange(e.target.value)} value={baseUrl} autoFocus />
                <Box className={classes.buttonsContainer}>
                    <GradientButton
                        title='Confirm changes'
                        onClick={() => {
                            onConfirm(baseUrl)
                            onClose()
                            if (url !== baseUrl) {
                                showFlashMessageWithTimeout(dispatch, 'Api base url was successfully updated.', 'success')
                            }
                        }}
                        style={{ marginLeft: 20 }}
                    ></GradientButton>
                    <EmptyButton style={{ marginRight: 20 }} text='Cancel' onClick={onModalClose} />
                </Box>
            </Root>
        </ModalBase>
    )
}

export default ChangeUrlModal
