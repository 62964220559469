import { Box, useTheme } from '@mui/material'
import SharedCodeImage from 'assets/images/SharedCodeImage'
import GradientButton from 'components/GradientButton'
import Text from 'components/Text'

interface NoSharedCodeProps {
    onAddNewTab: () => void
}

const NoSharedCode: React.FC<NoSharedCodeProps> = ({ onAddNewTab }) => {
    const theme = useTheme()

    return (
        <>
            <Box style={{ maxWidth: '100%' }}>
                <SharedCodeImage width={'100%'} />
            </Box>

            <Text color='normal' fontSize={24} style={{ maxWidth: 435, textAlign: 'center', marginTop: theme.spacing(3) }}>
                Shared code
            </Text>

            <Text
                color='light'
                fontSize={16}
                style={{ maxWidth: 435, textAlign: 'center', marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}
            >
                You can define code and reuse it in endpoints. Changes in Shared code are reflected in the whole project.
            </Text>
            <GradientButton title='Add shared code' style={{ marginTop: theme.spacing(3) }} onClick={onAddNewTab} />
        </>
    )
}

export default NoSharedCode
