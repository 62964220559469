import initState from 'services/types/initState'
import { ReduxAction } from 'services/types/mainReducer-types'
import { TemporaryUiChangesActionTypes, TemporaryUiChangesReduxState } from 'services/types/temporaryUiChanges-types'

export default function temporaryUiChangesReducer(
    state: TemporaryUiChangesReduxState = initState.temporaryUiChanges,
    action: ReduxAction
): TemporaryUiChangesReduxState {
    switch (action.type) {
        case TemporaryUiChangesActionTypes.SET_CREATED_ENDPOINT_INFO: {
            const { createdEndpointInfo } = action.payload
            if (!createdEndpointInfo) return { ...state, createdEndpointInfo: undefined }
            return { ...state, createdEndpointInfo: createdEndpointInfo }
        }

        case TemporaryUiChangesActionTypes.SET_SHARE_MODAL_OPEN: {
            const { isOpen } = action.payload
            return { ...state, isShareModalOpen: isOpen }
        }

        default:
            return state
    }
}
