import { useTheme, Tooltip, InputAdornment, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import Fonts from 'constants/fonts'

const PREFIX = 'EndpointInput'

const classes = {
    textField: `${PREFIX}-textField`,
    textFieldDisabled: `${PREFIX}-textFieldDisabled`,
    input: `${PREFIX}-input`,
    inputProps: `${PREFIX}-inputProps`,
    copyIcon: `${PREFIX}-copyIcon`,
    helperText: `${PREFIX}-helperText`,
}

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
    [`& .${classes.textField}`]: {
        flexGrow: 1,
        borderRadius: 5,
        height: 40,
        '& .MuiOutlinedInput-root': {
            '&:hover': {
                backgroundColor: theme.palette.input.hoverBackground,
            },
            '&:hover fieldset': {
                border: `2px solid ${theme.palette.primary.light}`,
            },
            '&.Mui-focused': {
                backgroundColor: theme.palette.input.hoverBackground,
            },
            '&.Mui-focused fieldset': {
                border: `2px solid ${theme.palette.primary.light}`,
            },
            '&.Mui-disabled fieldset': {
                border: `2px solid ${theme.palette.neutral.neutral2}`,
            },
        },
    },

    [`& .${classes.textFieldDisabled}`]: {
        flexGrow: 1,
        borderRadius: 5,
        height: 40,
    },

    [`& .${classes.input}`]: {
        border: 'none',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        color: theme.palette.text.normal,
        fontSize: Fonts.size.normal,
        height: 23,
        caretColor: theme.palette.primary.light,
    },

    [`& .${classes.inputProps}`]: {
        '&::placeholder': {
            color: theme.palette.neutral.light3,
            opacity: 1,
            fontSize: Fonts.size.normal,
        },
    },

    [`& .${classes.copyIcon}`]: {
        color: theme.palette.neutral.neutral2,
        cursor: 'pointer',
        transform: 'rotateX(180deg)',
    },

    [`& .${classes.helperText}`]: {
        marginTop: 0,
    },
}))

interface EndpointInputProps {
    requestUrl: string
    isReadOnly: boolean
    onRequestUrlChange: (value: string) => void
    onRequestUrlSave: () => void
    onCopyRequestUrl: () => void
    getPathVariables: (path: string) => void
    duplicateUrlEndpointName: string | undefined
}

const EndpointInput: React.FC<EndpointInputProps> = ({
    requestUrl,
    isReadOnly,
    onRequestUrlChange,
    onRequestUrlSave,
    onCopyRequestUrl,
    getPathVariables,
    duplicateUrlEndpointName,
}) => {
    const theme = useTheme()

    return (
        <TextField
            size='small'
            className={isReadOnly ? classes.textFieldDisabled : classes.textField}
            fullWidth
            placeholder='Specify'
            error={duplicateUrlEndpointName !== undefined}
            helperText={
                duplicateUrlEndpointName !== undefined
                    ? `There is already endpoint ${duplicateUrlEndpointName} with the same url`
                    : null
            }
            style={{
                backgroundColor: theme.palette.forms.inputBackgroundMain,
                border: 'none',
                color: theme.palette.text.normal,
            }}
            value={requestUrl}
            inputProps={{
                readOnly: isReadOnly,
                className: classes.input,
            }}
            InputProps={{
                classes: {
                    input: classes.inputProps,
                },
                endAdornment: (
                    <StyledInputAdornment position='end'>
                        <Tooltip title='Copy to clipboard' placement='left-start'>
                            <ContentCopyRoundedIcon className={classes.copyIcon} onClick={onCopyRequestUrl} />
                        </Tooltip>
                    </StyledInputAdornment>
                ),
            }}
            FormHelperTextProps={{
                className: classes.helperText,
            }}
            onChange={(e) => {
                getPathVariables(e.target.value)
                onRequestUrlChange(e.target.value)
            }}
            onBlur={() => {
                if (!isReadOnly) {
                    onRequestUrlSave()
                }
            }}
        />
    )
}

export default EndpointInput
