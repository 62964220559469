/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as monacoEditor from 'monaco-editor'
import MonacoEditor, { OnMount, EditorProps, Monaco } from '@monaco-editor/react'
import { Component } from 'react'
import { SharedCode } from 'shared/types/project-types'
import {
    addPropertiesFromExtendedInterfaceToDeclaration,
    formatTypescriptCode,
    getCompletionItemsForEditorFromSharedCodeCache,
    getDeclarationsTextFromCodeByName,
    getSharedCodeContentByFileName,
    getTypeDefinitionByNameAndFileNameFromCache,
    replaceTypesByDeclarationText,
    searchCodeForDeclaration,
} from 'components/MonacoEditor/monaco-utils'
import {
    MONACO_EXTRA_NUMBER_TYPES,
    MONACO_NUMBER_FLOAT_TYPE,
    MONACO_NUMBER_INT_32_TYPE,
    MONACO_NUMBER_INT_64_TYPE,
} from 'constants/monaco'

const DEFAULT_STATE = {
    editor: undefined as unknown as monacoEditor.editor.ICodeEditor,
    monaco: undefined as unknown as Monaco,
}
interface MonacoEditorCustomProps {
    onBlur?: () => void
    onSaveAction?: () => void
    sharedCodes?: SharedCode[]
    /**
     * Used as signal for editor to update sharedCodes (eg. on sharedCodes screen, update them after the saving of tabs)
     */
    shouldUpdateSharedCode?: boolean
    /**
     * Will be called when sharedCodes are updated (sharedCode will not update with this function undefined)
     */
    sharedCodeUpdated?: () => void
}
type MonacoEditorProps = EditorProps & MonacoEditorCustomProps

let isHoverProviderRegistered = false
let isCompletionProviderRegistered = false

export default class InlineMonacoEditor extends Component<MonacoEditorProps, typeof DEFAULT_STATE> {
    constructor(props: MonacoEditorProps) {
        super(props)
        this.state = DEFAULT_STATE
    }

    componentDidUpdate() {
        if (this.props.shouldUpdateSharedCode && this.props.sharedCodeUpdated) {
            this.setSharedCodeToExtraLibs()
            this.props.sharedCodeUpdated()
        }
        this.setEditorHeight()
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener('resize', () => this.setEditorHeight())
        }
    }

    render() {
        const { options = {}, onMount } = this.props
        // override a word wrapping, disable and hide the scroll bars
        const optionsOverride = {
            ...options,
            autoIndent: 'full',
            formatOnPaste: true,
            formatOnType: true,
            wordWrap: 'on',
            scrollBeyondLastLine: false,
            scrollbar: {
                vertical: 'hidden',
                horizontal: 'hidden',
                alwaysConsumeMouseWheel: false,
            },
            minimap: {
                enabled: false,
            },
        } as monacoEditor.editor.IEditorConstructionOptions

        return (
            <MonacoEditor
                {...this.props}
                beforeMount={this.onBeforeMount.bind(this)}
                onMount={this.editorDidMount(onMount)}
                options={optionsOverride}
                // NOTE: It's absolutely critical to keep definition like this instead of
                // onChange={this.props.onChange}
                // There was a really weird hard to reproduce bug where most likely the old reference
                // was kept during re-render which caused an old value to override the existing values
                // This was discovered in Response for endpoint overview page where sometimes switching
                // a tab (e.g. from 200 -> 404) caused onChange event trigger which updated the value for the
                // old tab (e.g. 200) with the new tab's value (e.g. 404) thus overriding the code.
                onChange={(val, editorEvent) => this.props.onChange?.(val, editorEvent)}
            />
        )
    }

    private onBeforeMount(monaco: Monaco) {
        this.registerHoverProvider(monaco)
        this.registerCompletionItemProvider(monaco)
    }

    /**
     * Completion provider is responsible for suggestion a correct type (when a user types in the editor) and automatically imports the type if it's already not imported.
     * @param monaco
     */
    private registerCompletionItemProvider(monaco: Monaco): void {
        if (!isCompletionProviderRegistered) {
            monaco.languages.registerCompletionItemProvider('typescript', {
                provideCompletionItems: (model, position) => getCompletionItemsForEditorFromSharedCodeCache(model, position),
            })

            isCompletionProviderRegistered = true
        }
    }

    private registerHoverProvider(monaco: Monaco) {
        if (!isHoverProviderRegistered) {
            monaco.languages.registerHoverProvider('typescript', {
                provideHover: this.handleEditorHover,
            })
            isHoverProviderRegistered = true
        }
    }

    /**
     * Insert declaration of hovered type into tooltip if it is from our shared code
     * @param model
     * @param position
     * @returns
     */
    private handleEditorHover(
        model: monacoEditor.editor.ITextModel,
        position: monacoEditor.Position
    ): monacoEditor.languages.ProviderResult<monacoEditor.languages.Hover> {
        if (position && model) {
            const hoveredWord = model.getWordAtPosition(position)?.word

            let result: string | undefined
            if (hoveredWord) {
                const searchResult = searchCodeForDeclaration(model.getValue(), hoveredWord)

                if (searchResult.fullText && searchResult.type) {
                    result = searchResult.fullText
                    result = addPropertiesFromExtendedInterfaceToDeclaration(result, model.getValue())
                    result = replaceTypesByDeclarationText(result, model.getValue())
                } else if (searchResult.fileName) {
                    const typeDeclarationFromCache = getTypeDefinitionByNameAndFileNameFromCache(
                        hoveredWord,
                        searchResult.fileName
                    )
                    const sharedCode = getSharedCodeContentByFileName(searchResult.fileName)

                    if (typeDeclarationFromCache) {
                        result = typeDeclarationFromCache
                    } else {
                        if (sharedCode) {
                            result = getDeclarationsTextFromCodeByName(sharedCode.content, hoveredWord)
                        }
                    }

                    if (sharedCode && result) {
                        result = addPropertiesFromExtendedInterfaceToDeclaration(result, sharedCode.content)
                        result = replaceTypesByDeclarationText(result, sharedCode.content)
                    }
                }
            }

            if (result)
                return {
                    contents: [{ value: '**SOURCE**' }, { value: '```typescript\n' + formatTypescriptCode(result) + '\n```' }],
                }
        }

        return null
    }

    private setSharedCodeToExtraLibs(monaco?: Monaco): void {
        if (!monaco && !this.state.monaco) return
        if (!monaco) monaco = this.state.monaco

        const sharedCodes = this.props.sharedCodes
        if (sharedCodes !== undefined && sharedCodes.length > 0) {
            const extraLibs: {
                content: string
                filePath?: string
            }[] = []
            sharedCodes.forEach((sc) => {
                // It is necessary to put the shared code in declare module to correctly import the interfaces
                // If the name of the shared code (the path to the file) starts with "/", importing from that file is not behaving correctly
                const filePath = sc.name.charAt(0) === '/' ? `${sc.name.slice(1)}` : `${sc.name}`
                const content = `declare module '${filePath}' { \n\n ${sc.content} \n}`
                extraLibs.push({ content: formatTypescriptCode(content) })
            })

            let extraTypesLibContent = ''
            MONACO_EXTRA_NUMBER_TYPES.forEach((numberType) => {
                const friendlyName =
                    numberType === MONACO_NUMBER_FLOAT_TYPE
                        ? 'Floating point number'
                        : numberType === MONACO_NUMBER_INT_32_TYPE
                        ? '32 bit integer number'
                        : numberType === MONACO_NUMBER_INT_64_TYPE
                        ? '64 bit integer number'
                        : 'unknown number'
                extraTypesLibContent += `type ${numberType} = "${friendlyName}" \n`
            })
            extraLibs.push({ content: extraTypesLibContent })
            monaco.languages.typescript.typescriptDefaults.setExtraLibs(extraLibs)
        }
    }

    private editorDidMount(prevEditorDidMount: OnMount | undefined): OnMount {
        return (editor, monaco) => {
            // chain an pre-existing editorDidMount handler
            if (prevEditorDidMount) {
                prevEditorDidMount(editor, monaco)
            }

            // put the edit in the state for the handler.
            this.setState({ editor, monaco })

            // set extraLibs (shared code) for the editor
            // must be here in editorDidMount. Can't be in beforeMount event, because if there are changes in the shared code after the editor mounts, it will not update
            this.setSharedCodeToExtraLibs(monaco)

            // do the initial set of the height (wait a bit)
            setTimeout(this.setEditorHeight, 0)

            // adjust height when the window resizes
            if (window) {
                window.addEventListener('resize', this.setEditorHeight)
            }

            // on each edit recompute height (wait a bit)
            editor.onDidContentSizeChange((e) => {
                if (e.contentHeightChanged) this.setEditorHeight()
            })

            editor.onDidBlurEditorText(() => {
                if (this.props.onBlur) this.props.onBlur()
            })

            editor.addAction({
                // An unique identifier of the contributed action.
                id: 'editor.save.action',

                // A label of the action that will be presented to the user.
                label: 'Save',

                // An optional array of keybindings for the action.
                keybindings: [monaco.KeyMod.CtrlCmd | monacoEditor.KeyCode.KeyS],

                // Method that will be executed when the action is triggered.
                // @param editor The editor instance is passed in as a convenience
                run: () => {
                    if (this.props.onSaveAction) this.props.onSaveAction()
                },
            })
        }
    }

    setEditorHeight = (() => {
        const { editor } = this.state

        if (!editor) {
            return
        }
        const editorDomNode = editor.getDomNode()
        if (!editorDomNode) {
            return
        }

        // set the height and redo layout
        editorDomNode.style.height = editor.getContentHeight() + 'px'
        editor.layout()
    }).bind(this) // bind now so addEventListener/removeEventListener works.
}
