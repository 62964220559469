import * as React from 'react'

export interface Props extends React.SVGProps<SVGSVGElement> {
    color: string
}

function BellIcon(props: Props): JSX.Element {
    return (
        <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M18 8A6 6 0 106 8c0 7-3 9-3 9h18s-3-2-3-9zM13.73 21a1.999 1.999 0 01-3.46 0'
                stroke={props.color}
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default BellIcon
