import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button, Paper, useTheme } from '@mui/material'
import ModalBase from 'components/ModalBase'
import { BackgroundImage, Project } from 'shared/types/project-types'
import { ProjectsManager } from 'services/api/ProjectsManager'
import { generateNewProject, getBackgroundImage } from 'shared/utils/project-utils'
import { useDispatch } from 'react-redux'
import { showFlashMessage, showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import GradientButton from 'components/GradientButton'
import EmptyButton from 'components/EmptyButton'
import CheckIcon from '@mui/icons-material/Check'
import Text from 'components/Text'
import { ExportProjectResponse, ProjectMetadataPayload } from 'shared/payload-types/project-payloads'
import StyledTextField from 'components/StyledTextField'
import { getErrorMessage } from 'shared/utils/generic-utils'
import { ChangeEvent } from 'react'

const PREFIX = 'CreateProjectModal'

const classes = {
    container: `${PREFIX}-container`,
    title: `${PREFIX}-title`,
    backgroundCard: `${PREFIX}-backgroundCard`,
    cardsContainer: `${PREFIX}-cardsContainer`,
    text: `${PREFIX}-text`,
    buttonsContainer: `${PREFIX}-buttonsContainer`,
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.container}`]: {
        width: 548,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 16,
        boxShadow: theme.palette.shadow.main,
        border: `1px solid ${theme.palette.neutral.dark1}`,
    },

    [`& .${classes.title}`]: {
        fontWeight: 700,
        marginBottom: 20,
    },

    [`& .${classes.backgroundCard}`]: {
        height: 52,
        width: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 6,
        marginRight: 16,
        marginBottom: 16,
    },

    [`& .${classes.cardsContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: 400,
        flexWrap: 'wrap',
    },

    [`& .${classes.text}`]: {
        color: theme.palette.primary.light,
        cursor: 'pointer',
        marginTop: 20,
    },

    [`& .${classes.buttonsContainer}`]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 20,
    },
}))

interface CreateProjectModalProps {
    isOpen: boolean
    onClose: () => void
    onProjectCreateHandler: (project: Project) => void
    project?: ProjectMetadataPayload
    handleProjectNameChange?: (newName: string, background: BackgroundImage) => void
}

const backgroundsList: BackgroundImage[] = ['orange', 'red', 'yellow', 'green', 'black', 'blue', 'turquoise', 'purple']

const CreateProjectModal = ({
    isOpen,
    onClose,
    onProjectCreateHandler,
    project,
    handleProjectNameChange,
}: CreateProjectModalProps): JSX.Element => {
    const theme = useTheme()
    const dispatch = useDispatch()

    const [name, setName] = useState<string | undefined>(project?.name ? project?.name : undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isImportProjectModalOpen, setIsImportProjectModalOpen] = useState<boolean>(false)
    const [importedProjectName, setImportedProjectName] = useState<string | undefined>(undefined)
    const [selectedFile, setSelectedFile] = useState<File | undefined>()
    const [selectedBackgroundImage, setSelectedBackgroundImage] = useState<BackgroundImage>(
        project?.backgroundImage ? project.backgroundImage : 'black'
    )

    useEffect(() => {
        if (project) {
            if (project.name) setName(project.name)
            if (project.backgroundImage) setSelectedBackgroundImage(project.backgroundImage)
        } else {
            setName('')
            setSelectedBackgroundImage('black')
        }
    }, [project])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    const onProjectCreate = async () => {
        if (name) {
            const newProject = generateNewProject(name, selectedBackgroundImage)
            try {
                setIsLoading(true)
                const _projectManager = ProjectsManager.getManager()
                const result = await _projectManager.createProjectAsync(newProject)
                onProjectCreateHandler(result.project)
                handleProjectCreated()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e) {
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
                setIsLoading(false)
            }
        } else {
            showFlashMessageWithTimeout(dispatch, 'Name can not be empty', 'error')
        }
    }

    const isConfirmButtonDisabled = (): boolean => {
        if (project?.id) {
            return name === project.name && selectedBackgroundImage === project.backgroundImage
        } else {
            return name === undefined || name.length === 0
        }
    }

    const onFileChanged = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return
        }

        const fileContent = await event.target.files[0].text()
        const importedProject = JSON.parse(fileContent) as ExportProjectResponse

        if (importedProject.project.name) setImportedProjectName(importedProject.project.name)
        else setImportedProjectName(undefined)

        setSelectedFile(event.target.files[0])
    }

    const onImportProject = async () => {
        if (!selectedFile) return

        try {
            const _projectManager = ProjectsManager.getManager()
            const result = await _projectManager.importProjectAsync(selectedFile)

            onProjectCreateHandler(result.project)
            handleProjectCreated()
            showFlashMessageWithTimeout(dispatch, 'Project was successfully imported', 'success')
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }

    const handleProjectCreated = () => {
        setSelectedFile(undefined)
        setIsImportProjectModalOpen(false)
        setIsLoading(false)
        setName('')
        setSelectedBackgroundImage('black')
        onClose()
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} center>
            <StyledPaper className={classes.container}>
                <Box style={{ margin: 24 }}>
                    <Text color='light' fontSize={20} className={classes.title}>
                        {project?.id ? `Rename ${project.name}` : 'New project'}
                    </Text>
                    <StyledTextField placeholder={'Project name'} onChange={handleChange} value={name} autoFocus />
                    <Box style={{ display: 'flex', flexDirection: 'row', marginTop: 32, marginBottom: 10 }}>
                        <Text color={theme.palette.neutral.light2} fontSize={16}>
                            Choose background
                        </Text>
                        <Text color={theme.palette.neutral.light3} fontSize={16}>
                            &nbsp;(for card view):
                        </Text>
                    </Box>
                    <Box className={classes.cardsContainer}>
                        {backgroundsList.map((bg, index) => (
                            <Box
                                key={index}
                                onClick={() => setSelectedBackgroundImage(bg)}
                                className={classes.backgroundCard}
                                style={{
                                    backgroundImage: getBackgroundImage[bg],
                                    border:
                                        bg === selectedBackgroundImage
                                            ? `3px solid ${theme.palette.neutral.light1}`
                                            : `3px solid ${theme.palette.background.default}`,
                                }}
                            >
                                {bg === selectedBackgroundImage && (
                                    <CheckIcon style={{ fontSize: 24, color: theme.palette.background.paper }} />
                                )}
                            </Box>
                        ))}
                    </Box>
                    {!project?.id && (
                        <Box style={{ cursor: 'pointer' }} onClick={() => setIsImportProjectModalOpen(true)}>
                            <Text color={theme.palette.neutral.unique1} fontSize={14}>
                                Import project
                            </Text>
                        </Box>
                    )}
                    <Box className={classes.buttonsContainer}>
                        <GradientButton
                            disabled={isConfirmButtonDisabled()}
                            title={project?.id ? 'Confirm changes' : 'Create'}
                            isLoading={isLoading}
                            onClick={() => {
                                if (project && name && handleProjectNameChange) {
                                    handleProjectNameChange(name, selectedBackgroundImage)
                                    onClose()
                                } else {
                                    onProjectCreate()
                                }
                            }}
                            width={project?.id ? 158 : 82}
                        />
                        <EmptyButton style={{ marginRight: 20, width: 82 }} text='Cancel' onClick={() => onClose()} />
                    </Box>
                </Box>
            </StyledPaper>
            <ModalBase
                isOpen={isImportProjectModalOpen}
                onClose={() => {
                    setSelectedFile(undefined)
                    setIsImportProjectModalOpen(false)
                }}
            >
                <StyledPaper className={classes.container}>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), padding: theme.spacing(4) }}>
                        <label htmlFor='upload-project'>
                            <input
                                accept='json/*'
                                onChange={(e) => onFileChanged(e)}
                                style={{ display: 'none' }}
                                id='upload-project'
                                name='upload-project'
                                type='file'
                            />
                            <Button color='primary' variant='contained' component='span'>
                                Select file
                            </Button>
                        </label>
                        {selectedFile ? (
                            <Box style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
                                <Text color='normal' fontSize={16}>
                                    File name: {selectedFile.name}
                                </Text>
                                <Text color='normal' fontSize={16}>
                                    Project name: {importedProjectName}
                                </Text>
                                <Text color='normal' fontSize={16}>
                                    File type: {selectedFile.type}
                                </Text>
                                <Text color='normal' fontSize={16}>
                                    Size: {selectedFile.size} B
                                </Text>
                            </Box>
                        ) : (
                            <Text color='normal' fontSize={16}>
                                Select a file to show details
                            </Text>
                        )}
                        <Box className={classes.buttonsContainer}>
                            <GradientButton
                                disabled={!selectedFile}
                                title='Import project'
                                isLoading={isLoading}
                                onClick={onImportProject}
                            />
                        </Box>
                    </Box>
                </StyledPaper>
            </ModalBase>
        </ModalBase>
    )
}

export default CreateProjectModal
