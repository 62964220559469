import { Box, Stack, useTheme } from '@mui/material'
import GradientButton from 'components/GradientButton'
import Text from 'components/Text'
import ForgotPassword from '../../assets/images/auth/forgotPassword/ForgotPassword.png'
import ForgotPassword2x from '../../assets/images/auth/forgotPassword/ForgotPassword2x.png'
import ForgotPassword3x from '../../assets/images/auth/forgotPassword/ForgotPassword3x.png'
import useIsMobileView from 'hooks/useIsMobileView'
import { useState } from 'react'
import { EMAIL_REGEX } from 'constants/ui'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import StyledTextField from 'components/StyledTextField'

interface ForgotPasswordFormProps {
    toggleToLoginMode: () => void
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ toggleToLoginMode }) => {
    const theme = useTheme()
    const isMobileView = useIsMobileView(theme)

    const [email, setEmail] = useState('')
    const [isEmailInErrorState, setIsEmailInErrorState] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [isEmailSent, setIsEmailSent] = useState(false)

    const handleEmailChange = (email: string) => {
        setEmail(email)
    }

    const onSendEmail = () => {
        let isFormInErrorState = false
        if (email.length === 0) {
            setIsEmailInErrorState(true)
            setEmailErrorMessage('Fill the email field')
            isFormInErrorState = true
        }

        if (email.length !== 0 && !EMAIL_REGEX.test(email)) {
            setIsEmailInErrorState(true)
            setEmailErrorMessage("Email doesn't have a correct format")
            isFormInErrorState = true
        }

        if (isFormInErrorState) return

        // TODO handle registration
        console.log('forgot password')

        // TODO set based on error from server
        setIsEmailInErrorState(true)
        setEmailErrorMessage('This email doesn’t exist.')

        // TODO only when success from server
        setIsEmailSent(true)
    }

    return (
        <Stack direction={isMobileView ? 'column-reverse' : 'row'} spacing={3} alignItems={isMobileView ? 'center' : 'stretch'}>
            {!isEmailSent && (
                <>
                    <Box style={{ maxHeight: 360, maxWidth: 250 }}>
                        <img
                            src={ForgotPassword}
                            srcSet={`${ForgotPassword2x} 2x, ${ForgotPassword3x} 3x`}
                            style={{ maxWidth: '100%', width: 'auto', height: 'auto' }}
                            alt='forgot password image'
                        />
                    </Box>
                    <form>
                        <Stack
                            direction='column'
                            flex={1}
                            spacing={3}
                            width={isMobileView ? undefined : 316}
                            justifyContent='flex-start'
                        >
                            <Stack direction='row' alignItems={'center'} spacing={1.5}>
                                <ArrowBackIosNewIcon
                                    style={{ color: theme.palette.text.faded, cursor: 'pointer', fontSize: 16 }}
                                    onClick={toggleToLoginMode}
                                />
                                <Text fontSize={20} fontWeight={700}>
                                    Reset password
                                </Text>
                            </Stack>
                            <StyledTextField
                                value={email}
                                label='Email address'
                                onChange={(e) => handleEmailChange(e.target.value)}
                                type='email'
                                size='small'
                                showTriangleError
                                error={isEmailInErrorState}
                                errorText={emailErrorMessage}
                                autoFocus
                            />
                            <Text color='light' fontSize={14} style={{ maxWidth: 284 }}>
                                We will send you an email to reset your password.
                            </Text>

                            <Stack direction='row' justifyContent='stretch'>
                                <GradientButton
                                    disabled={email.length === 0}
                                    title='Reset password'
                                    onClick={onSendEmail}
                                    style={{ flex: 1 }}
                                />
                                <Box style={{ width: 32 }}></Box>
                            </Stack>
                        </Stack>
                    </form>
                </>
            )}

            {isEmailSent && (
                <Stack direction='column' flex={1} spacing={3} justifyContent='flex-start' alignItems='center'>
                    <Text fontSize={20} fontWeight={700}>
                        Check your email
                    </Text>

                    <Text fontSize={16}>Link to reset the password was sent to {email}.</Text>

                    <GradientButton title='Back to login' onClick={toggleToLoginMode} width={148} />
                </Stack>
            )}
        </Stack>
    )
}

export default ForgotPasswordForm
