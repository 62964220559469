import * as React from 'react'

export interface Props extends React.SVGProps<SVGSVGElement> {
    color: string
}

function ErrorIcon(props: Props): JSX.Element {
    return (
        <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0v0zM12 9v4M12 16v.5'
                stroke={props.color}
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default ErrorIcon
