import { Box, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded'
import ModeNightOutlinedIcon from '@mui/icons-material/ModeNightOutlined'
import Brightness6OutlinedIcon from '@mui/icons-material/Brightness6Outlined'
import Text from '../components/Text'

const PREFIX = 'ThemeSwitcher'

const classes = {
    container: `${PREFIX}-container`,
    themeIconContainer: `${PREFIX}-themeIconContainer`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        height: 80,
        width: 320,
        backgroundColor: theme.palette.additional.add1,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    },

    [`& .${classes.themeIconContainer}`]: {
        marginLeft: 16,
        marginRight: 8,
        height: 24,
        width: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

interface ThemeSwitcherProps {
    selectedThemeStyle: 'light' | 'dark' | 'auto'
    toggleTheme: () => void
}

const ThemeSwitcher: React.FC<ThemeSwitcherProps> = ({ selectedThemeStyle, toggleTheme }) => {
    const theme = useTheme()

    let text = ''

    if (selectedThemeStyle === 'light') {
        text = 'Switch to Dark mode'
    } else if (selectedThemeStyle === 'dark') {
        text = 'Switch to Auto mode'
    } else if (selectedThemeStyle === 'auto') {
        text = 'Switch to Light mode'
    }

    const getThemeIcon = (style: 'light' | 'dark' | 'auto') => {
        switch (style) {
            case 'auto':
                return <WbSunnyRoundedIcon style={{ color: theme.palette.yellow }} />
            case 'dark':
                return <Brightness6OutlinedIcon style={{ color: theme.palette.blue.main }} />
            case 'light':
                return <ModeNightOutlinedIcon style={{ color: theme.palette.blue.main }} />
        }
    }

    return (
        <StyledBox className={classes.container} onClick={toggleTheme}>
            <Box style={{ height: 14 }} />
            <Box style={{ height: 48, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box className={classes.themeIconContainer}>{getThemeIcon(selectedThemeStyle)}</Box>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text fontSize={12} style={{ userSelect: 'none' }}>
                        {selectedThemeStyle === 'auto' ? 'Auto' : 'Manual'}
                    </Text>
                    <Text fontSize={16} style={{ userSelect: 'none' }}>
                        {text}
                    </Text>
                </Box>
            </Box>
        </StyledBox>
    )
}

export default ThemeSwitcher
