import { Box, useTheme, TextField, CircularProgress } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import GradientButton from '../GradientButton'
import EmailAvatar from 'assets/images/EmailAvatar'
import Fonts from 'constants/fonts'
import PermissionSelector from './PermissionSelector'
import {
    deleteUserFromProject,
    inviteUserToProject,
    setActiveProject,
    setLoggedUsersProjectRole,
} from 'services/actions/project-actions'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveProject } from 'services/selectors/projects-selectors'
import { ShareRoles } from 'shared/types/project-types'
import { ProjectsManager } from 'services/api/ProjectsManager'
import { showFlashMessage, showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import { UserSharing } from 'shared/types/user-types'
import { useHistory } from 'react-router-dom'
import { RouteConstants } from 'navigation/navigation-types'
import Text from '../Text'
import { getErrorMessage } from 'shared/utils/generic-utils'
import { getAuthState } from 'services/selectors/auth-selectors'
import { setShareModalOpen } from 'services/actions/temporaryUiChanges-actions'
import { styled } from '@mui/material/styles'

const PREFIX = 'EmailSharing'

const classes = {
    emailTextContainer: `${PREFIX}-emailTextContainer`,
    emailListContainer: `${PREFIX}-emailListContainer`,
    emailContainer: `${PREFIX}-emailContainer`,
    inputContainer: `${PREFIX}-inputContainer`,
    emailTabContainer: `${PREFIX}-emailTabContainer`,
    emailTextField: `${PREFIX}-emailTextField`,
    emailInput: `${PREFIX}-emailInput`,
    textFieldInput: `${PREFIX}-textFieldInput`,
    input: `${PREFIX}-input`,
    inputDisabled: `${PREFIX}-inputDisabled`,
    loadingContainer: `${PREFIX}-loadingContainer`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.emailTextContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
    },
    [`& .${classes.emailListContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        width: '100%',
        alignItems: 'center',
        maxHeight: 370,
        overflow: 'auto',
        scrollbarWidth: 'thin',
    },
    [`& .${classes.emailContainer}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: theme.spacing(3),
    },
    [`& .${classes.inputContainer}`]: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
    },
    [`&.${classes.emailTabContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        width: '100%',
    },
    [`& .${classes.emailTextField}`]: {
        flexGrow: 1,
        borderRadius: 5,
        height: 40,
        '& .MuiOutlinedInput-root': {
            // - The Input-root, inside the TextField-root
            '& fieldset': {
                // - The <fieldset> inside the Input-root
                border: `2px solid ${theme.palette.neutral.neutral2}`, // - Set the Input border
            },
            '&:hover fieldset': {
                border: `2px solid ${theme.palette.primary.light}`, // - Set the Input border when parent has :hover
            },
            '&.Mui-focused fieldset': {
                // - Set the Input border when parent is focused
                border: `2px solid ${theme.palette.primary.light}`,
                backgroundColor: theme.palette.input.hoverBackground,
            },
            '&.Mui-disabled fieldset': {
                // - Set the Input border when parent is disabled
                border: `2px solid ${theme.palette.neutral.neutral2}`,
            },
        },
    },
    [`& .${classes.emailInput}`]: {
        border: 'none',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        minWidth: 250,
        color: theme.palette.text.normal,
        fontSize: Fonts.size.normal,
        height: 40,
        // Used to keep input text upper than input background, without zIndex text is covered by input background.
        zIndex: 2,
        caretColor: theme.palette.primary.light,
    },
    [`& .${classes.textFieldInput}`]: {
        '&:hover': {
            backgroundColor: theme.palette.input.hoverBackground,
        },
    },
    [`& .${classes.input}`]: {
        '&::placeholder': {
            color: theme.palette.neutral.light3,
            opacity: 1,
            fontSize: Fonts.size.normal,
        },
    },
    [`& .${classes.inputDisabled}`]: {
        '&::placeholder': {
            '-webkit-text-fill-color': theme.palette.neutral.neutral2,
            opacity: 1,
            fontSize: Fonts.size.normal,
        },
    },
    [`& .${classes.loadingContainer}`]: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
}))

const EmailSharing: FC = () => {
    const theme = useTheme()
    const [email, setEmail] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [permission, setPermission] = useState<ShareRoles>('view')
    const [users, setUsers] = useState<UserSharing[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const activeProject = useSelector(getActiveProject)
    const history = useHistory()
    const { userEmail: loggedUserEmail } = useSelector(getAuthState)

    useEffect(() => {
        const fetchProjectsSharedUsersById = async (id: string) => {
            try {
                setIsLoading(true)
                const _projectManager = ProjectsManager.getManager()
                setUsers(await _projectManager.getProjectsSharedUsersAsync(id))
                setIsLoading(false)
            } catch (e) {
                setIsLoading(false)
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }

        if (activeProject) {
            fetchProjectsSharedUsersById(activeProject.id)
        }
    }, [activeProject])

    const getLoggedUserProjectRole = (): ShareRoles | undefined => {
        if (activeProject) {
            return activeProject.loggedUserShareRole
        }
    }

    const areTwoOrMoreOwnersInProject = (): boolean => {
        let ownerFound = false
        for (const user of users) {
            if (user.shareRole === 'owner') {
                if (ownerFound) {
                    return true
                }

                ownerFound = true
            }
        }

        return false
    }

    const canLoggedUserLeaveProject = (): boolean => {
        if (getLoggedUserProjectRole() !== 'owner') {
            return true
        }

        if (!areTwoOrMoreOwnersInProject()) {
            return false
        }

        return true
    }

    const handleInviteUser = () => {
        if (activeProject) {
            const usersCopy: UserSharing[] = { ...users }
            const previousEmail = email
            try {
                setUsers([...users, { email: email, shareRole: permission }])
                setEmail('')
                dispatch(inviteUserToProject(activeProject.id, permission, email))
                showFlashMessageWithTimeout(dispatch, 'Invitation has been sent.', 'success')
            } catch (e) {
                setUsers(usersCopy)
                setEmail(previousEmail)
                showFlashMessageWithTimeout(dispatch, getErrorMessage(e), 'error')
            }
        }
    }

    const handleUserPermissionChange = (userEmail: string, role: ShareRoles) => {
        if (activeProject) {
            const previousRole = changeUsersRole(userEmail, role)

            try {
                if (userEmail === loggedUserEmail) {
                    dispatch(setLoggedUsersProjectRole(role))
                }

                dispatch(inviteUserToProject(activeProject.id, role, userEmail))
                showFlashMessageWithTimeout(dispatch, 'Permission has been changed.', 'success')
            } catch (e) {
                changeUsersRole(userEmail, previousRole)
                showFlashMessageWithTimeout(dispatch, getErrorMessage(e), 'error')
            }
        }
    }

    const handleResendInvite = (userEmail: string, role: ShareRoles) => {
        if (activeProject) {
            try {
                dispatch(inviteUserToProject(activeProject.id, role, userEmail))
                showFlashMessageWithTimeout(dispatch, 'Invitation has been resent.', 'success')
            } catch (e) {
                showFlashMessageWithTimeout(dispatch, getErrorMessage(e), 'error')
            }
        }
    }

    const handleDelete = (userEmail: string) => {
        if (activeProject) {
            const usersCopy: UserSharing[] = { ...users }
            try {
                setUsers(users.filter((user) => user.email !== userEmail))
                dispatch(deleteUserFromProject(activeProject.id, userEmail))
                showFlashMessageWithTimeout(dispatch, 'User has been removed from the project.', 'success')
            } catch (e) {
                setUsers(usersCopy)
                showFlashMessageWithTimeout(dispatch, getErrorMessage(e), 'error')
            }
        }
    }

    const handleLeave = (userEmail: string) => {
        if (activeProject) {
            try {
                dispatch(deleteUserFromProject(activeProject.id, userEmail))
                dispatch(setShareModalOpen(false))
                dispatch(setActiveProject(undefined))
                setTimeout(() => {
                    history.push(RouteConstants.projectsList)
                })
                showFlashMessageWithTimeout(dispatch, 'You left the project.', 'error')
            } catch (e) {
                showFlashMessageWithTimeout(dispatch, getErrorMessage(e), 'error')
            }
        }
    }

    const changeUsersRole = (email: string, role: ShareRoles | null): ShareRoles | null => {
        if (!role) {
            return null
        }
        const copy: UserSharing[] = [...users]
        let previousRole = null

        copy.forEach((u) => {
            if (u.email === email) {
                previousRole = u.shareRole
                u.shareRole = role
            }
        })

        setUsers(copy)

        return previousRole
    }

    return (
        <StyledBox className={classes.emailTabContainer}>
            <Box className={classes.inputContainer}>
                <TextField
                    value={email}
                    className={classes.emailTextField}
                    sx={{
                        marginRight: theme.spacing(1),
                        padding: 0,
                        backgroundColor: isLoading
                            ? theme.palette.forms.backgroundDisabled
                            : `${theme.palette.background.paper}66`,
                    }}
                    inputProps={{
                        className: classes.emailInput,
                        style: { padding: '0px 0px', paddingLeft: 16 },
                    }}
                    InputProps={{
                        className: isLoading ? undefined : classes.textFieldInput,
                        classes: {
                            input: classes.input,
                            disabled: classes.inputDisabled,
                        },
                    }}
                    type='email'
                    disabled={getLoggedUserProjectRole() === 'view' || isLoading}
                    onChange={(e) => {
                        const newValue = e.target.value,
                            pattern = new RegExp(
                                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                            )

                        if (pattern.test(newValue)) {
                            setIsEmailValid(true)
                        } else {
                            setIsEmailValid(false)
                        }

                        setEmail(newValue)
                    }}
                    placeholder='User email'
                />
                <Box
                    sx={{
                        position: 'absolute',
                        right: theme.spacing(9),
                        zIndex: 3,
                    }}
                >
                    <PermissionSelector
                        size='small'
                        permission={permission}
                        onPermissionChosen={(permission) => {
                            setPermission(permission)
                        }}
                        disabled={getLoggedUserProjectRole() === 'view' || isLoading}
                        isViewPermissionVisible={getLoggedUserProjectRole() === 'owner' || getLoggedUserProjectRole() === 'edit'}
                        isEditPermissionVisible={getLoggedUserProjectRole() === 'owner' || getLoggedUserProjectRole() === 'edit'}
                        isOwnerPermissionVisible={getLoggedUserProjectRole() === 'owner'}
                    />
                </Box>
                <GradientButton
                    size='small'
                    title='Invite'
                    disabled={!isEmailValid || getLoggedUserProjectRole() === 'view'}
                    onClick={() => {
                        handleInviteUser()
                    }}
                />
            </Box>
            <Text color='faded' fontSize={14} style={{ marginTop: theme.spacing(2) }}>
                People in this project:
            </Text>
            {isLoading && (
                <Box className={classes.loadingContainer}>
                    <CircularProgress size={32} />
                </Box>
            )}
            {!isLoading && (
                <Box
                    className={classes.emailListContainer}
                    style={{ paddingRight: users && users.length > 5 ? theme.spacing(2) : undefined }}
                >
                    {users.map((u) => (
                        <Box className={classes.emailContainer} key={u.email}>
                            <Box display='flex' alignItems='center'>
                                <EmailAvatar />
                                <Box mr={2} />
                                <Box className={classes.emailTextContainer}>
                                    {/* <Text color='normal' fontSize={16}>{u.name}</Text> TODO: name is not available in db now */}
                                    <Text color='normal' fontSize={16}>
                                        {' '}
                                        {/** TODO: should be color: 'faded' when we have name */}
                                        {u.email}
                                    </Text>
                                </Box>
                            </Box>
                            <PermissionSelector
                                size='large'
                                permission={u.shareRole}
                                disabled={
                                    (u.shareRole === 'owner' && getLoggedUserProjectRole() !== 'owner') ||
                                    (u.shareRole === 'owner' && !areTwoOrMoreOwnersInProject()) ||
                                    (getLoggedUserProjectRole() === 'view' && loggedUserEmail !== u.email)
                                }
                                onPermissionChosen={(permission) => {
                                    handleUserPermissionChange(u.email, permission)
                                }}
                                resendInviteProps={{
                                    isActionVisible: u.email !== loggedUserEmail,
                                    onClickEvent: () => {
                                        handleResendInvite(u.email, u.shareRole)
                                    },
                                }}
                                deleteProps={{
                                    isActionVisible:
                                        (getLoggedUserProjectRole() === 'owner' || getLoggedUserProjectRole() === 'edit') &&
                                        u.email !== loggedUserEmail,
                                    onClickEvent: () => {
                                        handleDelete(u.email)
                                    },
                                }}
                                leaveProps={{
                                    isActionVisible:
                                        u.email === loggedUserEmail && (u.shareRole !== 'owner' || canLoggedUserLeaveProject()),
                                    onClickEvent: () => {
                                        handleLeave(u.email)
                                    },
                                }}
                                checkSelection={true}
                                isViewPermissionVisible={
                                    getLoggedUserProjectRole() === 'owner' || getLoggedUserProjectRole() === 'edit'
                                }
                                isEditPermissionVisible={
                                    getLoggedUserProjectRole() === 'owner' || getLoggedUserProjectRole() === 'edit'
                                }
                                isOwnerPermissionVisible={getLoggedUserProjectRole() === 'owner'}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </StyledBox>
    )
}

export default EmailSharing
