import { AuthToken } from 'shared/types/auth-types'
import jwt_decode from 'jwt-decode'

export interface AuthReduxState {
    isAuthenticated: boolean
    userEmail: string
    token: string
    parsedToken: AuthToken
}

export enum AuthActionTypes {
    SET_AUTH_STATE = 'SET_AUTH_STATE',
}

export interface SetAuthState {
    type: typeof AuthActionTypes.SET_AUTH_STATE
    payload: AuthReduxState
}

export type AuthActions = SetAuthState

export const AUTH_LOCAL_STORAGE_KEY = 'auth-token'

export const getEmptyAuthState = (): AuthReduxState => {
    return {
        isAuthenticated: false,
        token: '',
        userEmail: '',
        parsedToken: {
            userId: '',
            createdTimestamp: 0,
            expiration: 0,
            integrityHash: '',
            refreshIntervalInSeconds: 0,
            refreshTimestamp: 0,
            userEmail: '',
        },
    }
}

export const parseJwtEncodedTokenAndGetReduxState = (encodedToken: string): AuthReduxState => {
    const decoded = jwt_decode(encodedToken) as AuthToken
    if (decoded.expiration < Math.floor(new Date().getTime() / 1000.0)) {
        // expired token
        console.debug('Found expired token, returning empty auth state...')
        return {
            isAuthenticated: false,
            parsedToken: {
                userId: '',
                createdTimestamp: 0,
                expiration: 0,
                userEmail: '',
                integrityHash: '',
                refreshIntervalInSeconds: 0,
                refreshTimestamp: 0,
            },
            token: '',
            userEmail: '',
        }
    } else
        return {
            isAuthenticated: true,
            parsedToken: decoded,
            token: encodedToken,
            userEmail: decoded.userEmail,
        }
}
