import { Box, useTheme } from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { FlashMessageProps } from 'services/types/flashMessages-types'
import { removeFlashMessage } from 'services/actions/flashMessage-actions'
import BellIcon from 'images/bell-icon'
import ErrorIcon from 'images/error-icon'
import SuccessIcon from 'images/success-icon'

const truncate = (str: string) => (str.length > 85 ? `${str.substring(0, 85)}...` : str)

const FlashMessage = ({ id, message, type }: FlashMessageProps) => {
    const [shouldMessageBeTruncated, setShouldMessageBeTruncated] = useState<boolean>(message.length > 5)
    const dispatch = useDispatch()
    const truncatedMessage = truncate(message)
    const theme = useTheme()

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: 48,
                flex: 1,
                backgroundColor:
                    type === 'success'
                        ? theme.palette.success.light
                        : type === 'info'
                        ? theme.palette.info.light
                        : theme.palette.flashMessages.errorBackground,
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: 1400,
                borderRadius: 8,
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 24,
                    marginRight: 10,
                    maxWidth: '85%',
                    overflow: 'hidden',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexShrink: 0,
                    }}
                >
                    {type === 'info' ? (
                        <BellIcon color={theme.palette.info.main} />
                    ) : type === 'success' ? (
                        <SuccessIcon color={theme.palette.success.main} />
                    ) : (
                        <ErrorIcon color={theme.palette.alert.dark} />
                    )}
                </Box>
                <p
                    onClick={() => setShouldMessageBeTruncated(false)}
                    style={{
                        color:
                            type === 'info'
                                ? theme.palette.info.main
                                : type === 'success'
                                ? theme.palette.success.main
                                : theme.palette.alert.dark,
                        overflowWrap: 'break-word',
                        marginLeft: 18,
                        cursor: shouldMessageBeTruncated ? 'pointer' : 'default',
                    }}
                >
                    {shouldMessageBeTruncated ? truncatedMessage : message}
                </p>
            </Box>

            <Box
                onClick={() => dispatch(removeFlashMessage(id))}
                style={{
                    display: 'flex',
                    cursor: 'pointer',
                    marginRight: 24,
                }}
            >
                <CloseIcon
                    style={{
                        color:
                            type === 'info'
                                ? theme.palette.info.main
                                : type === 'success'
                                ? theme.palette.success.main
                                : theme.palette.alert.dark,
                    }}
                />
            </Box>
        </Box>
    )
}

export default FlashMessage
