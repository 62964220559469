import { ResponseDefinition } from 'shared/types/project-types'
import { styled } from '@mui/material/styles'
import { Box, Dialog, useTheme } from '@mui/material'
import Text from 'components/Text'
import ResponseInfoDropdown from './ResponseInfoDropdown'
import DropdownMenu from 'components/DropdownMenu'
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import { moveResponse } from 'services/actions/project-actions'
import { useState } from 'react'
import useIsMobileView from 'hooks/useIsMobileView'

const PREFIX = 'ResponseTab'

const classes = {
    responseTab: `${PREFIX}-responseTab`,
}

const StyledBox = styled(Box)(() => ({
    [`& .${classes.responseTab}`]: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        height: 32,
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
}))

interface ResponseTabProps {
    r: ResponseDefinition
    selectedResponseCode: number | null
    setSelectedResponseCode: (value: number) => void
    isReadOnly: boolean
    endpointId: string
    isFirst: boolean
    onAfterDelete: () => void
}

const ResponseTab: React.FC<ResponseTabProps> = ({
    r,
    selectedResponseCode,
    setSelectedResponseCode,
    isReadOnly,
    endpointId,
    isFirst,
    onAfterDelete,
}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobileView = useIsMobileView(theme)
    const [responseEditMenuOpen, setResponseEditMenuOpen] = useState(false)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

    const [, drag] = useDrag(() => ({
        type: 'responseTab',
        item: { httpCode: r.httpCode },
    }))

    const [, drop] = useDrop(() => ({
        accept: 'responseTab',
        drop: (item: { httpCode: number }) => {
            onDrop(item.httpCode, r.httpCode)
        },
    }))

    const onDrop = (movedResponseHttpCode: number, dropResponseHttpCode: number) => {
        if (movedResponseHttpCode !== dropResponseHttpCode)
            dispatch(moveResponse(movedResponseHttpCode, dropResponseHttpCode, endpointId))
    }

    return (
        <StyledBox ref={drag}>
            <Box
                ref={drop}
                className={classes.responseTab}
                style={{
                    backgroundColor:
                        selectedResponseCode === r.httpCode
                            ? theme.palette.monacoEditor.activeTabBackground
                            : theme.palette.monacoEditor.tabBackground,
                    borderTopLeftRadius: isFirst ? 3 : undefined,
                }}
                onClick={() => {
                    if (r.httpCode === selectedResponseCode && !isReadOnly) {
                        if (isMobileView) setIsEditDialogOpen(true)
                        else setResponseEditMenuOpen(true)
                    } else {
                        setSelectedResponseCode(r.httpCode)
                    }
                }}
            >
                <Box
                    style={{
                        borderRadius: 8,
                        width: 8,
                        height: 8,
                        marginRight: theme.spacing(2),
                        backgroundColor:
                            r.dotColor === 'green'
                                ? theme.palette.accent.main
                                : r.dotColor === 'yellow'
                                ? theme.palette.yellow
                                : r.dotColor === 'orange'
                                ? theme.palette.secondary.main
                                : r.dotColor === 'red'
                                ? theme.palette.alert.main
                                : theme.palette.neutral.dark3,
                    }}
                />

                <DropdownMenu
                    open={responseEditMenuOpen}
                    onClose={() => setResponseEditMenuOpen(false)}
                    content={
                        <ResponseInfoDropdown
                            mode='edit'
                            originalResponseCode={r.httpCode}
                            originalDotColor={r.dotColor}
                            endpointId={endpointId}
                            onClose={() => {
                                setResponseEditMenuOpen(false)
                            }}
                            setSelectedResponseCode={setSelectedResponseCode}
                            isMobileView={isMobileView}
                            onAfterDelete={onAfterDelete}
                        />
                    }
                >
                    <Box>
                        <Text
                            color={selectedResponseCode === r.httpCode ? theme.palette.text.normal : theme.palette.text.light}
                            fontSize={16}
                        >
                            {r.httpCode}
                        </Text>
                    </Box>
                </DropdownMenu>
            </Box>
            {isEditDialogOpen && (
                <Dialog onClose={() => setIsEditDialogOpen(false)} open={isEditDialogOpen} style={{ overflow: 'hidden' }}>
                    <ResponseInfoDropdown
                        mode='edit'
                        originalResponseCode={r.httpCode}
                        originalDotColor={r.dotColor}
                        endpointId={endpointId}
                        onClose={() => setIsEditDialogOpen(false)}
                        setSelectedResponseCode={setSelectedResponseCode}
                        isMobileView={isMobileView}
                        onAfterDelete={onAfterDelete}
                    />
                </Dialog>
            )}
        </StyledBox>
    )
}

export default ResponseTab
