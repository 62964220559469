import { useEffect } from 'react'
import { darkTheme, lightTheme } from 'misc/theme'
import { useSelector } from 'react-redux'
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { useMonaco } from '@monaco-editor/react'
import { MONACO_TOKENS_PROVIDER } from 'constants/monaco'
import AppContainer from 'AppContainer'
import { getSelectedThemeType } from 'services/selectors/userSettings-selectors'

/**
 * Workaround for monaco -> see more further below
 */
let isTokenProviderSetToMonaco = false

const App: React.FC = () => {
    const selectedThemeType = useSelector(getSelectedThemeType)
    const prefersDarkTheme = useMediaQuery('(prefers-color-scheme: dark)')
    const shouldSetDarkTheme =
        selectedThemeType === 'auto' && prefersDarkTheme ? true : selectedThemeType === 'dark' ? true : false
    const theme = shouldSetDarkTheme ? darkTheme : lightTheme
    const monaco = useMonaco()

    // When it was in useEffect, it didn't set the provider.
    if (!isTokenProviderSetToMonaco && monaco) {
        monaco.languages.setMonarchTokensProvider('typescript', MONACO_TOKENS_PROVIDER)
        isTokenProviderSetToMonaco = true
    }

    useEffect(() => {
        if (monaco) {
            // Set theme to monaco editor
            monaco.editor.defineTheme('customTheme', {
                base: shouldSetDarkTheme ? 'vs-dark' : 'vs',
                inherit: true,
                rules: [],
                // All color options: https://microsoft.github.io/monaco-editor/playground.html#customizing-the-appearence-exposed-colors
                colors: {
                    'editor.background': theme.palette.monacoEditor.backgroundColor,
                    'editorLineNumber.foreground': theme.palette.monacoEditor.lineNumberForeground,
                    'editorLineNumber.activeForeground': theme.palette.monacoEditor.lineNumberActiveForeground,
                    'editor.lineHighlightBorder': '#00000000',
                    'editorCursor.foreground': theme.palette.primary.light,
                },
            })

            monaco.editor.setTheme('customTheme')
        }
    }, [monaco, theme])

    return (
        <>
            <CssBaseline />
            {/* Changes CSS injection order. Docs: https://mui.com/guides/interoperability/#css-injection-order */}
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <AppContainer />
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    )
}

export default App
