import { Stack } from '@mui/material'
import React, { useState } from 'react'
import ForgotPasswordForm from './ForgotPasswordFrom'
import LoginForm from './LoginForm'
import RegistrationForm from './RegistrationForm'

const Login: React.FC = () => {
    const [mode, setMode] = useState<'login' | 'registration' | 'forgotPassword'>('login')

    return (
        <Stack direction='column' alignItems='center' justifyContent='center' flex={0.6} padding={2}>
            {mode === 'login' && (
                <LoginForm
                    toggleToRegistrationMode={() => setMode('registration')}
                    toggleToForgotPasswordMode={() => setMode('forgotPassword')}
                />
            )}
            {mode === 'registration' && <RegistrationForm toggleToLoginMode={() => setMode('login')} />}
            {mode === 'forgotPassword' && <ForgotPasswordForm toggleToLoginMode={() => setMode('login')} />}
        </Stack>
    )
}

export default Login
