import { Box } from '@mui/material'
import ErrorGenericContent from 'components/ErrorGenericContent'
import GradientButton from 'components/GradientButton'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { RouteConstants } from './navigation-types'

const NotMatch: React.FC = () => {
    const history = useHistory()

    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                flex: 0.6,
                justifyContent: 'center',
                gap: 12,
            }}
        >
            <ErrorGenericContent
                title="The address doesn't exist"
                subtitle="The url Alfred received doesn't exist, please check that it's correct"
            />

            <GradientButton title='Return to home page' onClick={() => history.push(RouteConstants.home)} />
        </Box>
    )
}

export default NotMatch
