import ModalBase from './ModalBase'
import { Box, Checkbox, Stack, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import Shredder from '../assets/images/Shredder'
import Text from '../components/Text'
import EmptyButton from '../components/EmptyButton'
import GradientButton from './GradientButton'
import useIsMobileView from 'hooks/useIsMobileView'
import { useState } from 'react'

const PREFIX = 'DeleteModal'
const classes = {
    container: `${PREFIX}-container`,
    imgContainer: `${PREFIX}-imgContainer`,
    img: `${PREFIX}-img`,
    buttonsContainer: `${PREFIX}-buttonsContainer`,
    titleContainer: `${PREFIX}-titleContainer`,
}

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.container}`]: {
        minHeight: 468,
        backgroundColor: theme.palette.background.default,
        borderRadius: 16,
        border: `1px solid ${theme.palette.neutral.dark1}`,
        boxShadow: theme.palette.shadow.main,
    },
    [`& .${classes.imgContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        marginTop: 24,
    },
    [`& .${classes.img}`]: {
        width: 246,
        height: 228,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    [`& .${classes.buttonsContainer}`]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginRight: 24,
        marginTop: 40,
        marginBottom: 24,
    },
    [`& .${classes.titleContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 24,
        marginBottom: 24,
    },
}))

interface DeleteModalProps {
    isOpen: boolean
    onClose: () => void
    onDelete: () => void
    type: 'collection' | 'endpoint' | 'response tab' | 'sharedCode' | 'project'
    name?: string
}

const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, type, name, onDelete }) => {
    const theme = useTheme()
    const isMobileView = useIsMobileView(theme)
    const [isDeletionConfirmed, setIsDeletionConfirmed] = useState<boolean>(false)

    return (
        <ModalBase center isOpen={isOpen} onClose={onClose} style={{ zIndex: 3000 }}>
            {/** Z-index is needed for response deletion. If it's not there, the response detail dropdown is covering the modal */}
            <Root className={classes.container} style={{ minWidth: isMobileView ? 328 : 442 }}>
                <Box className={classes.imgContainer}>
                    <Box className={classes.img}>
                        <Shredder />
                    </Box>
                </Box>
                <Box style={{ marginLeft: 24, marginRight: 24 }}>
                    <Box className={classes.titleContainer}>
                        <Text fontSize={20} color='light' style={{ textAlign: 'center' }}>
                            {type === 'collection'
                                ? `Delete ${name} collection?`
                                : type === 'endpoint'
                                ? `Delete ${name} endpoint?`
                                : type === 'sharedCode'
                                ? `Delete ${name}?`
                                : type === 'project'
                                ? `Delete ${name} project?`
                                : `Delete response ${name}`}
                        </Text>
                    </Box>
                    <Stack direction='row' alignItems='center' style={{ marginBottom: 40 }}>
                        {type === 'project' ? (
                            <Checkbox checked={isDeletionConfirmed} onChange={(e) => setIsDeletionConfirmed(e.target.checked)} />
                        ) : null}
                        <Text fontSize={16} color='light'>
                            {type === 'project' ? `Yes, I want to delete ${name} project.` : 'All data will be lost.'}
                        </Text>
                    </Stack>
                </Box>
                <Box className={classes.buttonsContainer}>
                    <GradientButton
                        buttonType='alert'
                        title='Delete'
                        disabled={type === 'project' && !isDeletionConfirmed}
                        onClick={() => {
                            setIsDeletionConfirmed(false)
                            if (type === 'project') {
                                isDeletionConfirmed && onDelete() // Only type project needs to confirm deletion
                            } else {
                                onDelete()
                            }
                        }}
                    />
                    <EmptyButton
                        text='Cancel'
                        onClick={() => {
                            setIsDeletionConfirmed(false)
                            onClose()
                        }}
                        style={{ marginRight: 20 }}
                    />
                </Box>
            </Root>
        </ModalBase>
    )
}

export default DeleteModal
