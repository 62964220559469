import { Box, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import NotMatch from 'navigation/NotMatch'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
    getActiveProject,
    getCollectionById,
    getSelectedModeType,
    isProjectEditable,
} from 'services/selectors/projects-selectors'
import { ReduxState } from 'services/types/mainReducer-types'
import { getEndpointIdsByCollectionIds } from 'shared/utils/project-utils'
import { removeCollection, setCollectionDescription, setCollectionName } from 'services/actions/project-actions'
import { RouteConstants } from 'navigation/navigation-types'
import { showFlashMessage, showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import { Collection } from 'shared/types/project-types'
import Text from 'components/Text'
import DeleteModal from 'components/DeleteModal'
import { getErrorMessage } from 'shared/utils/generic-utils'
import TextEditor from 'components/TextEditor'
import Fonts from 'constants/fonts'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import NothingHereImage from 'assets/images/NothingHereImage'
import { ModeType } from 'services/types/userSettings-types'
import { setSelectedModeType } from 'services/actions/userSettings-actions'
import { setCreatedEndpointInfo } from 'services/actions/temporaryUiChanges-actions'
import { duplicateNameCheck } from 'utils/project-utils'
import EditPanel from 'components/EditPanel'

const PREFIX = 'CollectionDetails'

const classes = {
    input: `${PREFIX}-input`,
    topRow: `${PREFIX}-topRow`,
    verticalLine: `${PREFIX}-verticalLine`,
    menuContainer: `${PREFIX}-menuContainer`,
    defaultIcon: `${PREFIX}-defaultIcon`,
    modeMenuContainer: `${PREFIX}-modeMenuContainer`,
    iconBox: `${PREFIX}-iconBox`,
    endpointInfoText: `${PREFIX}-endpointInfoText`,
    addEndpointContainer: `${PREFIX}-addEndpointContainer`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.input}`]: {
        color: theme.palette.neutral.light1,
    },

    [`& .${classes.topRow}`]: {
        display: 'flex',
        flex: 1,
        maxHeight: 26,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },

    [`& .${classes.verticalLine}`]: {
        display: 'flex',
        flex: 1,
        width: 1,
        minWidth: 1,
        backgroundColor: theme.palette.text.normal,
        marginLeft: 8,
        marginRight: 8,
    },

    [`& .${classes.menuContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    [`& .${classes.defaultIcon}`]: {
        color: theme.palette.neutral.light3,
        fontSize: 24,
        cursor: 'pointer',
    },

    [`& .${classes.modeMenuContainer}`]: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 29,
        width: 65,
        justifyContent: 'space-between',
        cursor: 'pointer',
    },

    [`& .${classes.iconBox}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 6,
    },

    [`& .${classes.endpointInfoText}`]: {
        padding: theme.spacing(1),
        borderRadius: 6,
        border: `2px solid transparent`,
        '&:hover': {
            backgroundColor: theme.palette.input.hoverBackground,
        },
    },

    [`& .${classes.addEndpointContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        float: 'left',
        marginTop: theme.spacing(1),
    },
}))

interface ParamsTypes {
    projectId: string
    collectionId: string
}

const CollectionDetails: React.FC = () => {
    const params = useParams<ParamsTypes>()
    const { projectId, collectionId } = params
    const theme = useTheme()

    const history = useHistory()
    const dispatch = useDispatch()
    const activeProject = useSelector(getActiveProject)
    const isReadOnly = !useSelector(isProjectEditable)
    const selectedMode = useSelector(getSelectedModeType)

    let collectionsMap: { [collectionId: string]: string[] } = {}
    if (activeProject) {
        collectionsMap = getEndpointIdsByCollectionIds(activeProject)
    }
    const collection = useSelector((state: ReduxState) => getCollectionById(state, collectionId))
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [descriptionEditorOpen, setDescriptionEditorOpen] = useState(false)
    const [titleEditorOpen, setTitleEditorOpen] = useState(false)
    const [nameErrorText, setNameErrorText] = useState<string>('')
    const isEditable = isReadOnly ? false : selectedMode === 'edit' ? true : false

    // Check for collection ID and project ID
    if (!collectionsMap[collectionId] || projectId !== activeProject?.id) return <NotMatch />

    const onCollectionNameChange = (text: string) => {
        setNameErrorText('')

        if (text === '') {
            setNameErrorText('Name of collection should not be empty')
        }
        if (text && duplicateNameCheck(text, 'collection', activeProject, collectionId)) {
            setNameErrorText('This name of the collection already exist in the same project')
        }
    }

    const onSaveName = (text?: string) => {
        if (nameErrorText) {
            showFlashMessageWithTimeout(dispatch, nameErrorText, 'error')
            return
        }
        dispatch(setCollectionName(collectionId, text ?? ''))
        setTitleEditorOpen(false)
        setNameErrorText('')
    }

    const onSaveDescription = (text?: string) => {
        dispatch(setCollectionDescription(collectionId, text ?? ''))
        setDescriptionEditorOpen(false)
    }

    const onCollectionRemove = async (collection: Collection) => {
        try {
            dispatch(removeCollection(collection, activeProject))
            let pathToRedirect = ''
            const collectionIndex = activeProject.collections.findIndex((c) => c.id === collection.id)
            if (collectionIndex !== undefined && collectionIndex >= 0) {
                const nextCollection = activeProject.collections[collectionIndex + 1]
                const previousCollection = activeProject.collections[collectionIndex - 1]
                // If there next collection in list, we redirect to it
                if (nextCollection !== undefined) {
                    pathToRedirect = `${RouteConstants.project}/${activeProject.id}/${nextCollection.id}`
                    // Next step we check if there is a previous collection
                } else if (previousCollection !== undefined) {
                    pathToRedirect = `${RouteConstants.project}/${activeProject.id}/${previousCollection.id}`
                }
            } else {
                // If it's last collection, we redirect
                pathToRedirect = `${RouteConstants.project}/${activeProject.id}`
            }
            history.push(pathToRedirect)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }

    const onEndpointAdd = () => {
        if (!isReadOnly) {
            dispatch(setCreatedEndpointInfo({ collectionId }))
        }
    }

    const onChangeModeType = (type: ModeType) => {
        if (activeProject) {
            dispatch(setSelectedModeType(type))
        }
    }

    return (
        <StyledBox
            style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 24, paddingRight: 24, paddingTop: 8 }}
        >
            <EditPanel
                name={activeProject.name}
                createdAt={activeProject.createdAt}
                lastModifiedAt={activeProject.lastModifiedAt}
                isReadOnly={isReadOnly}
                onChangeModeType={onChangeModeType}
                onDelete={() => setIsDeleteModalOpen(true)}
            />
            <Box>
                {/* COLLECTION TITLE */}
                <Box style={{ marginLeft: -10 }}>
                    {!isReadOnly && titleEditorOpen ? (
                        <TextEditor
                            onSave={onSaveName}
                            onChange={onCollectionNameChange}
                            errorMessage={nameErrorText}
                            onCancel={() => {
                                setTitleEditorOpen(false)
                            }}
                            defaultValue={collection?.name}
                            fontSize={Fonts.size.large}
                            bold
                            saveOnEnter
                            saveOnBlur
                        />
                    ) : (
                        <Text
                            className={classes.endpointInfoText}
                            color='normal'
                            fontSize={24}
                            style={{ fontWeight: 'bold' }}
                            onClick={() => (isEditable ? setTitleEditorOpen(true) : undefined)}
                        >
                            {collection?.name}
                        </Text>
                    )}
                </Box>

                {/* COLLECTION DESCRIPTION */}
                <Box style={{ marginBottom: theme.spacing(2), marginLeft: -10 }}>
                    {!isReadOnly && descriptionEditorOpen ? (
                        <TextEditor
                            multiline
                            defaultValue={collection?.description}
                            onSave={onSaveDescription}
                            onCancel={() => setDescriptionEditorOpen(false)}
                            saveOnBlur
                        />
                    ) : (
                        <Text
                            className={classes.endpointInfoText}
                            color={collection?.description ? 'normal' : 'faded'}
                            fontSize={16}
                            style={{ whiteSpace: 'pre-line' }}
                            onClick={() => (isEditable ? setDescriptionEditorOpen(true) : undefined)}
                        >
                            {collection?.description ? collection.description : 'Add description...'}
                        </Text>
                    )}
                </Box>

                <Text color='normal' fontSize={20} style={{ fontWeight: 700 }}>
                    Endpoints
                </Text>

                {collection?.endpoints.length === 0 ? (
                    <Text color='faded' fontSize={16} style={{ marginTop: theme.spacing(1) }}>
                        No endpoints
                    </Text>
                ) : (
                    <ul>
                        {collection?.endpoints.map((e) => {
                            return (
                                <li
                                    key={e.id}
                                    style={{ color: theme.palette.text.normal, marginBottom: theme.spacing(1), fontSize: 16 }}
                                >
                                    {e.name}
                                </li>
                            )
                        })}
                    </ul>
                )}

                {isEditable && (
                    <Box className={classes.addEndpointContainer} onClick={onEndpointAdd}>
                        <AddRoundedIcon style={{ color: theme.palette.neutral.unique1, fontSize: 16 }} />
                        <Text
                            style={{
                                color: theme.palette.neutral.unique1,
                                fontWeight: 400,
                                marginLeft: 10,
                            }}
                            color={theme.palette.neutral.unique1}
                            fontSize={16}
                        >
                            Add endpoint
                        </Text>
                    </Box>
                )}
            </Box>
            {collection?.endpoints.length === 0 && (
                <Box sx={{ display: 'flex', maxWidth: 273, marginTop: theme.spacing(5) }}>
                    <NothingHereImage />
                </Box>
            )}
            {collection && (
                <DeleteModal
                    type='collection'
                    name={collection?.name}
                    isOpen={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onDelete={() => {
                        setIsDeleteModalOpen(false)
                        onCollectionRemove(collection)
                    }}
                />
            )}
        </StyledBox>
    )
}

export default CollectionDetails
