import store from 'services/store'
import {
    SourceFile,
    createSourceFile,
    ScriptTarget,
    ScriptKind,
    isInterfaceDeclaration,
    isEnumDeclaration,
    isTypeAliasDeclaration,
    isPropertySignature,
    isTypeReferenceNode,
    Node,
    isImportDeclaration,
    isImportSpecifier,
    isUnionTypeNode,
} from 'typescript'
import prettier from 'prettier/standalone'
import parser from 'prettier/parser-typescript'
import { SharedCode } from 'shared/types/project-types'
import * as monaco from 'monaco-editor'
import { SharedCodeCache } from 'services/types/project-types'

/**
 * Result of searching for the name of type in a code
 */
interface SearchResult {
    /**
     * Name of the file from which the type was imported
     */
    fileName?: string | undefined
    /**
     * Declaration text of the type
     */
    fullText?: string | undefined
    /**
     * Type of the declaration
     */
    type?: 'interface' | 'enum' | 'type' | undefined
}

/**
 * Search for the name of the type in the code and returns SearchResult (either declaration text and type or name of the file from which it was imported)
 * @param code code to search in
 * @param searchedDeclarationName name of the type to search for
 * @returns
 */
export const searchCodeForDeclaration = (code: string, searchedDeclarationName: string): SearchResult => {
    const editorFile: SourceFile = buildAbstractSyntaxTree(code)

    const result: SearchResult = {}
    editorFile.forEachChild((node) => {
        searchCodeForDeclarationRecursively(node, searchedDeclarationName, result, undefined)
    })
    return result
}

/**
 * Recursively search the nodes for the searched type by its name
 * @param node node that is being checked
 * @param searchedDeclarationName name of the type
 * @param result result of the search
 * @param importedFrom name of the file from which the type was imported
 */
const searchCodeForDeclarationRecursively = (
    node: Node,
    searchedDeclarationName: string,
    result: SearchResult,
    importedFrom?: string
): void => {
    // If the node is import declaration, save the imported file name and send it to the node child nodes (types imported from the file)
    // For example "import { Foo } from 'my-file'" is import declaration, here "my-file" is saved for its child node "Foo"
    if (isImportDeclaration(node)) {
        const moduleName = node.moduleSpecifier.getText()
        importedFrom = moduleName.slice(1, moduleName.length - 1)
    }

    // If the node is the imported type save the file from which it was imported to the result
    if (isImportSpecifier(node)) {
        if (searchedDeclarationName === node.name.escapedText) {
            if (importedFrom) result.fileName = importedFrom
        }
    }

    // Save the declaration to the result if its name matches the searched name
    if (
        (isInterfaceDeclaration(node) || isEnumDeclaration(node) || isTypeAliasDeclaration(node)) &&
        node.name.getText() === searchedDeclarationName
    ) {
        result.type = isInterfaceDeclaration(node)
            ? 'interface'
            : isEnumDeclaration(node)
            ? 'enum'
            : isTypeAliasDeclaration(node)
            ? 'type'
            : undefined
        result.fullText = node.getText()
    }

    if (result.fullText && result.fullText !== '' && result.type) return
    if (result.fileName && result.fileName !== '') return

    if (node.getChildCount() > 0) {
        node.forEachChild((childNode) => {
            searchCodeForDeclarationRecursively(childNode, searchedDeclarationName, result, importedFrom)
        })
    }
}

export const getSharedCodeContentByFileName = (fileName: string): SharedCode | undefined => {
    return store.getState().projects.active?.sharedCode?.find((sc) => sc.name === fileName)
}

/**
 * Get declaration of type from code. Used, when you know for sure, that the declaration is in the code
 * @param code code to be searched in
 * @param declarationName name of the type
 * @returns
 */
export const getDeclarationsTextFromCodeByName = (code: string, declarationName: string): string | undefined => {
    const sourceFile: SourceFile = buildAbstractSyntaxTree(code)

    const result: SearchResult = {}
    sourceFile.forEachChild((node) => {
        getDeclarationsTextFromCodeByNameRecursively(node, declarationName, result)
    })
    return result.fullText
}

const getDeclarationsTextFromCodeByNameRecursively = (node: Node, declarationName: string, result: SearchResult): void => {
    if (
        (isInterfaceDeclaration(node) || isEnumDeclaration(node) || isTypeAliasDeclaration(node)) &&
        node.name.getText() === declarationName
    ) {
        result.fullText = node.getText()
    }

    if (result.fullText && result.fullText !== '') return

    if (node.getChildCount() > 0) {
        node.forEachChild((childNode) => {
            getDeclarationsTextFromCodeByNameRecursively(childNode, declarationName, result)
        })
    }
}

/**
 * Replaces types by their declarations in the code
 * @param codeToReplace code to be replaced
 * @param fileContent content of the file from which the code is taken
 * @returns
 */
export const replaceTypesByDeclarationText = (codeToReplace: string, fileContent: string): string => {
    const resultFile: SourceFile = buildAbstractSyntaxTree(codeToReplace)

    const splittedCode = codeToReplace.split('{')

    // All the code from before the '{' sign is saved to not replace the interfaces names in this part
    // Example:
    // interface Foo extends Bar { prop: Bar } - here the word Bar after the extends keyword should not be replaced
    // We use this simple method instead of building AST because it's faster
    const codesFirstPart = splittedCode[0]
    const codesSecondPart = splittedCode.slice(1).join('{')

    const replacingResult: { resultCode?: string | undefined } = {}

    replaceTypesByDeclarationTextRecursively(resultFile, codesSecondPart, fileContent, replacingResult)
    replaceTypesByDeclarationTextRecursively(resultFile, codeToReplace, fileContent, replacingResult)

    // If the code doesn't contain any types to replace or the types are not imported from our shared code, replacingResult.resultCode will be undefined
    return replacingResult.resultCode ? [codesFirstPart, replacingResult.resultCode].join('{') : codeToReplace
}

const replaceTypesByDeclarationTextRecursively = (
    node: Node,
    codeToReplace: string,
    fileContent: string,
    result: { resultCode?: string | undefined }
): void => {
    if (isPropertySignature(node) && node.type && isTypeReferenceNode(node.type)) {
        const propertyType = node.type.getText()

        const searchResult = searchCodeForDeclaration(fileContent, propertyType)

        // If the type is declared in the file, replace the type with its definition text
        if (searchResult.fullText && searchResult.type) {
            if (searchResult.type === 'interface' || searchResult.type === 'enum') {
                const interfaceBody = '{' + searchResult.fullText.split('{')[1]
                result.resultCode = result.resultCode
                    ? result.resultCode.replaceAll(propertyType, interfaceBody)
                    : codeToReplace.replaceAll(propertyType, interfaceBody)
            }

            if (searchResult.type === 'type') {
                const typeValues = searchResult.fullText.split('=')[1]
                result.resultCode = result.resultCode
                    ? result.resultCode.replaceAll(propertyType, typeValues)
                    : codeToReplace.replaceAll(propertyType, typeValues)
            }
        }
        // If the type is imported from a file, search for it in the shared code cache
        else if (searchResult.fileName) {
            const typeDeclarationFromCache = getTypeDefinitionByNameAndFileNameFromCache(propertyType, searchResult.fileName)

            if (typeDeclarationFromCache) {
                const declarationValuesOrProperties = getTypeDeclarationValuesOrProperties(typeDeclarationFromCache)

                if (declarationValuesOrProperties) {
                    result.resultCode = result.resultCode
                        ? result.resultCode.replaceAll(propertyType, declarationValuesOrProperties)
                        : codeToReplace.replaceAll(propertyType, declarationValuesOrProperties)
                }
            }
        }
    }

    if (node.getChildCount() > 0) {
        node.forEachChild((childNode) => {
            replaceTypesByDeclarationTextRecursively(childNode, codeToReplace, fileContent, result)
        })
    }
}

const getTypeDeclarationValuesOrProperties = (typeDeclaration: string) => {
    let result

    if (typeDeclaration.includes('{')) {
        result = '{' + typeDeclaration.split('{')[1]
    } else if (typeDeclaration.includes('=')) {
        result = typeDeclaration.split('=')[1]
    }

    return result
}

export const formatTypescriptCode = (code: string): string => {
    try {
        const formattedCode = prettier.format(code, {
            parser: 'typescript',
            plugins: [parser],
            semi: false,
            trailingComma: 'es5',
            singleQuote: true,
            jsxSingleQuote: true,
            printWidth: 130,
            tabWidth: 4,
            bracketSpacing: true,
            endOfLine: 'lf',
        })
        return formattedCode
    } catch {
        return code
    }
}

export const getSharedCodeCache = (): SharedCodeCache | undefined => {
    return store.getState().projects.sharedCodeCache
}

interface TypeDeclarationsSearchResult {
    name: string
    type: monaco.languages.CompletionItemKind
    declarationText: string
    isExported: boolean
}

export const createSharedCodeCache = (sharedCode: SharedCode[]): SharedCodeCache => {
    const sharedCodeCache: SharedCodeCache = {}

    // For each shared tab, search the code in it to find exported types
    sharedCode.forEach((sc) => {
        const ast = buildAbstractSyntaxTree(sc.content)
        const result: TypeDeclarationsSearchResult[] = []

        searchCodeForExportedTypesDeclarationsRecursively(ast, result)

        result.forEach((r) => {
            sharedCodeCache[`${r.name}@${sc.name}`] = {
                typeDefinition: r.declarationText,
                fileName: sc.name,
                name: r.name,
                type: r.type,
                isExported: r.isExported,
            }
        })
    })

    return sharedCodeCache
}

/**
 * Recursively search the nodes for exported types and adds them to the result
 * @param node node that is being checked
 * @param result result of the search
 */
const searchCodeForExportedTypesDeclarationsRecursively = (node: Node, result: TypeDeclarationsSearchResult[]): void => {
    // Save the declaration to the result if its type and is exported
    if (isInterfaceDeclaration(node) || isEnumDeclaration(node) || isTypeAliasDeclaration(node)) {
        const newResult: TypeDeclarationsSearchResult = {
            name: node.name.getText(),
            declarationText: node.getText(),
            type: isInterfaceDeclaration(node)
                ? monaco.languages.CompletionItemKind.Interface
                : isEnumDeclaration(node)
                ? monaco.languages.CompletionItemKind.Enum
                : isTypeAliasDeclaration(node)
                ? monaco.languages.CompletionItemKind.TypeParameter
                : monaco.languages.CompletionItemKind.TypeParameter,
            isExported: node.getText().includes('export'), //TODO: better way to check if the type is exported
        }

        result.push(newResult)

        // If the node is type declaration, it's descendants shouldn't be
        return
    }

    if (node.getChildCount() > 0) {
        node.forEachChild((childNode) => {
            searchCodeForExportedTypesDeclarationsRecursively(childNode, result)
        })
    }
}

export const getCompletionItemsForEditorFromSharedCodeCache = (
    model: monaco.editor.ITextModel,
    position: monaco.Position
): monaco.languages.ProviderResult<monaco.languages.CompletionList> => {
    const sharedCodeCache = getSharedCodeCache()
    const suggestions: monaco.languages.CompletionItem[] = []
    const word = model.getWordUntilPosition(position)
    // Position, where the completed word will be inserted to
    const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
    }

    for (const key in sharedCodeCache) {
        const sharedCodeCacheItem = sharedCodeCache[key]

        // If the type is not exported from the shared code, it can't be imported anywhere else
        if (!sharedCodeCacheItem.isExported) continue

        // Search if the name of the type is already imported or defined in the code in the editor. If yes, don't offer to import the type
        const searchResult = searchCodeForDeclaration(model.getValue(), sharedCodeCacheItem.name)

        if (searchResult.fileName || (searchResult.fullText && searchResult.type)) continue

        const regEx = `import ((.|\\n)*) from ('|")${sharedCodeCacheItem.fileName}('|")`

        // Search if the code in editor already contains import from the file, where actual type is declared
        const importDeclarationsFromFile = model.findMatches(regEx, true, true, true, null, false)

        let documentationText: string
        let importOptions: monaco.editor.ISingleEditOperation

        if (importDeclarationsFromFile && importDeclarationsFromFile.length > 0) {
            const lastImport = importDeclarationsFromFile[importDeclarationsFromFile.length - 1]
            let shouldInsertNewLineAfterImport = false
            // Position, where the import will be inserted
            let importRange

            documentationText = `Update import from "${sharedCodeCacheItem.fileName}"`

            for (let lineNumber = lastImport.range.endLineNumber; lineNumber >= lastImport.range.startLineNumber; lineNumber--) {
                const line = model.getLineContent(lineNumber)
                const indexOfImportDeclarationEnd = line.indexOf('}')

                if (indexOfImportDeclarationEnd !== -1) {
                    importRange = {
                        startLineNumber: lineNumber,
                        endLineNumber: lineNumber,
                        startColumn: indexOfImportDeclarationEnd,
                        endColumn: indexOfImportDeclarationEnd,
                    }

                    if (indexOfImportDeclarationEnd === 0) shouldInsertNewLineAfterImport = true

                    break
                }
            }

            // If the
            if (!importRange) continue

            importOptions = {
                range: importRange,
                text: `, ${sharedCodeCacheItem.name} ${shouldInsertNewLineAfterImport ? '\n' : ''}`,
            }
        } else {
            documentationText = `Add import from "${sharedCodeCacheItem.fileName}"`

            importOptions = {
                range: {
                    startLineNumber: 1,
                    endLineNumber: 1,
                    startColumn: 1,
                    endColumn: 1,
                },
                text: `import { ${sharedCodeCacheItem.name} } from '${sharedCodeCacheItem.fileName}'\n`,
            }
        }

        documentationText = documentationText + `\n\n${convertTypeToString(sharedCodeCacheItem.type)} ${sharedCodeCacheItem.name}`

        suggestions.push({
            label: sharedCodeCacheItem.name,
            insertText: sharedCodeCacheItem.name,
            detail: sharedCodeCacheItem.fileName,
            kind: sharedCodeCacheItem.type,
            range: range,
            documentation: documentationText,
            additionalTextEdits: [importOptions],
        })
    }

    return {
        suggestions: suggestions,
    }
}

const buildAbstractSyntaxTree = (code: string): SourceFile => {
    return createSourceFile(`file.ts`, code, ScriptTarget.Latest, true, ScriptKind.TS)
}

const convertTypeToString = (type: monaco.languages.CompletionItemKind): string => {
    let name
    switch (type) {
        case monaco.languages.CompletionItemKind.Interface:
            name = 'interface'
            break
        case monaco.languages.CompletionItemKind.TypeParameter:
            name = 'type'
            break
        case monaco.languages.CompletionItemKind.Enum:
            name = 'enum'
            break
        // Fallback to "type" for now.
        default:
            name = 'type'
            break
    }
    return name
}

/** Information about property in type */
export interface PropertyInfo {
    name: string
    type: string

    isRequired: boolean

    /**
     * Union type property is a property which can store multiple type of values
     *
     * Examples:
     * type myType = "foo" | "bar"
     * type myType = Foo | Bar | string
     */
    isUnionType?: boolean
}

/**
 * Returns information about all the properties in the interface
 * @param code interface definition
 * @returns
 */
export const getPropertiesFromInterface = (code: string): PropertyInfo[] => {
    const ast = buildAbstractSyntaxTree(code)
    const result: PropertyInfo[] = []
    getPropertiesFromInterfaceRecursively(ast, result)
    return result
}

const getPropertiesFromInterfaceRecursively = (node: Node, result: PropertyInfo[]) => {
    if (isPropertySignature(node) && node.type) {
        let isRequired = true

        // If the property node includes question mark, its not required
        // Example interface Foo { bar?: string }
        if (node.questionToken) {
            isRequired = false
        }
        // If the property type is union type and it contains undefined keyword, its not required
        // Example interface Foo { bar: string | undefined }
        else if (isUnionTypeNode(node.type) && node.type.getText().includes('undefined')) {
            isRequired = false
        }

        result.push({
            name: node.name.getText(),
            type: node.type.getText(),
            isUnionType: isUnionTypeNode(node.type),
            isRequired: isRequired,
        })

        // Take just first level of properties, not nested properties of inline type
        return
    }

    if (node.getChildCount() > 0) {
        node.getChildren().forEach((n) => {
            getPropertiesFromInterfaceRecursively(n, result)
        })
    }
}

export const getTypeDefinitionByNameAndFileNameFromCache = (typeName: string, fileName: string): string | undefined => {
    const sharedCodeCache = getSharedCodeCache()

    if (sharedCodeCache && sharedCodeCache[`${typeName}@${fileName}`]) {
        return sharedCodeCache[`${typeName}@${fileName}`].typeDefinition
    } else {
        return
    }
}

export const addPropertiesFromExtendedInterfaceToDeclaration = (
    interfaceDeclaration: string,
    interfaceOriginFileContent: string,
    alreadyExtendedBy?: string[]
): string => {
    const extendedByInterfaces = getExtendedInterfacesFromInterfaceDefinition(interfaceDeclaration)

    for (let extendedBy of extendedByInterfaces) {
        extendedBy = extendedBy.trim()
        if (alreadyExtendedBy?.includes(extendedBy)) continue

        const searchResult = searchCodeForDeclaration(interfaceOriginFileContent, extendedBy)

        let extendedInterfaceProperties: PropertyInfo[] = []

        if (searchResult.fullText && searchResult.type) {
            if (alreadyExtendedBy) {
                alreadyExtendedBy.push(extendedBy)
            } else {
                alreadyExtendedBy = [extendedBy]
            }

            const extendedInterfaceWithExtendedProperties = addPropertiesFromExtendedInterfaceToDeclaration(
                searchResult.fullText,
                interfaceOriginFileContent,
                alreadyExtendedBy
            )
            extendedInterfaceProperties = getPropertiesFromInterface(extendedInterfaceWithExtendedProperties)
        } else if (searchResult.fileName) {
            const typeDeclarationFromCache = getTypeDefinitionByNameAndFileNameFromCache(extendedBy, searchResult.fileName)
            const sharedCode = getSharedCodeContentByFileName(searchResult.fileName)

            if (typeDeclarationFromCache) {
                if (sharedCode) {
                    if (alreadyExtendedBy) {
                        alreadyExtendedBy.push(extendedBy)
                    } else {
                        alreadyExtendedBy = [extendedBy]
                    }
                    const extendedInterfaceWithExtendedProperties = addPropertiesFromExtendedInterfaceToDeclaration(
                        typeDeclarationFromCache,
                        sharedCode.content,
                        alreadyExtendedBy
                    )
                    extendedInterfaceProperties = getPropertiesFromInterface(extendedInterfaceWithExtendedProperties)
                } else {
                    extendedInterfaceProperties = getPropertiesFromInterface(typeDeclarationFromCache)
                }
            }
        }

        if (extendedInterfaceProperties.length > 0) {
            let textToAddToInterfaceDeclaration = `\n\n// Properties from extended interfaces`

            for (const property of extendedInterfaceProperties) {
                textToAddToInterfaceDeclaration += `\n${property.name}: ${property.type}`
            }

            interfaceDeclaration = interfaceDeclaration.replace('}', `${textToAddToInterfaceDeclaration}\n}`)
        }
    }

    return interfaceDeclaration
}

export const getExtendedInterfacesFromInterfaceDefinition = (interfaceDeclaration: string): string[] => {
    let splitInterfaceDeclaration = interfaceDeclaration.split('{')
    if (!splitInterfaceDeclaration[0].includes('extends')) return []

    splitInterfaceDeclaration = splitInterfaceDeclaration[0].trim().split('extends')

    const extendedByInterfaces = splitInterfaceDeclaration.length > 1 ? splitInterfaceDeclaration[1].trim().split(',') : []

    return extendedByInterfaces
}

export const isTypeImportedFromFile = (code: string, typeName: string, fileName: string): boolean => {
    const editorFile: SourceFile = buildAbstractSyntaxTree(code)

    const result: { isImported: boolean } = { isImported: false }
    editorFile.forEachChild((node) => {
        searchTypeImportFromFileRecursively(node, typeName, fileName, result)
    })
    return result.isImported
}

const searchTypeImportFromFileRecursively = (
    node: Node,
    typeName: string,
    fileName: string,
    result: { isImported: boolean },
    importedFrom?: string
): void => {
    // If the node is import declaration, save the imported file name and send it to the node child nodes (types imported from the file)
    // For example "import { Foo } from 'my-file'" is import declaration, here "my-file" is saved for its child node "Foo"
    if (isImportDeclaration(node)) {
        const moduleName = node.moduleSpecifier.getText()
        importedFrom = moduleName.slice(1, moduleName.length - 1)
    }

    // If the node is the imported type save the info to the result
    if (isImportSpecifier(node)) {
        if (typeName === node.name.escapedText) {
            if (importedFrom === fileName) {
                result.isImported = true
            }
        }
    }

    if (result.isImported === true) return

    if (node.getChildCount() > 0) {
        node.forEachChild((childNode) => {
            searchTypeImportFromFileRecursively(childNode, typeName, fileName, result, importedFrom)
        })
    }
}

export const getFirstInterfaceDefinitionFromCodeRecursively = (node: Node, result: SearchResult): string | undefined => {
    if (isInterfaceDeclaration(node)) {
        result.fullText = node.getText()
    }

    if (result.fullText && result.fullText !== '') return

    if (node.getChildCount() > 0) {
        node.forEachChild((childNode) => {
            getFirstInterfaceDefinitionFromCodeRecursively(childNode, result)
        })
    }
}
