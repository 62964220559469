import { Typography, TypographyProps, useTheme } from '@mui/material'
import React from 'react'

interface TextProps extends TypographyProps {
    color?: 'normal' | 'light' | 'faded' | string
    fontSize: 12 | 14 | 16 | 20 | 24 | 30
    /**
     * Of sets to true the font weight will be light
     */
    light?: boolean
    /**
     * If set to true the font weight will be bold (has priority over light)
     */
    bold?: boolean
}

const Text: React.FC<TextProps> = ({ children, color = 'normal', fontSize, light, bold, ...props }) => {
    const theme = useTheme()
    const textColor =
        color === 'normal'
            ? theme.palette.text.normal
            : color === 'light'
            ? theme.palette.text.light
            : color === 'faded'
            ? theme.palette.text.faded
            : color
    const fontWeight = bold ? 700 : light ? 300 : undefined

    return (
        <Typography {...props} style={{ color: textColor, fontSize: fontSize, fontWeight: fontWeight, ...props.style }}>
            {children}
        </Typography>
    )
}

export default Text
