import { FC } from 'react'
import { Box } from '@mui/system'
import GenericErrorImage from 'assets/images/GenericErrorImage'
import Text from './Text'
import { styled } from '@mui/material/styles'

const PREFIX = 'ErrorGenericContent'
const classes = {
    mainContainer: `${PREFIX}-mainContainer`,
    topMargin: `${PREFIX}-topMargin`,
}

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.mainContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
    },
    [`& .${classes.topMargin}`]: {
        marginTop: `${theme.spacing(2)}`,
    },
}))

interface ErrorGenericContentProps {
    title?: string
    subtitle?: string
}

const ErrorGenericContent: FC<ErrorGenericContentProps> = ({ title, subtitle }) => {
    return (
        <Root className={classes.mainContainer}>
            <Box sx={{ display: 'flex' }}>
                <GenericErrorImage />
            </Box>
            <Text color='normal' fontSize={24} sx={{ textAlign: 'center' }} className={classes.topMargin}>
                {title ? title : "Something's wrong here"}
            </Text>
            <Text color='normal' fontSize={16} sx={{ textAlign: 'center' }} className={classes.topMargin}>
                {subtitle ? subtitle : 'Our detectives are gathering clues, and we are on the case!'}
            </Text>
        </Root>
    )
}

export default ErrorGenericContent
