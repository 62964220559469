import { Box, TextField, useTheme, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { UrlRequestParameter } from 'shared/types/project-types'
import DropdownMenu from 'components/DropdownMenu'
import ParameterTypeDropdownMenu from './ParameterTypeDropdownMenu'
import { capitalizeFirstLetter } from 'shared/utils/generic-utils'
import { ReactComponent as ArrowDownSvg } from '../../assets/images/menu_arrow_down.svg'
import { styled } from '@mui/material/styles'

const PREFIX = 'PathParameter'

const classes = {
    container: `${PREFIX}-container`,
    containerDisabled: `${PREFIX}-containerDisabled`,
    defaultCell: `${PREFIX}-defaultCell`,
    dropdownMenuContainer: `${PREFIX}-dropdownMenuContainer`,
    menu: `${PREFIX}-menu`,
    menuDisabled: `${PREFIX}-menuDisabled`,
    input: `${PREFIX}-input`,
    inputMultiline: `${PREFIX}-inputMultiline`,
    root: `${PREFIX}-root`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        minHeight: 40,
        width: '100%',
        border: '1px solid grey',
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        marginBottom: 0,
        marginTop: 8,
        paddingTop: 4,
        paddingBottom: 4,
        '&:hover': {
            backgroundColor: theme.palette.forms.inputBackgroundNeutral,
        },
    },
    [`&.${classes.containerDisabled}`]: {
        minHeight: 40,
        width: '100%',
        border: '1px solid grey',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 0,
        marginTop: 8,
        paddingTop: 4,
        paddingBottom: 4,
    },
    [`& .${classes.defaultCell}`]: {
        height: '100%',
        flex: 3,
        display: 'flex',
        alignItems: 'center',
    },
    [`& .${classes.dropdownMenuContainer}`]: {
        height: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 150,
    },
    [`& .${classes.menu}`]: {
        height: 32,
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid ${theme.palette.neutral.light3}`,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        width: 127,
    },
    [`& .${classes.menuDisabled}`]: {
        height: 32,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        width: 127,
    },
    [`& .${classes.input}`]: {
        '&::placeholder': {
            color: theme.palette.neutral.light3,
        },
        color: theme.palette.neutral.light1,
        paddingTop: 4,
        paddingBottom: 4,
    },
    [`& .${classes.inputMultiline}`]: {
        '&::placeholder': {
            color: theme.palette.neutral.light3,
        },
        color: theme.palette.neutral.light1,
        paddingBottom: -1,
    },
    [`& .${classes.root}`]: {
        '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
            '-webkit-text-fill-color': theme.palette.neutral.light1,
        },
    },
}))

interface PathParameterProps {
    pathVariable: UrlRequestParameter
    onDescriptionUpdate: (variable: UrlRequestParameter) => void
    onTypeUpdate: (variable: UrlRequestParameter) => void
    isReadOnly: boolean
}

const PathParameter: FC<PathParameterProps> = ({ pathVariable, onDescriptionUpdate, onTypeUpdate, isReadOnly }) => {
    const theme = useTheme()

    const [parameterType, setParameterType] = useState<'number' | 'string' | 'boolean'>('string')
    const [isParameterTypeMenuOpen, setIsParameterTypeMenuOpen] = useState<boolean>(false)
    const [description, setDescription] = useState<string>(pathVariable.description ? pathVariable.description : '')

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value)
    }

    return (
        <StyledBox className={isReadOnly ? classes.containerDisabled : classes.container}>
            <Box className={classes.defaultCell}>
                <TextField
                    style={{ marginLeft: 10 }}
                    sx={{ input: { color: theme.palette.neutral.light1 } }}
                    id='path-var-id'
                    placeholder='Parameter'
                    disabled
                    value={pathVariable.name}
                    fullWidth
                    variant='standard'
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            root: classes.root,
                            input: classes.input,
                        },
                    }}
                />
            </Box>
            <Box className={classes.dropdownMenuContainer}>
                <DropdownMenu
                    arrow={false}
                    content={
                        <ParameterTypeDropdownMenu
                            isReadOnly={isReadOnly}
                            selectedParameterType={parameterType}
                            onParameterTypeSelect={(type: 'string' | 'number' | 'boolean') => {
                                setParameterType(type)
                                setIsParameterTypeMenuOpen(false)
                                onTypeUpdate({ name: pathVariable.name, type: type, description: description })
                            }}
                        />
                    }
                    open={isParameterTypeMenuOpen}
                    onClose={() => setIsParameterTypeMenuOpen(false)}
                >
                    <Box
                        className={isReadOnly ? classes.menuDisabled : classes.menu}
                        style={{ cursor: isReadOnly ? 'default' : 'pointer' }}
                        onClick={() => (isReadOnly ? null : setIsParameterTypeMenuOpen(true))}
                    >
                        <Typography
                            style={{
                                marginLeft: 16,
                                color: theme.palette.neutral.light1,
                                userSelect: 'none',
                                display: 'flex',
                                flex: 1,
                            }}
                        >
                            {capitalizeFirstLetter(parameterType.toString())}
                        </Typography>
                        {!isReadOnly && <ArrowDownSvg style={{ color: theme.palette.neutral.light3, marginRight: 16 }} />}
                    </Box>
                </DropdownMenu>
            </Box>
            <Box className={classes.defaultCell}>
                <TextField
                    style={{ marginLeft: 10, marginRight: 6 }}
                    sx={{ input: { color: theme.palette.neutral.light1 } }}
                    id='path-var-description-id'
                    placeholder='Description'
                    value={description}
                    onChange={handleDescriptionChange}
                    disabled={isReadOnly}
                    fullWidth
                    multiline
                    onBlur={() => onDescriptionUpdate({ name: pathVariable.name, description: description, type: parameterType })}
                    variant='standard'
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            root: classes.root,
                            input: classes.inputMultiline,
                        },
                    }}
                />
            </Box>
        </StyledBox>
    )
}

export default PathParameter
