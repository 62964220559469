import { styled } from '@mui/material/styles'
import Text from 'components/Text'
import { Box, Typography, useTheme } from '@mui/material'
import Fonts from 'constants/fonts'

const PREFIX = 'SortDropdown'

const classes = {
    uppercaseText: `${PREFIX}-uppercaseText`,
    dropdownContainer: `${PREFIX}-dropdownContainer`,
    item: `${PREFIX}-item`,
    itemNon: `${PREFIX}-itemNon`,
    dropdownItemTitle: `${PREFIX}-dropdownItemTitle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.uppercaseText}`]: {
        textTransform: 'uppercase',
    },

    [`&.${classes.dropdownContainer}`]: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        filter: theme.palette.dropdown.filter,
        zIndex: 2,
    },

    [`& .${classes.item}`]: {
        color: theme.palette.text.light,
        width: 128,
        height: 52,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
        fontSize: Fonts.size.small,
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.dropdown.hover,
            fontWeight: 'bold',
        },
    },

    [`& .${classes.itemNon}`]: {
        height: 38,
        paddingLeft: 16,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 14,
        cursor: 'default',
    },

    [`& .${classes.dropdownItemTitle}`]: {
        width: 112,
        height: 52,
        paddingLeft: 16,
        display: 'flex',
        alignItems: 'center',
        fontSize: Fonts.size.small,
        '&:hover': {
            fontWeight: 'bold',
            color: theme.palette.dropdown.titleHover,
        },
    },
}))

export interface ProjectSortMenuItem {
    title: string
    style?: React.CSSProperties
    sortType?: SortFilterType
    orderType?: OrderFilterType
}

export type SortFilterType = 'alphabetical' | 'createDate' | 'editDate' | 'lastViewDate'
export type OrderFilterType = 'ascending' | 'descending'

const firstMenuItems: ProjectSortMenuItem[] = [
    {
        title: 'Alphabetical',
        sortType: 'alphabetical',
    },
    {
        title: 'Date Created',
        sortType: 'createDate',
    },
    {
        title: 'Edited',
        sortType: 'editDate',
    },
    {
        title: 'Last viewed',
        sortType: 'lastViewDate',
    },
]

const secondMenuItems: ProjectSortMenuItem[] = [
    {
        title: 'Ascending',
        orderType: 'ascending',
    },
    {
        title: 'Descending',
        orderType: 'descending',
        style: {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
    },
]

interface SortDropdownProps {
    selectedSortFilter: SortFilterType
    selectedOrderFilter: OrderFilterType
    onSortFilterSelect: (filter: SortFilterType) => void
    onOrderFilterSelect: (filter: OrderFilterType) => void
}

const SortDropdown: React.FC<SortDropdownProps> = ({
    selectedSortFilter,
    selectedOrderFilter,
    onSortFilterSelect,
    onOrderFilterSelect,
}) => {
    const theme = useTheme()
    return (
        <StyledBox className={classes.dropdownContainer}>
            <Box className={classes.itemNon} style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
                <Text color='faded' fontSize={14} className={classes.uppercaseText}>
                    SORT BY:
                </Text>
            </Box>
            {firstMenuItems.map((item: ProjectSortMenuItem, index) => {
                const bgColor = selectedSortFilter === item.sortType ? theme.palette.dropdown.hover : undefined
                const textColor = selectedSortFilter === item.sortType ? theme.palette.dropdown.titleHover : undefined
                const fontWeight = selectedSortFilter === item.sortType ? 'bold' : undefined
                return (
                    <Box
                        style={{ backgroundColor: bgColor }}
                        className={classes.item}
                        key={`${item.title}-${index}`}
                        onClick={() => (item.sortType ? onSortFilterSelect(item.sortType) : undefined)}
                    >
                        <Typography style={{ color: textColor, fontWeight: fontWeight }} className={classes.dropdownItemTitle}>
                            {item.title}
                        </Typography>
                    </Box>
                )
            })}
            <Box className={classes.itemNon}>
                <Text color='faded' fontSize={14} className={classes.uppercaseText}>
                    ORDER:
                </Text>
            </Box>
            {secondMenuItems.map((item: ProjectSortMenuItem, index) => {
                const bgColor = selectedOrderFilter === item.orderType ? theme.palette.dropdown.hover : undefined
                const textColor = selectedOrderFilter === item.orderType ? theme.palette.dropdown.titleHover : undefined
                const fontWeight = selectedOrderFilter === item.orderType ? 'bold' : undefined
                return (
                    <Box
                        className={classes.item}
                        style={{ ...item.style, backgroundColor: bgColor }}
                        key={`${item.title}-${index}`}
                        onClick={() => (item.orderType ? onOrderFilterSelect(item.orderType) : undefined)}
                    >
                        <Typography style={{ color: textColor, fontWeight: fontWeight }} className={classes.dropdownItemTitle}>
                            {item.title}
                        </Typography>
                    </Box>
                )
            })}
        </StyledBox>
    )
}

export default SortDropdown
