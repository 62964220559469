import { FC } from 'react'
import GradientButtonBase, { GradientButtonBaseProps } from './GradientButtonBase'
import { CircularProgress, Typography } from '@mui/material'

type Size = 'small' | 'medium' | 'large'

interface GradientButtonProps extends GradientButtonBaseProps {
    title: string
    isLoading?: boolean
    size?: Size
    width?: number
    bold?: boolean
}

const GradientButton: FC<GradientButtonProps> = ({ title, isLoading, size = 'medium', width, bold, style, onClick, ...rest }) => {
    const height = size === 'small' ? 40 : size === 'large' ? 52 : 48
    return (
        <GradientButtonBase
            {...rest}
            onClick={isLoading ? undefined : onClick}
            style={{ ...style, height: height, width: width }}
        >
            {isLoading && <CircularProgress size={24} />}
            {!isLoading && <Typography sx={{ fontWeight: bold ? 'bold' : '400', color: '#FAFAFC' }}>{title}</Typography>}
        </GradientButtonBase>
    )
}

export default GradientButton
