const Fonts = {
    size: {
        extraLarge: 30,
        large: 24,
        medium: 20,
        normal: 16,
        small: 14,
        micro: 12,
    },
}

export default Fonts
