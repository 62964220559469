import { Box, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CheckIcon from '@mui/icons-material/Check'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import DropdownMenu from '../DropdownMenu'
import { ShareRoles } from 'shared/types/project-types'
import { capitalizeFirstLetter } from 'shared/utils/generic-utils'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Text from 'components/Text'
import { MAIN_BORDER_RADIUS } from 'constants/ui'

const PREFIX = 'PermissionSelector'

const classes = {
    emailRole: `${PREFIX}-emailRole`,
    item: `${PREFIX}-item`,
    itemTitle: `${PREFIX}-itemTitle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.item}`]: {
        color: theme.palette.text.normal,
        fontWeight: 'normal',
        height: 52,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.dropdown.hover,
            fontWeight: 'bold',
        },
    },

    [`& .${classes.itemTitle}`]: {
        height: 52,
        width: (props: { itemWidth: number }) => props.itemWidth,
        display: 'flex',
        fontSize: 16,
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 'bold',
            color: theme.palette.dropdown.titleHover,
        },
        // Used to make text bold on icon hover. Does not work without it.
        zIndex: 2,
    },
}))

const StyledArrow = styled(Box)(({ theme }) => ({
    [`&.${classes.emailRole}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderTopRightRadius: BORDER_RADIUS_SMALLER,
        borderBottomRightRadius: BORDER_RADIUS_SMALLER,
        padding: '8px 16px',
        marginRight: theme.spacing(2),
        width: 75,
    },
}))

interface PermissionSelectorProps {
    permission: ShareRoles
    onPermissionChosen: (permission: ShareRoles) => void
    size: 'large' | 'small'
    isViewPermissionVisible?: boolean
    isEditPermissionVisible?: boolean
    isOwnerPermissionVisible?: boolean
    disabled?: boolean
    resendInviteProps?: ExtendedActionProps
    deleteProps?: ExtendedActionProps
    leaveProps?: ExtendedActionProps
    checkSelection?: boolean
}

interface ExtendedActionProps {
    isActionVisible: boolean
    onClickEvent: () => void
}

const BORDER_RADIUS_SMALLER = 4

const PermissionSelector: FC<PermissionSelectorProps> = ({
    permission,
    size,
    onPermissionChosen,
    isViewPermissionVisible,
    isEditPermissionVisible,
    isOwnerPermissionVisible,
    deleteProps,
    leaveProps,
    resendInviteProps,
    disabled,
    checkSelection,
}) => {
    const itemWidth = size === 'small' ? 84 : 168

    const theme = useTheme()
    const [permissionsDropdownVisible, setPermissionsDropdownVisible] = useState<boolean>(false)

    const isDividerVisible = (): boolean => {
        if (
            (resendInviteProps?.isActionVisible || deleteProps?.isActionVisible || leaveProps?.isActionVisible) &&
            (isViewPermissionVisible || isEditPermissionVisible || isOwnerPermissionVisible)
        ) {
            return true
        }

        return false
    }

    return (
        <DropdownMenu
            arrow={false}
            borderRadius={MAIN_BORDER_RADIUS}
            content={
                <StyledBox style={{ borderRadius: MAIN_BORDER_RADIUS }}>
                    {isEditPermissionVisible && (
                        <Box
                            className={classes.item}
                            style={{
                                borderTopRightRadius: MAIN_BORDER_RADIUS,
                                borderTopLeftRadius: MAIN_BORDER_RADIUS,
                                width: itemWidth,
                            }}
                            onClick={() => {
                                onPermissionChosen('edit')
                                setPermissionsDropdownVisible(false)
                            }}
                        >
                            <Typography className={classes.itemTitle} sx={{ paddingLeft: 2, width: itemWidth }}>
                                Edit
                            </Typography>
                            {checkSelection && permission === 'edit' && (
                                <CheckIcon style={{ color: theme.palette.neutral.neutral2, paddingRight: 16 }} />
                            )}
                        </Box>
                    )}
                    {isOwnerPermissionVisible && (
                        <Box
                            className={classes.item}
                            style={{
                                borderBottomLeftRadius:
                                    deleteProps?.isActionVisible || resendInviteProps?.isActionVisible ? undefined : 0,
                                borderBottomRightRadius:
                                    deleteProps?.isActionVisible || resendInviteProps?.isActionVisible ? undefined : 0,
                                width: itemWidth,
                            }}
                            onClick={() => {
                                onPermissionChosen('owner')
                                setPermissionsDropdownVisible(false)
                            }}
                        >
                            <Typography className={classes.itemTitle} sx={{ paddingLeft: 2, width: itemWidth }}>
                                Owner
                            </Typography>
                            {checkSelection && permission === 'owner' && (
                                <CheckIcon style={{ color: theme.palette.neutral.neutral2, paddingRight: 16 }} />
                            )}
                        </Box>
                    )}
                    {isViewPermissionVisible && (
                        <Box
                            className={classes.item}
                            style={{
                                borderBottomRightRadius: size === 'small' ? MAIN_BORDER_RADIUS : 0,
                                borderBottomLeftRadius: size === 'small' ? MAIN_BORDER_RADIUS : 0,
                                width: itemWidth,
                            }}
                            onClick={() => {
                                onPermissionChosen('view')
                                setPermissionsDropdownVisible(false)
                            }}
                        >
                            <Typography className={classes.itemTitle} sx={{ paddingLeft: 2, width: itemWidth }}>
                                View
                            </Typography>
                            {checkSelection && permission === 'view' && (
                                <CheckIcon style={{ color: theme.palette.neutral.neutral2, paddingRight: 16 }} />
                            )}
                        </Box>
                    )}
                    {isDividerVisible() ? <Box style={{ height: 1, display: 'flex', backgroundColor: '#555770' }} /> : null}
                    {resendInviteProps?.isActionVisible && (
                        <Box
                            className={classes.item}
                            style={{
                                borderBottomLeftRadius:
                                    deleteProps?.isActionVisible || leaveProps?.isActionVisible
                                        ? undefined
                                        : BORDER_RADIUS_SMALLER,
                                borderBottomRightRadius:
                                    deleteProps?.isActionVisible || leaveProps?.isActionVisible
                                        ? undefined
                                        : BORDER_RADIUS_SMALLER,
                                width: itemWidth,
                            }}
                            onClick={() => {
                                resendInviteProps.onClickEvent && resendInviteProps.onClickEvent()
                                setPermissionsDropdownVisible(false)
                            }}
                        >
                            <MailOutlineIcon
                                sx={{
                                    marginLeft: 2,
                                    color: theme.palette.neutral.neutral2,
                                    position: 'absolute',
                                }}
                            />
                            <Typography className={classes.itemTitle} sx={{ paddingLeft: 6, width: itemWidth }}>
                                Resend invite
                            </Typography>
                        </Box>
                    )}
                    {deleteProps?.isActionVisible && (
                        <Box
                            className={classes.item}
                            style={{
                                borderBottomLeftRadius: MAIN_BORDER_RADIUS,
                                borderBottomRightRadius: MAIN_BORDER_RADIUS,
                                width: itemWidth,
                            }}
                            onClick={() => {
                                deleteProps.onClickEvent && deleteProps.onClickEvent()
                                setPermissionsDropdownVisible(false)
                            }}
                        >
                            <DeleteOutlineIcon sx={{ marginLeft: 2, color: theme.palette.alert.main, position: 'absolute' }} />
                            <Typography className={classes.itemTitle} sx={{ paddingLeft: 6, width: itemWidth }}>
                                Remove
                            </Typography>
                        </Box>
                    )}
                    {leaveProps?.isActionVisible && (
                        <Box
                            className={classes.item}
                            style={{
                                borderBottomLeftRadius: BORDER_RADIUS_SMALLER,
                                borderBottomRightRadius: BORDER_RADIUS_SMALLER,
                                width: itemWidth,
                            }}
                            onClick={() => {
                                leaveProps.onClickEvent && leaveProps.onClickEvent()
                                setPermissionsDropdownVisible(false)
                            }}
                        >
                            <ExitToAppIcon sx={{ marginRight: theme.spacing(1), color: theme.palette.alert.main }} />
                            <Typography className={classes.itemTitle} style={{ width: itemWidth }}>
                                Leave
                            </Typography>
                        </Box>
                    )}
                </StyledBox>
            }
            open={permissionsDropdownVisible}
            onClose={() => setPermissionsDropdownVisible(false)}
        >
            <StyledArrow
                className={classes.emailRole}
                style={{ color: theme.palette.text.normal, cursor: disabled ? 'default' : 'pointer' }}
                onClick={() => {
                    !disabled ? setPermissionsDropdownVisible(!permissionsDropdownVisible) : null
                }}
            >
                <Text
                    color='light'
                    fontSize={16}
                    style={{ marginRight: theme.spacing(2), color: disabled ? theme.palette.neutral.neutral2 : undefined }}
                >
                    {capitalizeFirstLetter(permission)}
                </Text>

                <KeyboardArrowDownIcon
                    style={{
                        transform: permissionsDropdownVisible ? 'rotate(-180deg)' : undefined,
                        color: disabled ? theme.palette.neutral.neutral2 : undefined,
                    }}
                />
            </StyledArrow>
        </DropdownMenu>
    )
}

export default PermissionSelector
