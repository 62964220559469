import React, { useState } from 'react'
import { Box, Typography, useTheme, Button } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { RouteConstants } from 'navigation/navigation-types'
import { ReactComponent as AlfredLogo } from '../assets/images/alfred_logo.svg'
import Fonts from 'constants/fonts'
import ShareIcon from '@mui/icons-material/Share'
import GradientButton from 'components/GradientButton'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveProject } from 'services/selectors/projects-selectors'
import ShareProjectModal from 'components/ShareProjectModal/ShareProjectModal'
import { useHistory, useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import { getAuthState } from 'services/selectors/auth-selectors'
import { isShareModalOpen } from 'services/selectors/temporaryUiChanges-selectors'
import { setShareModalOpen } from 'services/actions/temporaryUiChanges-actions'
import DropdownMenu from 'components/DropdownMenu'
import ProfileDropdown from './ProfileDropdown'
import { logoutUser } from 'services/actions/auth-actions'
import { styled } from '@mui/material/styles'
import { handleTabNavigation, handleLinkClick } from 'utils/generic-utils'

const PREFIX = 'TopPanel'

const classes = {
    header: `${PREFIX}-header`,
    iconContainer: `${PREFIX}-iconContainer`,
}

export const OPENED_MENU_WIDTH = 264
export const TOP_PANEL_HEIGHT = 56

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.header}`]: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        position: 'fixed',
        width: '100%',
        height: TOP_PANEL_HEIGHT,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
    },
    [`& .${classes.iconContainer}`]: {
        height: 30,
        width: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 12,
    },
}))
interface TopPanelProps {
    handleGoTo: (link: string) => void
    isMobileView: boolean
    toggleMenu: () => void
    shouldHideSidePanel: boolean
}

const TopPanel = ({ handleGoTo, isMobileView, toggleMenu, shouldHideSidePanel }: TopPanelProps): JSX.Element => {
    const theme = useTheme()
    const project = useSelector(getActiveProject)
    const shareModalOpen = useSelector(isShareModalOpen)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const { isAuthenticated, userEmail } = useSelector(getAuthState)
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState<boolean>(false)

    const onGoToUserProfile = () => {
        setIsProfileDropdownOpen(false)
        handleGoTo(`${RouteConstants.user}${RouteConstants.profile}`)
    }

    const onGoToProjectsOverview = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        dispatch(setShareModalOpen(false))

        if (history.location.pathname === RouteConstants.projectsList && project) {
            handleTabNavigation(handleLinkClick(e), `${RouteConstants.project}/${project.id}`, () =>
                handleGoTo(`${RouteConstants.project}/${project.id}`)
            )
        } else {
            handleTabNavigation(handleLinkClick(e), RouteConstants.projectsList, () => handleGoTo(RouteConstants.projectsList))
        }
    }

    const onGoToSharedCode = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (project) {
            handleTabNavigation(handleLinkClick(e), `${RouteConstants.project}/${project.id}${RouteConstants.shared}`, () =>
                handleGoTo(`${RouteConstants.project}/${project.id}${RouteConstants.shared}`)
            )
        }
    }

    const onLogout = () => {
        setIsProfileDropdownOpen(false)
        dispatch(logoutUser())
        history.push(RouteConstants.login)
    }

    return (
        <>
            <StyledBox className={classes.header}>
                <Box
                    style={{
                        height: TOP_PANEL_HEIGHT,
                        width: OPENED_MENU_WIDTH,
                        display: 'flex',
                        alignItems: 'center',
                        flex: isMobileView ? 1 : undefined,
                    }}
                >
                    <AlfredLogo style={{ marginLeft: 20 }} />
                    <Typography
                        style={{
                            fontSize: Fonts.size.small,
                            color: theme.palette.accent.light,
                            marginLeft: 20,
                            cursor: 'default',
                            fontFamily: 'Zilla Slab',
                        }}
                    >
                        A L F R E D
                    </Typography>
                </Box>
                {(isAuthenticated || project?.isSharedForView) && (
                    <>
                        {!isMobileView && (
                            <Box style={{ display: 'flex', flex: 1, marginLeft: 20, alignItems: 'center' }}>
                                {isAuthenticated && (
                                    <Typography
                                        id='Projects'
                                        style={{ color: theme.palette.primary.light, cursor: 'pointer', marginRight: 24 }}
                                        onMouseDown={onGoToProjectsOverview}
                                    >
                                        Projects
                                    </Typography>
                                )}

                                {project &&
                                    history.location.pathname !== RouteConstants.projectsList &&
                                    (isAuthenticated || project.isSharedForView) && (
                                        <Typography
                                            id='SharedCode'
                                            style={{
                                                color: theme.palette.primary.light,
                                                cursor: 'pointer',
                                                marginLeft: theme.spacing(3),
                                            }}
                                            onMouseDown={onGoToSharedCode}
                                        >
                                            Shared code
                                        </Typography>
                                    )}
                            </Box>
                        )}
                        {shareModalOpen && project && isAuthenticated && (
                            <ShareProjectModal
                                title={project.name}
                                shareLink={`${window.location.origin}/project/${project.id}`}
                                onRequestClose={() => dispatch(setShareModalOpen(false))}
                                isOpen={shareModalOpen && !!project}
                            />
                        )}
                        {/* Hide for project list screen */}
                        {project && !(location.pathname === RouteConstants.projectsList) && isAuthenticated && (
                            <GradientButton
                                size='small'
                                title='Share'
                                style={{ width: 108 }}
                                startIcon={<ShareIcon style={{ fontSize: Fonts.size.large }} />}
                                onClick={() => {
                                    if (project) {
                                        dispatch(setShareModalOpen(true))
                                    }
                                }}
                            />
                        )}
                        {isAuthenticated && (
                            <DropdownMenu
                                open={isProfileDropdownOpen}
                                onClose={() => setIsProfileDropdownOpen(false)}
                                placement='bottom-start'
                                content={
                                    <ProfileDropdown email={userEmail} onLogout={onLogout} onProfilePress={onGoToUserProfile} />
                                }
                            >
                                <Box className={classes.iconContainer} style={{ marginLeft: 16, marginRight: 12 }}>
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        borderRadius={15}
                                        bgcolor={theme.palette.secondary.light}
                                        border={`1px solid ${theme.palette.secondary.main}`}
                                        height={30}
                                        width={30}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => setIsProfileDropdownOpen(true)}
                                    >
                                        <PersonIcon sx={{ color: theme.palette.secondary.main }} />
                                    </Box>
                                </Box>
                            </DropdownMenu>
                        )}
                    </>
                )}
                {!isAuthenticated && (
                    <>
                        <Box sx={{ flex: 1 }} />
                        <Button
                            id='log-in-button'
                            variant='outlined'
                            style={{
                                fontSize: Fonts.size.normal,
                                height: 40,
                                marginRight: theme.spacing(2),
                                color: theme.palette.primary.light,
                                borderColor: theme.palette.primary.light,
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                history.push(RouteConstants.login)
                            }}
                        >
                            Log in
                        </Button>
                        <GradientButton
                            size='small'
                            title='Register'
                            onClick={() => {
                                history.push(RouteConstants.login)
                            }}
                        />
                        <Box sx={{ width: 20 }} />
                    </>
                )}
            </StyledBox>
            {isMobileView && (isAuthenticated || process.env.NODE_ENV === 'development') && (
                <>
                    {shouldHideSidePanel && <Box style={{ height: 56 }} />}
                    {!shouldHideSidePanel && (
                        <Box
                            style={{
                                height: 40,
                                marginTop: TOP_PANEL_HEIGHT,
                                backgroundColor: theme.palette.background.paper,
                                borderTop: `1px solid ${theme.palette.neutral.dark2}`,
                                borderBottom: `1px solid ${theme.palette.neutral.dark2}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <MenuIcon style={{ color: theme.palette.text.faded, cursor: 'pointer' }} onClick={toggleMenu} />

                            {project && history.location.pathname !== RouteConstants.projectsList && (
                                <Typography
                                    id='SharedCode'
                                    style={{
                                        color: theme.palette.primary.light,
                                        cursor: 'pointer',
                                    }}
                                    onMouseDown={onGoToSharedCode}
                                >
                                    Shared code
                                </Typography>
                            )}
                            <Typography
                                style={{ color: theme.palette.primary.light, cursor: 'pointer' }}
                                onMouseDown={onGoToProjectsOverview}
                            >
                                Projects
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </>
    )
}

export default TopPanel
