import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { matchPath, Redirect } from 'react-router'
import SidePanel from './SidePanel'
import { Box, useTheme } from '@mui/material'
import TopPanel from './TopPanel'
import { LoadProjectState, RouteParameters, RouteConstants } from 'navigation/navigation-types'
import { useSelector } from 'react-redux'
import { getActiveProject } from 'services/selectors/projects-selectors'
import { propertyOf } from 'shared/utils/generic-utils'
import { Project } from 'shared/types/project-types'
import useIsMobileView from 'hooks/useIsMobileView'
import { styled } from '@mui/material/styles'

const PREFIX = 'Navigation'

const classes = {
    mainContainer: `${PREFIX}-mainContainer`,
    childrenContainer: `${PREFIX}-childrenContainer`,
}

export const OPENED_MENU_WIDTH = 264
export const TOP_PANEL_HEIGHT = 56

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.mainContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        minHeight: '100vh',
        overflow: 'hidden',
    },
    [`& .${classes.childrenContainer}`]: {
        backgroundColor: theme.palette.background.default,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
}))

interface SideNavigationProps {
    children?: React.ReactNode
}

/**
 * If a accessed url is project related, we need to load it to redux if it's not present.
 * This is related to the auth logic and protected routes. A project can be shared for view
 * with anonymous users thus we need to know if this is such case before simply restricting
 * accessing the project.
 */
const shouldRedirectToLoadProject: (
    path: string,
    project: Project | undefined
) => { shouldRedirectToLoad: boolean; redirectProjectId: string; originalPath: string } = (path, project) => {
    const match = matchPath<RouteParameters>(path, {
        path: `${RouteConstants.project}/:${propertyOf<RouteParameters>('projectId')}`,
        exact: false,
        strict: false,
    })
    if (match) {
        const projectId = match.params.projectId
        if (!project || projectId !== project.id)
            return {
                redirectProjectId: projectId,
                shouldRedirectToLoad: true,
                originalPath: path,
            }
        else
            return {
                shouldRedirectToLoad: false,
                redirectProjectId: '',
                originalPath: '',
            }
    }
    return {
        shouldRedirectToLoad: false,
        redirectProjectId: '',
        originalPath: '',
    }
}

const Navigation: React.FC<SideNavigationProps> = ({ children }: SideNavigationProps) => {
    const theme = useTheme()
    const history = useHistory()
    const location = useLocation()
    const activeProject = useSelector(getActiveProject)
    const isMobileView = useIsMobileView(theme)
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(true)
    const shouldHideSidePanel =
        location.pathname.includes(RouteConstants.projectsList) ||
        location.pathname.includes(RouteConstants.unauthenticated) ||
        location.pathname.includes(RouteConstants.login) ||
        location.pathname.includes(RouteConstants.projectLoading) ||
        location.pathname === RouteConstants.home ||
        !activeProject
    const { shouldRedirectToLoad, redirectProjectId, originalPath } = shouldRedirectToLoadProject(
        location.pathname,
        activeProject
    )

    const toggleMenu = () => {
        setIsMenuOpened(!isMenuOpened)
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= theme.breakpoints.values.sm !== isMenuOpened) {
                setIsMenuOpened(true)
            } else {
                setIsMenuOpened(false)
            }
        }
        window.removeEventListener('resize', handleResize)
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleGoTo = (link: string): void => {
        history.push(link)
    }

    const loadProjectState: LoadProjectState = {
        originalPath: originalPath,
    }

    return (
        <StyledBox className={classes.mainContainer}>
            {!shouldHideSidePanel && isMenuOpened && (
                <SidePanel isMobileView={isMobileView} handleGoTo={handleGoTo} toggleMenu={toggleMenu} />
            )}
            <TopPanel
                isMobileView={isMobileView}
                handleGoTo={handleGoTo}
                toggleMenu={toggleMenu}
                shouldHideSidePanel={shouldHideSidePanel}
            />
            {shouldRedirectToLoad && (
                <Redirect
                    to={{
                        pathname: `${RouteConstants.projectLoading}/${redirectProjectId}`,
                        state: loadProjectState,
                    }}
                />
            )}
            {!shouldRedirectToLoad && (
                <Box
                    className={classes.childrenContainer}
                    style={{
                        marginTop: isMobileView ? 0 : TOP_PANEL_HEIGHT,
                        marginLeft: isMenuOpened && !isMobileView && !shouldHideSidePanel ? OPENED_MENU_WIDTH : 0,
                    }}
                >
                    {children}
                </Box>
            )}
        </StyledBox>
    )
}

export default Navigation
