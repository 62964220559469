import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, useTheme, Typography, Tooltip } from '@mui/material'
import { useDrag, useDrop } from 'react-dnd'
import { RouteConstants } from './navigation-types'
import { useDispatch, useSelector } from 'react-redux'
import { moveEndpoint, updateEndpointDetail } from 'services/actions/project-actions'
import Fonts from 'constants/fonts'
import Text from 'components/Text'
import { Endpoint, RequestType } from 'shared/types/project-types'
import { ReduxState } from 'services/types/mainReducer-types'
import { getActiveProject, getEndpointById } from 'services/selectors/projects-selectors'
import NameInput from './NameInput'
import DropdownMenu from 'components/DropdownMenu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import OptionsDropdown, { MenuDropdownOptionType } from './OptionsDropdown'
import { handleLinkClick } from 'utils/generic-utils'

const PREFIX = 'SubMenuItem'

const classes = {
    subMenuBox: `${PREFIX}-subMenuBox`,
    text: `${PREFIX}-text`,
    hover: `${PREFIX}-hover`,
}

export const MENU_ITEM_HEIGHT = 32

const StyledBox = styled(Box)(() => ({
    [`& .${classes.subMenuBox}`]: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginLeft: 16,
    },

    [`& .${classes.text}`]: {
        fontSize: Fonts.size.small,
    },

    [`&.${classes.hover}`]: {
        '&:hover': {
            backgroundColor: '#C690F166',
        },
    },
}))

interface SubMenuItemProps {
    endpointId: string
    collectionId: string
    getNameForEmptyEndpoint: (collectionId: string) => string
    validateEndpointName: (name: string, collectionId: string, endpointId?: string) => boolean
    title: string
    route: RouteConstants | string
    onEndpointDuplicate: () => void
    onSubMenuItemPress: (openInNewTab: boolean) => void
    requestType: RequestType
    toggleDropdown: () => void
    isDropdownOpen: boolean
    isInEditedState: boolean
    toggleEditedStateFor: (value: string) => void
    handleGoTo: (title: string) => void
    onEndpointRemove: (endpointId: string) => void
    setEndpointIdToRename: (endpointId: string) => void
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({
    endpointId,
    collectionId,
    getNameForEmptyEndpoint,
    validateEndpointName,
    title,
    route,
    onEndpointDuplicate,
    onSubMenuItemPress,
    requestType,
    toggleDropdown,
    isDropdownOpen,
    toggleEditedStateFor,
    handleGoTo,
    isInEditedState,
    onEndpointRemove,
    setEndpointIdToRename,
}) => {
    const isSubMenuActive = location.pathname.includes(route)

    const theme = useTheme()
    const dispatch = useDispatch()
    const activeProject = useSelector(getActiveProject)
    const endpoint = useSelector((state: ReduxState) => getEndpointById(state, endpointId))
    const [newName, setNewName] = useState<string>(endpoint?.name || '')
    const [showButtons, setShowButtons] = useState<boolean>(false)
    const [isNameDuplicate, setIsNameDuplicate] = useState(false)

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'subMenuItem',
        item: { endpointId },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'subMenuItem',
        drop: (item: { endpointId: string }) => onDrop(item.endpointId, endpointId),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }))

    const onDrop = async (movedEndpointId: string, dropEndpointId: string) => {
        if (movedEndpointId === dropEndpointId) return

        dispatch(moveEndpoint(movedEndpointId, dropEndpointId))

        // If endpoint was opened before moving, go to new route (it can be moved here from another collection)
        const wasEndpointActive = location.pathname.includes(movedEndpointId)
        if (activeProject && wasEndpointActive)
            handleGoTo(`${RouteConstants.project}/${activeProject.id}/${collectionId}/${movedEndpointId}`)
    }

    const onRenameHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(e.target.value)
        setIsNameDuplicate(validateEndpointName(e.target.value, collectionId, endpointId))
    }

    const onBlur = () => {
        if (isNameDuplicate) {
            setNewName(endpoint?.name || '')
        } else {
            if (endpoint) {
                const copy: Endpoint = { ...endpoint }
                copy.name = newName === '' ? getNameForEmptyEndpoint(collectionId) : newName
                dispatch(updateEndpointDetail(copy))
            }
        }
        setEndpointIdToRename('')
        toggleEditedStateFor('')
        setIsNameDuplicate(false)
    }

    const RequestTypeColors = {
        [RequestType.GET]: theme.palette.blue.main,
        [RequestType.POST]: theme.palette.accent.main,
        [RequestType.PUT]: theme.palette.secondary.main,
        [RequestType.DELETE]: theme.palette.alert.main,
    }

    const onOptionSelect = (option: MenuDropdownOptionType) => {
        if (option === 'rename') {
            setEndpointIdToRename(endpointId)
        } else if (option === 'delete') {
            onEndpointRemove(endpointId)
        } else if (option === 'duplicate') {
            onEndpointDuplicate()
        }
        toggleDropdown()
    }

    return (
        <StyledBox
            ref={drop}
            className={classes.hover}
            style={{ backgroundColor: isSubMenuActive ? theme.palette.primary.main : undefined }}
        >
            <Tooltip
                title={'This name already exist in the same collection'}
                open={isNameDuplicate}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <Box
                    ref={drag}
                    style={{
                        backgroundColor: isDragging ? theme.palette.action.hover : 'initial',
                        borderTop: isOver ? `1px solid ${theme.palette.text.normal}` : 'initial',
                        height: MENU_ITEM_HEIGHT,
                    }}
                    key={endpointId}
                    className={classes.subMenuBox}
                    onMouseDown={(e) => {
                        e.stopPropagation()
                        onSubMenuItemPress(handleLinkClick(e))
                    }}
                    onMouseEnter={() => setShowButtons(true)}
                    onMouseLeave={() => setShowButtons(false)}
                >
                    <Box style={{ textAlign: 'end' }}>
                        <Typography
                            color={isSubMenuActive ? theme.palette.neutral.neutral1 : RequestTypeColors[requestType]}
                            fontSize={10}
                            style={{ marginRight: 8, width: 43 }}
                        >
                            {requestType === RequestType.DELETE ? 'DEL' : requestType}
                        </Typography>
                    </Box>
                    {!isInEditedState && (
                        <Text
                            color={isSubMenuActive ? theme.palette.neutral.neutral1 : theme.palette.neutral.light1}
                            fontSize={14}
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {title}
                        </Text>
                    )}
                    {isInEditedState && (
                        <Box
                            style={{
                                height: MENU_ITEM_HEIGHT,
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <NameInput value={newName} handleChange={onRenameHandle} onBlur={onBlur} />
                        </Box>
                    )}
                    <Box sx={{ flex: 1 }} />
                    {(showButtons || isDropdownOpen) && (
                        <Box style={{ marginRight: 12, display: 'flex' }}>
                            <DropdownMenu
                                open={isDropdownOpen}
                                borderRadius={8}
                                onClose={toggleDropdown}
                                content={<OptionsDropdown onOptionSelect={onOptionSelect} />}
                            >
                                <MoreVertIcon
                                    style={{
                                        color: isSubMenuActive ? theme.palette.neutral.neutral1 : theme.palette.neutral.light1,
                                        fontSize: 22,
                                    }}
                                    onClick={(e) => {
                                        toggleDropdown()
                                        e.stopPropagation()
                                    }}
                                />
                            </DropdownMenu>
                        </Box>
                    )}
                </Box>
            </Tooltip>
        </StyledBox>
    )
}

export default SubMenuItem
