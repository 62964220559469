import { getEmptyAuthState } from './auth-types'
import { ReduxState } from './mainReducer-types'
import { SELECTED_THEME_KEY, ThemeType, themeTypes } from './userSettings-types'

function getInitialSelectedTheme(): ThemeType {
    const selectedThemeFromLocalStorage = localStorage.getItem(SELECTED_THEME_KEY)
    if (selectedThemeFromLocalStorage && themeTypes.includes(selectedThemeFromLocalStorage as ThemeType)) {
        return selectedThemeFromLocalStorage as ThemeType
    }
    return 'auto'
}

const initState: ReduxState = {
    flashMessages: {
        flashMessages: [],
    },
    projects: {
        active: undefined,
        openedCollections: [],
        isSyncingWithServer: false,
    },
    userSettings: {
        selectedMode: 'edit',
        selectedTheme: getInitialSelectedTheme(),
        editorSettings: {
            prefillResponseBodyOnCreate: true,
            prefillRequestBodyOnCreate: true,
        },
        openApiSettings: {
            dateType: 'date-time',
        },
    },
    auth: getEmptyAuthState(),
    temporaryUiChanges: {
        isShareModalOpen: false,
    },
}

export default initState
