import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import ModalBase from './ModalBase'
import EmptyButton from './EmptyButton'
import GradientButton from './GradientButton'
import Text from './Text'
import StyledTextField from './StyledTextField'

const PREFIX = 'RenameProjectModal'

const classes = {
    textfield: `${PREFIX}-textfield`,
    container: `${PREFIX}-container`,
    title: `${PREFIX}-title`,
    secondTitle: `${PREFIX}-secondTitle`,
    buttonsContainer: `${PREFIX}-buttonsContainer`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.textfield}`]: {
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.neutral.dark2,
            '& fieldset': {
                borderWidth: 0,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.light,
            },
        },
    },

    [`&.${classes.container}`]: {
        width: 440,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        padding: 24,
        borderRadius: 16,
        border: `1px solid ${theme.palette.neutral.dark1}`,
        boxShadow: theme.palette.shadow.main,
    },

    [`& .${classes.title}`]: {
        fontWeight: 'bold',
        marginBottom: 32,
    },

    [`& .${classes.secondTitle}`]: {
        fontWeight: 'bold',
        marginBottom: 18,
    },

    [`& .${classes.buttonsContainer}`]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 28,
    },
}))

interface RenameProjectModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: (newUrl: string) => void
    projectName: string
}

const RenameProjectModal: React.FC<RenameProjectModalProps> = ({ isOpen, onClose, onConfirm, projectName }) => {
    const [name, setName] = useState<string>(projectName)

    const onRequestUrlChange = (requestUrl: string) => {
        setName(requestUrl)
    }

    const onModalClose = () => {
        setName(projectName)
        onClose()
    }

    useEffect(() => {
        if (name !== projectName) setName(projectName)
    }, [projectName])

    return (
        <ModalBase
            center
            isOpen={isOpen}
            onClose={onModalClose}
            style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}
        >
            <StyledBox className={classes.container}>
                <Text color='light' fontSize={20} className={classes.title}>
                    Dou you wish to change the project name?
                </Text>
                <Text color='light' fontSize={16} className={classes.secondTitle}>
                    New name
                </Text>
                <StyledTextField onChange={(e) => onRequestUrlChange(e.target.value)} value={name} autoFocus />
                <Box className={classes.buttonsContainer}>
                    <GradientButton
                        title='Confirm changes'
                        onClick={() => {
                            onConfirm(name)
                            onClose()
                        }}
                    ></GradientButton>
                    <EmptyButton text='Cancel' onClick={onModalClose} style={{ marginRight: 20 }} />
                </Box>
            </StyledBox>
        </ModalBase>
    )
}

export default RenameProjectModal
