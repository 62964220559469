import { Box, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import ProjectCard from 'components/ProjectCard'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BackgroundImage, Project } from 'shared/types/project-types'
import TypewriterImage from 'images/typewriter-image'
import TypewriterImagePressed from 'images/typewriter-image-pressed'
import { ProjectsManager } from 'services/api/ProjectsManager'
import { ProjectMetadataPayload } from 'shared/payload-types/project-payloads'
import { showFlashMessage } from 'services/actions/flashMessage-actions'
import { useHistory } from 'react-router-dom'
import { RouteConstants } from 'navigation/navigation-types'
import CreateProjectModal from './CreateProjectModal'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import GradientButton from 'components/GradientButton'
import { setActiveProject, setOpenedCollectionsIds } from 'services/actions/project-actions'
import ProjectDetailsModal from 'components/ProjectDetailsModal'
import ShareProjectModal from 'components/ShareProjectModal/ShareProjectModal'
import CircularProgress from '@mui/material/CircularProgress'
import Text from 'components/Text'
import DropdownMenu from 'components/DropdownMenu'
import SortDropdown, { OrderFilterType, SortFilterType } from './SortDropdown'
import { getErrorMessage } from 'shared/utils/generic-utils'
import { isShareModalOpen } from 'services/selectors/temporaryUiChanges-selectors'
import { setShareModalOpen } from 'services/actions/temporaryUiChanges-actions'
import DeleteModal from 'components/DeleteModal'
import { handleTabNavigation } from 'utils/generic-utils'

const PREFIX = 'ProjectsOverview'

const classes = {
    container: `${PREFIX}-container`,
    rowContainer: `${PREFIX}-rowContainer`,
    editBox: `${PREFIX}-editBox`,
    uppercaseText: `${PREFIX}-uppercaseText`,
    text: `${PREFIX}-text`,
    createCard: `${PREFIX}-createCard`,
    projectsContainer: `${PREFIX}-projectsContainer`,
    loadingBox: `${PREFIX}-loadingBox`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginLeft: 24,
        marginRight: 24,
        marginTop: 24,
    },

    [`& .${classes.rowContainer}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
    },

    [`& .${classes.editBox}`]: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: 157,
        justifyContent: 'end',
    },

    [`& .${classes.uppercaseText}`]: {
        textTransform: 'uppercase',
    },

    [`& .${classes.text}`]: {
        color: theme.palette.text.normal,
    },

    [`& .${classes.createCard}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: 210,
        height: 202,
        border: `3px solid ${theme.palette.neutral.unique2}`,
        '&:hover': {
            border: `3px solid ${theme.palette.neutral.light1}`,
            backgroundColor: theme.palette.background.paper,
        },
        borderRadius: 10,
        boxShadow: theme.palette.shadow.secondary,
        backgroundColor: theme.palette.background.default,
    },

    [`& .${classes.projectsContainer}`]: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    [`& .${classes.loadingBox}`]: {
        width: 212,
        height: 204,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
        marginBottom: 16,
    },
}))

const getStringFromSelectedFilter = (sortFilter: SortFilterType, orderFilter: OrderFilterType) => {
    let a = ''
    let b = ''
    if (sortFilter === 'alphabetical') a = 'Alphabetical'
    if (sortFilter === 'createDate') a = 'Date Created'
    if (sortFilter === 'editDate') a = 'Edited'
    if (sortFilter === 'lastViewDate') a = 'Last viewed'
    if (orderFilter === 'ascending') {
        b = 'ASC'
    } else b = 'DESC'
    return `${a} (${b})`
}

const ProjectsOverview: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const theme = useTheme()
    const shareModalOpen = useSelector(isShareModalOpen)

    // Holds id and name of project for delete modal or undefined if modal is closed
    const [deleteModalInfo, setDeleteModalInfo] = useState<{ id: string; name: string } | undefined>(undefined)

    const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState<boolean>(false)
    const [allProjects, setAllProjects] = useState<ProjectMetadataPayload[]>([])

    const [sortOpen, setSortOpen] = useState(false)
    const [detailsOfProject, setDetailOfProject] = useState<ProjectMetadataPayload | undefined>(undefined)
    const [shareParams, setShareParams] = useState<{ projectId: string; projectTitle: string } | undefined>(undefined)
    // Holds project name, backgroundImage and id to edit
    const [renameProjectModalData, setRenameProjectModalData] = useState<ProjectMetadataPayload | undefined>(undefined)
    // Used to change icon and border color of create project card
    const [isPressed, setIsPressed] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    // Filter settings
    const [selectedSortFilter, setSelectedSortFilter] = useState<SortFilterType>('editDate')
    const [selectedOrderFilter, setSelectedOrderFilter] = useState<OrderFilterType>('ascending')

    useEffect(() => {
        const fetchAllProjects = async () => {
            try {
                setIsLoading(true)
                const _projectManager = ProjectsManager.getManager()
                const data = await _projectManager.fetchAllProjectsAsync()
                setAllProjects(data.projects)
                setIsLoading(false)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch {
                setIsLoading(false)
                dispatch(showFlashMessage('Could not fetch projects from server.', 'error'))
            }
        }

        fetchAllProjects()
    }, [])

    const onDelete = async (id: string) => {
        try {
            const _projectManager = ProjectsManager.getManager()
            await _projectManager.deleteProjectAsync(id)
            setAllProjects((prevState) => {
                const result = prevState?.filter((ps) => ps.id !== id)
                return result
            })
            setDeleteModalInfo(undefined)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }

    const onModalClose = () => {
        setIsCreateProjectModalOpen(false)
        setRenameProjectModalData(undefined)
    }

    const onProjectCreateHandler = (project: Project) => {
        const result: ProjectMetadataPayload = {
            id: project.id,
            name: project.name,
            baseUrl: project.baseUrl,
            collectionsCount: project.collections.length,
            backgroundImage: project.backgroundImage,
            createdAt: new Date(),
            lastModifiedAt: new Date(),
        }
        setAllProjects([...allProjects, result])
    }

    const onProjectCardClick = (openInNewTab: boolean, projectId: string) => {
        dispatch(setActiveProject(undefined))
        dispatch(setOpenedCollectionsIds([]))
        handleTabNavigation(openInNewTab, `${RouteConstants.project}/${projectId}`, () =>
            history.push(`${RouteConstants.project}/${projectId}`)
        )
    }

    const onShareClick = (project: ProjectMetadataPayload) => {
        setShareParams({ projectId: project.id, projectTitle: project.name })
        dispatch(setShareModalOpen(true))
    }

    const handleProjectNameChange = async (newName: string, background: BackgroundImage) => {
        setAllProjects((prevState: ProjectMetadataPayload[]) => {
            const project = prevState.find((ps) => ps.id === renameProjectModalData?.id)
            if (project) {
                project.name = newName
                project.backgroundImage = background
            }
            return prevState
        })
        if (renameProjectModalData?.id) {
            try {
                const projectManager = ProjectsManager.getManager()
                const project = await projectManager.getSingleProjectByIdAsync(renameProjectModalData.id)
                project.name = newName
                project.backgroundImage = background
                await projectManager.updateProjectAsync(project)
            } catch (e) {
                dispatch(showFlashMessage('Could not update project name.', 'error'))
            }
        }
    }

    const getSortedProjects = (
        sortFilter: SortFilterType,
        orderFilter: OrderFilterType,
        projectsList: ProjectMetadataPayload[]
    ): ProjectMetadataPayload[] => {
        let result: ProjectMetadataPayload[] = []
        // Sort filter
        if (sortFilter === 'alphabetical') {
            result = projectsList.sort((a, b) => a.name.localeCompare(b.name))
        }
        if (sortFilter === 'createDate') {
            result = projectsList.sort((a, b) => Date.parse(b.createdAt.toString()) - Date.parse(a.createdAt.toString()))
        }
        if (sortFilter === 'editDate') {
            result = projectsList.sort(
                (a, b) => Date.parse(b.lastModifiedAt.toString()) - Date.parse(a.lastModifiedAt.toString())
            )
        }
        if (sortFilter === 'lastViewDate') {
            // Whole logic for this is not implemented yet
            result = projectsList
        }
        // Order filter
        if (orderFilter === 'descending') {
            return result.reverse()
        }
        return result
    }

    const sortedProjects = getSortedProjects(selectedSortFilter, selectedOrderFilter, allProjects)

    return (
        <>
            <StyledBox className={classes.container}>
                <Box className={classes.rowContainer}>
                    <Text color='normal' fontSize={14} className={classes.uppercaseText}>
                        Your projects
                    </Text>
                </Box>
                <Box className={classes.rowContainer}>
                    <Box />
                    <DropdownMenu
                        placement='bottom-end'
                        open={sortOpen}
                        borderRadius={8}
                        onClose={() => setSortOpen(false)}
                        content={
                            <SortDropdown
                                selectedOrderFilter={selectedOrderFilter}
                                selectedSortFilter={selectedSortFilter}
                                onSortFilterSelect={(filter: SortFilterType) => setSelectedSortFilter(filter)}
                                onOrderFilterSelect={(filter: OrderFilterType) => setSelectedOrderFilter(filter)}
                            />
                        }
                    >
                        <Box
                            className={classes.editBox}
                            onClick={() => {
                                setSortOpen(!sortOpen)
                            }}
                        >
                            <Text color='light' fontSize={14} style={{ userSelect: 'none' }}>
                                {getStringFromSelectedFilter(selectedSortFilter, selectedOrderFilter)}
                            </Text>
                            <ArrowDropDownIcon className={classes.text} />
                        </Box>
                    </DropdownMenu>
                </Box>
                <Box>
                    <Box className={classes.projectsContainer}>
                        {isLoading && (
                            <Box className={classes.loadingBox}>
                                <CircularProgress />
                            </Box>
                        )}
                        {sortedProjects &&
                            sortedProjects.map((p, i) => (
                                <ProjectCard
                                    project={p}
                                    key={i}
                                    onDelete={() => setDeleteModalInfo({ id: p.id, name: p.name })}
                                    onProjectSelect={onProjectCardClick}
                                    onDetailsPress={(project) => setDetailOfProject(project)}
                                    onShare={(project) => onShareClick(project)}
                                    onProjectRename={(project) => {
                                        setIsCreateProjectModalOpen(true)
                                        setRenameProjectModalData(project)
                                    }}
                                />
                            ))}
                        {!isLoading && (
                            <Box
                                className={classes.createCard}
                                style={{ border: isPressed ? `3px solid ${theme.palette.neutral.light3}` : undefined }}
                            >
                                {isPressed ? <TypewriterImagePressed /> : <TypewriterImage />}
                                <GradientButton
                                    size='small'
                                    title='Create project'
                                    disableElevation
                                    width={138}
                                    onClick={() => setIsCreateProjectModalOpen(true)}
                                    onMouseDown={() => setIsPressed(true)}
                                    onMouseUp={() => setIsPressed(false)}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </StyledBox>
            <CreateProjectModal
                isOpen={isCreateProjectModalOpen}
                onClose={onModalClose}
                onProjectCreateHandler={onProjectCreateHandler}
                project={renameProjectModalData}
                handleProjectNameChange={handleProjectNameChange}
            />
            <DeleteModal
                isOpen={!!deleteModalInfo}
                onClose={() => setDeleteModalInfo(undefined)}
                onDelete={() => deleteModalInfo && onDelete(deleteModalInfo.id)}
                name={deleteModalInfo?.name}
                type='project'
            />
            {detailsOfProject && (
                <ProjectDetailsModal
                    isOpen={!!detailsOfProject}
                    onClose={() => setDetailOfProject(undefined)}
                    project={detailsOfProject}
                />
            )}
            {shareModalOpen && shareParams && (
                <ShareProjectModal
                    title={shareParams.projectTitle}
                    shareLink={`${window.location.origin}/project/${shareParams.projectId}`}
                    onRequestClose={() => dispatch(setShareModalOpen(false))}
                    isOpen={shareModalOpen}
                />
            )}
        </>
    )
}

export default ProjectsOverview
