import * as React from 'react'

function EmailAvatar(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={32} height={33} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <circle cx={16} cy={16.002} r={15.5} fill='#FFAF6B' stroke='#FF851D' />
            <path
                d='M16 16.002c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'
                fill='#FF851D'
            />
        </svg>
    )
}

export default EmailAvatar
