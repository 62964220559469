import { Box, Button, InputBase, Stack, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, useState } from 'react'
import GradientButton from './GradientButton'

const PREFIX = 'TextEditor'

const classes = {
    container: `${PREFIX}-container`,
    input: `${PREFIX}-input`,
    errorInput: `${PREFIX}-errorInput`,
    buttonRow: `${PREFIX}-buttonRow`,
    cancelButton: `${PREFIX}-cancelButton`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },

    [`& .${classes.input}`]: {
        backgroundColor: theme.palette.input.mainBackground,
        borderRadius: 3,
        padding: theme.spacing(1),
        border: `2px solid ${theme.palette.primary.light}`,
    },

    [`& .${classes.errorInput}`]: {
        backgroundColor: theme.palette.input.mainBackground,
        borderRadius: 3,
        padding: theme.spacing(1),
        border: `2px solid ${theme.palette.error.main}`,
    },

    [`& .${classes.buttonRow}`]: {
        display: 'flex',
        alignSelf: 'flex-end',
        alignItems: 'center',
        padding: theme.spacing(1),
    },

    [`& .${classes.cancelButton}`]: {
        marginRight: 30,
    },
}))

interface TextEditorProps {
    onSave: (text?: string) => void
    onCancel: () => void
    onChange?: (text: string) => void
    defaultValue?: string
    errorMessage?: string
    multiline?: boolean
    fontSize?: number | string
    bold?: boolean
    saveOnEnter?: boolean
    saveOnBlur?: boolean
}

const TextEditor: FC<TextEditorProps> = ({
    onSave,
    onCancel,
    onChange,
    defaultValue,
    errorMessage,
    multiline,
    fontSize,
    bold,
    saveOnEnter,
    saveOnBlur,
}) => {
    const [text, setText] = useState<string | undefined>(defaultValue)

    const theme = useTheme()
    return (
        <StyledBox className={classes.container}>
            <InputBase
                className={errorMessage ? classes.errorInput : classes.input}
                multiline={multiline}
                value={text}
                minRows={multiline ? 3 : undefined}
                onChange={(e) => {
                    onChange && onChange(e.target.value)
                    setText(e.target.value)
                }}
                onBlur={() => saveOnBlur && onSave(text)}
                autoFocus={true}
                inputProps={{
                    style: {
                        fontSize: fontSize,
                        color: theme.palette.text.normal,
                        fontWeight: bold ? 'bold' : undefined,
                        padding: 0,
                    },
                }}
                onKeyDown={(e) => {
                    if ((e.key === 'Enter' && !multiline && saveOnEnter) || ((e.ctrlKey || e.metaKey) && e.key === 's')) {
                        e.preventDefault()
                        onSave(text)
                    }
                }}
            />
            <Typography variant='caption' color='error'>
                {errorMessage}
            </Typography>
            <Box className={classes.buttonRow}>
                <Stack direction='row'>
                    <Box className={classes.cancelButton}>
                        <Button onMouseDown={onCancel} style={{ textTransform: 'none' }}>
                            Cancel
                        </Button>
                    </Box>
                    <GradientButton
                        title='Save'
                        size='small'
                        color='primary'
                        onClick={() => onSave(text)}
                        disabled={errorMessage ? true : false}
                    />
                </Stack>
            </Box>
        </StyledBox>
    )
}

export default TextEditor

export {}
