import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'
import { getAuthState } from 'services/selectors/auth-selectors'
import { getActiveProject } from 'services/selectors/projects-selectors'
import { RouteConstants } from './navigation-types'

export default function ProtectedRoute({ ...routeProps }: RouteProps): JSX.Element {
    const { isAuthenticated } = useSelector(getAuthState)
    const activeProject = useSelector(getActiveProject)

    if (isAuthenticated || activeProject?.isSharedForView) {
        return <Route {...routeProps} />
    } else {
        return <Redirect to={{ pathname: RouteConstants.unauthenticated }} />
    }
}
