import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, Tooltip, useTheme } from '@mui/material'
import { ProjectMetadataPayload } from 'shared/payload-types/project-payloads'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { getBackgroundImage } from 'shared/utils/project-utils'
import Text from './Text'
import moment from 'moment'
import DropdownMenu from './DropdownMenu'
import Fonts from 'constants/fonts'
import { MAIN_BORDER_RADIUS } from 'constants/ui'
import { handleLinkClick } from 'utils/generic-utils'

const PREFIX = 'ProjectCard'

const classes = {
    container: `${PREFIX}-container`,
    imageContainer: `${PREFIX}-imageContainer`,
    textPart: `${PREFIX}-textPart`,
    threeDotsBox: `${PREFIX}-threeDotsBox`,
    icon: `${PREFIX}-icon`,
    text: `${PREFIX}-text`,
    dropdownContainer: `${PREFIX}-dropdownContainer`,
    item: `${PREFIX}-item`,
    dropdownItemTitle: `${PREFIX}-dropdownItemTitle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 24,
        marginBottom: 24,
        width: 210,
        height: 202,
        border: `3px solid transparent`,
        boxShadow: theme.palette.shadow.secondary,
        backdropFilter: 'blur(40px)',
        borderRadius: 12,
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            border: `3px solid ${theme.palette.neutral.light1}`,
            boxShadow: theme.palette.shadow.main,
            backdropFilter: 'blur(40px)',
            backgroundColor: theme.palette.neutral.light1,
        },
        cursor: 'pointer',
    },

    [`& .${classes.imageContainer}`]: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        borderTopRightRadius: MAIN_BORDER_RADIUS,
        borderTopLeftRadius: MAIN_BORDER_RADIUS,
        backgroundPosition: 'center',
        height: 94,
        width: 204,
    },

    [`& .${classes.textPart}`]: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        textOverflow: 'ellipsis',
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    },

    [`& .${classes.threeDotsBox}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 24,
        height: 24,
        borderRadius: 2,
        backgroundColor: 'rgba(85, 87, 112, 0.8)',
        margin: theme.spacing(1),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(85, 87, 112, 0.4)',
        },
    },

    [`& .${classes.icon}`]: {
        color: theme.palette.neutral.main,
    },

    [`& .${classes.text}`]: {
        color: theme.palette.text.primary,
    },
}))

const StyledDropdownContainer = styled(Box)(({ theme }) => ({
    [`&.${classes.dropdownContainer}`]: {
        backgroundColor: theme.palette.background.paper,
        filter: theme.palette.dropdown.filter,
        marginTop: 4,
        borderRadius: MAIN_BORDER_RADIUS,
    },

    [`& .${classes.item}`]: {
        width: MENU_ITEM_WIDTH,
        height: MENU_ITEM_HEIGHT,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 16,
        cursor: 'pointer',
        backgroundColor: theme.palette.dropdown.mainBackground,
        '&:hover': {
            backgroundColor: theme.palette.dropdown.hover,
        },
    },

    [`& .${classes.dropdownItemTitle}`]: {
        width: MENU_ITEM_WIDTH,
        height: MENU_ITEM_HEIGHT,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.text.light,
        fontSize: Fonts.size.small,
        '&:hover': {
            fontWeight: 'bold',
            color: theme.palette.dropdown.titleHover,
        },
        fontFamily: 'Open Sans',
    },
}))

interface ProjectCardProps {
    project: ProjectMetadataPayload
    onDelete: (id: string) => void
    onProjectSelect: (openInNewTab: boolean, projectId: string) => void
    onDetailsPress: (project: ProjectMetadataPayload) => void
    onShare: (project: ProjectMetadataPayload) => void
    onProjectRename: (project: ProjectMetadataPayload) => void
}

interface MenuItem {
    title: string
    onClick: () => void
    style?: React.CSSProperties
}

const MENU_ITEM_HEIGHT = 52
const MENU_ITEM_WIDTH = 128

const ProjectCard: React.FC<ProjectCardProps> = ({
    project,
    onDelete,
    onProjectSelect,
    onDetailsPress,
    onShare,
    onProjectRename,
}) => {
    const theme = useTheme()
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const cutLongProjectName = (name: string) => {
        if (name.length > 37) {
            return `${name.slice(0, 37)}...`
        }
        return name
    }

    const menuItems: MenuItem[] = [
        {
            title: 'Delete',
            onClick: () => {
                onDelete(project.id)
            },
            style: { borderTopLeftRadius: MAIN_BORDER_RADIUS, borderTopRightRadius: MAIN_BORDER_RADIUS },
        },
        {
            title: 'Details',
            onClick: () => {
                onDetailsPress(project)
            },
        },
        {
            title: 'Rename',
            onClick: () => {
                onProjectRename(project)
            },
        },
        {
            title: 'Share',
            onClick: () => {
                onShare(project)
            },
            style: { borderBottomLeftRadius: MAIN_BORDER_RADIUS, borderBottomRightRadius: MAIN_BORDER_RADIUS },
        },
    ]

    return (
        <Box position='relative' id={project.id}>
            <StyledBox className={classes.container} onMouseDown={(e) => onProjectSelect(handleLinkClick(e), project.id)}>
                <Box
                    className={classes.imageContainer}
                    style={{
                        backgroundImage: project.backgroundImage
                            ? getBackgroundImage[project.backgroundImage]
                            : getBackgroundImage['black'],
                    }}
                >
                    <DropdownMenu
                        placement='bottom-end'
                        open={dropdownOpen}
                        onClose={() => setDropdownOpen(false)}
                        borderRadius={MAIN_BORDER_RADIUS}
                        content={
                            <StyledDropdownContainer className={classes.dropdownContainer}>
                                {menuItems.map((item: MenuItem, index) => (
                                    <Box
                                        key={`${item.title}-${index}`}
                                        className={classes.item}
                                        style={item.style}
                                        onClick={(e) => {
                                            setDropdownOpen(false)
                                            item.onClick()
                                            // Used to prevent upper function call. Project select in our case "onProjectSelect(project.id)".
                                            e.stopPropagation()
                                        }}
                                    >
                                        <Typography className={classes.dropdownItemTitle}>{item.title}</Typography>
                                    </Box>
                                ))}
                            </StyledDropdownContainer>
                        }
                    >
                        <Box
                            className={classes.threeDotsBox}
                            onClick={(e) => {
                                // Used to prevent upper function call. Project select in our case "onProjectSelect(project.id)".
                                e.stopPropagation()
                                setDropdownOpen(!dropdownOpen)
                            }}
                        >
                            <MoreHorizIcon className={classes.icon} />
                        </Box>
                    </DropdownMenu>
                </Box>

                <Box className={classes.textPart}>
                    <Tooltip
                        title={project.name.length > 37 ? project.name : ''}
                        placement='bottom'
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    backgroundColor: theme.palette.background.paper,
                                    boxShadow: theme.palette.shadow.main,
                                    color: theme.palette.text.normal,
                                },
                            },
                        }}
                    >
                        <Box>
                            <Text
                                color='normal'
                                fontSize={16}
                                onMouseDown={(e) => onProjectSelect(handleLinkClick(e), project.id)}
                                style={{ marginBottom: 8 }}
                            >
                                {cutLongProjectName(project.name)}
                            </Text>
                        </Box>
                    </Tooltip>
                    <Text color='light' fontSize={12}>
                        {`Edited ${moment(project.lastModifiedAt).format('YYYY/MM/DD')}`}
                    </Text>
                    <Text color='light' fontSize={12}>
                        Viewed xx days ago
                    </Text>
                </Box>
            </StyledBox>
        </Box>
    )
}

export default ProjectCard
