import { Box, Dialog, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import Text from 'components/Text'
import { Project, SharedCode } from 'shared/types/project-types'
import { useDispatch } from 'react-redux'
import SharedCodeTab from './SharedCodeTab'
import { useRef, useState } from 'react'
import { MAIN_BORDER_RADIUS, MAXIMUM_NUMBER_OF_SHARED_TABS } from 'constants/ui'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { deleteSharedCode, moveSharedCodeToFirst, updateSharedCodeContent } from 'services/actions/project-actions'
import DeleteModal from 'components/DeleteModal'
import GradientButton from 'components/GradientButton'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Prompt } from 'react-router'
import { Location } from 'history'
import UnsavedChangesWarningModal from './UnsavedChangesWarningModal'
import { useHistory } from 'react-router-dom'
import React from 'react'
import InlineMonacoEditor from 'components/MonacoEditor/InlineMonacoEditor'
import TypesComparisonModal from './TypesComparisonModal'
import { createSharedCodeCache, getSharedCodeCache } from 'components/MonacoEditor/monaco-utils'
import { EditedSharedCodeTypeInfo } from 'types/sharedCode-types'
import { filterTypesOnlyImportedInEndpointOrInSharedCode } from 'utils/project-utils'

const PREFIX = 'SharedCodeOverview'

const classes = {
    container: `${PREFIX}-container`,
    sharedCodeTabRow: `${PREFIX}-sharedCodeTabRow`,
    saveButtonRow: `${PREFIX}-saveButtonRow`,
    editorBox: `${PREFIX}-editorBox`,
    sharedCodeCreate: `${PREFIX}-sharedCodeCreate`,
    tabListItem: `${PREFIX}-tabListItem`,
    tabListItemTitle: `${PREFIX}-tabListItemTitle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        flex: 1,
        width: '100%',
    },
    [`& .${classes.sharedCodeTabRow}`]: {
        display: 'flex',
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
    },

    [`& .${classes.saveButtonRow}`]: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(1.5),
    },

    [`& .${classes.editorBox}`]: {
        borderBottomLeftRadius: MAIN_BORDER_RADIUS,
        borderBottomRightRadius: MAIN_BORDER_RADIUS,
        backgroundColor: theme.palette.monacoEditor.backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: 8,
    },

    [`& .${classes.sharedCodeCreate}`]: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32,
        backgroundColor: '#55577033',
        cursor: 'pointer',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: '50%',
            left: 0,
            borderRight: `1px solid ${theme.palette.neutral.dark1}`,
        },
    },
}))

const StyledTabList = styled(Box)(({ theme }) => ({
    [`& .${classes.tabListItem}`]: {
        color: theme.palette.text.normal,
        fontWeight: 'normal',
        height: 52,
        width: 256,
        maxWidth: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.dropdown.hover,
            fontWeight: 'bold',
        },
    },

    [`& .${classes.tabListItemTitle}`]: {
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}))

interface SharedCodeOverviewProps {
    onAddNewTab: () => void
    sharedCode: SharedCode[]
    activeProject: Project | undefined
}

const SharedCodeOverview: React.FC<SharedCodeOverviewProps> = ({ onAddNewTab, sharedCode, activeProject }) => {
    const theme = useTheme()

    const history = useHistory()
    const dispatch = useDispatch()
    const tabRow = useRef<HTMLDivElement>(null)
    const [selectedSharedCode, setSelectedSharedCode] = useState<SharedCode>(sharedCode[0])
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [unsavedSharedCode, setUnsavedSharedCode] = useState<SharedCode[]>([])
    const isAnySharedCodeUnsaved = unsavedSharedCode.length > 0
    const selectedSharedCodeContent =
        unsavedSharedCode.find((sc) => sc.id === selectedSharedCode.id)?.content || selectedSharedCode.content
    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState<boolean>(false)
    const [isTabsListOpen, setIsTabsListOpen] = useState<boolean>(false)
    const [shouldUpdateSharedCode, setShouldUpdateSharedCode] = useState<boolean>(false)
    const [leavePath, setLeavePath] = useState<string | undefined>(undefined)
    const [isTabRowOverflown, setIsTabRowOverflown] = useState<boolean>(false)
    const [isComparisonModalOpen, setIsComparisonModalOpen] = useState<boolean>(false)
    const [deletedTypes, setDeletedTypes] = useState<EditedSharedCodeTypeInfo[]>([])
    const [addedTypes, setAddedTypes] = useState<EditedSharedCodeTypeInfo[]>([])
    const [unsavedCodeRequestedToSave, setUnsavedCodeRequestedToSave] = useState<SharedCode[]>([])

    React.useEffect(() => {
        checkIfTabRowIsOverflown()
    }, [tabRow])

    React.useLayoutEffect(() => {
        window.addEventListener('resize', checkIfTabRowIsOverflown)
        return () => window.removeEventListener('resize', checkIfTabRowIsOverflown)
    }, [])

    const checkIfTabRowIsOverflown = () => {
        if (!tabRow.current) return

        if (tabRow.current.clientWidth < tabRow.current.scrollWidth) {
            setIsTabRowOverflown(true)
        } else {
            setIsTabRowOverflown(false)
        }
    }

    const onSelectTab = (id: string) => {
        const openedSharedCode = sharedCode.find((sc) => sc.id === id)
        if (openedSharedCode) setSelectedSharedCode(openedSharedCode)
    }

    const moveTabToFirstPlace = (id: string) => {
        if (activeProject) dispatch(moveSharedCodeToFirst(id, activeProject))
    }

    const onDeleteTab = () => {
        setIsDeleteModalOpen(true)
    }

    const onConfirmDeleteTab = () => {
        if (activeProject) {
            const openedSharedCodeIndex = sharedCode.findIndex((sc) => sc.id === selectedSharedCode.id)
            dispatch(deleteSharedCode(selectedSharedCode.id, activeProject))
            setIsDeleteModalOpen(false)
            if (openedSharedCodeIndex !== 0) {
                setSelectedSharedCode(sharedCode[openedSharedCodeIndex - 1])
            } else {
                setSelectedSharedCode(sharedCode[openedSharedCodeIndex + 1])
            }
            setTimeout(() => checkIfTabRowIsOverflown())
        }
    }

    const selectedSharedCodeChanged = (newContent: string) => {
        if (selectedSharedCode.content === newContent) {
            if (unsavedSharedCode.findIndex((sc) => sc.id === selectedSharedCode.id) !== -1) {
                setUnsavedSharedCode(unsavedSharedCode.filter((sc) => sc.id !== selectedSharedCode.id))
            }
        } else {
            const unsavedCodeCopy = [...unsavedSharedCode]
            const sharedCode = unsavedCodeCopy.find((sc) => sc.id === selectedSharedCode.id)

            if (sharedCode) {
                sharedCode.content = newContent
            } else {
                unsavedCodeCopy.push({ id: selectedSharedCode.id, content: newContent, name: selectedSharedCode.name })
            }

            setUnsavedSharedCode(unsavedCodeCopy)
        }
    }

    const onRemoveUnsavedChanges = () => {
        setUnsavedSharedCode([])
    }

    const onConfirmSaveChanges = (deletedTypes?: EditedSharedCodeTypeInfo[]) => {
        if (activeProject) {
            dispatch(updateSharedCodeContent(unsavedSharedCode, activeProject, deletedTypes))
            // Filter out the shared code that was just saved
            setUnsavedSharedCode(
                unsavedSharedCode.filter((usc) => unsavedCodeRequestedToSave.findIndex((x) => x.id === usc.id) === -1)
            )
            setShouldUpdateSharedCode(true)
            setIsComparisonModalOpen(false)
        }
    }

    const onSaveUnsavedChanges = () => {
        // If there is a difference in types between current shared code and unsaved shared code, open difference modal, otherwise save code
        if (createSharedCodeDifference(unsavedSharedCode)) {
            setUnsavedCodeRequestedToSave([...unsavedSharedCode])
            setIsComparisonModalOpen(true)
        } else {
            if (activeProject) {
                dispatch(updateSharedCodeContent(unsavedSharedCode, activeProject, deletedTypes))
                onRemoveUnsavedChanges()
                setShouldUpdateSharedCode(true)
                setIsComparisonModalOpen(false)
                setUnsavedCodeRequestedToSave([])
            }
        }
    }

    const onSaveSelectedTab = () => {
        const selectedTabChanges = unsavedSharedCode.find((usc) => usc.id === selectedSharedCode.id)

        // If there is a difference in types between current shared code and unsaved shared code, open difference modal, otherwise save code
        if (activeProject && selectedTabChanges) {
            if (createSharedCodeDifference([selectedTabChanges])) {
                setUnsavedCodeRequestedToSave([selectedTabChanges])
                setIsComparisonModalOpen(true)
            } else {
                dispatch(updateSharedCodeContent([selectedTabChanges], activeProject, deletedTypes))
                setUnsavedSharedCode(unsavedSharedCode.filter((usc) => usc.id !== selectedTabChanges.id))
                setShouldUpdateSharedCode(true)
                setIsComparisonModalOpen(false)
                setUnsavedCodeRequestedToSave([])
            }
        }
    }

    /**
     * Creates difference between current shared code types and types in shared code, that would exist after saving
     *
     * @param unsavedCodes unsaved shared code
     * @returns if there is any created and deleted types between current and new shared code
     */
    const createSharedCodeDifference = (unsavedCodes: SharedCode[]): boolean => {
        const currentCache = getSharedCodeCache()
        // Cannot use spread operator, the shared code objects in the array would keep the reference
        const newSharedCode = JSON.parse(JSON.stringify(sharedCode)) as SharedCode[]

        // If there is no current cache or shared code in active project, difference can't be made
        if (!newSharedCode || !currentCache || !activeProject) return false

        // Change content of shared code tabs to include unsaved changes
        for (const unsavedCode of unsavedCodes) {
            const newSharedCodeItem = newSharedCode.find((sc) => sc.id === unsavedCode.id)

            if (newSharedCodeItem) {
                newSharedCodeItem.content = unsavedCode.content
            }
        }

        const newCache = createSharedCodeCache(newSharedCode)
        let removedTypes: EditedSharedCodeTypeInfo[] = []
        const createdTypes: EditedSharedCodeTypeInfo[] = []

        // If there is a type in current cache but not in new cache, the type was removed
        Object.keys(currentCache).forEach((key) => {
            if (!newCache[key]) {
                removedTypes.push({
                    file: currentCache[key].fileName,
                    name: currentCache[key].name,
                })
            }
        })

        // If there is a type in new cache but not in current cache, the type was created
        Object.keys(newCache).forEach((key) => {
            if (!currentCache[key]) {
                createdTypes.push({
                    file: newCache[key].fileName,
                    name: newCache[key].name,
                })
            }
        })

        removedTypes = filterTypesOnlyImportedInEndpointOrInSharedCode(removedTypes, activeProject)

        // If there are no removed types or created types, there is no need to create difference structure
        // because there is either no deleted type to replace or no created type to replace the deleted ones
        if (removedTypes.length === 0 || createdTypes.length === 0) return false

        setAddedTypes(createdTypes)
        setDeletedTypes(removedTypes)

        return true
    }

    const onLeavingWithUnsavedChanges = (location: Location) => {
        // if leave
        if (unsavedSharedCode.length === 0 || leavePath) return true

        setLeavePath(location.pathname)
        setIsUnsavedChangesModalOpen(true)
        return false
    }

    const onLeaveToPath = () => {
        setIsUnsavedChangesModalOpen(false)
        history.push(leavePath || '')
    }

    return (
        <>
            {/* Used to intercept leaving the page without saving the changes */}
            <Prompt when={unsavedSharedCode.length > 0} message={onLeavingWithUnsavedChanges} />
            <StyledBox className={classes.container}>
                <Text color='light' fontSize={16} style={{ marginBottom: theme.spacing(3) }}>
                    Define code which can be shared across all endpoints.
                </Text>
                <Box className={classes.saveButtonRow}>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                        {isAnySharedCodeUnsaved && (
                            <>
                                <Text color='faded' fontSize={12}>
                                    Not saved
                                </Text>
                                <ErrorOutlineIcon style={{ color: theme.palette.text.faded, fontSize: 12 }} />
                            </>
                        )}
                        {!isAnySharedCodeUnsaved && (
                            <>
                                <Text color='faded' fontSize={12}>
                                    Saved
                                </Text>
                                <CheckIcon style={{ color: theme.palette.text.faded, fontSize: 12 }} />
                            </>
                        )}
                    </Box>
                    <GradientButton
                        size='small'
                        title='Save changes'
                        style={{ width: 133 }}
                        disabled={!isAnySharedCodeUnsaved}
                        onClick={onSaveUnsavedChanges}
                    />
                </Box>
                {/* EDITOR WITH TABS */}
                <Box className={classes.sharedCodeTabRow}>
                    <Box
                        style={{
                            display: 'flex',
                            overflowX: 'hidden',
                        }}
                        ref={tabRow}
                    >
                        {sharedCode.map((sc, index) => {
                            return (
                                <SharedCodeTab
                                    key={sc.id}
                                    sharedCode={sc}
                                    isSelected={sc.id === selectedSharedCode.id}
                                    isSaved={unsavedSharedCode.findIndex((unsavedCode) => unsavedCode.id === sc.id) === -1}
                                    onSelectTab={() => onSelectTab(sc.id)}
                                    onDeleteTab={() => onDeleteTab()}
                                    activeProject={activeProject}
                                    isFirst={index === 0}
                                    onNameUpdated={() => setShouldUpdateSharedCode(true)}
                                />
                            )
                        })}
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <>
                            {isTabRowOverflown && (
                                <Box className={classes.sharedCodeCreate} onClick={() => setIsTabsListOpen(true)}>
                                    <KeyboardArrowDownIcon
                                        style={{
                                            color: theme.palette.text.light,
                                        }}
                                    />
                                </Box>
                            )}
                            <Box
                                className={classes.sharedCodeCreate}
                                style={{
                                    borderTopRightRadius: 3,
                                }}
                                onClick={() => {
                                    onAddNewTab()
                                    setTimeout(() => checkIfTabRowIsOverflown())
                                }}
                            >
                                <AddIcon
                                    style={{
                                        color:
                                            sharedCode.length >= MAXIMUM_NUMBER_OF_SHARED_TABS
                                                ? theme.palette.text.faded
                                                : theme.palette.text.light,
                                        cursor: sharedCode.length >= MAXIMUM_NUMBER_OF_SHARED_TABS ? 'default' : undefined,
                                    }}
                                />
                            </Box>
                        </>
                    </Box>
                </Box>
                {sharedCode && selectedSharedCode !== null && (
                    <Box display='flex' flexDirection='column'>
                        <Box
                            className={classes.editorBox}
                            style={{ borderTopRightRadius: !isTabRowOverflown ? MAIN_BORDER_RADIUS : undefined }}
                        >
                            <InlineMonacoEditor
                                sharedCodes={sharedCode}
                                width={'99%'}
                                defaultLanguage='typescript'
                                value={selectedSharedCodeContent}
                                theme={'customTheme'}
                                onChange={(sharedCode) => {
                                    selectedSharedCodeChanged(sharedCode || '')
                                }}
                                onSaveAction={onSaveSelectedTab}
                                shouldUpdateSharedCode={shouldUpdateSharedCode}
                                sharedCodeUpdated={() => setShouldUpdateSharedCode(false)}
                            />
                        </Box>
                    </Box>
                )}

                {isAnySharedCodeUnsaved && (
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Text
                            color={theme.palette.alert.main}
                            fontSize={16}
                            style={{ cursor: 'pointer' }}
                            onClick={onRemoveUnsavedChanges}
                        >
                            Remove unsaved changes
                        </Text>
                    </Box>
                )}

                <DeleteModal
                    type='sharedCode'
                    name={selectedSharedCode.name}
                    isOpen={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onDelete={onConfirmDeleteTab}
                ></DeleteModal>
            </StyledBox>
            <UnsavedChangesWarningModal
                isOpen={isUnsavedChangesModalOpen}
                onClose={() => {
                    setLeavePath(undefined)
                    setIsUnsavedChangesModalOpen(false)
                }}
                onSaveChanges={() => {
                    onConfirmSaveChanges()
                    onLeaveToPath()
                }}
                onLeave={onLeaveToPath}
            />
            <Dialog onClose={() => setIsTabsListOpen(false)} open={isTabsListOpen} style={{ overflow: 'hidden' }}>
                <StyledTabList
                    style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', overflowX: 'hidden' }}
                >
                    {sharedCode.map((sc) => {
                        return (
                            <Box
                                className={classes.tabListItem}
                                key={sc.id}
                                onClick={() => {
                                    onSelectTab(sc.id)
                                    moveTabToFirstPlace(sc.id)
                                    setIsTabsListOpen(false)
                                }}
                            >
                                {unsavedSharedCode.findIndex((unsaved) => unsaved.id === sc.id) !== -1 && (
                                    <ErrorOutlineIcon style={{ color: theme.palette.secondary.main, fontSize: 14 }} />
                                )}
                                <Typography
                                    className={classes.tabListItemTitle}
                                    style={{
                                        maxWidth:
                                            unsavedSharedCode.findIndex((unsaved) => unsaved.id === sc.id) === -1
                                                ? `calc(100% - 2 * ${theme.spacing(2)})`
                                                : `calc(100% - (2 * ${theme.spacing(2)} + ${theme.spacing(2)} + 14px))`, // If the tab is unsaved, shrink the maxWidth by icon size and gap
                                    }}
                                >
                                    {sc.name}
                                </Typography>
                            </Box>
                        )
                    })}
                </StyledTabList>
            </Dialog>
            {isComparisonModalOpen && (
                <TypesComparisonModal
                    isOpen={isComparisonModalOpen}
                    onConfirm={(removedTypes) => {
                        onConfirmSaveChanges(removedTypes)
                    }}
                    onClose={() => setIsComparisonModalOpen(false)}
                    deletedTypes={deletedTypes}
                    addedTypes={addedTypes}
                />
            )}
        </>
    )
}

export default SharedCodeOverview
