import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { useEffect, useState } from 'react'

interface CustomSwitchProps {
    checked: boolean
    onSwitch?: () => void
    disabled?: boolean
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ checked, onSwitch, disabled }) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked)

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked)
        if (onSwitch) onSwitch()
    }

    return <IOSSwitch sx={{ m: 1 }} checked={isChecked} onChange={handleChange} disabled={disabled} />
}

const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(
    ({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#FFFFFF',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.primary.light,
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: 'black',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    })
)

export default CustomSwitch
