import {
    ModeType,
    SetSelectedMode,
    SetSelectedTheme,
    ThemeType,
    UserSettingsActionTypes,
    EditorSettings,
    SELECTED_THEME_KEY,
} from '../types/userSettings-types'

export const setSelectedModeType = (type: ModeType): SetSelectedMode => {
    return {
        type: UserSettingsActionTypes.SET_SELECTED_MODE,
        payload: type,
    }
}

export const setSelectedThemeType = (type: ThemeType): SetSelectedTheme => {
    localStorage.setItem(SELECTED_THEME_KEY, type)
    return {
        type: UserSettingsActionTypes.SET_SELECTED_THEME,
        payload: type,
    }
}

export const setEditorSettings = (editorSettings: EditorSettings) => {
    return {
        type: UserSettingsActionTypes.SET_EDITOR_SETTINGS,
        payload: editorSettings,
    }
}
