import initState from 'services/types/initState'
import { ReduxState } from 'services/types/mainReducer-types'
import { ReduxAction } from 'services/types/mainReducer-types'
import authReducer from './auth-reducer'
import flashMessagesReducer from './flashMessages-reducer'
import projectReducer from './project-reducer'
import temporaryUiChangesReducer from './temporaryUiChanges-reducer'
import userSettingsReducer from './userSettings-reducer'

export default function mainReducer(state: ReduxState = initState, action: ReduxAction): ReduxState {
    return {
        flashMessages: flashMessagesReducer(state.flashMessages, action),
        projects: projectReducer(state.projects, action),
        userSettings: userSettingsReducer(state.userSettings, action),
        auth: authReducer(state.auth, action),
        temporaryUiChanges: temporaryUiChangesReducer(state.temporaryUiChanges, action),
    }
}
