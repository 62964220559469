import { Box, Tooltip, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { moveCollection } from 'services/actions/project-actions'
import Text from 'components/Text'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DropdownMenu from 'components/DropdownMenu'
import OptionsDropdown, { MenuDropdownOptionType } from './OptionsDropdown'
import { setCollectionName } from '../services/actions/project-actions'
import { getCollectionById } from 'services/selectors/projects-selectors'
import { ReduxState } from 'services/types/mainReducer-types'
import NameInput from './NameInput'
import { Collection } from 'shared/types/project-types'
import { setCreatedEndpointInfo } from 'services/actions/temporaryUiChanges-actions'
import { styled } from '@mui/material/styles'
import { handleLinkClick } from 'utils/generic-utils'

const PREFIX = 'SidePanelMenuItemTitle'

const classes = {
    titleWithIconContainer: `${PREFIX}-titleWithIconContainer`,
    titleWithIconContainerHover: `${PREFIX}-titleWithIconContainerHover`,
    arrowIcon: `${PREFIX}-arrowIcon`,
    title: `${PREFIX}-title`,
}

export const MENU_ITEM_HEIGHT = 32

const StyledBox = styled(Box)(() => ({
    [`&.${classes.titleWithIconContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 156,
        height: MENU_ITEM_HEIGHT,
    },
    [`&.${classes.titleWithIconContainerHover}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 156,
        height: MENU_ITEM_HEIGHT,
        '&:hover': {
            backgroundColor: '#C690F166',
        },
    },
    [`& .${classes.arrowIcon}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 26,
        width: 8,
        marginLeft: 24,
        marginRight: 12,
    },
    [`& .${classes.title}`]: {
        userSelect: 'none',
    },
}))
interface SidePanelMenuItemTitleProps {
    collectionId: string
    isRouteActive: boolean
    title: string
    validateCollectionName: (name: string, collectionId: string) => boolean
    onRedirect: (openInNewTab: boolean) => void
    toggleCollectionOpen: (collectionId: string) => void
    isOpened: boolean
    isMobileView: boolean
    toggleMenu: () => void
    toggleDropdown: () => void
    isDropdownOpen: boolean
    onCollectionRemove: (collection: Collection) => void
    isInEditedState: boolean
    toggleEditedStateFor: (value: string) => void
    isReadOnly: boolean
}

const SidePanelMenuItemTitle: React.FC<SidePanelMenuItemTitleProps> = ({
    isRouteActive,
    title,
    validateCollectionName,
    collectionId,
    onRedirect,
    toggleCollectionOpen,
    isOpened,
    isMobileView,
    toggleMenu,
    toggleDropdown,
    isDropdownOpen,
    onCollectionRemove,
    toggleEditedStateFor,
    isInEditedState,
}) => {
    const theme = useTheme()

    const dispatch = useDispatch()
    const [showButtons, setShowButtons] = useState<boolean>(false)
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false)
    const [showRenameInput, setShowRenameInput] = useState<boolean>(isInEditedState ? true : false)
    const collection = useSelector((state: ReduxState) => getCollectionById(state, collectionId))
    const [isNameDuplicate, setIsNameDuplicate] = useState(false)

    if (!collection) return null

    const [newName, setNewName] = useState<string>(collection.name)

    const onOptionSelect = (option: MenuDropdownOptionType) => {
        if (option === 'rename') {
            setShowRenameInput(true)
        }
        if (option === 'duplicate') {
            alert('Duplicate is not ready for collection')
        }
        if (option === 'delete') {
            if (collection) {
                onCollectionRemove(collection)
            }
        }
        toggleDropdown()
    }

    const onRenameHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(e.target.value)
        setIsNameDuplicate(validateCollectionName(e.target.value, collectionId))
    }

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'collectionTitle',
        item: { collectionId },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: () => {
            setIsMouseDown(false)
        },
    }))

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'collectionTitle',
        drop: (item: { collectionId: string }) => onDrop(item.collectionId, collectionId),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }))

    const onDrop = (movedCollectionId: string, dropCollectionId: string) => {
        if (movedCollectionId !== dropCollectionId) dispatch(moveCollection(movedCollectionId, dropCollectionId))
    }

    const onBlur = () => {
        if (isNameDuplicate || newName === '') {
            dispatch(setCollectionName(collectionId, collection.name))
            setNewName(collection.name)
        } else {
            dispatch(setCollectionName(collectionId, newName))
            setNewName(newName)
        }
        setShowRenameInput(false)
        toggleEditedStateFor('')
        setIsNameDuplicate(false)
    }

    return (
        <Box ref={drop} style={{ backgroundColor: isRouteActive ? theme.palette.primary.main : undefined }}>
            <Tooltip
                title={'This name of the collection already exist in the same project'}
                open={isNameDuplicate}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <StyledBox
                    ref={drag}
                    className={isRouteActive ? classes.titleWithIconContainer : classes.titleWithIconContainerHover}
                    style={{
                        backgroundColor: isMouseDown ? theme.palette.primary.dark : undefined,
                        borderTop: isOver ? `1px solid ${theme.palette.text.normal}` : 'initial',
                        position: 'relative',
                    }}
                    onMouseEnter={() => setShowButtons(true)}
                    onMouseLeave={() => setShowButtons(false)}
                    onDoubleClick={() => toggleCollectionOpen(collectionId)}
                >
                    <Box className={classes.arrowIcon} onClick={() => toggleCollectionOpen(collectionId)}>
                        {isOpened ? (
                            <ArrowDropDownRoundedIcon style={{ color: theme.palette.neutral.main }} />
                        ) : (
                            <ArrowRightRoundedIcon style={{ color: theme.palette.neutral.main }} />
                        )}
                    </Box>
                    <Box
                        style={{
                            height: MENU_ITEM_HEIGHT,
                            display: 'flex',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            flex: 1,
                        }}
                        onMouseDown={(e) => {
                            onRedirect(handleLinkClick(e))
                            if (isMobileView) {
                                toggleMenu()
                            }
                        }}
                    >
                        {!showRenameInput && (
                            <Text
                                color='normal'
                                fontSize={14}
                                className={classes.title}
                                style={{
                                    color: isRouteActive ? theme.palette.neutral.neutral1 : theme.palette.neutral.light1,
                                    backgroundColor: isDragging ? theme.palette.action.focus : 'initial',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {title}
                            </Text>
                        )}
                        {showRenameInput && (
                            <Box style={{ height: MENU_ITEM_HEIGHT, flex: 1, display: 'flex', alignItems: 'center' }}>
                                <NameInput
                                    value={newName}
                                    handleChange={onRenameHandle}
                                    onBlur={onBlur}
                                    error={isNameDuplicate}
                                />
                            </Box>
                        )}
                    </Box>
                    {(showButtons || isDropdownOpen) && (
                        <Box style={{ marginRight: 12, display: 'flex' }}>
                            <AddIcon
                                onClick={(e) => {
                                    dispatch(setCreatedEndpointInfo({ collectionId }))
                                    e.stopPropagation()
                                }}
                                style={{
                                    color: isRouteActive ? theme.palette.neutral.neutral1 : theme.palette.neutral.light1,
                                    fontSize: 22,
                                }}
                            />
                            <DropdownMenu
                                open={isDropdownOpen}
                                borderRadius={8}
                                onClose={toggleDropdown}
                                content={<OptionsDropdown onOptionSelect={onOptionSelect} />}
                            >
                                <MoreVertIcon
                                    style={{
                                        color: isRouteActive ? theme.palette.neutral.neutral1 : theme.palette.neutral.light1,
                                        fontSize: 22,
                                    }}
                                    onClick={(e) => {
                                        toggleDropdown()
                                        e.stopPropagation()
                                    }}
                                />
                            </DropdownMenu>
                        </Box>
                    )}
                </StyledBox>
            </Tooltip>
        </Box>
    )
}

export default SidePanelMenuItemTitle
