import { Box, Tabs, Tab, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ModalBase from '../ModalBase'
import EmailSharing from './EmailSharing'
import LinkSharing from './LinkSharing'
import Fonts from 'constants/fonts'
import Text from 'components/Text'

const PREFIX = 'ShareProjectModal'

const classes = {
    mainContainer: `${PREFIX}-mainContainer`,
    topContainer: `${PREFIX}-topContainer`,
    title: `${PREFIX}-title`,
    tabsDivider: `${PREFIX}-tabsDivider`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.mainContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        maxWidth: 578,
        height: 620,
        border: `1px solid ${theme.palette.neutral.black}`,
        borderRadius: 16,
        margin: theme.spacing(3),
        boxShadow: theme.palette.shadow.main,
        backgroundColor: theme.palette.background.default,
    },

    [`& .${classes.topContainer}`]: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row-reverse',
    },

    [`& .${classes.title}`]: {
        marginBottom: theme.spacing(3),
        fontWeight: 700,
    },

    [`& .${classes.tabsDivider}`]: {
        width: '100%',
        height: 1,
        backgroundColor: theme.palette.neutral.dark2,
        position: 'absolute',
        marginTop: 46.5,
        borderRadius: 3,
    },
}))

type TabTypes = 'link' | 'email'

interface ShareProjectModalProps {
    title: string
    shareLink: string
    onRequestClose: () => void
    isOpen: boolean
}

const ShareProjectModal: FC<ShareProjectModalProps> = ({ title, shareLink, onRequestClose, isOpen }) => {
    const theme = useTheme()
    const [selectedTab, setSelectedTab] = useState<TabTypes>('link')

    const handleSwitch = (event: React.SyntheticEvent, newValue: TabTypes) => {
        setSelectedTab(newValue)
    }

    return (
        <ModalBase
            isOpen={isOpen}
            onClose={onRequestClose}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}
        >
            <StyledBox className={classes.mainContainer} style={{ position: 'relative' }}>
                <Box className={classes.topContainer}>
                    <Box onClick={onRequestClose}>
                        <CloseIcon style={{ color: theme.palette.text.light, cursor: 'pointer' }} />
                    </Box>
                </Box>
                <Text color='light' fontSize={20} className={classes.title}>{`Share ${title}`}</Text>

                <Tabs
                    sx={{
                        width: '100%',
                        float: 'left',
                        height: 46,
                    }}
                    value={selectedTab}
                    onChange={handleSwitch}
                    indicatorColor='primary'
                    TabIndicatorProps={{ style: { borderRadius: 3 } }}
                >
                    <Tab
                        disableRipple={true}
                        value='link'
                        label='Link'
                        style={{
                            color: selectedTab === 'link' ? theme.palette.text.normal : theme.palette.text.faded,
                            fontSize: Fonts.size.normal,
                            textTransform: 'none',
                            fontWeight: selectedTab === 'link' ? 600 : 'initial',
                        }}
                    />
                    <Tab
                        disableRipple={true}
                        value='email'
                        label='Email'
                        style={{
                            color: selectedTab === 'email' ? theme.palette.text.normal : theme.palette.text.faded,
                            fontSize: Fonts.size.normal,
                            textTransform: 'none',
                            fontWeight: selectedTab === 'email' ? 600 : 'initial',
                        }}
                    />
                    <Box className={classes.tabsDivider} />
                </Tabs>

                {selectedTab === 'link' && <LinkSharing shareLink={shareLink} />}
                {selectedTab === 'email' && <EmailSharing />}
            </StyledBox>
        </ModalBase>
    )
}

export default ShareProjectModal
