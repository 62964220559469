import { IconButton, InputAdornment, Stack, StandardTextFieldProps, TextField, useTheme } from '@mui/material'
import Text from 'components/Text'
import React, { useState } from 'react'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { Visibility, VisibilityOff } from '@mui/icons-material'

interface StyledTextFieldProps extends StandardTextFieldProps {
    errorText?: string
    placeholder?: string
    /**
     * If set to true, leaves space after the input for error icon and shows
     * it if the input is in an error state.
     */
    showTriangleError?: boolean
}

const StyledTextField: React.FC<StyledTextFieldProps> = React.forwardRef(
    ({ onChange, value, placeholder, error, errorText, type, showTriangleError, ...props }, ref) => {
        const theme = useTheme()
        const [showPassword, setShowPassword] = useState(false)

        const handleClickShowPassword = () => {
            setShowPassword((showPassword) => !showPassword)
        }

        return (
            <Stack spacing={1}>
                <Stack direction={'row'} spacing={1} alignItems='center'>
                    <TextField
                        ref={ref}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                fieldset: {
                                    borderColor: error ? theme.palette.alert.main : undefined,
                                },

                                '&:hover fieldset': {
                                    borderColor: error ? theme.palette.alert.main : undefined,
                                },

                                '&.Mui-focused fieldset': {
                                    borderColor: error ? theme.palette.alert.main : undefined,
                                },
                            },
                        }}
                        placeholder={placeholder}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        InputProps={{
                            endAdornment:
                                type === 'password' ? (
                                    <React.Fragment>
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                                edge='end'
                                                tabIndex={100}
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff style={{ color: theme.palette.text.faded }} />
                                                ) : (
                                                    <Visibility style={{ color: theme.palette.text.faded }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    </React.Fragment>
                                ) : undefined,
                        }}
                        autoComplete={type === 'password' ? 'password' : 'none'}
                        type={type !== 'password' ? type : showPassword ? 'text' : 'password'}
                        {...props}
                    />
                    {showTriangleError && (
                        <ReportProblemOutlinedIcon style={{ color: theme.palette.alert.main, opacity: error ? 1 : 0 }} />
                    )}
                </Stack>
                {error && errorText && (
                    <Text color={theme.palette.alert.main} fontSize={12}>
                        {errorText}
                    </Text>
                )}
            </Stack>
        )
    }
)

StyledTextField.displayName = 'StyledTextField'

export default StyledTextField
