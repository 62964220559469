import { Stack } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { getFlashMessages } from 'services/selectors/flashMessages-selectors'
import FlashMessage from './FlashMessage'

const FlashMessageList: FC = () => {
    const flashMessagesToDisplay = useSelector(getFlashMessages)

    return (
        <>
            {flashMessagesToDisplay && flashMessagesToDisplay.length > 0 && (
                <Stack
                    direction='column-reverse'
                    spacing={2}
                    style={{
                        width: '95vw',
                        maxWidth: 730,
                        position: 'fixed',
                        left: '50%',
                        transform: 'translate(-50%, 0%)',
                        bottom: 0,
                        zIndex: 1500,
                        paddingBottom: 10,
                    }}
                >
                    {flashMessagesToDisplay.map((fm) => (
                        <FlashMessage key={fm.id} id={fm.id} message={fm.message} type={fm.type} />
                    ))}
                </Stack>
            )}
        </>
    )
}
export default FlashMessageList
