import initState from 'services/types/initState'
import { ReduxAction } from 'services/types/mainReducer-types'
import { Collection, Endpoint } from '../../shared/types/project-types'
import { getIndexesByEndpointId } from 'shared/utils/project-utils'
import { ProjectActionTypes, ProjectReduxState } from '../types/project-types'

export default function projectReducer(state: ProjectReduxState = initState.projects, action: ReduxAction): ProjectReduxState {
    switch (action.type) {
        case ProjectActionTypes.SET_ACTIVE_PROJECT: {
            const { project } = action.payload
            return { ...state, active: project }
        }

        case ProjectActionTypes.ADD_COLLECTION: {
            if (!state.active) return state
            const { id, name, description, endpoints } = action.payload
            const collection = {
                id,
                name,
                description,
                endpoints,
            }
            const clone = { ...state.active }
            if (state.active.collections) {
                clone.collections = [...state.active.collections, collection]
            } else clone.collections = [collection]
            return { ...state, active: clone }
        }

        case ProjectActionTypes.SET_SHARED_CODE: {
            if (!state.active) return state
            const { sharedCode } = action.payload
            const clone = { ...state.active }

            const oldCode = clone.sharedCode.find((code) => code.id === sharedCode.id)
            oldCode ? (oldCode.content = sharedCode.content) : clone.sharedCode.push(sharedCode)

            return { ...state, active: clone }
        }

        case ProjectActionTypes.REMOVE_COLLECTION: {
            if (!state.active) return state
            const { id } = action.payload
            const clone = { ...state.active }
            clone.collections = clone.collections.filter((c: Collection) => c.id !== id)
            return { ...state, active: clone }
        }

        case ProjectActionTypes.ADD_ENDPOINT: {
            if (!state.active) return state
            const { collectionId, endpoint } = action.payload
            const clone = { ...state.active }
            const collectionIndex = clone.collections.findIndex((c: Collection) => c.id === collectionId)
            if (collectionIndex !== undefined) {
                const collection = clone.collections[collectionIndex]
                if (collection) {
                    collection.endpoints.push(endpoint)
                }
            }

            return { ...state, active: clone }
        }

        case ProjectActionTypes.REMOVE_ENDPOINT: {
            if (!state.active) return state
            const { collectionId, endpointId } = action.payload
            const clone = { ...state.active }
            const collectionIndex = clone.collections.findIndex((c: Collection) => c.id === collectionId)
            if (collectionIndex !== undefined) {
                const collection = clone.collections[collectionIndex]
                if (collection) {
                    collection.endpoints = collection.endpoints.filter((e: Endpoint) => e.id !== endpointId)
                }
            }
            return { ...state, active: clone }
        }
        case ProjectActionTypes.SET_RESPONSE: {
            const { response, responseHttpCode, endpointId } = action.payload
            if (state.active) {
                let endpoint = undefined
                let collectionIndex = -1
                let endpointIndex = -1
                for (let index = 0; index < state.active.collections.length; index++) {
                    endpoint = state.active.collections[index].endpoints.find((e, i) => {
                        endpointIndex = i
                        return e.id === endpointId
                    })
                    if (endpoint) {
                        collectionIndex = index
                        break
                    }
                }
                if (endpoint !== undefined && collectionIndex !== -1 && endpointIndex !== -1) {
                    const copy = { ...state.active }
                    const responseIndex = copy.collections[collectionIndex].endpoints[endpointIndex].response.findIndex(
                        (r) => r.httpCode === responseHttpCode
                    )
                    copy.collections[collectionIndex].endpoints[endpointIndex].response[responseIndex].stringFormat = response
                    return { ...state, active: copy }
                }
            }
            return state
        }

        case ProjectActionTypes.UPDATE_ENDPOINT_DETAIL: {
            if (!state.active) {
                return state
            }
            const { endpoint } = action.payload
            const { collectionIndex, endpointIndex } = getIndexesByEndpointId(state.active, endpoint.id)
            if (collectionIndex === -1) {
                console.error(`Endpoint with id: ${endpoint.id} was not found in any collection`)
                return state
            }
            if (endpointIndex === -1) {
                console.error(
                    `Endpoint with id: ${endpoint.id} was not found inside collection with id: ${state.active.collections[collectionIndex].id}`
                )
                return state
            }
            const copy = { ...state.active }
            copy.collections[collectionIndex].endpoints[endpointIndex] = endpoint
            return { ...state, active: copy }
        }

        case ProjectActionTypes.SET_OPENED_COLLECTIONS_IDS: {
            const { collectionIds } = action.payload
            return { ...state, openedCollections: collectionIds }
        }

        case ProjectActionTypes.SET_LOGGED_USER_PROJECT_ROLE: {
            if (!state.active) return state
            const { projectRole } = action.payload
            const clone = { ...state.active }
            clone.loggedUserShareRole = projectRole
            return { ...state, active: clone }
        }

        case ProjectActionTypes.SET_IS_SYNCING_WITH_SERVER: {
            if (!state.active) return state
            return { ...state, isSyncingWithServer: action.payload }
        }

        case ProjectActionTypes.SET_SHARED_CODE_CACHE: {
            const { sharedCodeCache } = action.payload

            return { ...state, sharedCodeCache: sharedCodeCache }
        }

        default:
            return state
    }
}
