import { Dispatch } from 'redux'
import { AuthActionTypes, AuthReduxState, AUTH_LOCAL_STORAGE_KEY, SetAuthState } from 'services/types/auth-types'
import { setActiveProject } from './project-actions'

/**
 * Displays new flash message.
 * @param flashMessage Flash message to display
 */
export const setAuthState = (state: AuthReduxState): SetAuthState => ({
    type: AuthActionTypes.SET_AUTH_STATE,
    payload: state,
})

export const logoutUser = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
        dispatch(
            setAuthState({
                isAuthenticated: false,
                userEmail: '',
                token: '',
                parsedToken: {
                    userId: '',
                    createdTimestamp: 0,
                    userEmail: '',
                    expiration: 0,
                    integrityHash: '',
                    refreshIntervalInSeconds: 0,
                    refreshTimestamp: 0,
                },
            })
        )
        dispatch(setActiveProject(undefined))
    }
}
