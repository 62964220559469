import { ReduxState } from 'services/types/mainReducer-types'
import { ThemeType } from 'services/types/userSettings-types'
import { EditorSettings } from '../types/userSettings-types'

export const getIsPrefillRequestOnCreateActive = (state: ReduxState): boolean =>
    state.userSettings.editorSettings.prefillRequestBodyOnCreate

export const getIsPrefillResponseOnCreateActive = (state: ReduxState): boolean =>
    state.userSettings.editorSettings.prefillResponseBodyOnCreate

export const getSelectedThemeType = (state: ReduxState): ThemeType => state.userSettings.selectedTheme

export const getEditorSettings = (state: ReduxState): EditorSettings => state.userSettings.editorSettings
