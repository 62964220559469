export const downloadFile = (data: BlobPart, fileName: string, fileType: string): void => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    downloadBlob(fileName, blob)
}
export async function downloadFileFromBodyContentAsync(bodyContent: Uint8Array, fileName: string) {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([bodyContent], { type: 'application/octet-stream' })
    downloadBlob(fileName, blob)
}

export const transformStringToCamelCase = (string: string): string => {
    let result = ''

    const splittedString = string.split(' ')

    if (splittedString.length === 1) return string.charAt(0).toLowerCase() + string.slice(1)

    splittedString.forEach((stringPart, index) => {
        if (index === 0) {
            result += stringPart.charAt(0).toLowerCase() + stringPart.slice(1)
        } else {
            result += stringPart.charAt(0).toUpperCase() + stringPart.slice(1)
        }
    })

    return result
}
function downloadBlob(fileName: string, blob: Blob) {
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

/**
 * Gets @param reader and reads its content as bytes array
 * @param reader
 * @returns
 */
export async function readBytesAsync(reader: ReadableStreamDefaultReader<Uint8Array>) {
    // Read the response in chunks
    let result = await reader.read()
    const chunks = []
    while (!result.done) {
        chunks.push(result.value)
        result = await reader.read()
    }

    // Concatenate all of the chunks into a single Uint8Array
    const bytes = new Uint8Array(chunks.reduce((acc, chunk) => acc + chunk.length, 0))
    let offset = 0
    for (const chunk of chunks) {
        bytes.set(chunk, offset)
        offset += chunk.length
    }

    return bytes
}

/**
 * normalizes a string by removing all spaces and converting it to lowercase
 * @param string
 * @returns normalized string
 * @example normalizeString('Hello World') // returns 'helloworld'
 */
export const normalizeString = (string: string): string => {
    return string.replace(/\s/g, '').toLowerCase()
}

/**
 * handleTabNavigation
 * @param openInNewTab boolean to open in new tab or not
 * @param route route to open
 * @param onCurrentTab function to execute when opening in current tab (optional) - used when you need to execute some function before opening in current tab
 */
export const handleTabNavigation = (openInNewTab: boolean, route: string, onCurrentTab?: () => void) => {
    if (openInNewTab) {
        window.open(route, '_blank', 'noopener,noreferrer')
    } else {
        onCurrentTab ? onCurrentTab() : window.open(route, '_self')
    }
}

/** handleLinkClick function is used to get boolean for opening new tab if left click - false (current tab), middle mouse click or ctrl/cmd + left click - true (new tab)
 * @param event onMouseDown event
 */
export const handleLinkClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement | HTMLSpanElement, MouseEvent>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return event.metaKey || event.button === 1 || event.ctrlKey
}

export function getNextUntitledItemNumber(names: string[]): number {
    const untitledRegExp = /^Untitled (\d+)$/
    let max = 0
    names.forEach((name) => {
        const match = name.match(untitledRegExp)
        if (match) {
            const itemNumber = Number(match[1])
            if (itemNumber > max) {
                max = itemNumber
            }
        }
    })
    return max + 1
}
