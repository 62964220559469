import { Box, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { getIsSyncingWithServer } from 'services/selectors/projects-selectors'
import CircularProgress from '@mui/material/CircularProgress'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import Fonts from 'constants/fonts'

const PREFIX = 'SyncingWithServerIndicator'

const classes = {
    container: `${PREFIX}-container`,
    icon: `${PREFIX}-icon`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        width: 28,
        height: 28,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    [`& .${classes.icon}`]: {
        color: theme.palette.neutral.light3,
        fontSize: Fonts.size.large,
        cursor: 'pointer',
    },
}))

interface SyncingIndicatorProps {
    style?: React.CSSProperties
    tooltip?: string
    tooltipPlacement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
}

const SyncingWithServerIndicator: React.FC<SyncingIndicatorProps> = ({ style, tooltip, tooltipPlacement = 'bottom' }) => {
    const isSyncingWithServer = useSelector(getIsSyncingWithServer)
    return (
        <Tooltip title={tooltip ? tooltip : ''} placement={tooltipPlacement}>
            <StyledBox style={style || undefined} className={classes.container}>
                {isSyncingWithServer && <CircularProgress size={20} />}
                {!isSyncingWithServer && <CloudDoneIcon className={classes.icon} />}
            </StyledBox>
        </Tooltip>
    )
}

export default SyncingWithServerIndicator
