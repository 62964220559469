import {
    CreatedEndpointInfo,
    SetCreatedEndpointInfo,
    SetShareModalOpen,
    TemporaryUiChangesActionTypes,
} from 'services/types/temporaryUiChanges-types'

export const setCreatedEndpointInfo = (createdEndpointInfo: CreatedEndpointInfo | undefined): SetCreatedEndpointInfo => ({
    type: TemporaryUiChangesActionTypes.SET_CREATED_ENDPOINT_INFO,
    payload: { createdEndpointInfo },
})

export const setShareModalOpen = (isOpen: boolean): SetShareModalOpen => ({
    type: TemporaryUiChangesActionTypes.SET_SHARE_MODAL_OPEN,
    payload: {
        isOpen,
    },
})
