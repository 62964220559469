import { Box, useTheme, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, useState } from 'react'
import GradientButton from '../GradientButton'
import LinkIcon from '@mui/icons-material/Link'
import AgentsImage from 'assets/images/AgentsImage'
import { showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveProject } from 'services/selectors/projects-selectors'
import { updateActiveProjectAsync } from 'services/actions/project-actions'
import Text from 'components/Text'
import NotSharedImage from 'assets/images/NotSharedImage'
import Fonts from 'constants/fonts'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DropdownMenu from 'components/DropdownMenu'
import CheckIcon from '@mui/icons-material/Check'
import { MAIN_BORDER_RADIUS } from 'constants/ui'

const PREFIX = 'LinkSharing'

const classes = {
    columnContainer: `${PREFIX}-columnContainer`,
    linkRow: `${PREFIX}-linkRow`,
    linkUrlBox: `${PREFIX}-linkUrlBox`,
    dropdownSelect: `${PREFIX}-dropdownSelect`,
    dropdownInput: `${PREFIX}-dropdownInput`,
    dropdownContainer: `${PREFIX}-dropdownContainer`,
    item: `${PREFIX}-item`,
    itemTitle: `${PREFIX}-itemTitle`,
    selectedOptionText: `${PREFIX}-selectedOptionText`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.columnContainer}`]: {
        display: 'flex',
        width: '90%',
        flexDirection: 'column',
        marginTop: theme.spacing(4),
    },

    [`& .${classes.linkRow}`]: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 24,
        marginBottom: 26,
    },

    [`& .${classes.linkUrlBox}`]: {
        backgroundColor: `${theme.palette.background.paper}66`,
        marginRight: 8,
        padding: '8px 16px',
        borderRadius: 5,
        maxWidth: '80%',
        overflow: 'hidden',
        flex: 1,
    },

    [`& .${classes.dropdownSelect}`]: {
        borderRadius: MAIN_BORDER_RADIUS,
    },

    [`& .${classes.dropdownInput}`]: {
        padding: 0,
        backgroundColor: theme.palette.background.default,
        '&.MuiSelect-select ': {
            backgroundColor: theme.palette.background.default,
        },
    },

    [`& .${classes.selectedOptionText}`]: {
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        marginTop: 36,
    },
}))

const StyledDropdownContainer = styled(Box)(({ theme }) => ({
    [`&.${classes.dropdownContainer}`]: {
        marginTop: 5,
        height: 104,
        width: 388,
        backgroundColor: theme.palette.background.paper,
        filter: theme.palette.dropdown.filter,
        borderRadius: MAIN_BORDER_RADIUS,
    },

    [`& .${classes.item}`]: {
        color: theme.palette.text.light,
        height: 52,
        width: 388,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.dropdown.hover,
            fontWeight: 'bold',
        },
    },

    [`& .${classes.itemTitle}`]: {
        marginLeft: 16,
        height: 52,
        width: 388,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 'bold',
            color: theme.palette.dropdown.titleHover,
        },
    },
}))

interface LinkSharingProps {
    shareLink: string
}

type ShareTypes = 'anyone' | 'invite'

const LinkSharing: FC<LinkSharingProps> = ({ shareLink }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const project = useSelector(getActiveProject)
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

    const selectShareVariant = (value: ShareTypes) => {
        if (project) {
            const copy = { ...project }
            copy.isSharedForView = value === 'anyone'
            dispatch(updateActiveProjectAsync(copy))
        }
    }

    return (
        <StyledBox className={classes.columnContainer}>
            {project?.isSharedForView && (
                <Box alignSelf='center'>
                    <AgentsImage />
                </Box>
            )}
            {!project?.isSharedForView && (
                <Box alignSelf='center'>
                    <NotSharedImage />
                </Box>
            )}

            <DropdownMenu
                placement='bottom-start'
                onClose={() => setIsDropdownOpen(false)}
                open={isDropdownOpen}
                borderRadius={MAIN_BORDER_RADIUS}
                content={
                    <StyledDropdownContainer className={classes.dropdownContainer}>
                        <Box
                            style={{ borderTopLeftRadius: MAIN_BORDER_RADIUS, borderTopRightRadius: MAIN_BORDER_RADIUS }}
                            className={classes.item}
                            onClick={() => {
                                setIsDropdownOpen(false)
                                selectShareVariant('anyone')
                            }}
                        >
                            <Typography className={classes.itemTitle}>Anyone who has the link can view</Typography>

                            {project && project.isSharedForView && <CheckIcon style={{ marginRight: 16 }} />}
                        </Box>
                        <Box
                            style={{ borderBottomLeftRadius: MAIN_BORDER_RADIUS, borderBottomRightRadius: MAIN_BORDER_RADIUS }}
                            className={classes.item}
                            onClick={() => {
                                setIsDropdownOpen(false)
                                selectShareVariant('invite')
                            }}
                        >
                            <Typography className={classes.itemTitle}>Only people invite to this project can access</Typography>
                            {project && !project.isSharedForView && <CheckIcon style={{ marginRight: 16 }} />}
                        </Box>
                    </StyledDropdownContainer>
                }
            >
                <Box className={classes.selectedOptionText} onClick={() => setIsDropdownOpen(true)}>
                    <Typography style={{ color: theme.palette.text.normal }}>
                        {project && project.isSharedForView && 'Anyone who has the link can view'}
                        {project && !project.isSharedForView && 'Only people invite to this project can access'}
                    </Typography>
                    <KeyboardArrowDownIcon style={{ color: theme.palette.neutral.dark3, marginLeft: 20 }} />
                </Box>
            </DropdownMenu>
            <Box>
                {project?.isSharedForView && (
                    <Text color='faded' fontSize={14}>
                        To view the project, user doesn’t need to be sign in.
                    </Text>
                )}
                {!project?.isSharedForView && (
                    <Typography style={{ color: theme.palette.alert.light, fontSize: Fonts.size.small }}>
                        The shared link is not active.
                    </Typography>
                )}
            </Box>
            <Box className={classes.linkRow}>
                <Box className={classes.linkUrlBox}>
                    <Text
                        color='faded'
                        fontSize={16}
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    >
                        {shareLink}
                    </Text>
                </Box>
                <GradientButton
                    size='small'
                    title='Copy'
                    style={{ minWidth: 36 }}
                    startIcon={<LinkIcon style={{ color: '#FAFAFC' }} />}
                    onClick={() => {
                        navigator.clipboard.writeText(shareLink || '')
                        showFlashMessageWithTimeout(dispatch, 'Copied to clipboard', 'success', 2500)
                    }}
                />
            </Box>
        </StyledBox>
    )
}

export default LinkSharing
