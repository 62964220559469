import { GlobalVars } from 'global'
import store from 'services/store'
import { ApiError } from 'shared/payload-types/base-payloads'
import {
    AssignUserShareRole,
    CreateProjectRequest,
    CreateProjectResponse,
    DeleteProjectRequest,
    ExportProjectResponse,
    FetchAllProjectsResponse,
    FetchProjectResponse,
    FetchUserSharingsResponse,
    GetLatestNpmPackageVersionResponse,
    GetProjectOpenApiResponse,
    ImportProjectResponse,
    PublishNpmPackageRequest,
    PublishNpmPackageResponse,
    UnassignUserShareRole,
    UpdateProjectRequest,
    UpdateProjectResponse,
    UpdateProjectSharedCode,
} from 'shared/payload-types/project-payloads'
import { Project, SharedCode, ShareRoles } from 'shared/types/project-types'
import { UserSharing } from 'shared/types/user-types'
import { readBytesAsync } from 'utils/generic-utils'
import { ErrorCategory, logError } from './Analytics'
import { handleNetworkResponseAndGetJsonAsync, handleNetworkResponseWithNoPayloadAsync, Network } from './Network'

export class ProjectsManager {
    static _instance: ProjectsManager | null = null

    static getManager(): ProjectsManager {
        if (this._instance === null) {
            this._instance = new ProjectsManager()
        }
        return this._instance
    }

    async fetchAllProjectsAsync(): Promise<FetchAllProjectsResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/all`
        try {
            response = await Network.fetch(url)
        } catch (e) {
            console.log(e)
            logError(ErrorCategory.Projects, 'fetchAllProjectsAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }

        if (response) {
            const data: FetchAllProjectsResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return data
        } else {
            logError(ErrorCategory.Projects, 'fetchAllProjectsAsync:: No response returned from the server', {
                url: url,
            })
            throw new Error('Server error while fetching all projects')
        }
    }

    async createProjectAsync(project: Omit<Project, 'id'>): Promise<CreateProjectResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/create`

        const body: CreateProjectRequest = { project: project }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'createProjectAsync:: Error executing fetch request', { project })
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const data: CreateProjectResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return data
        } else {
            logError(ErrorCategory.Network, 'createProjectAsync:: No response returned', {
                project,
            })
            throw new Error('No response returned.')
        }
    }

    async deleteProjectAsync(id: string): Promise<void> {
        const url = `${GlobalVars.SERVER_ENDPOINT}/project`
        const body: DeleteProjectRequest = { id: id }
        try {
            await Network.fetch(url, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'deleteProjectAsync:: Error executing fetch request', { id })
            throw new Error("Couldn't execute server request.")
        }
    }

    async getSingleProjectByIdAsync(id: string): Promise<Project> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/single?id=${id}`
        if (!id) throw new Error('Param id is empty')

        try {
            response = await Network.fetch(url)
        } catch (e) {
            logError(ErrorCategory.Projects, 'getSingleProjectByIdAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const data: FetchProjectResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return data.project
        } else {
            logError(ErrorCategory.Network, 'getSingleProjectByIdAsync:: No response returned', {
                id,
            })
            throw new Error('No response returned.')
        }
    }

    async updateProjectAsync(project: Project): Promise<UpdateProjectResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/update`
        const body: UpdateProjectRequest = { project }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'updateProjectAsync:: Error executing fetch request', { project })
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const data: UpdateProjectResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return data
        } else {
            logError(ErrorCategory.Network, 'updateProjectAsync:: No response returned', {
                project,
            })
            throw new Error('No response returned.')
        }
    }

    async inviteUserToProjectAsync(projectId: string, shareRole: ShareRoles, userEmail: string): Promise<void> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/share`
        const body: AssignUserShareRole = {
            projectId: projectId,
            shareRole: shareRole,
            userEmail: userEmail,
        }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'inviteUserToProjectAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            await handleNetworkResponseWithNoPayloadAsync(response, url)
        } else {
            logError(ErrorCategory.Network, 'inviteUserToProjectAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }

    async deleteUserFromProjectAsync(projectId: string, userEmail: string): Promise<void> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/share`
        const body: UnassignUserShareRole = {
            projectId: projectId,
            userEmail: userEmail,
        }
        try {
            response = await Network.fetch(url, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'deleteUserFromProjectAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            await handleNetworkResponseWithNoPayloadAsync(response, url)
        } else {
            logError(ErrorCategory.Network, 'deleteUserFromProjectAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }

    async getProjectsSharedUsersAsync(projectId: string): Promise<UserSharing[]> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/user?projectId=${projectId}`
        if (!projectId) throw new Error('Param projectId is empty')

        try {
            response = await Network.fetch(url)
        } catch (e) {
            logError(ErrorCategory.Projects, 'getProjectsSharedUsersAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const data: FetchUserSharingsResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return data.users
        } else {
            logError(ErrorCategory.Network, 'getProjectsSharedUsersAsync:: No response returned', {
                projectId,
            })
            throw new Error('No response returned.')
        }
    }

    async updateProjectsSharedCodeAsync(projectId: string, sharedCode: SharedCode[]): Promise<void> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/sharedCode/update`
        const body: UpdateProjectSharedCode = {
            projectId: projectId,
            sharedCode: sharedCode,
        }
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'updateProjectsSharedCodeAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            await handleNetworkResponseWithNoPayloadAsync(response, url)
        } else {
            logError(ErrorCategory.Network, 'updateProjectsSharedCodeAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }

    async exportProjectAsync(projectId: string): Promise<ExportProjectResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/export?id=${projectId}`

        try {
            response = await Network.fetch(url)
        } catch (e) {
            logError(ErrorCategory.Network, 'exportProjectAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const result = (await handleNetworkResponseAndGetJsonAsync(response, url)) as ExportProjectResponse
            return result
        } else {
            logError(ErrorCategory.Network, 'exportProjectAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }

    async importProjectAsync(file: File): Promise<ImportProjectResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/import`
        const formData = new FormData()
        formData.append('project', file)
        const accessToken = store.getState().auth.token

        try {
            response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'importProjectAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const result: ImportProjectResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
            return result
        } else {
            logError(ErrorCategory.Network, 'importProjectAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }

    async getOpenApiAsync(projectId: string): Promise<GetProjectOpenApiResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/openapi/download?id=${projectId}`

        try {
            response = await Network.fetch(url)
        } catch (e) {
            logError(ErrorCategory.Network, 'generateOpenapiAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const result = (await handleNetworkResponseAndGetJsonAsync(response, url)) as GetProjectOpenApiResponse
            return result
        } else {
            logError(ErrorCategory.Network, 'generateOpenapiAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }

    async getLatestNpmPackageVersion(projectId: string): Promise<GetLatestNpmPackageVersionResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/definition/latest-version?id=${projectId}`

        try {
            response = await Network.fetch(url)
        } catch (e) {
            logError(ErrorCategory.Network, 'getLatestNpmPackageVersion:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            const result = (await handleNetworkResponseAndGetJsonAsync(response, url)) as GetLatestNpmPackageVersionResponse
            return result
        } else {
            logError(ErrorCategory.Network, 'getLatestNpmPackageVersion:: No response returned')
            throw new Error('No response returned.')
        }
    }

    async getProjectDefinitionsContentAsync(projectId: string): Promise<Uint8Array> {
        let response: Response | null

        const url = `${GlobalVars.SERVER_ENDPOINT}/project/definition/download?id=${projectId}`

        try {
            response = await Network.fetch(url)
        } catch (e) {
            logError(ErrorCategory.Network, 'getProjectDefinitionsContentAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response && response.body) {
            const reader = response.body.getReader()
            return await readBytesAsync(reader)
        } else {
            logError(ErrorCategory.Network, 'getProjectDefinitionsContentAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }

    async publishNpmPackageAsync(projectId: string, version: string): Promise<PublishNpmPackageResponse> {
        let response: Response | null
        const url = `${GlobalVars.SERVER_ENDPOINT}/project/definition/publish`
        const body: PublishNpmPackageRequest = { id: projectId, version: version }
        const accessToken = store.getState().auth.token
        try {
            response = await Network.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',

                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(body),
            })
        } catch (e) {
            logError(ErrorCategory.Network, 'publishNpmPackageAsync:: Error executing fetch request')
            throw new Error("Couldn't execute server request.")
        }
        if (response) {
            if (response.status >= 400 && response.status <= 500) {
                const apiError: ApiError = await handleNetworkResponseAndGetJsonAsync(response, url)
                throw new Error(apiError.message)
            } else {
                const result: PublishNpmPackageResponse = await handleNetworkResponseAndGetJsonAsync(response, url)
                return result
            }
        } else {
            logError(ErrorCategory.Network, 'publishNpmPackageAsync:: No response returned')
            throw new Error('No response returned.')
        }
    }
}
