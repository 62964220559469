import { Component, ErrorInfo, ReactNode } from 'react'
import { connect } from 'react-redux'
import { showFlashMessage } from 'services/actions/flashMessage-actions'
import { FlashMessageType, ShowFlashMessage } from 'services/types/flashMessages-types'

interface DispatchProps {
    showFlashMessage: (message: string, type: FlashMessageType, id?: number | undefined) => ShowFlashMessage
}

interface ErrorBoundaryProps extends DispatchProps {
    children: ReactNode
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(`${error}: ${errorInfo}`)
        this.props.showFlashMessage(`${error}`, 'error')
        //this.props.showFlashMessage(`${error}: ${errorInfo.componentStack}`, 'error') //this is used to display stack trace
    }

    public render() {
        return this.props.children
    }
}

export default connect(null, { showFlashMessage })(ErrorBoundary)
