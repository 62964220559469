import * as React from 'react'

export interface Props extends React.SVGProps<SVGSVGElement> {
    color: string
}

function SuccessIcon(props: Props): JSX.Element {
    return (
        <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M22 11.08V12a10 10 0 11-5.93-9.14'
                stroke={props.color}
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M22 4L12 14.01l-3-3' stroke={props.color} strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    )
}

export default SuccessIcon
