import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { LoadProjectRouteParams, LoadProjectState, RouteConstants } from 'navigation/navigation-types'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { ProjectsManager } from 'services/api/ProjectsManager'
import { showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import { useDispatch, useSelector } from 'react-redux'
import {
    setActiveProject,
    setOpenedCollectionsIds,
    updateSharedCodeCacheForActiveProject,
} from 'services/actions/project-actions'
import ErrorGenericContent from 'components/ErrorGenericContent'
import LoadingGenericContent from 'components/LoadingGenericContent'
import { getAuthState } from 'services/selectors/auth-selectors'

/**
 * The goal of this screen is to load the project from the url and show the loading screen meanwhile.
 * If there is an error, the user sees it.
 */
const LoadProject: React.FC = () => {
    const { projectId } = useParams<LoadProjectRouteParams>()
    const { isAuthenticated } = useSelector(getAuthState)

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation<LoadProjectState>()

    const [serverRequestState, setServerRequestState] = useState<{ fetched: boolean; isFetching: boolean; isError: boolean }>({
        fetched: false,
        isFetching: false,
        isError: false,
    })

    useEffect(() => {
        const callback = async () => {
            if (!serverRequestState.isFetching && !serverRequestState.fetched) {
                setServerRequestState({ fetched: false, isFetching: true, isError: false })
                try {
                    const project = await ProjectsManager.getManager().getSingleProjectByIdAsync(projectId)
                    const urlParts = location.state.originalPath.split('/').filter((el) => el !== '')
                    const projectCollectionsIds = project.collections.map((c) => c.id)
                    dispatch(setActiveProject(project))
                    dispatch(updateSharedCodeCacheForActiveProject())
                    setServerRequestState({ isError: false, isFetching: false, fetched: true })
                    // Expected structure of urlParts: project/projectId/collectionId/endpointId
                    // EndpointId is optional value in this case.
                    if (`/${urlParts[0]}` === RouteConstants.project && urlParts.length >= 3) {
                        const collectionId = urlParts[2]
                        if (collectionId && projectCollectionsIds.includes(collectionId)) {
                            dispatch(setOpenedCollectionsIds([collectionId]))
                        }
                    } else {
                        // If project details screen was active before refresh, it will be opened again.
                        // In other cases we open active collection/endpoint.
                        history.push(`${RouteConstants.project}/${project.id}`)
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (e: any) {
                    setServerRequestState({ isFetching: false, fetched: true, isError: true })
                    showFlashMessageWithTimeout(dispatch, e ? (e.message ? e.message : e.toString()) : 'Unknown error', 'error')
                }
            }
        }
        callback()
    }, [isAuthenticated, projectId])

    if (!projectId) {
        return (
            <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', flex: 0.6, justifyContent: 'center' }}>
                <ErrorGenericContent subtitle='Project ID not found in the url.' />
            </Box>
        )
    }

    if (serverRequestState.fetched && !serverRequestState.isError) {
        return <Redirect to={{ pathname: location?.state?.originalPath || `${RouteConstants.project}/${projectId}` }} />
    }

    if (serverRequestState.isError && isAuthenticated) {
        return (
            <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', flex: 0.6, justifyContent: 'center' }}>
                <ErrorGenericContent subtitle="Couldn't load the project" />
            </Box>
        )
    }

    if (serverRequestState.isError && !isAuthenticated) {
        return <Redirect to={{ pathname: RouteConstants.unauthenticated }} />
    }

    return <LoadingGenericContent />
}

export default LoadProject
