import { Button, Divider, IconButton, Stack, Tooltip } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined' // check if right import
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import Text from './Text'
import SyncingWithServerIndicator from './SyncingIndicator'
import DropdownMenu from './DropdownMenu'
import ModeDropdown from 'screens/ApiDetails/ModeDropdown'
import { capitalizeFirstLetter } from 'shared/utils/generic-utils'
import { ModeType } from 'services/types/userSettings-types'
import { getSelectedModeType } from 'services/selectors/projects-selectors'
import EditInfo from './EditInfo'

interface EditPanelProps {
    name: string
    createdAt: Date
    lastModifiedAt: Date
    isReadOnly: boolean
    onChangeModeType: (type: ModeType) => void
    onDelete: () => void
}

const EditPanel: React.FC<EditPanelProps> = ({ name, createdAt, lastModifiedAt, isReadOnly, onChangeModeType, onDelete }) => {
    const selectedModeType = useSelector(getSelectedModeType)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const isEditable = isReadOnly ? false : selectedModeType === 'edit' ? true : false
    return (
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between' sx={{ minHeight: 40 }}>
            <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                divider={<Divider orientation='vertical' sx={{ bgcolor: 'text.normal' }} flexItem />}
            >
                <Text color='faded' fontSize={16}>
                    {name}
                </Text>
                <EditInfo lastModifiedAt={lastModifiedAt} createdAt={createdAt} />
            </Stack>
            <Stack direction='row' spacing={2} alignItems='center'>
                {isEditable && (
                    <Tooltip title={`Delete ${name}`} placement='bottom'>
                        <IconButton onClick={onDelete} sx={{ color: 'neutral.light3' }} disableRipple>
                            <DeleteOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}

                <SyncingWithServerIndicator tooltip={'All changes saved'} />
                <DropdownMenu
                    open={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    borderRadius={10}
                    content={
                        <ModeDropdown
                            onChangeModeType={(type: ModeType) => {
                                onChangeModeType(type)
                                setIsDropdownOpen(false)
                            }}
                        />
                    }
                >
                    <Button
                        onClick={() => (isReadOnly ? null : setIsDropdownOpen(true))}
                        endIcon={!isReadOnly ? <KeyboardArrowDown sx={{ color: 'neutral.light3' }} /> : undefined}
                        sx={{ width: 65, textTransform: 'none' }}
                        disableElevation
                    >
                        <Text color='light' fontSize={16}>
                            {capitalizeFirstLetter(selectedModeType)}
                        </Text>
                    </Button>
                </DropdownMenu>
            </Stack>
        </Stack>
    )
}

export default EditPanel
