import * as React from 'react'

function ChangesDetectedImage(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width='100%' height='100%' viewBox='0 0 252 226' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M8.5542 8.52026L18.3706 218.876H232.367L241.763 8.52026H8.5542Z' fill='#FFD88B' />
            <path
                d='M249.336 0.5L239.379 225.49H9.95659L0 0.5H249.336ZM18.3706 218.876H232.367L241.763 8.52027H8.55426H6.17028L15.5659 218.876H18.3706Z'
                fill='#FF851D'
            />
            <path
                d='M8.55438 8.52027L18.3707 218.876H15.5661L6.17041 8.52027H8.55438ZM249.336 0.5L239.379 225.49H243.025L252 0.5H249.336Z'
                fill='#DC590F'
            />
            <path
                opacity='0.5'
                d='M222.13 75.3558H223.533C223.813 89.1451 222.972 102.934 221.149 116.864C210.07 115.035 198.992 114.332 188.194 114.754L187.072 113.206L222.13 75.3558Z'
                fill='#583819'
            />
            <path
                d='M222.411 71.1346C221.569 86.3309 220.167 100.683 217.502 113.769L184.407 113.206C185.108 101.387 184.267 87.0345 182.164 70.7125L222.411 71.1346Z'
                fill='#FFFAE3'
            />
            <path opacity='0.6' d='M205.302 79.2956H198.29L197.309 62.1294H206.284L205.302 79.2956Z' fill='#FFFAD3' />
            <path
                d='M211.192 79.7177C211.192 79.9991 210.912 80.2806 210.631 80.2806C210.351 80.2806 210.07 79.9991 210.07 79.7177C210.07 79.4363 210.351 79.1549 210.631 79.1549C210.912 79.1549 211.192 79.4363 211.192 79.7177Z'
                fill='#8864D3'
            />
            <path
                d='M213.576 79.7177C213.576 79.9991 213.295 80.2806 213.015 80.2806C212.735 80.2806 212.454 79.9991 212.454 79.7177C212.454 79.4363 212.735 79.1549 213.015 79.1549C213.295 79.1549 213.576 79.4363 213.576 79.7177Z'
                fill='#FBD15B'
            />
            <path
                d='M215.82 79.8585C215.82 80.1399 215.54 80.4213 215.259 80.4213C214.979 80.4213 214.698 80.1399 214.698 79.8585C214.698 79.5771 214.979 79.2957 215.259 79.2957C215.54 79.2957 215.82 79.5771 215.82 79.8585Z'
                fill='#FF8578'
            />
            <path d='M196.608 84.2203H192.12V84.6425H196.608V84.2203Z' fill='#FF8578' />
            <path d='M201.796 84.2203H197.309V84.6425H201.796V84.2203Z' fill='#FBD15B' />
            <path d='M200.394 86.1902H193.522V86.6123H200.394V86.1902Z' fill='#8864D3' />
            <path d='M205.021 86.1902H201.516V86.6123H205.021V86.1902Z' fill='#FF8578' />
            <path d='M198.991 88.3008H195.906V88.7229H198.991V88.3008Z' fill='#FBD15B' />
            <path d='M210.771 88.3008H200.674V88.7229H210.771V88.3008Z' fill='#FBD15B' />
            <path d='M209.79 90.2708H198.291V90.6929H209.79V90.2708Z' fill='#8864D3' />
            <path d='M198.852 92.3813H195.346V92.8035H198.852V92.3813Z' fill='#FF8578' />
            <path d='M203.9 92.3813H200.394V92.8035H203.9V92.3813Z' fill='#8864D3' />
            <path d='M197.309 102.934H192.12V103.356H197.309V102.934Z' fill='#FF8578' />
            <path d='M204.32 102.934H199.132V103.356H204.32V102.934Z' fill='#FBD15B' />
            <path d='M211.052 102.934H205.863V103.356H211.052V102.934Z' fill='#FF8578' />
            <path d='M203.619 100.824H193.522V101.246H203.619V100.824Z' fill='#8864D3' />
            <path d='M215.259 100.824H205.162V101.246H215.259V100.824Z' fill='#FF8578' />
            <path d='M206.003 98.8538H195.906V99.2759H206.003V98.8538Z' fill='#FF8578' />
            <path d='M209.649 98.8538H206.845V99.2759H209.649V98.8538Z' fill='#FBD15B' />
            <path d='M198.991 96.7432H195.205V97.1653H198.991V96.7432Z' fill='#FBD15B' />
            <path d='M204.741 96.7432H200.955V97.1653H204.741V96.7432Z' fill='#8864D3' />
            <path d='M211.051 96.7432H207.265V97.1653H211.051V96.7432Z' fill='#FF8578' />
            <path d='M204.882 94.7733H199.553V95.1954H204.882V94.7733Z' fill='#FBD15B' />
            <path d='M197.589 94.7733H192.26V95.1954H197.589V94.7733Z' fill='#8864D3' />
            <path d='M189.596 83.7982H189.175V84.2203H189.596V83.7982Z' fill='#F9FBFC' />
            <path
                d='M189.596 101.527H189.175V100.542H189.596V101.527ZM189.596 99.1352H189.175V98.1502H189.596V99.1352ZM189.596 96.6025H189.175V95.6175H189.596V96.6025ZM189.596 94.2105H189.175V93.2255H189.596V94.2105ZM189.596 91.6777H189.175V90.6928H189.596V91.6777ZM189.596 89.2857H189.175V88.3008H189.596V89.2857ZM189.596 86.753H189.175V85.7681H189.596V86.753Z'
                fill='#F9FBFC'
            />
            <path d='M189.596 103.075H189.175V103.497H189.596V103.075Z' fill='#F9FBFC' />
            <path
                opacity='0.5'
                d='M208.387 150.212H209.649C209.93 163.297 209.089 176.383 207.406 189.469C196.888 187.78 186.511 187.077 176.134 187.499L175.012 186.092L208.387 150.212Z'
                fill='#583819'
            />
            <path
                d='M208.668 146.272C207.967 160.624 206.564 174.132 204.04 186.514L172.628 185.951C173.329 174.835 172.488 161.187 170.524 145.709L208.668 146.272Z'
                fill='#FFFAE3'
            />
            <path opacity='0.6' d='M192.401 154.011H185.81L184.968 137.689H193.382L192.401 154.011Z' fill='#FFFAD3' />
            <path
                opacity='0.5'
                d='M64.5076 99.1353H65.7697C66.0501 111.517 65.2087 123.9 63.6662 136.282C53.7096 134.734 43.8932 134.03 34.0768 134.312L33.0952 133.046L64.5076 99.1353Z'
                fill='#583819'
            />
            <path
                d='M64.648 95.4769C63.9468 109.125 62.6847 121.93 60.3007 133.608L30.5712 133.046C31.2724 122.493 30.4309 109.548 28.6079 94.9141L64.648 95.4769Z'
                fill='#FFFAE3'
            />
            <path opacity='0.6' d='M49.3624 102.653H43.0519L42.2104 87.3159H50.2038L49.3624 102.653Z' fill='#FFFAD3' />
            <path
                d='M53.9905 100.542C53.9905 100.824 53.7101 101.105 53.4296 101.105C53.1491 101.105 52.8687 100.824 52.8687 100.542C52.8687 100.261 53.1491 99.9795 53.4296 99.9795C53.7101 99.9795 53.9905 100.261 53.9905 100.542Z'
                fill='#8864D3'
            />
            <path
                d='M56.3743 100.542C56.3743 100.824 56.0938 101.105 55.8134 101.105C55.5329 101.105 55.2524 100.824 55.2524 100.542C55.2524 100.261 55.5329 99.9795 55.8134 99.9795C56.2341 99.9795 56.3743 100.261 56.3743 100.542Z'
                fill='#FBD15B'
            />
            <path
                d='M58.6185 100.683C58.6185 100.964 58.338 101.246 58.0575 101.246C57.7771 101.246 57.4966 100.964 57.4966 100.683C57.4966 100.402 57.7771 100.12 58.0575 100.12C58.338 100.12 58.6185 100.402 58.6185 100.683Z'
                fill='#FF8578'
            />
            <path d='M39.4059 105.045H34.9185V105.467H39.4059V105.045Z' fill='#FF8578' />
            <path d='M44.5944 105.045H40.1069V105.467H44.5944V105.045Z' fill='#FBD15B' />
            <path d='M43.1923 107.015H36.3208V107.437H43.1923V107.015Z' fill='#8864D3' />
            <path d='M47.8198 107.015H44.314V107.437H47.8198V107.015Z' fill='#FF8578' />
            <path d='M41.7897 109.125H38.7046V109.547H41.7897V109.125Z' fill='#FBD15B' />
            <path d='M53.5695 109.125H43.4727V109.547H53.5695V109.125Z' fill='#FBD15B' />
            <path d='M52.588 111.095H41.0889V111.517H52.588V111.095Z' fill='#8864D3' />
            <path d='M41.6499 113.206H38.144V113.628H41.6499V113.206Z' fill='#FF8578' />
            <path d='M46.6982 113.206H43.1924V113.628H46.6982V113.206Z' fill='#8864D3' />
            <path d='M40.1071 123.759H34.9185V124.181H40.1071V123.759Z' fill='#FF8578' />
            <path d='M47.1188 123.759H41.9302V124.181H47.1188V123.759Z' fill='#FBD15B' />
            <path d='M53.8498 123.759H48.6611V124.181H53.8498V123.759Z' fill='#FF8578' />
            <path d='M46.4176 121.648H36.3208V122.07H46.4176V121.648Z' fill='#8864D3' />
            <path d='M58.0568 121.648H47.96V122.07H58.0568V121.648Z' fill='#FF8578' />
            <path d='M48.8014 119.678H38.7046V120.1H48.8014V119.678Z' fill='#FF8578' />
            <path d='M52.4477 119.678H49.6431V120.1H52.4477V119.678Z' fill='#FBD15B' />
            <path d='M41.7897 117.568H38.0034V117.99H41.7897V117.568Z' fill='#FBD15B' />
            <path d='M47.5397 117.568H43.7534V117.99H47.5397V117.568Z' fill='#8864D3' />
            <path d='M53.8498 117.568H50.0635V117.99H53.8498V117.568Z' fill='#FF8578' />
            <path d='M47.68 115.598H42.3511V116.02H47.68V115.598Z' fill='#FBD15B' />
            <path d='M40.3875 115.598H35.0586V116.02H40.3875V115.598Z' fill='#8864D3' />
            <path d='M32.3943 104.623H31.9736V105.045H32.3943V104.623Z' fill='#F9FBFC' />
            <path
                d='M32.3943 122.352H31.9736V121.367H32.3943V122.352ZM32.3943 119.96H31.9736V118.975H32.3943V119.96ZM32.3943 117.427H31.9736V116.442H32.3943V117.427ZM32.3943 115.035H31.9736V114.05H32.3943V115.035ZM32.3943 112.502H31.9736V111.517H32.3943V112.502ZM32.3943 110.11H31.9736V109.125H32.3943V110.11ZM32.3943 107.578H31.9736V106.593H32.3943V107.578Z'
                fill='#F9FBFC'
            />
            <path d='M32.3943 123.9H31.9736V124.322H32.3943V123.9Z' fill='#F9FBFC' />
            <path
                opacity='0.5'
                d='M68.7147 30.8926H70.2572C70.5377 46.7924 69.5561 62.8329 67.4525 78.7328C54.6913 76.7629 41.93 75.9186 29.4492 76.3408L28.0469 74.6523L68.7147 30.8926Z'
                fill='#583819'
            />
            <path
                d='M68.995 26.1085C68.1536 43.6968 66.3306 60.1594 63.3857 75.3557L25.1018 74.6522C25.9432 61.0037 24.8214 44.4003 22.5776 25.5457L68.995 26.1085Z'
                fill='#FFFAE3'
            />
            <path opacity='0.6' d='M49.2221 35.5359H41.2288L40.1069 15.5555H50.344L49.2221 35.5359Z' fill='#FFFAD3' />
            <path
                d='M39.6863 50.0287L34.0769 52.4207L39.6863 54.8127L38.9851 56.5012L32.2539 53.4056V51.295L38.9851 48.3402L39.6863 50.0287Z'
                fill='#8864D3'
            />
            <path d='M47.9602 44.4004L43.8934 60.0188L41.9302 59.5967L45.997 43.9783L47.9602 44.4004Z' fill='#8864D3' />
            <path
                d='M57.636 51.295V53.4056L50.9048 56.3604L50.2036 54.672L55.813 52.2799L50.2036 49.8879L50.9048 48.1995L57.636 51.295Z'
                fill='#8864D3'
            />
            <path
                opacity='0.5'
                d='M142.197 71.416H82.3169C83.4388 104.341 84.4204 136.141 83.7192 154.433C119.198 153.448 102.511 153.166 139.953 153.729C141.776 127.136 142.477 99.698 142.197 71.416Z'
                fill='#583819'
            />
            <path
                d='M130.417 136.141C130.277 139.096 130.137 142.051 129.996 145.005C116.253 145.568 102.651 145.99 89.4688 146.131C89.4688 143.036 89.4688 139.94 89.3286 136.845C103.212 136.845 116.955 136.563 130.417 136.141Z'
                fill='#AF8A74'
            />
            <path
                d='M140.514 68.6019C140.795 96.8839 140.093 124.322 138.27 150.774C100.828 152.604 117.516 152.885 82.0366 151.478C82.5975 133.327 81.6159 101.386 80.6343 68.4612H140.514V68.6019Z'
                fill='#FFFAE3'
            />
            <path d='M100.267 104.764H92.6943V105.467H100.267V104.764Z' fill='#FF8578' />
            <path d='M106.858 108.141H95.0781V108.844H106.858V108.141Z' fill='#8864D3' />
            <path d='M114.711 108.141H108.681V108.844H114.711V108.141Z' fill='#FF8578' />
            <path d='M104.334 111.658H99.145V112.362H104.334V111.658Z' fill='#FBD15B' />
            <path d='M104.194 118.553H98.1636V119.256H104.194V118.553Z' fill='#FF8578' />
            <path d='M112.888 118.553H106.858V119.256H112.888V118.553Z' fill='#8864D3' />
            <path d='M101.389 136.282H92.6943V136.985H101.389V136.282Z' fill='#FF8578' />
            <path d='M113.169 136.282H104.474V136.985H113.169V136.282Z' fill='#FBD15B' />
            <path d='M124.668 136.282H115.973V136.985H124.668V136.282Z' fill='#FF8578' />
            <path d='M112.187 132.905H95.0781V133.608H112.187V132.905Z' fill='#8864D3' />
            <path d='M131.96 132.905H114.851V133.608H131.96V132.905Z' fill='#FF8578' />
            <path d='M116.113 129.528H99.145V130.231H116.113V129.528Z' fill='#FF8578' />
            <path d='M122.284 129.528H117.516V130.231H122.284V129.528Z' fill='#FBD15B' />
            <path d='M104.474 126.01H98.0234V126.714H104.474V126.01Z' fill='#FBD15B' />
            <path d='M114.29 126.01H107.839V126.714H114.29V126.01Z' fill='#8864D3' />
            <path d='M124.668 126.01H118.217V126.714H124.668V126.01Z' fill='#FF8578' />
            <path d='M114.431 122.633H105.456V123.337H114.431V122.633Z' fill='#FBD15B' />
            <path d='M102.09 122.633H93.1152V123.337H102.09V122.633Z' fill='#8864D3' />
            <path d='M88.6275 104.201H87.7861V105.045H88.6275V104.201Z' fill='#F9FBFC' />
            <path
                d='M88.4874 134.171H87.646V132.483H88.4874V134.171ZM88.4874 129.95H87.646V128.261H88.4874V129.95ZM88.4874 125.729H87.646V124.04H88.4874V125.729ZM88.4874 121.648H87.646V119.96H88.4874V121.648ZM88.4874 117.427H87.646V115.739H88.4874V117.427ZM88.4874 113.347H87.646V111.658H88.4874V113.347ZM88.4874 109.125H87.646V107.437H88.4874V109.125Z'
                fill='#F9FBFC'
            />
            <path d='M88.6275 136.563H87.7861V137.407H88.6275V136.563Z' fill='#F9FBFC' />
            <path
                d='M125.088 76.4814C125.088 77.0442 124.668 77.4664 124.107 77.4664C123.546 77.4664 123.125 77.0442 123.125 76.4814C123.125 75.9186 123.546 75.4965 124.107 75.4965C124.668 75.4965 125.088 75.9186 125.088 76.4814Z'
                fill='#8864D3'
            />
            <path
                d='M129.155 76.4814C129.155 77.0442 128.734 77.4664 128.174 77.4664C127.613 77.4664 127.192 77.0442 127.192 76.4814C127.192 75.9186 127.613 75.4965 128.174 75.4965C128.734 75.4965 129.155 75.9186 129.155 76.4814Z'
                fill='#FBD15B'
            />
            <path
                d='M132.801 76.6221C132.801 77.185 132.38 77.6071 131.82 77.6071C131.259 77.6071 130.838 77.185 130.838 76.6221C130.838 76.0593 131.259 75.6372 131.82 75.6372C132.38 75.6372 132.801 76.0593 132.801 76.6221Z'
                fill='#FF8578'
            />
            <path d='M100.267 83.9388H92.6943V84.6424H100.267V83.9388Z' fill='#FF8578' />
            <path d='M109.102 83.9388H101.529V84.6424H109.102V83.9388Z' fill='#FBD15B' />
            <path d='M106.858 87.4565H95.0781V88.1601H106.858V87.4565Z' fill='#8864D3' />
            <path d='M114.711 87.4565H108.681V88.1601H114.711V87.4565Z' fill='#FF8578' />
            <path d='M104.334 90.8335H99.145V91.537H104.334V90.8335Z' fill='#FBD15B' />
            <path d='M124.387 90.8335H107.279V91.537H124.387V90.8335Z' fill='#FBD15B' />
            <path d='M122.564 94.3511H103.072V95.0546H122.564V94.3511Z' fill='#8864D3' />
            <path d='M104.194 97.728H98.1636V98.4316H104.194V97.728Z' fill='#FF8578' />
            <path d='M112.888 97.728H106.858V98.4316H112.888V97.728Z' fill='#8864D3' />
            <path d='M101.389 115.598H92.6943V116.301H101.389V115.598Z' fill='#FF8578' />
            <path d='M113.169 115.598H104.474V116.301H113.169V115.598Z' fill='#FBD15B' />
            <path d='M124.668 115.598H115.973V116.301H124.668V115.598Z' fill='#FF8578' />
            <path d='M112.187 112.08H95.0781V112.784H112.187V112.08Z' fill='#8864D3' />
            <path d='M131.96 112.08H114.851V112.784H131.96V112.08Z' fill='#FF8578' />
            <path d='M122.284 108.703H117.516V109.407H122.284V108.703Z' fill='#FBD15B' />
            <path d='M104.474 105.186H98.0234V105.889H104.474V105.186Z' fill='#FBD15B' />
            <path d='M114.29 105.186H107.839V105.889H114.29V105.186Z' fill='#8864D3' />
            <path d='M124.668 105.186H118.217V105.889H124.668V105.186Z' fill='#FF8578' />
            <path d='M114.431 101.809H105.456V102.512H114.431V101.809Z' fill='#FBD15B' />
            <path d='M102.09 101.809H93.1152V102.512H102.09V101.809Z' fill='#8864D3' />
            <path d='M88.6275 83.376H87.7861V84.2202H88.6275V83.376Z' fill='#F9FBFC' />
            <path
                d='M88.4874 113.346H87.646V111.658H88.4874V113.346ZM88.4874 109.125H87.646V107.437H88.4874V109.125ZM88.4874 105.045H87.646V103.356H88.4874V105.045ZM88.4874 100.824H87.646V99.1351H88.4874V100.824ZM88.4874 96.6023H87.646V94.9139H88.4874V96.6023ZM88.4874 92.5219H87.646V90.8334H88.4874V92.5219ZM88.4874 88.3007H87.646V86.6122H88.4874V88.3007Z'
                fill='#F9FBFC'
            />
            <path d='M88.6275 115.879H87.7861V116.723H88.6275V115.879Z' fill='#F9FBFC' />
            <path
                opacity='0.5'
                d='M193.102 27.0935H152.995C153.696 47.3552 154.257 67.0541 153.837 78.3106C175.713 77.6071 168.561 77.4664 191.559 77.8885C192.821 61.5666 193.242 44.5411 193.102 27.0935Z'
                fill='#583819'
            />
            <path
                d='M151.873 25.2643C152.574 45.526 153.135 65.2249 152.714 76.4814C174.591 77.3257 167.439 77.1849 190.437 76.0593C191.559 59.7374 191.98 42.7119 191.84 25.2643H151.873Z'
                fill='#FFFAE3'
            />
            <path
                d='M168.14 39.3349C166.738 39.3349 166.317 39.757 166.317 40.742V45.2446C166.317 46.9331 165.616 47.4959 164.354 47.918C165.616 48.1994 166.317 48.7623 166.317 50.4507V54.9533C166.317 55.9383 166.738 56.3604 168.14 56.3604V58.3303C164.775 58.3303 163.793 57.2046 163.793 54.9533V50.7321C163.793 49.4658 163.512 49.0437 162.391 49.0437V46.7924C163.512 46.7924 163.793 46.2295 163.793 45.1039V40.8827C163.793 38.6314 164.775 37.5057 168.14 37.5057V39.3349Z'
                fill='#8864D3'
            />
            <path
                d='M179.219 40.742V44.9632C179.219 46.2296 179.499 46.6517 180.621 46.6517V48.903C179.499 48.903 179.219 49.3251 179.219 50.5915V54.8127C179.219 57.064 178.237 58.1896 174.872 58.1896V56.2197C176.134 56.2197 176.695 55.7976 176.695 54.8127V50.3101C176.695 48.6216 177.396 48.0588 178.658 47.7773C177.396 47.4959 176.695 46.9331 176.695 45.1039V40.6013C176.695 39.6164 176.274 39.1943 174.872 39.1943V37.2244C178.377 37.3651 179.219 38.4907 179.219 40.742Z'
                fill='#8864D3'
            />
            <path opacity='0.6' d='M173.89 29.0634H167.439L166.458 12.7415H174.872L173.89 29.0634Z' fill='#FFFAD3' />
            <path
                opacity='0.5'
                d='M58.0569 163.297H27.3457C27.9066 178.775 28.3273 193.831 28.0469 202.414C44.7347 201.992 39.2656 201.851 56.935 201.992C57.7764 189.609 58.0569 176.664 58.0569 163.297Z'
                fill='#583819'
            />
            <path
                d='M26.5044 162.031C27.0653 177.509 27.486 192.564 27.2056 201.147C43.8934 201.851 38.4243 201.71 56.0937 200.725C56.9351 188.343 57.2156 175.257 57.2156 162.031H26.5044Z'
                fill='#FFFAE3'
            />
            <path
                d='M38.8449 172.725C37.8633 172.725 37.4426 173.006 37.4426 173.71V177.087C37.4426 178.494 36.8816 178.775 35.9 179.056C36.8816 179.338 37.4426 179.76 37.4426 181.026V184.403C37.4426 185.107 37.8633 185.388 38.8449 185.388V186.936C36.3207 186.936 35.4793 186.092 35.4793 184.403V181.167C35.4793 180.182 35.1988 179.901 34.3574 179.901V178.212C35.1988 178.212 35.4793 177.79 35.4793 176.946V173.71C35.4793 172.021 36.1805 171.177 38.8449 171.177V172.725Z'
                fill='#8864D3'
            />
            <path
                d='M47.3993 173.71V176.946C47.3993 177.931 47.6798 178.212 48.5212 178.212V179.901C47.6798 179.901 47.3993 180.323 47.3993 181.167V184.403C47.3993 186.092 46.6981 186.936 44.0337 186.936V185.388C45.0153 185.388 45.436 185.107 45.436 184.403V181.026C45.436 179.76 45.997 179.338 46.9786 179.056C45.997 178.775 45.436 178.353 45.436 177.087V173.71C45.436 173.006 45.1556 172.725 44.0337 172.725V171.177C46.6981 171.177 47.3993 172.021 47.3993 173.71Z'
                fill='#8864D3'
            />
            <path opacity='0.6' d='M43.3326 164.845H38.4244L37.583 152.463H44.174L43.3326 164.845Z' fill='#FFFAD3' />
            <path
                d='M73.6229 147.96C84.9818 137.267 96.3408 126.292 107.7 115.457L141.356 134.875C147.105 138.252 152.995 141.629 158.745 144.865L176.274 154.714L177.676 155.559L178.377 154.151L206.845 93.3663L207.266 92.522L206.424 91.9592L169.823 66.632L107.279 112.08H107.139L76.1471 78.5921L43.1922 44.9633L41.9301 46.2296L73.7631 80.8434L104.755 112.502C101.389 112.924 97.8833 113.206 94.5177 113.628L81.8967 115.176L56.5144 118.131L55.5327 118.271L55.2523 119.116C52.4476 129.387 49.6429 139.799 47.1187 150.212C44.4543 160.483 41.9301 170.755 39.2656 180.886C50.4843 169.77 61.9835 158.935 73.6229 147.96ZM109.803 113.628L110.224 113.206L169.963 68.3205L204.461 93.0849L176.554 151.056L160.568 141.91C154.818 138.533 148.928 135.297 143.039 132.061L109.803 113.628ZM73.3424 145.568C62.4042 156.684 51.3257 167.941 40.107 179.057C43.0519 168.926 46.1371 158.935 49.2222 149.086C52.1671 139.518 54.9718 130.091 57.7765 120.523L82.1771 117.99L94.7982 116.724C97.4626 116.442 100.267 116.161 102.932 115.879C92.9751 125.729 83.1588 135.578 73.3424 145.568Z'
                fill='#C690F1'
            />
            <path
                d='M51.0454 118.834L57.9169 120.101L58.3376 119.256L52.588 115.317C52.7282 116.583 52.027 117.99 51.0454 118.834Z'
                fill='#FFD126'
            />
            <path
                d='M40.5278 118.693L49.643 119.678C50.204 119.538 50.6247 119.256 51.0454 118.834C52.1672 117.99 52.7282 116.724 52.5879 115.316C52.5879 114.754 52.3075 114.05 51.8868 113.487L44.3141 107.578C49.5028 112.221 44.4544 117.99 40.5278 118.693Z'
                fill='#54C6BE'
            />
            <path
                d='M44.1738 107.578C39.1254 106.874 36.6012 113.909 40.3875 118.693C44.4543 117.99 49.5027 112.221 44.1738 107.578Z'
                fill='#91E1D3'
            />
            <path
                d='M163.793 67.3355L170.664 68.6019L171.085 67.7577L165.336 63.8179C165.476 65.2249 164.775 66.4913 163.793 67.3355Z'
                fill='#FFD126'
            />
            <path
                d='M153.135 67.1948L162.25 68.1797C162.811 68.039 163.232 67.7576 163.653 67.3355C164.775 66.4913 165.336 65.2249 165.195 63.8178C165.195 63.255 164.915 62.5515 164.494 61.9887L156.922 56.079C162.25 60.863 157.202 66.632 153.135 67.1948Z'
                fill='#54C6BE'
            />
            <path
                d='M156.921 56.079C151.873 55.3754 149.349 62.4108 153.135 67.1948C157.202 66.632 162.25 60.863 156.921 56.079Z'
                fill='#91E1D3'
            />
            <path
                d='M199.553 92.9442L206.424 94.2105L206.845 93.3663L201.095 89.4265C201.236 90.6929 200.675 91.9592 199.553 92.9442Z'
                fill='#FFD126'
            />
            <path
                d='M189.035 92.8034L198.15 93.7884C198.711 93.6477 199.132 93.3662 199.553 92.9441C200.675 92.0999 201.235 90.8335 201.095 89.4265C201.095 88.8636 200.815 88.1601 200.394 87.5973L192.821 81.6876C198.01 86.3309 193.102 92.0999 189.035 92.8034Z'
                fill='#54C6BE'
            />
            <path
                d='M192.821 81.6876C187.773 80.9841 185.249 88.0194 189.035 92.8034C193.102 92.0999 198.01 86.3309 192.821 81.6876Z'
                fill='#91E1D3'
            />
            <path
                d='M32.8145 178.494L39.6859 179.76L40.1066 178.916L34.357 174.976C34.6375 176.242 33.9363 177.509 32.8145 178.494Z'
                fill='#FFD126'
            />
            <path
                d='M22.2974 178.212L31.4126 179.197C31.9735 179.056 32.3942 178.775 32.8149 178.353C33.9368 177.509 34.4977 176.242 34.3575 174.835C34.3575 174.272 34.077 173.569 33.6563 173.006L26.0837 167.096C31.2723 171.88 26.3641 177.649 22.2974 178.212Z'
                fill='#54C6BE'
            />
            <path
                d='M26.0835 167.237C21.0351 166.534 18.5108 173.569 22.2972 178.353C26.3639 177.649 31.2721 171.88 26.0835 167.237Z'
                fill='#91E1D3'
            />
            <path
                d='M98.2153 111.744L105.964 113.172L106.439 112.22L99.9549 107.777C100.113 109.205 99.3223 110.792 98.2153 111.744Z'
                fill='#FFD126'
            />
            <path
                d='M86.1958 111.585L96.4751 112.696C97.1077 112.537 97.5821 112.22 98.0566 111.744C99.3217 110.792 99.9543 109.364 99.7962 107.777C99.7962 107.142 99.4799 106.349 99.0055 105.714L90.4657 99.0497C96.4752 104.286 90.782 110.792 86.1958 111.585Z'
                fill='#8864D3'
            />
            <path
                d='M90.4659 99.0497C84.7727 98.2563 81.9261 106.19 86.196 111.585C90.7822 110.792 96.4754 104.286 90.4659 99.0497Z'
                fill='#9FABFF'
            />
            <path
                d='M35.4795 44.8226L42.3509 46.089L42.7716 45.2447L37.0221 41.3049C37.1623 42.712 36.4611 43.9784 35.4795 44.8226Z'
                fill='#FFD126'
            />
            <path
                d='M24.8218 44.6819L33.937 45.6668C34.4979 45.5261 34.9186 45.2447 35.3393 44.8226C36.4612 43.9783 37.0221 42.712 36.8819 41.3049C36.8819 40.7421 36.6014 40.0385 36.1807 39.4757L28.6081 33.566C33.937 38.3501 28.8886 44.119 24.8218 44.6819Z'
                fill='#54C6BE'
            />
            <path
                d='M28.6079 33.566C23.5595 32.8625 21.0353 39.8978 24.8216 44.6819C28.8884 44.119 33.9368 38.3501 28.6079 33.566Z'
                fill='#91E1D3'
            />
            <path
                d='M169.683 152.604L176.555 153.87L176.975 153.026L171.226 149.086C171.366 150.493 170.665 151.759 169.683 152.604Z'
                fill='#FFD126'
            />
            <path
                d='M159.025 152.463L168.141 153.448C168.702 153.307 169.122 153.026 169.543 152.604C170.665 151.759 171.226 150.493 171.085 149.086C171.085 148.523 170.805 147.82 170.384 147.257L162.812 141.347C168.141 146.131 163.092 151.9 159.025 152.463Z'
                fill='#54C6BE'
            />
            <path
                d='M162.812 141.347C157.763 140.644 155.239 147.679 159.025 152.463C163.092 151.9 168.14 146.131 162.812 141.347Z'
                fill='#91E1D3'
            />
            <path
                d='M183.846 161.468L178.237 163.86L183.846 166.252L183.145 167.941L176.414 164.986V162.875L183.145 159.92L183.846 161.468Z'
                fill='#8864D3'
            />
            <path d='M192.12 155.981L188.054 171.599L186.09 171.177L190.157 155.558L192.12 155.981Z' fill='#8864D3' />
            <path
                d='M201.796 162.875V164.986L195.065 167.941L194.364 166.252L199.973 163.86L194.364 161.468L195.065 159.78L201.796 162.875Z'
                fill='#8864D3'
            />
        </svg>
    )
}

export default ChangesDetectedImage
