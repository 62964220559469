import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { RequestType } from 'shared/types/project-types'
import { capitalizeFirstLetter } from 'shared/utils/generic-utils'
import Fonts from 'constants/fonts'

const PREFIX = 'RequestTypeDropdownMenu'

const classes = {
    menuItem: `${PREFIX}-menuItem`,
}

const StyledBox = styled(Box)(() => ({
    [`& .${classes.menuItem}`]: {
        width: 84,
        height: 52,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#332A46',
        },
    },
}))

interface RequestTypeDropdownMenuProps {
    onRequestTypeSelect: (type: RequestType) => void
}

const requestTypes = [RequestType.GET, RequestType.POST, RequestType.PUT, RequestType.DELETE]

const getTextColorFromRequestType = {
    [RequestType.GET]: '#5E86ED',
    [RequestType.POST]: '#54C6BE',
    [RequestType.PUT]: '#FF851D',
    [RequestType.DELETE]: '#EE3B3B',
}

const RequestTypeDropdownMenu: React.FC<RequestTypeDropdownMenuProps> = ({ onRequestTypeSelect }) => {
    return (
        <StyledBox
            style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#28293D', borderRadius: 3, marginTop: 10 }}
        >
            {requestTypes.map((rt: RequestType, index: number) => {
                return (
                    <Box
                        className={classes.menuItem}
                        style={{ borderRadius: index === 0 || index === 3 ? 3 : 0 }}
                        key={rt}
                        onClick={() => onRequestTypeSelect(rt)}
                    >
                        <Typography
                            style={{
                                color: getTextColorFromRequestType[rt],
                                marginLeft: 20,
                                fontSize: Fonts.size.normal,
                                fontWeight: 'bold',
                            }}
                        >
                            {capitalizeFirstLetter(rt.toLowerCase())}
                        </Typography>
                    </Box>
                )
            })}
        </StyledBox>
    )
}

export default RequestTypeDropdownMenu
