import React from 'react'
import { styled } from '@mui/material/styles'
import { Modal, Box } from '@mui/material'

const PREFIX = 'ModalBase'
const classes = {
    root: `${PREFIX}-root`,
    body: `${PREFIX}-body`,
}

const StyledModal = styled(Modal)(({ theme }) => ({
    [`&.${classes.root}`]: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    [`& .${classes.body}`]: {
        [theme.breakpoints.up('md')]: {
            width: 720,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: 600,
        },
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
        outline: 'none',
    },
}))

interface ModalBaseProps {
    isOpen: boolean
    onClose: () => void
    children?: React.ReactNode
    style?: React.CSSProperties
    /**
     * Use "center" option if modal has smaller width than default from breakpoints
     */
    center?: boolean
}

const centerBodyStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const ModalBase: React.FC<ModalBaseProps> = ({ isOpen, onClose, children, style, center }) => {
    return (
        <StyledModal className={classes.root} open={isOpen} onClose={onClose} style={style}>
            <Box className={classes.body} style={center ? centerBodyStyle : undefined}>
                {children}
            </Box>
        </StyledModal>
    )
}

export default ModalBase
