import { Dispatch } from 'redux'
import {
    ProjectActionTypes,
    SetRequest,
    SetResponse,
    SetActiveProject,
    SetIsSyncingWithServer,
    SetSharedCode,
    SetOpenedCollectionsIds,
    SetLoggedUsersProjectRole,
    SetSharedCodeCache,
    SharedCodeCache,
} from 'services/types/project-types'
import { ProjectsManager } from 'services/api/ProjectsManager'
import { Collection, DotColors, Endpoint, Project, ShareRoles, SharedCode, UrlRequestParameter } from 'shared/types/project-types'
import { showFlashMessage, showFlashMessageWithTimeout } from './flashMessage-actions'
import { ReduxState } from 'services/types/mainReducer-types'
import { findEndpointById, getIndexesByEndpointId } from 'shared/utils/project-utils'
import { getErrorMessage } from 'shared/utils/generic-utils'
import { createSharedCodeCache } from 'components/MonacoEditor/monaco-utils'
import { EditedSharedCodeTypeInfo } from 'types/sharedCode-types'
import {
    generateNewResponseContent,
    replaceRemovedTypesWithNewOnes,
    updateImportsFromFileInEndpointsAndSharedCode,
} from 'utils/project-utils'

export const setActiveProject = (project: Project | undefined): SetActiveProject => ({
    type: ProjectActionTypes.SET_ACTIVE_PROJECT,
    payload: { project },
})

/**
 * This action handles update of the project with the server and automatic rollback when there is an
 * error. It expects IMMUTABLE copy of the project to work correctly
 * @param project Immutable copy of project. Needs for rollback in case of server fail.
 */
export const updateActiveProjectAsync = (project: Project) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const projectManager = ProjectsManager.getManager()
        const state: ReduxState = getState()
        const oldProject = state.projects.active
        try {
            dispatch(setActiveProject(project))
            dispatch(setIsSyncingWithServer(true))
            await projectManager.updateProjectAsync(project)
            dispatch(setIsSyncingWithServer(false))

            dispatch(setSharedCodeCache(createSharedCodeCache(project.sharedCode)))
        } catch (e) {
            dispatch(setIsSyncingWithServer(false))
            if (oldProject) {
                dispatch(setActiveProject(oldProject))
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }
    }
}

export const updateSharedCodeName = (sharedCodeId: string, name: string, project: Project) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const copy: Project = JSON.parse(JSON.stringify(project)) as Project
        try {
            const oldCode = copy.sharedCode.find((code) => code.id === sharedCodeId)
            if (oldCode) {
                const oldName = oldCode.name

                oldCode.name = name

                updateImportsFromFileInEndpointsAndSharedCode(oldName, name, copy)
                updateActiveProjectAsync(copy)(dispatch, getState)
                dispatch(setSharedCodeCache(createSharedCodeCache(copy.sharedCode)))
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const updateSharedCodeContent = (
    sharedCodes: SharedCode[],
    project: Project,
    deletedTypes?: EditedSharedCodeTypeInfo[]
) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const copy: Project = { ...project }
        try {
            sharedCodes.forEach((sc) => {
                const oldCode = copy.sharedCode.find((code) => code.id === sc.id)
                if (oldCode) {
                    oldCode.content = sc.content
                }
            })

            if (deletedTypes && deletedTypes.length > 0) {
                replaceRemovedTypesWithNewOnes(deletedTypes, copy)
            }

            dispatch(setActiveProject(copy))
            await ProjectsManager.getManager().updateProjectsSharedCodeAsync(copy.id, copy.sharedCode)
            showFlashMessageWithTimeout(dispatch, 'Shared code was successfully saved.', 'success')

            dispatch(setSharedCodeCache(createSharedCodeCache(copy.sharedCode)))
        } catch (e) {
            dispatch(setActiveProject(project))
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const addSharedCode = (sharedCode: SharedCode, project: Project) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const copy: Project = { ...project }
        try {
            copy.sharedCode.push(sharedCode)

            dispatch(setActiveProject(copy))
            await ProjectsManager.getManager().updateProjectsSharedCodeAsync(copy.id, copy.sharedCode)

            dispatch(setSharedCodeCache(createSharedCodeCache(copy.sharedCode)))
        } catch (e) {
            dispatch(setActiveProject(project))
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const deleteSharedCode = (sharedCodeId: string, project: Project) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const copy: Project = { ...project }
        try {
            copy.sharedCode = copy.sharedCode.filter((code) => code.id !== sharedCodeId)
            dispatch(setActiveProject(copy))
            await ProjectsManager.getManager().updateProjectsSharedCodeAsync(copy.id, copy.sharedCode)

            dispatch(setSharedCodeCache(createSharedCodeCache(copy.sharedCode)))
        } catch (e) {
            dispatch(setActiveProject(project))
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

/**
 * Reorders responses inside of a an endpoint
 */
export const moveSharedCode = (movedSharedCodeId: string, dropSharedCodeId: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const project = getState().projects.active

        if (!project) return

        const copy: Project = { ...project }
        try {
            const sharedCodeToMove = copy.sharedCode.find((code) => code.id === movedSharedCodeId)

            if (sharedCodeToMove) {
                const dropSharedCodeIndex = copy.sharedCode.findIndex((sc) => sc.id === dropSharedCodeId)

                if (dropSharedCodeIndex === -1) {
                    console.error(`Shared code with id: ${dropSharedCodeId} was not found`)
                    return
                }

                const sharedCodeBefore = copy.sharedCode.slice(0, dropSharedCodeIndex)
                const sharedCodeAfter = copy.sharedCode.slice(dropSharedCodeIndex)

                const firstHalf = sharedCodeBefore.filter((sc) => sc.id !== movedSharedCodeId)
                firstHalf.push(sharedCodeToMove)
                const secondHalf = sharedCodeAfter.filter((sc) => sc.id !== movedSharedCodeId)
                copy.sharedCode = [...firstHalf, ...secondHalf]

                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(setActiveProject(project))
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const moveSharedCodeToFirst = (sharedCodeId: string, project: Project) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const copy: Project = { ...project }
        try {
            const sharedCodeToMove = copy.sharedCode.find((code) => code.id === sharedCodeId)

            if (sharedCodeToMove) {
                copy.sharedCode = copy.sharedCode.filter((code) => code.id !== sharedCodeId)
                copy.sharedCode.unshift(sharedCodeToMove)
                dispatch(setActiveProject(copy))
            }
        } catch (e) {
            dispatch(setActiveProject(project))
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const addCollection = (collection: Collection, project: Project) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const copy: Project = JSON.parse(JSON.stringify(project))
            copy.collections.push({
                ...collection,
            })
            updateActiveProjectAsync(copy)(dispatch, getState)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const removeCollection = (collection: Collection, project: Project) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const copy: Project = JSON.parse(JSON.stringify(project))
            const updatedCollections = copy.collections.filter((c) => c.id !== collection.id)
            copy.collections = updatedCollections
            updateActiveProjectAsync(copy)(dispatch, getState)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const setResponse = (response: string, responseHttpCode: number, endpointId: string): SetResponse => ({
    type: ProjectActionTypes.SET_RESPONSE,
    payload: {
        response,
        responseHttpCode,
        endpointId,
    },
})

export const createResponse = (response: string, responseHttpCode: number, dotColor: DotColors, endpointId: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const state: ReduxState = getState()
            if (state.projects.active) {
                const copy: Project = JSON.parse(JSON.stringify(state.projects.active))
                const endpoint = findEndpointById(copy, endpointId)

                if (!endpoint) {
                    showFlashMessageWithTimeout(dispatch, 'Endpoint was not found', 'error')
                    return
                }

                if (state.userSettings.editorSettings.prefillResponseBodyOnCreate && response.length === 0) {
                    response = generateNewResponseContent(endpoint.name)
                }

                endpoint.response.push({
                    httpCode: responseHttpCode,
                    stringFormat: response,
                    dotColor,
                })
                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const editResponse = (originalResponseCode: number, responseHttpCode: number, dotColor: DotColors, endpointId: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const state: ReduxState = getState()
            if (state.projects.active) {
                const copy: Project = JSON.parse(JSON.stringify(state.projects.active))
                const { collectionIndex, endpointIndex } = getIndexesByEndpointId(state.projects.active, endpointId)
                const responseIndex = copy.collections[collectionIndex].endpoints[endpointIndex].response.findIndex(
                    (r) => r.httpCode === originalResponseCode
                )
                if (responseIndex !== -1) {
                    const responseString =
                        copy.collections[collectionIndex].endpoints[endpointIndex].response[responseIndex].stringFormat
                    copy.collections[collectionIndex].endpoints[endpointIndex].response.splice(responseIndex, 1, {
                        httpCode: responseHttpCode,
                        stringFormat: responseString,
                        dotColor,
                    })
                }
                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const deleteResponse = (responseHttpCode: number, endpointId: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const state: ReduxState = getState()
            if (state.projects.active) {
                const copy: Project = JSON.parse(JSON.stringify(state.projects.active))
                const { collectionIndex, endpointIndex } = getIndexesByEndpointId(state.projects.active, endpointId)
                const filteredResponses = copy.collections[collectionIndex].endpoints[endpointIndex].response.filter(
                    (r) => r.httpCode !== responseHttpCode
                )
                copy.collections[collectionIndex].endpoints[endpointIndex].response = filteredResponses
                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const setRequest = (request: string, endpointId: string): SetRequest => ({
    type: ProjectActionTypes.SET_REQUEST,
    payload: {
        request,
        endpointId,
    },
})

/**
 * Moves endpoint to a drop endpoint position
 * @param movedEndpointId endpoint which will be moved
 * @param dropEndpointId  endpoint above which moved endpoint will be placed
 */
export const moveEndpoint = (movedEndpointId: string, dropEndpointId: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const state = getState()
            const project = state.projects.active
            if (project) {
                const copy: Project = { ...project }

                // Get endpoint that is moved and collection from where it is moved (can be equal to droppedToCollection)
                let movedEndpoint: Endpoint | undefined
                const movedFromCollection = copy.collections.find((c) => {
                    const movedEndpointSearch = c.endpoints.find((e) => e.id === movedEndpointId)

                    if (movedEndpointSearch !== undefined) {
                        movedEndpoint = movedEndpointSearch
                        return true
                    }
                })

                // Get collection where endpoint is dropped to (can be equal to movedFromCollection)
                const droppedToCollection = copy.collections.find((c) => c.endpoints.find((e) => e.id === dropEndpointId))

                if (!movedFromCollection || !droppedToCollection) return

                const dropEndpointIndex = droppedToCollection.endpoints.findIndex((e) => e.id === dropEndpointId)

                // Remove moved endpoint from collection
                movedFromCollection.endpoints = movedFromCollection.endpoints.filter((e) => e.id !== movedEndpointId)

                if (!movedEndpoint) {
                    console.error(`Endpoint with id: ${movedEndpointId} was not found`)
                    return
                }
                if (dropEndpointIndex === -1) {
                    console.error(`Endpoint with id: ${dropEndpointId} was not found`)
                    return
                }

                // Push endpoint into the dropped collection at the right index
                const endpointsBefore = droppedToCollection.endpoints.slice(0, dropEndpointIndex)
                const endpointsAfter = droppedToCollection.endpoints.slice(dropEndpointIndex)
                endpointsBefore.push(movedEndpoint)

                droppedToCollection.endpoints = [...endpointsBefore, ...endpointsAfter]

                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

/**
 * Reorders collections inside of a project
 * @param movedCollectionId collection which will be moved
 * @param dropCollectionId collection above which moved collection will be placed
 */
export const moveCollection = (movedCollectionId: string, dropCollectionId: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const state = getState()
            const project = state.projects.active
            if (project) {
                const copy = { ...project }
                const movedCollectionData = copy.collections.find((c) => c.id === movedCollectionId)
                if (!movedCollectionData) {
                    console.error(`Collection with id: ${movedCollectionId} was not found`)
                    return
                }
                const dropCollectionIndex = copy.collections.findIndex((c) => c.id === dropCollectionId)
                if (dropCollectionIndex === -1) {
                    console.error(`Collection with id: ${dropCollectionId} was not found`)
                    return
                }
                const endpointsBefore = copy.collections.slice(0, dropCollectionIndex)
                const endpointsAfter = copy.collections.slice(dropCollectionIndex)
                const firstHalf = endpointsBefore.filter((e) => e.id !== movedCollectionId)
                firstHalf.push(movedCollectionData)
                const secondHalf = endpointsAfter.filter((e) => e.id !== movedCollectionId)

                copy.collections = [...firstHalf, ...secondHalf]

                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

/**
 * Reorders responses inside of a an endpoint
 * @param movedResponseCode response which will be moved
 * @param dropResponseCode  response above which moved response will be placed
 * @param endpointId endpoint to which both codes belong to
 */
export const moveResponse = (movedResponseCode: number, dropResponseCode: number, endpointId: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const state: ReduxState = getState()
            if (state.projects.active) {
                const copy = { ...state.projects.active }
                let endpoint: Endpoint | undefined

                copy.collections.map((c) => {
                    const searchedEndpoint = c.endpoints.find((e) => e.id === endpointId)
                    if (searchedEndpoint !== undefined) endpoint = searchedEndpoint
                })

                if (!endpoint) {
                    console.error(`Endpoint with id: ${endpointId} was not found`)
                    return
                }

                const dropResponseIndex = endpoint.response.findIndex((r) => r.httpCode === dropResponseCode)
                const movedResponseData = endpoint.response.find((r) => r.httpCode === movedResponseCode)

                if (!movedResponseData) {
                    console.error(`Response with http code: ${movedResponseCode} was not found`)
                    return
                }
                if (dropResponseIndex === -1) {
                    console.error(`Response with http code: ${dropResponseCode} was not found`)
                    return
                }

                const responsesBefore = endpoint.response.slice(0, dropResponseIndex)
                const responsesAfter = endpoint.response.slice(dropResponseIndex)

                const firstHalf = responsesBefore.filter((r) => r.httpCode !== movedResponseCode)
                firstHalf.push(movedResponseData)
                const secondHalf = responsesAfter.filter((r) => r.httpCode !== movedResponseCode)
                endpoint.response = [...firstHalf, ...secondHalf]

                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const moveResponseToFirst = (responseCode: number, endpointId: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const project = getState().projects.active

        if (!project) return

        const copy: Project = { ...project }
        try {
            let endpoint: Endpoint | undefined

            copy.collections.map((c) => {
                const searchedEndpoint = c.endpoints.find((e) => e.id === endpointId)
                if (searchedEndpoint !== undefined) endpoint = searchedEndpoint
            })

            if (!endpoint) {
                console.error(`Endpoint with id: ${endpointId} was not found`)
                return
            }

            const movedResponse = endpoint.response.find((r) => r.httpCode === responseCode)

            if (!movedResponse) {
                return
            }

            endpoint.response = endpoint.response.filter((r) => r.httpCode !== responseCode)
            endpoint.response.unshift(movedResponse)
            updateActiveProjectAsync(copy)(dispatch, getState)
        } catch (e) {
            dispatch(setActiveProject(project))
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const updateEndpointDetail = (endpoint: Endpoint) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const state = getState()
            const project = state.projects.active
            if (project) {
                const { collectionIndex, endpointIndex } = getIndexesByEndpointId(project, endpoint.id)
                const copy: Project = JSON.parse(JSON.stringify(project))
                copy.collections[collectionIndex].endpoints[endpointIndex] = endpoint
                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

// =============== ENDPOINTS ===============

export const addEndpoint = (collectionId: string, endpoint: Endpoint, project: Project) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const copy: Project = JSON.parse(JSON.stringify(project))
            const collection = copy.collections.find((collection) => collection.id === collectionId)
            if (collection) {
                collection.endpoints.push(endpoint)
                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const removeEndpoint = (collectionId: string, endpoint: Endpoint, project: Project) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const copy: Project = JSON.parse(JSON.stringify(project))
            const collection = copy.collections.find((collection) => collection.id === collectionId)
            if (collection) {
                const endpoints = collection.endpoints.filter((e) => e.id !== endpoint.id)
                collection.endpoints = endpoints
                updateActiveProjectAsync(copy)(dispatch, getState)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const setCollectionName = (collectionId: string, newName: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const state = getState()
            const project = state.projects.active
            const copy: Project = JSON.parse(JSON.stringify(project))
            const collection = copy.collections.find((collection) => collection.id === collectionId)
            if (collection) {
                collection.name = newName
                updateActiveProjectAsync(copy)(dispatch, getState)
            } else {
                throw new Error(`Collection with ID: ${collectionId} not found.`)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const setCollectionDescription = (collectionId: string, newDescription: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        try {
            const state = getState()
            const project = state.projects.active
            const copy: Project = JSON.parse(JSON.stringify(project))
            const collection = copy.collections.find((collection) => collection.id === collectionId)
            if (collection) {
                collection.description = newDescription
                updateActiveProjectAsync(copy)(dispatch, getState)
            } else {
                throw new Error(`Collection with ID: ${collectionId} not found.`)
            }
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const addUrlRequestParameterToEndpoint = (endpointId: string, parameter: UrlRequestParameter, project: Project) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const { collectionIndex, endpointIndex } = getIndexesByEndpointId(project, endpointId)
        const copy: Project = JSON.parse(JSON.stringify(project))
        const parameters = copy.collections[collectionIndex].endpoints[endpointIndex].queryParameters?.parameters
        if (parameters) {
            try {
                const updatedParameters = [...parameters, parameter]
                const urlRequest = copy.collections[collectionIndex].endpoints[endpointIndex].queryParameters
                if (urlRequest) {
                    urlRequest.parameters = updatedParameters
                    updateActiveProjectAsync(copy)(dispatch, getState)
                }
            } catch (e) {
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }
    }
}

export const removeUrlRequestParameterFromEndpoint = (endpointId: string, parameterIndex: number, project: Project) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const { collectionIndex, endpointIndex } = getIndexesByEndpointId(project, endpointId)
        const copy: Project = JSON.parse(JSON.stringify(project))
        const parameters = copy.collections[collectionIndex].endpoints[endpointIndex].queryParameters?.parameters
        if (parameters) {
            try {
                parameters.splice(parameterIndex, 1)
                updateActiveProjectAsync(copy)(dispatch, getState)
            } catch (e) {
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }
    }
}

export const setSharedCode = (sharedCode: SharedCode): SetSharedCode => ({
    type: ProjectActionTypes.SET_SHARED_CODE,
    payload: {
        sharedCode,
    },
})

export const editEndpointUrlRequestParameterFromEndpoint = (
    endpointId: string,
    parameter: UrlRequestParameter,
    parameterIndex: number,
    project: Project
) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const { collectionIndex, endpointIndex } = getIndexesByEndpointId(project, endpointId)
        const copy: Project = JSON.parse(JSON.stringify(project))
        const parameters = copy.collections[collectionIndex].endpoints[endpointIndex].queryParameters?.parameters
        if (parameters !== undefined) {
            parameters[parameterIndex] = parameter
            try {
                updateActiveProjectAsync(copy)(dispatch, getState)
            } catch (e) {
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }
    }
}

export const editBodyRequestForEndpoint = (endpointId: string, bodyRequestText: string, project: Project) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const { collectionIndex, endpointIndex } = getIndexesByEndpointId(project, endpointId)
        const copy: Project = JSON.parse(JSON.stringify(project))

        copy.collections[collectionIndex].endpoints[endpointIndex].bodyRequest = {
            stringFormat: bodyRequestText,
        }

        try {
            updateActiveProjectAsync(copy)(dispatch, getState)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const inviteUserToProject = (projectId: string, shareRole: ShareRoles, userEmail: string) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const projectManager = ProjectsManager.getManager()

        try {
            await projectManager.inviteUserToProjectAsync(projectId, shareRole, userEmail)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}
export const setOpenedCollectionsIds = (collectionsIds: string[]): SetOpenedCollectionsIds => ({
    type: ProjectActionTypes.SET_OPENED_COLLECTIONS_IDS,
    payload: {
        collectionIds: collectionsIds,
    },
})

export const updateProjectBaseUrl = (newUrl: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const state = getState()
        const project = state.projects.active
        const copy: Project = JSON.parse(JSON.stringify(project))
        copy.baseUrl = newUrl
        try {
            updateActiveProjectAsync(copy)(dispatch, getState)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const deleteUserFromProject = (projectId: string, userEmail: string) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const projectManager = ProjectsManager.getManager()

        try {
            await projectManager.deleteUserFromProjectAsync(projectId, userEmail)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}
export const updateProjectName = (newName: string) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const state = getState()
        const project = state.projects.active
        const copy: Project = JSON.parse(JSON.stringify(project))
        copy.name = newName
        try {
            updateActiveProjectAsync(copy)(dispatch, getState)
        } catch (e) {
            dispatch(showFlashMessage(getErrorMessage(e), 'error'))
        }
    }
}

export const setLoggedUsersProjectRole = (projectRole: ShareRoles): SetLoggedUsersProjectRole => ({
    type: ProjectActionTypes.SET_LOGGED_USER_PROJECT_ROLE,
    payload: {
        projectRole,
    },
})

export const setIsSyncingWithServer = (state: boolean): SetIsSyncingWithServer => ({
    type: ProjectActionTypes.SET_IS_SYNCING_WITH_SERVER,
    payload: state,
})

export const editEndpointPathVariable = (project: Project, endpointId: string, variable: UrlRequestParameter) => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const { collectionIndex, endpointIndex } = getIndexesByEndpointId(project, endpointId)
        const copy: Project = JSON.parse(JSON.stringify(project))
        const pathParameters = copy.collections[collectionIndex].endpoints[endpointIndex].pathParameters
        if (pathParameters !== undefined && pathParameters.parameters !== undefined) {
            const variableIndex = pathParameters.parameters.findIndex((p) => p.name === variable.name)
            if (variableIndex >= 0) {
                pathParameters.parameters[variableIndex] = variable
            }
            try {
                updateActiveProjectAsync(copy)(dispatch, getState)
            } catch (e) {
                dispatch(showFlashMessage(getErrorMessage(e), 'error'))
            }
        }
    }
}

export const setSharedCodeCache = (sharedCodeCache: SharedCodeCache): SetSharedCodeCache => ({
    type: ProjectActionTypes.SET_SHARED_CODE_CACHE,
    payload: { sharedCodeCache },
})

export const updateSharedCodeCacheForActiveProject = () => {
    return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
        const sharedCode = getState().projects.active?.sharedCode
        sharedCode && dispatch(setSharedCodeCache(createSharedCodeCache(sharedCode)))
    }
}
