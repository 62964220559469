// import { GlobalVars } from '../../global'
// We can't use store as it would create a circular dependency
// import { store } from '../../store'
// import { LogErrorRequest } from '../types-server'
// import NetInfo from '@react-native-community/netinfo'

export enum ErrorCategory {
    Network = 'Network',
    Projects = 'Projects',
}

// const ENDPOINT = `${GlobalVars.SERVER_ENDPOINT}/analytics/error`

export class Analytics {}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const logError = async (category: ErrorCategory, message: string, params?: unknown): Promise<void> => {
    console.log('>>>> [ERR] ', message, '')
}
