import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import mainReducer from './reducers/main-reducer'
import { applyMiddleware, createStore } from 'redux'

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

const store = createStore(mainReducer, composedEnhancer)

export default store
