import { Stack, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import moment from 'moment'

import Text from './Text'

interface EditInfoProps {
    createdAt: Date
    lastModifiedAt: Date
}

function EditInfo({ createdAt, lastModifiedAt }: EditInfoProps) {
    return (
        <Stack direction='row' spacing={1} alignItems='center'>
            <Tooltip
                title={
                    <div>
                        <div>Project created: {moment(createdAt).format('YYYY-MM-DD')}</div>
                        <div>Project last edit: {moment(lastModifiedAt).format('YYYY-MM-DD')}</div>
                    </div>
                }
            >
                <InfoOutlinedIcon sx={{ color: 'text.faded', fontSize: 20 }} />
            </Tooltip>
            <Text color='faded' fontSize={16}>
                Edited {moment(lastModifiedAt).format('YYYY-MM-DD')}
            </Text>
        </Stack>
    )
}

export default EditInfo
