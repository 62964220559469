import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const PREFIX = 'OptionsDropdown'

const classes = {
    container: `${PREFIX}-container`,
    item: `${PREFIX}-item`,
    itemTitle: `${PREFIX}-itemTitle`,
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        boxShadow: theme.palette.shadow.main,
        borderRadius: MAIN_BORDER_RADIUS,
    },

    [`& .${classes.item}`]: {
        color: theme.palette.text.normal,
        fontWeight: 'normal',
        width: 127,
        height: 52,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.dropdown.hover,
            fontWeight: 'bold',
        },
    },

    [`& .${classes.itemTitle}`]: {
        height: 52,
        width: 127,
        display: 'flex',
        fontSize: 16,
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 'bold',
            color: theme.palette.dropdown.titleHover,
        },
        // Used to make text bold on icon hover. Does not work without it.
        zIndex: 2,
    },
}))

const MAIN_BORDER_RADIUS = 8

interface OptionsDropdownProps {
    onOptionSelect: (option: MenuDropdownOptionType) => void
}

export type MenuDropdownOptionType = 'rename' | 'duplicate' | 'delete'

const OptionsDropdown: React.FC<OptionsDropdownProps> = ({ onOptionSelect }) => {
    return (
        <StyledBox className={classes.container}>
            <Box
                className={classes.item}
                style={{ borderTopRightRadius: MAIN_BORDER_RADIUS, borderTopLeftRadius: MAIN_BORDER_RADIUS }}
                onClick={() => onOptionSelect('duplicate')}
            >
                <Typography className={classes.itemTitle} sx={{ paddingLeft: 2 }}>
                    Duplicate
                </Typography>
            </Box>
            <Box className={classes.item} onClick={() => onOptionSelect('delete')}>
                <Typography className={classes.itemTitle} sx={{ paddingLeft: 2 }}>
                    Delete
                </Typography>
            </Box>
            <Box
                className={classes.item}
                style={{ borderBottomRightRadius: MAIN_BORDER_RADIUS, borderBottomLeftRadius: MAIN_BORDER_RADIUS }}
                onClick={() => onOptionSelect('rename')}
            >
                <Typography className={classes.itemTitle} sx={{ paddingLeft: 2 }}>
                    Rename
                </Typography>
            </Box>
        </StyledBox>
    )
}

export default OptionsDropdown
