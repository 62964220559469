import { FC } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from 'components/ErrorBoundary'
import FlashMessageList from 'components/FlashMessages/FlashMessageList'
import MainNavigator from 'navigation/MainNavigator'

const AppContainer: FC = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <FlashMessageList />
            <ErrorBoundary>
                <BrowserRouter>
                    <MainNavigator />
                </BrowserRouter>
            </ErrorBoundary>
        </DndProvider>
    )
}

export default AppContainer
