import * as React from 'react'

function UnauthorizedImage(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width='100%' height='100%' viewBox='0 0 440 310' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M439.348 0.242676H0.648438V309.743H439.348V0.242676Z' fill='#F2F2F5' />
            <rect x='10.6484' y='17.2427' width='417' height='280' fill='#1E1A27' />
            <path d='M12.8486 15.8428V17.5428L20.5486 15.8428H12.8486Z' fill='#101014' />
            <path
                d='M427.149 235.843L238.049 111.243L127.649 67.2428L214.649 95.7428L208.549 91.7428L213.649 80.5428L223.149 80.6428L13.5486 31.6428L427.149 74.1428V45.0428L108.249 21.3428L427.149 21.6428V15.8428H24.6486L12.8486 18.2428V47.5428L427.149 260.243V235.843Z'
                fill='#101014'
            />
            <path d='M12.8486 141.643V295.543H78.1486L12.8486 141.643Z' fill='#101014' />
            <path
                d='M188.049 295.543L48.8486 139.843L253.649 295.543H350.749L12.8486 53.3428V125.243L111.449 295.543H188.049Z'
                fill='#101014'
            />
            <path d='M427.149 295.543V260.243L12.8486 47.543V53.343L350.749 295.543H427.149Z' fill='#101014' />
            <path opacity='0.39' d='M427.149 295.543V260.243L12.8486 47.543V53.343L350.749 295.543H427.149Z' fill='#332A46' />
            <path d='M127.648 67.2427L238.048 111.243L214.648 95.7427L127.648 67.2427Z' fill='#101014' />
            <path opacity='0.39' d='M127.648 67.2427L238.048 111.243L214.648 95.7427L127.648 67.2427Z' fill='#332A46' />
            <path d='M223.149 80.6426L427.149 82.6426V74.1426L13.5488 31.6426L223.149 80.6426Z' fill='#101014' />
            <path opacity='0.39' d='M223.149 80.6426L427.149 82.6426V74.1426L13.5488 31.6426L223.149 80.6426Z' fill='#332A46' />
            <path d='M427.149 45.0428V21.6428L108.249 21.3428L427.149 45.0428Z' fill='#101014' />
            <path opacity='0.39' d='M427.149 45.0428V21.6428L108.249 21.3428L427.149 45.0428Z' fill='#332A46' />
            <path d='M20.5486 15.8428L12.8486 17.5428V18.2428L24.6486 15.8428H20.5486Z' fill='#101014' />
            <path opacity='0.39' d='M20.5486 15.8428L12.8486 17.5428V18.2428L24.6486 15.8428H20.5486Z' fill='#332A46' />
            <path d='M188.049 295.543H253.649L48.8486 139.843L188.049 295.543Z' fill='#101014' />
            <path opacity='0.39' d='M188.049 295.543H253.649L48.8486 139.843L188.049 295.543Z' fill='#332A46' />
            <path d='M111.449 295.543L12.8486 125.243V141.643L78.1486 295.543H111.449Z' fill='#101014' />
            <path opacity='0.39' d='M111.449 295.543L12.8486 125.243V141.643L78.1486 295.543H111.449Z' fill='#332A46' />
            <path d='M427.149 235.843V186.543L238.049 111.243L427.149 235.843Z' fill='#101014' />
            <path opacity='0.8' d='M427.149 235.843V186.543L238.049 111.243L427.149 235.843Z' fill='#FFEA9E' />
            <path
                d='M213.649 80.543L208.549 91.743L214.649 95.743L427.149 165.443V128.243L223.149 80.643L213.649 80.543Z'
                fill='#101014'
            />
            <path d='M238.048 111.243L427.148 186.543V165.443L214.648 95.7427L238.048 111.243Z' fill='#101014' />
            <path opacity='0.39' d='M238.048 111.243L427.148 186.543V165.443L214.648 95.7427L238.048 111.243Z' fill='#332A46' />
            <path opacity='0.8' d='M238.048 111.243L427.148 186.543V165.443L214.648 95.7427L238.048 111.243Z' fill='#FFEA9E' />
            <path d='M427.148 128.243V82.6426L223.148 80.6426L427.148 128.243Z' fill='#101014' />
            <path opacity='0.39' d='M427.148 128.243V82.6426L223.148 80.6426L427.148 128.243Z' fill='#332A46' />
            <path opacity='0.8' d='M427.148 128.243V82.6426L223.148 80.6426L427.148 128.243Z' fill='#FFEA9E' />
            <path
                opacity='0.8'
                d='M213.649 80.5425L208.549 91.7425L214.649 95.7425L427.149 165.442V128.242L223.149 80.6425L213.649 80.5425Z'
                fill='#FFEA9E'
            />
            <path d='M427.049 15.8428H12.8486V295.443H427.049V15.8428Z' stroke='#101014' strokeWidth='4.2994' />
            <path
                d='M190.348 136.443C190.348 136.443 199.548 132.143 201.948 134.043C204.348 135.943 208.148 143.443 205.448 144.943C202.748 146.543 198.448 141.943 197.548 140.643C193.748 144.543 188.148 142.243 188.148 142.243L190.348 136.443Z'
                fill='#E8A99B'
            />
            <path
                d='M174.449 72.1427C174.049 74.4427 172.749 76.9427 173.349 79.6427C171.449 79.1427 169.449 78.5427 167.449 78.0427C166.749 74.6427 169.349 70.7427 169.349 70.7427L174.449 72.1427Z'
                fill='#1B8188'
            />
            <path
                d='M174.249 72.1426L202.249 79.6426L200.349 85.8426C200.349 85.8426 187.949 83.6426 173.349 79.6426C172.649 76.9426 173.949 74.3426 174.249 72.1426Z'
                fill='#28293D'
            />
            <path
                d='M215.449 77.8428C215.449 77.8428 203.249 74.7428 200.149 82.0428C197.049 89.4428 212.349 94.2428 212.349 94.2428C212.349 94.2428 217.549 86.7428 215.449 77.8428Z'
                fill='#28293D'
            />
            <path
                d='M199.648 79.2426L199.948 78.4426C200.048 78.0426 199.848 77.5426 199.248 77.3426L196.148 76.5426C195.648 76.4426 195.248 76.6426 195.048 77.2426L194.948 78.0426C194.848 78.4426 195.048 78.9426 195.648 78.9426L198.648 79.7426C199.048 79.9426 199.548 79.6426 199.648 79.2426Z'
                fill='#54C6BE'
            />
            <path
                d='M210.749 85.2426C209.549 89.9426 209.949 94.1426 211.649 94.5426C213.349 94.9426 215.649 91.4426 216.849 86.7426C218.049 82.0426 217.649 77.8426 215.949 77.4426C214.249 76.9426 211.949 80.4426 210.749 85.2426Z'
                fill='#FFEA9D'
            />
            <path
                d='M177.048 80.943C177.048 80.943 182.248 75.543 185.148 75.543C187.848 75.543 195.748 75.643 195.248 77.043C194.748 78.343 190.848 78.143 190.848 78.143C190.848 78.143 190.748 84.843 182.948 84.043C180.648 83.643 178.548 85.743 178.548 85.743C178.548 85.743 176.948 84.343 177.048 80.943Z'
                fill='#E8A99B'
            />
            <path
                d='M140.649 72.9429C140.649 72.9429 119.149 65.9429 101.449 69.5429C106.949 80.9429 109.249 83.8429 109.249 83.8429C109.249 83.8429 94.3489 88.0429 93.2489 95.8429C92.1489 103.643 99.0489 117.143 96.0489 120.443C93.0489 123.843 76.8489 119.343 72.9489 127.143C69.0489 134.943 68.6489 144.243 61.1489 140.443C53.6489 136.643 31.8489 125.243 24.6489 131.543C17.5489 137.843 24.5489 147.543 21.4489 152.843C18.3489 158.043 -1.05114 174.143 7.44886 191.243C15.7489 208.343 49.9489 242.643 126.649 197.043C148.549 184.543 154.949 154.943 149.149 146.343C143.249 137.743 142.849 126.043 142.849 126.043C142.849 126.043 161.149 141.343 170.149 145.543C175.649 148.243 181.549 142.843 181.549 142.843L188.449 144.443C188.449 144.443 191.949 140.043 191.149 134.843C188.049 133.943 185.449 133.243 185.449 133.243C185.449 133.243 186.949 128.043 181.249 126.143C175.549 124.243 145.549 110.243 145.149 110.043C171.349 106.943 176.149 95.9429 175.149 91.8429C181.049 90.5429 181.449 88.8429 181.449 88.8429C181.449 88.8429 178.849 80.6429 176.449 79.6429C172.649 80.3429 171.749 81.8429 171.749 81.8429C171.749 81.8429 169.149 78.2429 165.449 79.9429C161.649 81.6429 145.249 91.5429 140.149 91.5429C136.549 86.3429 131.849 82.9429 131.849 82.9429L140.649 72.9429Z'
                fill='#FF851D'
            />
            <path
                d='M114.749 63.3427L116.349 77.6427L126.049 77.1427L125.149 68.6427C125.249 68.6427 118.849 63.0427 114.749 63.3427Z'
                fill='#E8A99B'
            />
            <path
                d='M41.1484 271.543C44.1484 268.843 46.8484 266.543 46.8484 266.543L51.2484 270.843C51.2484 270.843 48.1484 273.643 45.8484 276.543C44.2484 274.943 42.9484 273.043 41.1484 271.543Z'
                fill='#E8A99B'
            />
            <path
                d='M41.1484 271.543C42.8484 273.143 44.2484 275.043 45.8484 276.543C44.7484 277.843 43.8484 279.143 43.4484 280.143C42.9484 281.343 43.3484 283.543 43.9484 285.643C40.1484 283.643 37.6484 280.143 36.1484 276.043C37.5484 274.743 39.4484 273.043 41.1484 271.543Z'
                fill='#28293D'
            />
            <path
                d='M43.8485 285.543C44.9485 289.343 46.6485 293.343 46.1485 293.243C45.2485 292.943 33.0485 281.043 34.0485 278.443C34.3485 277.943 34.9485 277.143 36.0485 276.043C37.5485 280.043 40.0485 283.643 43.8485 285.543Z'
                fill='#28293D'
            />
            <path
                d='M40.8486 271.643C42.4486 270.143 44.0486 268.843 45.1486 267.843C46.0486 267.043 47.4486 267.043 48.2486 267.943L49.4486 269.143C50.3486 270.043 50.3486 271.443 49.4486 272.343C48.5486 273.243 47.1486 274.643 45.9486 276.143C44.1486 274.943 42.2486 273.543 40.8486 271.643Z'
                fill='#E8A99B'
            />
            <path
                d='M37.2491 275.043C38.3491 273.943 39.6491 272.843 40.8491 271.643C42.1491 273.543 43.9491 274.843 45.9491 276.043C44.7491 277.343 43.6491 278.843 43.2491 279.943C43.2491 280.043 43.1491 280.243 43.1491 280.443C42.7491 282.343 40.4491 283.143 39.1491 281.643C38.0491 280.443 37.2491 278.943 36.5491 277.443C36.3491 276.743 36.5491 275.743 37.2491 275.043Z'
                fill='#28293D'
            />
            <path
                d='M43.0485 285.143C43.5485 285.443 43.9485 285.943 44.1485 286.443C45.2485 289.943 46.7485 293.443 46.1485 293.343C45.2485 293.043 33.0485 281.143 34.0485 278.543C34.3485 278.043 34.9485 277.243 36.0485 276.143C37.3485 279.743 39.6485 283.143 43.0485 285.143Z'
                fill='#28293D'
            />
            <path
                d='M144.349 287.043C144.449 288.243 144.749 289.343 145.149 290.143C145.649 291.343 147.449 292.543 149.449 293.643C145.449 294.943 141.149 294.443 137.249 292.543C137.249 290.943 137.349 288.943 137.349 287.043C139.649 287.043 142.049 287.043 144.349 287.043Z'
                fill='#28293D'
            />
            <path
                d='M137.749 278.143L143.949 278.043C143.949 278.043 143.849 283.143 144.349 286.943C142.049 286.943 139.649 286.943 137.349 286.943C137.549 282.543 137.749 278.143 137.749 278.143Z'
                fill='#E8A99B'
            />
            <path
                d='M149.449 293.643C152.949 295.543 157.149 297.043 156.549 297.243C155.749 297.643 138.649 298.143 137.549 295.643C137.249 295.143 137.249 293.843 137.249 292.543C141.049 294.243 145.449 294.943 149.449 293.643Z'
                fill='#28293D'
            />
            <path
                d='M137.649 280.243C137.649 279.043 138.749 278.043 139.949 278.043H141.649C142.849 278.043 143.949 278.943 143.949 280.343C143.949 282.243 144.049 284.743 144.349 286.943C141.949 286.943 139.649 286.943 137.349 286.943C137.549 284.443 137.649 282.043 137.649 280.243Z'
                fill='#E8A99B'
            />
            <path
                d='M144.348 287.043C144.448 288.243 144.748 289.343 145.148 290.143C145.148 290.243 145.248 290.443 145.448 290.543C146.548 292.143 145.448 294.443 143.548 294.343C141.948 294.243 140.348 293.843 138.848 293.243C137.948 292.843 137.248 292.043 137.348 291.043C137.348 289.843 137.448 288.343 137.448 287.043C139.648 287.043 141.948 287.043 144.348 287.043Z'
                fill='#28293D'
            />
            <path
                d='M148.549 293.843C149.049 293.743 149.749 293.743 150.249 293.943C153.449 295.643 156.949 296.943 156.549 297.143C155.749 297.543 138.649 298.043 137.549 295.543C137.249 295.043 137.249 293.743 137.249 292.443C140.749 294.143 144.749 294.843 148.549 293.843Z'
                fill='#28293D'
            />
            <path
                d='M131.749 49.2428L133.249 57.1428C133.549 58.4428 132.449 59.8428 130.949 59.8428C129.749 59.8428 128.749 59.0428 128.649 57.8428L127.549 48.8428L131.749 49.2428Z'
                fill='#28293D'
            />
            <path
                d='M130.949 51.2426C130.949 51.2426 135.349 70.7426 125.549 70.8426C115.749 70.9426 111.549 59.6426 111.549 59.6426C111.549 59.6426 111.249 61.8426 108.349 62.2426C105.349 62.6426 104.049 54.7426 105.749 53.2426C107.449 51.7426 109.749 54.0426 109.749 54.0426L109.649 51.6426C109.649 51.6426 127.149 46.1426 130.949 51.2426Z'
                fill='#E8A99B'
            />
            <path
                d='M119.449 56.2427L123.449 56.5427C123.949 56.5427 124.549 56.1427 124.549 55.6427C124.549 55.1427 124.149 54.5427 123.649 54.5427L119.649 54.2427C119.149 54.2427 118.549 54.6427 118.549 55.1427C118.549 55.6427 118.949 56.2427 119.449 56.2427Z'
                fill='#28293D'
            />
            <path
                d='M132.849 54.2429L128.649 56.1429C128.149 56.4429 127.549 56.1429 127.349 55.6429C127.049 55.1429 127.349 54.5429 127.849 54.3429L132.049 52.4429C132.549 52.1429 133.149 52.4429 133.349 52.9429C133.649 53.4429 133.349 54.0429 132.849 54.2429Z'
                fill='#28293D'
            />
            <path
                d='M129.449 60.4428L125.049 60.3428C123.849 60.3428 122.649 60.8428 121.949 61.8428L119.649 64.6428C119.249 65.0428 119.649 65.8428 120.149 65.8428C122.849 65.7428 128.749 64.7428 133.749 65.5428C134.449 65.6428 134.949 64.8428 134.549 64.3428C133.849 63.2428 132.949 62.3428 132.249 61.6428C131.449 60.8428 130.549 60.4428 129.449 60.4428Z'
                fill='#28293D'
            />
            <path
                d='M113.249 50.1427L114.749 58.0427C115.049 59.3427 113.949 60.7427 112.449 60.7427C111.249 60.7427 110.249 59.9427 110.149 58.7427L109.049 49.7427L113.249 50.1427Z'
                fill='#28293D'
            />
            <path
                d='M114.549 38.3427C114.549 38.3427 110.749 34.1427 109.549 34.7427C108.249 35.2427 109.449 50.0427 110.649 50.8427C111.849 51.6427 127.449 47.0427 129.049 44.7427C130.549 42.3427 116.149 30.7427 115.149 31.2427C114.349 31.8427 113.949 36.8427 114.549 38.3427Z'
                fill='#FF851D'
            />
            <path
                d='M117.949 44.3427C117.949 44.3427 147.149 39.2427 148.049 43.6427C148.949 48.0427 94.3485 60.2427 92.4485 56.8427C90.6485 53.5427 117.949 44.3427 117.949 44.3427Z'
                fill='#FF851D'
            />
            <path
                d='M111.549 50.5429C113.449 50.5429 121.649 48.2429 125.549 47.0429C127.449 46.3429 128.649 45.5429 129.049 44.4429C129.449 43.3429 129.049 42.5429 129.049 42.4429L128.249 42.8429L128.649 42.5429L128.249 42.8429C128.249 42.8429 128.549 43.3429 128.249 44.0429C127.849 44.8429 126.949 45.5429 125.249 46.0429C120.549 47.5429 112.549 49.6429 111.549 49.5429C110.749 49.4429 110.349 48.0429 110.249 47.5429L109.349 47.6429C109.349 47.7429 109.849 50.2429 111.549 50.4429C111.549 50.5429 111.549 50.5429 111.549 50.5429Z'
                fill='#28293D'
            />
            <path
                d='M127.249 73.343C127.249 73.343 116.649 72.643 114.349 72.543C114.349 78.243 115.449 81.743 115.449 81.743L113.049 82.043L114.249 92.043C114.249 92.043 130.549 92.843 134.049 92.443C133.749 87.343 130.149 82.643 130.149 82.643L127.549 81.343C127.549 81.243 128.649 76.243 127.249 73.343Z'
                fill='#91E1D3'
            />
            <path
                d='M143.149 125.943C143.149 125.843 143.149 117.043 144.949 110.243L144.049 109.943C142.249 116.943 142.249 125.943 142.249 125.943H143.149Z'
                fill='#28293D'
            />
            <path
                d='M174.649 91.8427H175.549C175.449 87.0427 172.449 81.8427 172.349 81.7427L171.549 82.2427C171.549 82.2427 174.549 87.3427 174.649 91.8427Z'
                fill='#28293D'
            />
            <path
                d='M181.749 143.043C185.349 140.043 185.949 133.443 185.949 133.243H185.049C185.049 133.243 184.649 139.543 181.249 142.243L181.749 143.043Z'
                fill='#28293D'
            />
            <path
                d='M134.149 135.343C134.149 135.343 152.149 186.043 155.549 200.043C158.949 214.043 158.249 282.443 146.449 281.743C134.749 281.043 128.249 281.343 129.249 270.843C130.349 260.343 131.549 211.143 129.549 204.643C127.349 198.143 120.149 179.443 120.149 179.443C120.149 179.443 109.249 218.843 100.949 227.343C92.6489 235.943 65.2489 274.343 56.5489 274.443C47.8489 274.743 42.9489 266.943 41.6489 262.343C40.4489 257.743 74.7489 217.943 78.5489 215.343C82.1489 212.943 96.8489 163.143 97.6489 156.243C98.4489 149.343 101.049 137.043 106.849 132.443C112.449 127.843 134.149 135.343 134.149 135.343Z'
                fill='#28293D'
            />
            <path
                d='M137.249 110.743C137.249 110.743 135.649 114.943 137.149 121.243C138.749 127.443 139.349 136.743 139.349 136.743C139.349 136.743 115.749 149.243 97.3486 130.843C109.549 126.243 116.849 125.643 116.549 118.443C116.249 111.343 116.149 110.143 116.149 110.143C116.149 110.143 128.349 111.443 137.249 110.743Z'
                fill='#91E1D3'
            />
            <path
                d='M11.2487 197.643L12.4487 199.143C10.5487 191.243 11.4487 188.343 14.2487 184.143C23.5487 170.643 55.4487 168.043 68.6487 167.443C81.9487 166.943 82.6487 158.043 83.1487 149.543C83.2487 147.843 83.4487 146.043 83.5487 144.443C84.7487 134.643 98.3487 132.043 98.5487 131.843L97.3487 130.643C96.8487 130.543 83.8487 133.743 82.5487 144.243C82.2487 146.043 82.2487 147.743 82.1487 149.343C81.6487 158.243 81.0487 165.943 68.5487 166.443C55.2487 166.943 22.9487 169.843 13.3487 183.543C10.3487 187.943 9.24868 191.843 11.2487 197.643Z'
                fill='#28293D'
            />
            <path d='M126.149 154.243L127.349 154.543L120.449 179.743L119.249 179.443L126.149 154.243Z' fill='#28293D' />
            <path
                d='M119.649 82.7427C124.049 82.7427 127.549 82.0427 127.749 81.9427L127.449 80.7427C127.449 80.7427 121.549 81.9427 115.649 81.2427L115.549 82.4427C116.749 82.6427 118.249 82.7427 119.649 82.7427Z'
                fill='#28293D'
            />
            <path
                d='M124.448 93.1427C133.448 93.1427 140.148 92.0427 140.348 91.9427L140.248 91.0427C140.148 91.0427 121.948 94.0427 105.948 90.2427L105.648 91.1427C111.948 92.5427 118.648 93.1427 124.448 93.1427Z'
                fill='#28293D'
            />
            <path
                d='M129.849 111.543C138.549 111.543 145.149 110.643 145.349 110.443L145.249 109.543C144.949 109.543 116.049 113.843 98.9492 104.343L98.4492 105.143C107.549 110.143 120.049 111.543 129.849 111.543Z'
                fill='#28293D'
            />
            <path
                d='M125.549 70.8428C125.549 70.8428 119.249 70.8428 115.049 65.3428L115.349 68.4428C115.349 68.4428 120.449 72.4428 125.549 72.3428C125.649 71.5428 125.549 70.8428 125.549 70.8428Z'
                fill='#644842'
            />
            <path
                d='M99.0488 217.843C101.249 217.843 102.949 217.143 102.949 217.143L102.449 215.943C102.149 216.043 96.7488 218.343 92.4488 213.343L91.5488 214.143C93.9488 217.143 96.7488 217.843 99.0488 217.843Z'
                fill='#28293D'
            />
            <path
                d='M146.949 214.443C147.749 214.443 148.549 214.343 149.249 214.043C153.049 212.543 154.749 207.743 154.949 207.443L153.749 207.043C153.749 207.043 151.949 211.643 148.749 212.843C147.449 213.343 145.949 213.243 144.349 212.543L143.849 213.643C144.749 214.243 145.849 214.443 146.949 214.443Z'
                fill='#28293D'
            />
            <path
                d='M207.749 131.143L198.349 148.643C197.449 149.943 195.349 150.443 194.049 149.443C192.449 148.343 192.149 146.243 193.549 144.843L206.649 130.043L207.749 131.143Z'
                fill='#28293D'
            />
            <path
                d='M208.149 118.243C204.549 120.543 203.449 125.343 205.749 128.843C208.049 132.343 212.749 133.443 216.549 131.143C220.149 128.843 221.249 124.043 218.949 120.543C216.549 116.943 211.749 115.943 208.149 118.243Z'
                fill='#28293D'
            />
            <path
                d='M209.249 119.443C206.449 121.243 205.649 124.843 207.349 127.543C209.149 130.243 212.749 131.043 215.449 129.243C218.249 127.543 219.049 123.843 217.349 121.143C215.549 118.543 211.949 117.743 209.249 119.443Z'
                fill='#54C6BE'
            />
            <path
                d='M212.449 125.243C211.849 124.243 212.249 122.843 213.349 122.143C214.449 121.443 215.949 121.643 216.549 122.643C217.149 123.643 216.749 125.043 215.649 125.743C214.549 126.443 213.149 126.243 212.449 125.243Z'
                fill='white'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M128.349 55.643C128.449 55.243 128.149 54.843 127.749 54.743C127.149 54.643 126.249 54.543 125.449 54.643C124.649 54.743 123.649 55.143 123.149 56.143C122.949 56.543 123.149 56.943 123.549 57.143C123.949 57.343 124.349 57.143 124.549 56.743C124.649 56.443 125.049 56.243 125.649 56.143C126.249 56.043 126.949 56.143 127.449 56.243C127.849 56.343 128.249 56.043 128.349 55.643Z'
                fill='#28293D'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M111.549 59.1427L117.749 58.0427L117.449 56.2427L111.249 57.3427L111.549 59.1427Z'
                fill='#28293D'
            />
            <path
                d='M124.249 58.8429C125.449 56.8429 124.849 54.243 122.849 53.043C120.849 51.843 118.249 52.443 116.949 54.443C115.749 56.443 116.349 59.043 118.349 60.243C120.449 61.443 123.049 60.8429 124.249 58.8429Z'
                fill='#1B8188'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M122.449 53.7429C124.049 54.7429 124.549 56.7429 123.549 58.3429C122.549 59.9429 120.549 60.4429 118.949 59.4429C117.349 58.4429 116.849 56.4429 117.849 54.8429C118.849 53.2429 120.849 52.7429 122.449 53.7429ZM122.949 52.9429C124.949 54.1429 125.549 56.7429 124.349 58.7429C123.149 60.7429 120.449 61.3429 118.449 60.1429C116.449 58.9429 115.849 56.3429 117.049 54.3429C118.349 52.3429 120.949 51.7429 122.949 52.9429Z'
                fill='#28293D'
            />
            <path
                d='M134.249 57.7429C135.449 55.7429 134.849 53.1429 132.849 51.9429C130.849 50.7429 128.249 51.3429 126.949 53.3429C125.749 55.3429 126.349 57.9429 128.349 59.1429C130.449 60.3429 133.049 59.7429 134.249 57.7429Z'
                fill='#1B8188'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M132.449 52.6429C134.049 53.6429 134.549 55.6429 133.549 57.2429C132.549 58.8429 130.549 59.3429 128.949 58.3429C127.349 57.3429 126.849 55.3429 127.849 53.7429C128.749 52.2429 130.849 51.7429 132.449 52.6429ZM132.949 51.9429C134.949 53.1429 135.549 55.7429 134.349 57.7429C133.149 59.7429 130.449 60.3429 128.449 59.1429C126.449 57.9429 125.849 55.3429 127.049 53.3429C128.249 51.3429 130.949 50.7429 132.949 51.9429Z'
                fill='#28293D'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M122.449 60.2426C122.849 60.0426 122.949 59.5426 122.649 59.1426L119.049 53.7426C118.849 53.3426 118.249 53.2426 117.949 53.5426C117.549 53.7426 117.449 54.2426 117.749 54.6426L121.349 60.0426C121.549 60.4426 122.049 60.5426 122.449 60.2426Z'
                fill='#91E1D3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M123.649 58.9429C123.849 58.8429 123.849 58.6429 123.749 58.4429L119.949 52.8429C119.849 52.6429 119.649 52.6429 119.449 52.7429C119.249 52.8429 119.249 53.0429 119.349 53.2429L123.149 58.8429C123.349 59.0429 123.549 59.0429 123.649 58.9429Z'
                fill='#91E1D3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M132.449 59.1425C132.849 58.9425 132.949 58.4425 132.649 58.0425L129.049 52.6425C128.849 52.2425 128.249 52.1425 127.949 52.4425C127.549 52.6425 127.449 53.1425 127.749 53.5425L131.349 58.9425C131.549 59.2425 132.049 59.3425 132.449 59.1425Z'
                fill='#91E1D3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M133.649 57.8428C133.849 57.7428 133.849 57.5428 133.749 57.3428L129.949 51.7428C129.849 51.5428 129.649 51.5428 129.449 51.6428C129.249 51.7428 129.249 51.9428 129.349 52.1428L133.149 57.7428C133.249 57.8428 133.549 57.9428 133.649 57.8428Z'
                fill='#91E1D3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M122.449 53.7429C124.049 54.7429 124.549 56.7429 123.549 58.3429C122.549 59.9429 120.549 60.4429 118.949 59.4429C117.349 58.4429 116.849 56.4429 117.849 54.8429C118.849 53.2429 120.849 52.7429 122.449 53.7429ZM122.949 52.9429C124.949 54.1429 125.549 56.7429 124.349 58.7429C123.149 60.7429 120.449 61.3429 118.449 60.1429C116.449 58.9429 115.849 56.3429 117.049 54.3429C118.349 52.3429 120.949 51.7429 122.949 52.9429Z'
                fill='#28293D'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M132.449 52.6429C134.049 53.6429 134.549 55.6429 133.549 57.2429C132.549 58.8429 130.549 59.3429 128.949 58.3429C127.349 57.3429 126.849 55.3429 127.849 53.7429C128.749 52.2429 130.849 51.7429 132.449 52.6429ZM132.949 51.9429C134.949 53.1429 135.549 55.7429 134.349 57.7429C133.149 59.7429 130.449 60.3429 128.449 59.1429C126.449 57.9429 125.849 55.3429 127.049 53.3429C128.249 51.3429 130.949 50.7429 132.949 51.9429Z'
                fill='#28293D'
            />
        </svg>
    )
}

export default UnauthorizedImage
