import { Box, useTheme } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveProject, getActiveProjectSharedCode } from 'services/selectors/projects-selectors'
import { SharedCode } from 'shared/types/project-types'
import { addSharedCode } from 'services/actions/project-actions'
import NoSharedCode from './NoSharedCode'
import SharedCodeOverview from './SharedCodeOverview'
import { generateNewSharedCode } from 'shared/utils/project-utils'
import { showFlashMessageWithTimeout } from 'services/actions/flashMessage-actions'
import { MAXIMUM_NUMBER_OF_SHARED_TABS } from 'constants/ui'

const SharedCodeScreen: React.FC = () => {
    const dispatch = useDispatch()
    const activeProject = useSelector(getActiveProject)
    const sharedCode = useSelector(getActiveProjectSharedCode)
    const theme = useTheme()

    const addSharedCodeTab = () => {
        if (sharedCode && sharedCode.length >= MAXIMUM_NUMBER_OF_SHARED_TABS) {
            showFlashMessageWithTimeout(dispatch, `Maximum number of tabs is ${MAXIMUM_NUMBER_OF_SHARED_TABS}.`, 'info')
            return
        }

        if (activeProject) {
            const newTab: SharedCode = generateNewSharedCode()
            let newNameSet = false
            let i = 1
            // Generate new name "Tab 1-{MAXIMUM_NUMBER_OF_SHARED_TABS}", based on which is not used yet
            while (!newNameSet) {
                if (sharedCode && sharedCode.findIndex((sc) => sc.name === `Tab${i}`) === -1) {
                    newTab.name = `Tab${i}`
                    newNameSet = true
                }
                i++
            }

            if (sharedCode && sharedCode.length === 0) {
                newTab.content =
                    'export interface SharedDefinition {\n   // You can write properties here\n   // Import and reuse this interface across your endpoints\n}'
            }

            dispatch(addSharedCode(newTab, activeProject))
        }
    }

    return (
        <Box
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(3),
            }}
        >
            {(!sharedCode || sharedCode.length < 1) && <NoSharedCode onAddNewTab={addSharedCodeTab} />}
            {sharedCode && sharedCode.length > 0 && (
                <SharedCodeOverview onAddNewTab={addSharedCodeTab} sharedCode={sharedCode} activeProject={activeProject} />
            )}
        </Box>
    )
}

export default SharedCodeScreen
